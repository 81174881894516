var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "add-sku-modal",
        "modal-class": "add-sku-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Add SKU ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("div", { staticClass: "mw596 mx-auto" }, [
        _c(
          "div",
          {
            class: { file_upload_active: _vm.isbulkactive, file_upload: true }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col py-16 py-md-24 d-flex align-items-center justify-content-between"
                },
                [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500" }, [
                    _vm._v(" Import SKU's file "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass: "icon-disclaimer text-gray-400 fs12 ml-4",
                      attrs: {
                        title:
                          "Please Download the Template from right for Correct Format"
                      }
                    })
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-none d-md-inline-flex",
                      attrs: { variant: "tertiary" },
                      on: { click: _vm.downloadToast }
                    },
                    [
                      _c("i", { staticClass: "icon-download mr-11" }),
                      _vm._v(" Download Template ")
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-inline-flex d-md-none",
                      attrs: { variant: "tertiary" },
                      on: { click: _vm.downloadToast }
                    },
                    [_c("i", { staticClass: "icon-download" })]
                  )
                ],
                1
              )
            ]),
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.uploadFile($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col pb-32" },
                    [
                      _c("b-form-file", {
                        ref: "bulkUpload",
                        staticClass: "bulk-input",
                        class: { "has-file": _vm.active },
                        attrs: {
                          accept: ".xlsx, .xls",
                          placeholder:
                            "Drop your template here or click on the button below",
                          "drop-placeholder":
                            "Drop your template here or click on the button below",
                          id: "bulk-input",
                          disabled: _vm.uploading
                        },
                        model: {
                          value: _vm.files,
                          callback: function($$v) {
                            _vm.files = $$v
                          },
                          expression: "files"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col pt-8 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.uploading,
                            type: "submit",
                            variant: "primary",
                            size: "lg"
                          }
                        },
                        [
                          _vm._v(" Submit "),
                          _vm.uploading
                            ? _c("b-spinner", { staticClass: "sm" })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            class: {
              manual_upload_inactive: _vm.isbulkactive,
              manual_upload: true
            }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col py-16 py-md-24 d-flex align-items-center justify-content-between"
                },
                [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500" }, [
                    _vm._v(" New SKU Detail ")
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-none d-md-inline-flex",
                      attrs: { variant: "tertiary" },
                      on: {
                        click: function($event) {
                          _vm.isbulkactive = !_vm.isbulkactive
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "icon-upload mr-11" }),
                      _vm._v(" Import Bulk ")
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-inline-flex d-md-none",
                      attrs: { variant: "tertiary" },
                      on: {
                        click: function($event) {
                          _vm.isbulkactive = !_vm.isbulkactive
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-upload" })]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.onSubmit($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "sku-field",
                                label: "SKU",
                                "label-for": "sku"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "sku",
                                  placeholder: "- Type SKU Name -"
                                },
                                model: {
                                  value: _vm.form.sku,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "sku", $$v)
                                  },
                                  expression: "form.sku"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "item-name-field",
                                label: "Item Name",
                                "label-for": "item-name"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "item-name",
                                  placeholder: "Type Item Name",
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "item-weight-field",
                                label: "Item Weight(Kg)",
                                "label-for": "item-weight"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "item-weight",
                                  min: this.$store.state.minW,
                                  max: this.$store.state.maxW,
                                  type: "number",
                                  step: ".01",
                                  placeholder: "0.00"
                                },
                                model: {
                                  value: _vm.form.weight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "weight", $$v)
                                  },
                                  expression: "form.weight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mode-field",
                                label: "Mode",
                                "label-for": "weight"
                              }
                            },
                            [
                              _c("multiselect", {
                                attrs: {
                                  id: "mode",
                                  options: _vm.options,
                                  placeholder: "Select mode type",
                                  searchable: false,
                                  "track-by": "name",
                                  label: "name"
                                },
                                model: {
                                  value: _vm.form.mode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "mode", $$v)
                                  },
                                  expression: "form.mode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4 col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "length-field",
                                label: "Length (cm)",
                                "label-for": "length"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  type: "number",
                                  id: "length",
                                  min: this.$store.state.minD,
                                  max: this.$store.state.maxD,
                                  placeholder: "0"
                                },
                                model: {
                                  value: _vm.form.length,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "length", $$v)
                                  },
                                  expression: "form.length"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4 col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "width-field",
                                label: "Width (cm)",
                                "label-for": "width"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  type: "number",
                                  min: this.$store.state.minD,
                                  max: this.$store.state.maxD,
                                  id: "width",
                                  placeholder: "0"
                                },
                                model: {
                                  value: _vm.form.width,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "width", $$v)
                                  },
                                  expression: "form.width"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4 col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "height-field",
                                label: "Height (cm)",
                                "label-for": "height"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  type: "number",
                                  min: this.$store.state.minD,
                                  max: this.$store.state.maxD,
                                  id: "height",
                                  placeholder: "0"
                                },
                                model: {
                                  value: _vm.form.height,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "height", $$v)
                                  },
                                  expression: "form.height"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col py-16" }, [
                        _c("span", [
                          _c("i", {
                            staticClass:
                              "icon-support text-yellow mr-8 vam fs16"
                          }),
                          _vm._v(
                            " Please add the value SKU carefully. It cannot be changed later. "
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _vm.errorFlag || _vm.errormode
                        ? _c(
                            "div",
                            { staticClass: "col pt-8 mt-22 text-left error" },
                            [_vm._v(" " + _vm._s(_vm.error) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col pt-8" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "submit",
                                disabled: _vm.errorFlag,
                                variant: "primary",
                                size: "lg"
                              }
                            },
                            [_vm._v(" Submit ")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }