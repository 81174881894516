<template>
	<b-modal
		id="add-warehouse-modal"
		modal-class="add-sku-modal"
		size="lg"
		hide-footer
		v-model="isOpen"
	>
		<template v-slot:modal-header="{ close }">
			<span v-if="editId" class="d-block mt-auto fs18 lh28 fw500"> Edit Warehouse </span>
			<span v-else class="d-block mt-auto fs18 lh28 fw500"> Add Warehouse </span>
			<b-button variant="rounded" @click="close()">
				<i class="icon-close-variant"></i>
			</b-button>
		</template>

		<b-form class="mw596 mx-auto" @submit.prevent="onSubmit">
			<div class="row underline-dashed">
				<div class="col-sm-12 col-md-6 py-40">
					<span class="fs14 lh16 text-gray-600 mr-20"> Default Pickup </span>
					<b-form-checkbox
						class="d-inline mr-40"
						name="default-pickup"
						v-model="form.defaultPickup"
						switch
						@change="checkPickup(form.defaultPickup)"
					></b-form-checkbox>
				</div>
				<div class="col-sm-12 col-md-6 py-40">
					<span class="fs14 lh16 text-gray-600 mr-20"> Default Billing </span>
					<b-form-checkbox
						class="d-inline"
						name="default-pickup"
						v-model="form.defaultBilling"
						switch
						@change="checkBilling(form.defaultBilling)"
					></b-form-checkbox>
				</div>
				<div v-if="errorText" style="color:#fa5a5a !important" class="col-md-12 col-md-6 py-10 text-gray-600"> <strong>NOTE</strong> :  {{errorMsg}}</div>
			</div>


			<div class="row pt-36">
				<div class="col-md-3 col-12">
					<b-form-group
						id="address-name-field"
						label="Address Name *"
						label-for="address-name"
					>
						<b-form-input
							type="text"
							id="address-name"
							maxlength="35"
							required
							v-model="form.addressName"
							placeholder="Address Name"
						></b-form-input>
					</b-form-group>
				</div>
				<div class="col-md-3 col-12">
					<b-form-group
						id="vendor-name-field"
						label="Vendor Name"
						label-for="vendor-name"
					>
						<b-form-input
							type="text"
							id="vendor-name"
							placeholder="Vendor Name"
							v-model="form.vendorName"
							maxlength="35"
						></b-form-input>
					</b-form-group>
				</div>
				<div class="col-md-3 col-12">
					<b-form-group
						id="pin-code-field"
						label="Pin Code *"
						label-for="pin-code"
					>
						<b-form-input
							type="text"
							id="pin-code"
							@keyup="mobileValidation($event.target.value,'pin')"
							placeholder="Type Pin"
							v-model="form.sellerPinCode"
							required
							:formatter="numbers"
							maxlength="6"
							minlength="6"
							:state="pincode"
						></b-form-input>
					</b-form-group>
				</div>
				<div class="col-md-3 col-12">
					<b-form-group id="city-field" label="City *" label-for="city">
						<b-form-input
							type="text"
							id="city"
							required
							placeholder="City"
							maxlength="35"
							v-model="form.sellerCity"
						></b-form-input>
					</b-form-group>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4 col-12 pt-8">
					<b-form-group
						id="contact-name-field"
						label="Contact Name *"
						label-for="contact-name"
					>
						<b-form-input
							type="text"
							id="contact-name"
							maxlength="35"
							required
							v-model="form.contactName"
							placeholder="Contact Name"
						></b-form-input>
					</b-form-group>
				</div>
				<div class="col-md-4 col-12 pt-8">
					<b-form-group
						id="contact-one-field"
						label="Contact No.1 *"
						label-for="contact-one"
					>
						<b-form-input
							type="text"
							id="contact-one"
							v-model="form.contactNumber1"
							@keyup="mobileValidation($event.target.value,'contact1')"
							:formatter="numbers"
							maxlength="10"
							minlength="10"
							required
							placeholder="Contact Number"
							:state="isMobile"
						></b-form-input>
					</b-form-group>
				</div>
				<div class="col-md-4 col-12 pt-8">
					<b-form-group
						id="contact-two-field"
						label="Contact No.2"
						label-for="contact-two"
					>
						<b-form-input
							type="text"
							id="contact-two"
							@keyup="mobileValidation($event.target.value,'contact2')"
							v-model="form.contactNumber2"	
							pattern="[6-9]{1}[0-9]{9}"
							oninvalid="this.setCustomValidity('Enter valid mobile number.')"
                      		oninput="this.setCustomValidity('')"
							:formatter="numbers"
							maxlength="10"
							minlength="10"
							placeholder="Contact Number"
							:state="isMobile1"
						></b-form-input>
					</b-form-group>
				</div>
			</div>


			<div class="row">
				<div class="col pt-8">
					<b-form-group
						id="address-field"
						label="Address *"
						label-for="address"
						:description="'Characters: ' + form.sellerAddressLine1.length + ' / ' + maxLength">
						<b-form-input
							type="text"
							id="address"
							v-model="form.sellerAddressLine1"
							required
							:disabled="doEdit"
							placeholder="Add Address"
							:maxlength="maxLength"
                        	rows="3"
                        	max-rows="6"
                        	required
						></b-form-input>
					</b-form-group>
				</div>
			</div>

			<!-- <div class="row">
				<div class="col-sm-12 col-md-6 py-20">
					<span class="fs14 lh16 text-gray-600 mr-20"> Hyperlocal </span>
					<b-form-checkbox
						class="d-inline"
						name="hyper-local"
						v-model="form.hyperlocal"
						switch
					></b-form-checkbox>
				</div>
			</div>

			<div class="row" v-if="form.hyperlocal">
				<div class="col pt-8">
					<b-form-group
						id="location-field"
						label="Locaton"
						label-for="location"
					>
						<b-form-input
							type="text"
							id="location"
							placeholder="- Type Location -"
							v-model="form.customerCoordinates"
						></b-form-input>
					</b-form-group>
				</div>
			</div> -->

			<div class="row" v-if="form.hyperlocal">
				<div class="col py-12">
					<!-- <GoogleMap :mapClass="warehouse" :lat="lat" :lng="lng"></GoogleMap> -->
				</div>
			</div>

			<div class="row" v-if="sent">
				<div class="col-md-4 col-12 pt-8">
					<b-form-group id="otp-field" label="Enter OTP *" label-for="otp">
						<b-form-input
							type="text"
							id="otp"
							v-model="form.otp"
							placeholder="- OTP -"
							autofocus
							required
							:formatter="maxsix"
						></b-form-input>
					</b-form-group>
				</div>
				<div class="col-md-4 col-12 pt-30">
					<b-button variant="primary" :disabled="!resend" @click="resendOtp" size="lg" class="mr-16"> {{ btns.resend }}</b-button>
				</div>
			</div>
			<div class="row">
				<div class="col pt-20 d-none d-md-flex">
					<b-button type="submit" :disabled="submitting || !isMobile || !pincode" variant="primary" size="lg" class="mr-16">
						<span v-if="sent"> {{ btns.verify }} </span>
						<span v-else> {{ btns.otp }} </span>
						<b-spinner v-if="submitting" class="ml-10"></b-spinner>
					</b-button>
					<b-button @click="hideModal" variant="secondary" size="lg">
						Cancel
					</b-button>
				</div>
				<div class="col pt-20 d-flex d-md-none">
					<b-button  @click="hideModal" variant="secondary" size="lg">
						Cancel
					</b-button>
					<b-button
						type="submit"
						variant="primary"
						size="lg"
						class="ml-16 w-sm-100"
						:disabled="submitting || !isMobile || !pincode"
					>
						<span v-if="sent"> {{ btns.verify }} </span>
						<span v-else> {{ btns.otp }} </span>
					</b-button>
				</div>
			</div>
		</b-form>
	</b-modal>
</template>

<script>
// import GoogleMap from "@/components/GoogleMap.vue";

export default {
	name: "SettingsWarehousesAddNew",
	props:['editItem'],
	data() {
		return {
			sellerAddressLine1: "",
			maxLength: "105",
			lat: 27.05,
			lng: 10.32,
			isOpen: this.modalOpen,
			warehouse: "warehouse",
			sent: false,
			form: {
				hyperlocal: false,
				sellerAddressLine1: '',
				defaultPickup:false,
				defaultBilling:false
			},
			isMobile: undefined,
			isMobile1: undefined,
			submitting: false,
			btns: {
				otp: 'Send OTP',
				verify: 'Verify & Add',
				resend:'Resend OTP'
			},
			editId: '',
			pincode:undefined,
			resend:false,
			count:0,
			errorText:false,
			setBilling:null,
			setPickup:null,
			errorMsg:null,
			doEdit:false,
		};
	},
	// components: {
	// 	GoogleMap,
	// },
	methods: {
		checkPickup(pickup) {
			if(this.setPickup == true) {
				this.errorText = !this.setBilling && !pickup ? true : false
				this.errorMsg = this.errorText ? "Defalut Pickup cannot be turned off, You can update this with other warehouse" : " "
				console.log(this.errorText,this.errorMsg,'pickup error')
			}
		},
		checkBilling(billing) {
			if(this.setBilling == true) {
				this.errorText = !this.setPickup && !billing ? true : false
				this.errorMsg = this.errorText ? "Defalut Billing cannot be turned off, You can update this with other warehouse" : " "
				console.log(this.errorText,this.errorMsg,'billing error')
			}
		},
		async resendOtp() {
			this.count += 1
			if (this.count > 1) {
				this.popToast("failed", "Failed!", "Too many Attempts!! Please Try Again");
				this.hideModal();
			} else {
				try {
				this.btns.resend = 'Sending'
				const response = await this.axios.post("/settings/warehousesOTP/sendcontactotp", { contact: this.form.contactNumber1, type: 4 });
				
				if (response.data.success) {
					this.popToast("booked", "OTP Resent!", response.data.message);
					this.btns.resend = 'Resend OTP'
					this.resend = false
					setTimeout(() => {
						this.resend = true
						this.form.otp = ''
					},30000)
				} else {
					this.popToast("failed", "Failed!", response.data.message);
				}
			} catch (error) {
				console.error(error);
			}
			}
			
		},
		async onSubmit() {
			this.form.sellerCompanyName = this.$store.getters.user.sellerCompanyName

			if (this.sent == false) {
				try {
					this.submitting = true
					this.btns.otp = 'Sending'
					const response = await this.axios.post("/settings/warehousesOTP/sendcontactotp", { contact: this.form.contactNumber1, type: 4 });
					
					if (response.data.success) {
						this.sent = true;
						this.popToast("booked", "OTP Sent!", response.data.message);
						this.form.otp = ''
						this.btns.otp = 'Send OTP'
						this.submitting = false
						setTimeout(() => {
							this.resend = true
							this.form.otp = null
						},30000)
					} else {
						this.form.otp = ''
						this.btns.otp = 'Resend OTP'
						this.submitting = false
						this.popToast("failed", "Failed!", response.data.message);
					}
				} catch (error) {
					console.error(error);
				}
			} else {

				try {
					this.submitting = true
					this.btns.verify = 'Verifying'
					let res = {}
					if(this.editId) {
						delete this.form.editId
						let newData = Object.assign({}, this.form)
						if(newData.defaultBilling == false) {
							delete newData.defaultBilling
						} 
						if (newData.defaultPickup == false) {
							delete newData.defaultPickup
						}
						res = await this.axios.post(`/settings/warehouses/${this.editId}`, newData);
					}else{
						res = await this.axios.post("/settings/warehouses/new", this.form);
					}
					if (res.data.success) {
						this.popToast("booked", "Success!", res.data.message);
						let warehouses = await this.axios.get('/warehouse/list')
						this.$store.commit('setWarehouses', warehouses.data)
						if(this.editId) {
							this.$parent.getItems()
							this.$emit('update:editId', null)
						}else{
							this.$parent.getItems()
						}
						this.hideModal();
					} else {
						this.popToast("failed", "Failed!", res.data.message);
					}

					this.submitting = false
					this.btns.verify = 'Verify & Add'
				
				} catch (error) {
					console.error(error);
				}
			}
		},
		hideModal() {
			this.sent = false
			this.submitting = false
			this.form = {
				hyperlocal: false,
				sellerAddressLine1: ''
			}
			this.isMobile = undefined
			this.isMobile1 = undefined
			this.pincode = undefined
			this.$emit('update:editItem', {})
			this.$bvModal.hide("add-warehouse-modal");
		},
		mobileValidation(val,con) {
	      if((val || val == '') && con === "contact1") {
				if(val.match(/^[6-9]{1}[0-9]{9}$/)){
					this.isMobile = true
				}else if (val == ""){
					this.isMobile = undefined
				} else {
		          	this.isMobile = false
		        }
			}
			 else if((val || val == '') && con === "contact2") {
			      if(val.match(/^[6-9]{1}[0-9]{9}$/)){
			        this.isMobile1 = true
			      }else if(val == ''){
			        this.isMobile1 = undefined
			      } else {
			        this.isMobile1 = false
			      }
	        } else if ((val || val == '') && con === "pin") {
		          if(val.match(/^[0-9]{6}$/)){
		            this.pincode = true
		          }else if(val == ''){
		            this.pincode = undefined
		          } else {
		            this.pincode = false
		          }
		      } else {

		      }
      	},
		maxten(e){
			return String(e).substring(0, 10);
		},
		maxsix(e){
			return String(e).substring(0, 6);
		},
		async formatting(data) {
			try {
				const res = await this.axios.get(`/settings/warehouses/${data.addressId}`)
				const warehouse = res.data.warehouse
				this.setBilling = warehouse.defaultBilling ? true : false
				this.setPickup = warehouse.defaultPickup ? true : false
				const address = JSON.parse(warehouse.address)
				const editItem = {
					id: warehouse.id,
					sellerCompanyName: this.$store.getters.user.sellerCompanyName,
					addressName: warehouse.name,
					vendorName: warehouse.vendorName,
					sellerAddressLine1: address.address1+' '+address.address2+' '+address.address3,
					sellerPinCode: warehouse.pincode,
					sellerCity: address.city,
					contactName: address.contactname,
					contactNumber1: warehouse.contactOne,
					contactNumber2: warehouse.contactTwo,
					defaultPickup: warehouse.defaultPickup ? true : false,
					defaultBilling: warehouse.defaultBilling ? true : false,
					customerCoordinates: warehouse.lng?warehouse.lng+','+warehouse.lat:''
				}
				this.form = editItem
			} catch (error) {
				console.log(error)
			}
		} 
	},
	watch: {
		isOpen: function(newValue) {
			if(newValue == false) {
				this.hideModal()
				this.resend = true
				this.editId = null
				this.errorText = false
				this.errorMsg = null
				this.doEdit = false
			}
		},
		editItem:function(newValue) {
			if(newValue.addressId) {
				this.editId = newValue.addressId
				this.formatting(newValue)
				this.isMobile = true
				this.pincode = true
				this.resend = false	
				this.doEdit = true
			}
		}
	}
};
</script>
