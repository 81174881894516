var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "edit-sku-modal",
        "modal-class": "add-connector-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Edit SKU ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.editSkuform($event)
                }
              }
            },
            [
              _c("div", { staticClass: "mw596 mx-auto" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "sku-field",
                            label: "SKU",
                            "label-for": "sku"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "sku", value: "", disabled: "" },
                            model: {
                              value: _vm.form.sku,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sku", $$v)
                              },
                              expression: "form.sku"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "product-name-field",
                            label: "Product Name",
                            "label-for": "product-name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              id: "product-name",
                              maxlength: "100",
                              value: "BreatheFree 6x Oxygen Ampule Package"
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4 col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "length-field",
                            label: "Length (cm)",
                            "label-for": "length"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              type: "number",
                              min: this.$store.state.minD,
                              max: this.$store.state.maxD,
                              id: "length",
                              placeholder: "0"
                            },
                            model: {
                              value: _vm.form.length,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "length", $$v)
                              },
                              expression: "form.length"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-4 col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "width-field",
                            label: "Width (cm)",
                            "label-for": "width"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              type: "number",
                              min: this.$store.state.minD,
                              max: this.$store.state.maxD,
                              id: "width",
                              placeholder: "0"
                            },
                            model: {
                              value: _vm.form.width,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "width", $$v)
                              },
                              expression: "form.width"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-4 col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "height-field",
                            label: "Height (cm)",
                            "label-for": "height"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              type: "number",
                              id: "height",
                              min: this.$store.state.minD,
                              max: this.$store.state.maxD,
                              placeholder: "0"
                            },
                            model: {
                              value: _vm.form.height,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "height", $$v)
                              },
                              expression: "form.height"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "weight-field",
                            label: "Weight (kg)",
                            "label-for": "weight"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              type: "number",
                              id: "weight",
                              step: ".01",
                              min: this.$store.state.minW,
                              max: this.$store.state.maxW,
                              placeholder: "0.00"
                            },
                            model: {
                              value: _vm.form.weight,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "weight", $$v)
                              },
                              expression: "form.weight"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "mode-field",
                            label: "Mode",
                            "label-for": "mode"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              id: "mode",
                              options: _vm.options,
                              searchable: false,
                              "track-by": "name",
                              label: "name"
                            },
                            model: {
                              value: _vm.form.mode,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "mode", $$v)
                              },
                              expression: "form.mode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm.errorFlag || _vm.errormode
                    ? _c(
                        "div",
                        { staticClass: "col pt-8 mt-26 text-left error" },
                        [_vm._v(" " + _vm._s(_vm.error) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col pt-16 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            size: "lg",
                            disabled: _vm.errorFlag,
                            type: "submit"
                          }
                        },
                        [_vm._v("Update SKU")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }