<template>
  <div class="card mt-24">
    <div class="card__content">
      <PriorityInfoCard />
    </div>
  </div>
</template>

<script>
import PriorityInfoCard from "@/components/PriorityInfoCard.vue";

export default {
  name: "ModelessCheapest",
  components: { PriorityInfoCard },
};
</script>

<style></style>