<template>
	<b-modal
	id="password-modal"
	modal-class="password-modal"
	size="lg"
	hide-footer
	v-model="isOpen"
	ref="changePassword"
	>
	<template v-slot:modal-header="{ close }">
		<span class="d-block mt-auto fs18 lh28 fw500">
			Change {{ type }} Password
		</span>
		<b-button variant="rounded" @click="close()">
			<i class="icon-close-variant"></i>
		</b-button>
	</template>

	<b-form @submit.prevent="validatePassword" class="mw596 mx-auto">
		<div class="row">
			<div class="col-md-6 col-12">
				<span class="d-block fs16 lh19 fw500 mb-12">
					1. Current Password
				</span>
				<span class="fs14 lh16 text-gray-600">
					Type your current password below
				</span>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6 col-12">
				<b-form-group
				id="current-password-field"
				label="Current Password"
				label-for="current-password"
				class="mt-36 mb-52"
				>
				<b-input-group>
					<b-form-input
					id="current-password"
					placeholder="********"
					v-model="password.password"
					type="password"
					autofocus
					required
					></b-form-input>
				</b-input-group>
			</b-form-group>
		</div>
	</div>

	<div class="row">
		<div class="col">
			<span class="d-block fs16 lh19 fw500 mb-12">
				2. New Password
			</span>
			<span class="fs14 lh16 text-gray-600">
				Type your new password below and confirm
			</span>
		</div>
	</div>

	<div class="row">
		<div class="col-md-6 col-12">
			<b-form-group
			id="new-password-field"
			label="New Password"
			label-for="new-password"
			class="mt-36 mb-48">
			<b-form-input
			id="new-password"
			type="password"
			placeholder="********"
			minlength="8"
			v-model="password.newPassword"
			:state="valid"
			@keyup="validate"
			required
			></b-form-input>
			<span v-if="!valid && password.newPassword.length>2" class="text-red small">Minimum 8 characters required</span>
		</b-form-group>
	</div>
	<div class="col-md-6 col-12">
		<b-form-group
		id="confirm-password-field"
		label="Confirm new Password"
		label-for="confirm-password"
		class="mt-36 mb-48"
		>
		<b-form-input
		id="confirm-password"
		type="password"
		placeholder="********"
		minlength="8"
		v-model="password.verifyPassword"
		:state="match"
		@keyup="matching"
		required
		></b-form-input>
		<span v-if="!match && password.verifyPassword.length>2" class="text-red small">New and confirm password doesn't match</span>
	</b-form-group>
</div>
</div>

<div class="row">
	<div class="col text-right text-md-left">
		<b-button
		:disabled="!match"
		variant="primary"
		type="submit"
		size="lg"
		>
		Update
	</b-button>
</div>
</div>
</b-form>
</b-modal>
</template>

<script>
export default {
	name: "SettingsPasswordModal",
	props: ['passpath'],
	data() {
		return {
			state: null,
			isOpen: this.modalOpen,
			type: '',
			match: null,
			valid: null,
			password: {
				password: '',
				newPassword: '',
				verifyPassword: ''
			},
		};
	},
	methods: {
		validate() {
			if(this.password.newPassword.length >= 8 && this.password.newPassword == this.password.verifyPassword) {
				this.match = true
			}else {
				this.match = false
			}
			if(this.password.newPassword.length >= 8) {
				this.valid = true
			} else {
				this.valid = false
			}
		},
		matching() {
			if(this.password.newPassword.length >= 8 && this.password.newPassword == this.password.verifyPassword) {
				this.state = null
				this.match = true
			}else {
				this.match = false
			}
		},
		async validatePassword() {
			if (this.password.newPassword != this.password.verifyPassword) {
				this.state = false;
			} else if (this.password.newPassword == this.password.verifyPassword) {
				try {
					let post = {}
					if(this.type == 'Profile') {
						post = {
							profile_password: this.password.password,
							profile_newPassword: this.password.newPassword,
							profile_verifyPassword: this.password.verifyPassword,
						}
					}else{
						post = this.password
					}
					const res = await this.axios.post(`/settings/profile/${this.passpath}`, post)
					if(res.data.success == true) {
						this.popToast("booked", "Success!",res.data.message);
						this.state = true;
						setTimeout(() => {
							this.$bvModal.hide("password-modal")
							this.$store.dispatch('logout')
						},2000);
					}else{
						this.popToast("failed", "Failed",res.data.message);
					}
				} catch(e) {
					console.log(e);
				}
			}
		},
	},
	watch: {
		passpath: function(newValue) {
			if(newValue == 'updatepassword'){
				this.type = 'Login'
			}else if(newValue == 'profilepassword'){
				this.type = 'Profile'
			}
		}
	}
};
</script>
