<template>
	<b-modal
	id="gms-connector-modal"
	modal-class="gms-connector-modal"
	size="lg"
	hide-footer
	v-model="isOpen"
	ref="gmsConnector"
	>
	<template v-slot:modal-header="{ close }">
		<span class="d-block mt-auto fs18 lh28 fw500">
			GMAS Credentials
		</span>
		<b-button variant="rounded" @click="close()">
			<i class="icon-close-variant"></i>
		</b-button>
	</template>
	<div class="mw596 mx-auto">
		<div class="row">
			<div
			class="col pt-30 pb-16 d-flex justify-content-between align-items-center"
			>
			<span class="fs16 fw500">
				Please Enter Your GMAS Username and Password
			</span>
		</div>
	</div>

	<b-form @submit.prevent="gmasConnect()">

		<div class="row">
			<div class="col-md-6 pt-8">
				<b-form-group
				id="mws-token-field"
				label="Username:"
				label-for="username"
				>
				<b-form-input
				required
				v-model="form.username"
				id="username"
				placeholder="Username"
				></b-form-input>
				</b-form-group>
			</div>
		<div class="col-md-6 pt-8">
			<b-form-group
			id="mws-token-field"
			label="Password:"
			label-for="password"
			>
			<b-form-input
			required
			id="password"
			type="password"
			v-model="form.password"
			placeholder="Password"
			></b-form-input>
		</b-form-group>
			</div>
		</div>


		<div class="row">
			<div class="col-md-6 py-16">
				<b-form-group label="Get Orders">
					<b-form-radio name="get-orders" v-model="form.isactive" value="1" class="d-inline-block mr-20">
						Enable
					</b-form-radio>
					<b-form-radio name="get-orders" v-model="form.isactive" value="0" class="d-inline-block">
						Disable
					</b-form-radio>
				</b-form-group>
			</div>
		</div>


		<div class="row">
			<div class="col pt-12 pb-8 d-none d-md-block">
				<b-button v-if="sellerDisable" type="submit" variant="primary mr-16" size="lg">
		          Update
		        </b-button>
		        <b-button v-else type="submit" variant="primary mr-16" size="lg">
		          Create
		        </b-button>
		        <b-button @click="hideModal" size="lg">Cancel</b-button>
			</div>
			<div class="col pt-12 pb-8 d-flex d-md-none">
				<b-button @click="hideModal" size="lg">Cancel</b-button>
			      <b-button v-if="sellerDisable"  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			        Update
			      </b-button>
			      <b-button v-else type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			        Create
			      </b-button>
			</div>
		</div>
	</b-form>
</div>
</b-modal>
</template>

<script>
export default {
	name: "SettingsShopifyConnector",
	props:{
		editGId : Number
	},
	data() {
		return {
			isOpen: this.modalOpen,
			form:{},
			sellerDisable:false
		}
	},
	methods: {
		hideModal() {
			this.$bvModal.hide("gms-connector-modal");
		},
		async gmasConnect(){
			const editres = await this.axios.post('/settings/connectors/gmas', this.form)
			try {
				if (editres.data.result.response.success) {
					this.popToast("booked", "Success!", editres.data.result.response.message);
					setTimeout(() => {
						this.$router.go()
					},2000)
				} else {
					this.popToast("booked", "Success!", editres.data.result.response.error);
				}
			} catch(e) {
				this.popToast("failed", "Failed!", "Updation Failed Please try again later!!!");
				console.log(e);
			}
		}
		,
		async formatting(id) {
			const res = await this.axios.get(`/settings/connector/${id}`)
			try {
				if (res.data.success) {
					const editConn = {
						id:res.data.result[0].id,
						username:res.data.result[0].key1,
						password:res.data.result[0].key2,
						isactive:res.data.result[0].isactive
					}
					this.form = editConn
				} else {
					this.form = {}
					this.popToast("failed", "Failed!", "Data Fetching Failed Please try again later!!!");
				}
			} catch(e) {
				this.popToast("failed", "Failed!", "Data Fetching Failed Please try again later!!!");
				console.log(e);
			}
		}
	},
	watch: {
		isOpen(newValue){
			if(newValue){
				if(this.editGId) {
					this.formatting(this.editGId)
					this.sellerDisable = true
				} else {
					this.form = {}
					this.sellerDisable = false
				}
			} else {
				this.$emit('update:editGId', undefined)
			}
		}
	}
};
</script>

<style lang="scss">
#btn-disable {
	position: absolute;
	right: rem(-88px);
	@include media-breakpoint-down(sm) {
		right: 0;
	}
}
</style>
