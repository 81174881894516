<template>
  <b-modal
    id="add-sku-modal"
    modal-class="add-sku-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
  >
    <template v-slot:modal-header="{ close }">
      <span  class="d-block mt-auto fs18 lh28 fw500"> Add New Customer </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <div class="mw596 mx-auto">
      <div class="pt-md-25 pt-25 pb-md-10 pb-10">
        <div class="form">
          <b-form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group id="sku-field" label="Name" label-for="sku">
                  <b-form-input
                    required
                    id="sku"
                    type="text"
                    maxlength="35"
                    v-model="form.name"
                    placeholder="Enter customer name"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-name-field"
                  label="Email (Optional)"
                  label-for="item-name"
                >
                  <b-form-input
                    id="item-name"
                    v-model="form.email"
                    type="text"
                    title="Only valid email id is allowed."
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    placeholder="Enter customer email"
                    maxlength="100"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-weight-field"
                  label="Contact Number"
                  label-for="item-weight"
                >
                  <b-form-input
                    required
                    id="item-weight"
                    type="text"
                    maxlength="10"
                    minlength="10"
                    :formatter="numbers"
                    pattern="[6-9]{1}[0-9]{9}"
                    v-model="form.contact"
                    placeholder="8888888888"
                  ></b-form-input>
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <i class="icon-phone fs16 text-gray-600"></i>
                    </div>
                  </div>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-city-field"
                  label="City"
                  label-for="item-weight"
                >
                  <b-form-input
                    required
                    id="item-city"
                    type="text"
                    maxlength="35"
                    v-model="form.city"
                    placeholder="Enter city"
                  ></b-form-input>
                </b-form-group>
              </div>
              
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-pincode-field"
                  label="Pincode"
                  label-for="item-pincode"
                >
                  <b-form-input
                    required
                    id="item-pincode"
                    type="text"
                    minlength="6"
                    :formatter="numbers"
                    maxlength="6"
                    pattern="[0-9]{6,}"
                    v-model="form.pincode"
                    placeholder="110001"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-source-field"
                  label="Source"
                  label-for="item-source"
                >
                  <b-form-input
                    id="item-source"
                    type="text"
                    disabled
                    v-model="source"
                  ></b-form-input>
                </b-form-group>
              </div>

              
            </div>

            <div class="row">
              <div class="col-md-12 col-12">
                <b-form-group
                  id="item-address-field"
                  label="Address"
                  label-for="item-address"
                  :description="
                      'Characters: ' +
                        form.address.length +
                        ' / ' +
                        this.maxLength
                    "
                >
                  <b-form-textarea
                    required
                    id="item-address"
                    type="text"
                    v-model="form.address"
                    placeholder="Enter customer address"
                    rows="3"
                    max-rows="6"
                    maxlength="140"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col py-16">
                <span>
                  <i class="icon-support text-yellow mr-8 vam fs16"></i>
                  Please add the Customer Details carefully. It cannot be changed
                  later.
                </span>
              </div>
            </div>

            <div class="row pt-15">
              <div class="col-12">
                <b-button type="submit" :disabled="errorFlag" variant="primary" size="lg">
                  <b-spinner v-if="submitting"></b-spinner>
                  <span >Add</span>
                </b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>

    
    </div>

  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";

export default {
  name: "AddNewCustomer",
  data() {
    return {
      maxLength:140,
      submitting:false,
      source:'Manually Created',
      form: {
        address:'',
      },
      generatPassword:false,
      files: null,
      active: false,
      browseText: "Browse",
      isOpen: this.modalOpen,
      itemAdded: false,
      isbulkactive: false,
      uploading: false,
      fileUpload: false,
      options: ['ADMIN','OPERATIONS','FINANCE'],
      error:"",
      errorFlag:false,
      errormode:false,
      };
  },
  components: {
    Multiselect,
    draggable,
  },
  beforeCreate() {
    this.form = {};
  },
  methods: {
    async onSubmit() {
      const data = Object.assign({}, this.form)
      data['source'] = 0

      this.submitting = true

      try {
              const res = await this.axios.post('/v4/customerAddressBook',data)
              if (res.data.success) {
                this.popToast("booked", "Success", res.data.message);
                this.isOpen = false;
                this.$parent.getItems()
              } else {
                this.popToast("failed", "Failed!!", res.data.message);
              }
            } catch(e) {
              this.popToast("failed", "Failed!!", "Something went wrong!! Please Try Later");
              console.log(e);
            }      

      this.submitting = false
    },
    hideModal() {
      this.form = {
        address: ''
      };
      this.$bvModal.hide("add-user-modal");
    },
  },
  watch: {
    isOpen: function (newValue) {
        if (newValue == false) {
          this.hideModal();
        }
    }
  },
};
</script>

<style scoped>
.input-group-text{
  height: 2.75rem !important;
  top: 26px !important;
  right: 7px !important;
}
.file_upload {
  display: none;
}
.file_upload_active {
  display: block;
}
.manual_upload_inactive {
  display: none;
}

.error{
  background: #ffa6a6;
  height: 2.1rem;
  color: red;
  border: 1px #ffa6a6;
  border-radius: 5px;
}
.addUser {
  border-bottom: 2px solid #c8ced6;
}

.add-user-modal .modal-header{
  padding: 1rem 1.5rem 1rem 2rem !important;
  border-bottom: 2px solid #f5f7fa !important;
}

/deep/ .form-control:disabled, .form-control[readonly] {
    color: #7a828f !important;
  }

@media only screen and (max-device-width: 400px) {
    .error {
      height: 3.1rem !important;
    }
  }
</style>
