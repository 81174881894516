<template>
	<div class="container-fluid pb-80 pb-md-60 pb-lg-0">
		<div class="row">
			<div class="col-lg-8">

			<div class="card profile mb-18">
				<div class="card__title">
					<span class="fs18 fw500">
						Tracklite Settings
					</span>
					<b-link href="https://tracklite.in/" target="_new" class="fs14 lh16">
						Visit Tracklite <i class="icon-share text-blue ml-4 fs12"></i>
					</b-link>
				</div>
				<div class="card__content">
					<b-form @submit.prevent="submitTracklite" class="mw596 mx-auto">
						<div class="row mt-8 mb-32 mb-md-40">
							<div class="col-md-4 pb-md-0 pb-32">
								<div class="rounded border text-center d-inline-block w-sm-100">
									<img :src="logo" class="p-44 w-100" />
								</div>
							</div>
							<div class="col-md-8 d-flex flex-column justify-content-center">
								<span class="d-block fs16 lh19 mb-12 fw500">
									Upload Logo
								</span>
								<span class="d-block fs14 lh20 mb-20 text-gray-600">
									The logo size should be at least 96px x 96px and at most 1024px x 1024px. The recommended ratio is 3:1 (288px x 96px). The allowed file formats are <code v-pre>jpg, png, webp and svg.</code>
								</span>
								<b-form-file ref="file" accept=".jpg, .png, .webp, .svg" @change="validate" v-model="tracklite.file" class="d-none" plain></b-form-file>
								<b-button @click="popInput" variant="tertiary">
									{{ tracklite.file ? tracklite.file.name : 'Choose File' }}
								</b-button>
								<small v-if="fileerror" class="mt-5 text-danger">
									{{ fileerror }}
								</small>
								<small v-if="valid" class="mt-5 text-orange">
									{{ valid }}
								</small>
							</div>
						</div>

						<div class="row mb-24 mb-md-16">
							<div class="col-md-6 mb-24 mb-md-0">
								<b-form-group id="brand-name-field" class="mb-0 w-100" label="Brand Name" label-for="brand">
									<b-input-group>
										<b-form-input
										id="brand"
										type="text"
										minlength="3"
										maxlength="30"
										trim
										placeholder="Must be less than and equal to 30 characters"
										v-model="tracklite.brandName"
										></b-form-input>
									</b-input-group>
								</b-form-group>
							</div>

							<div class="col-md-6">
								<b-form-group
								id="website-field"
								class="mb-0 w-100"
								label="Your Website URL"
								label-for="website"
								>
								<b-input-group>
									<b-form-input
									id="website"
									type="url"
									maxlength="250"
									placeholder="https://example.com"
									pattern="^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$"
									v-model="tracklite.website"
									></b-form-input>
									<div class="input-group-append">
										<div class="input-group-text">
											<i class="icon-url fs16 text-gray-600"></i>
										</div>
									</div>
								</b-input-group>
							</b-form-group>
						</div>
					</div>

					<div class="row mb-24 mb-md-16">
						<div class="col">
							<b-form-group
								id="support-email-field"
								class="mb-0 w-100"
								label="Support Email"
								label-for="support-email"
								>
								<b-input-group>
									<b-form-input
										id="support-email"
										type="email"
										placeholder="support@example.com"
										pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
										autocomplete="off"
										v-model="tracklite.supportEmail"
									></b-form-input>
									<div class="input-group-append">
										<div class="input-group-text">
											<i class="icon-mail fs16 text-gray-600"></i>
										</div>
									</div>
								</b-input-group>
							</b-form-group>
						</div>
					</div>

					<div class="row mb-24 mb-md-16">
						<div class="col">
							<b-form-group
							id="support-contact-field"
							label="Support Contact"
							label-for="support-contact"
							class="mb-0 w-100"
							>
								<b-input-group>
									<b-form-input
									id="support-contact"
									v-model="tracklite.supportContact"
									type="tel"
									maxlength="10"
									ref="supportContact"
									placeholder="8888888888"
									oninvalid="this.setCustomValidity('Enter valid mobile number.')"
									oninput="this.setCustomValidity('')"
									pattern="^[6-9]{1}[0-9]{9}$"
									></b-form-input>
									<div class="input-group-append">
										<div class="input-group-text">
											<i class="icon-phone fs16 text-gray-600"></i>
										</div>
									</div>
								</b-input-group>
							</b-form-group>
						</div>
					</div>

					<div class="row mb-24 mb-md-16">
						<div class="col">
							<b-form-group
							id="url-tag-field"
							label="Custom URL Tag"
							label-for="url-tag"
							class="mb-0 w-100"
							>
							<b-input-group>
								<b-form-input
								v-model="tracklite.urltagtracklite"
								type="text"
								minlength="3"
								maxlength="30"
								@keyup="validateCustomUrlTag(tracklite.urltagtracklite)"
								:formatter="removeSpace"
								trim
								placeholder="Must be less than and equal to 30 characters"
								></b-form-input>
								<div class="input-group-append">
									<div class="input-group-text">
										<b-button @click.prevent="copyUrl" size="xxs" variant="tertiary">Copy</b-button>
									</div>
								</div>
							</b-input-group>
						</b-form-group>
						<input type="hidden" id="url-tag" :value="'https://tracklite.in/c/'+tracklite.urltagtracklite">
						<small>https://tracklite.in/c/{{ tracklite.urltagtracklite }}</small>
						<span v-html="customURLTag"></span>
					</div>
				</div>

				<div class="row mb-24 mb-md-16">
					<div class="col-12 col-md-6">
						<b-form-group id="accept-return-field" label="Accept Return For" label-for="accept-return">
							<b-input-group>
								<b-form-input 
									min="0"
									max="180"
									id="accept-return"
									placeholder="No of days" 
									v-model.number="tracklite.returnDays"
									type="number"
								></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
					<div class="col-12 col-md-6 my-auto">
						<b-form-group class="ml-10" label="Logo Location (Mobile only)">
							<b-form-radio name="get-orders" v-model="tracklite.profileImageLocation" value="0" class="d-inline-block mr-20">
								Left
							</b-form-radio>
							<b-form-radio name="get-orders" v-model="tracklite.profileImageLocation" value="1" class="d-inline-block mr-15">
								Center
							</b-form-radio>
							<b-form-radio name="get-orders" v-model="tracklite.profileImageLocation" value="2" class="d-inline-block">
								Right
							</b-form-radio>
						</b-form-group>
					</div>
				</div>

				<div class="row mb-24 mb-md-16">

					<div class="col-4">
						<b-form-group label="Left Color" label-for="rightColor">
							<b-form-input id="rightColor" type="color" v-model="tracklite.sellerBrandColors[2]">
							</b-form-input>
						</b-form-group>
					</div>

					<div class="col-4">
						<b-form-group label="Center Color" label-for="centerColor">
							<b-form-input id="centerColor" type="color" v-model="tracklite.sellerBrandColors[1]">
							</b-form-input>
						</b-form-group>
					</div>

					<div class="col-4">
						<b-form-group label="Right Color" label-for=" leftColor">
							<b-form-input id="leftColor" type="color" v-model="tracklite.sellerBrandColors[0]">
							</b-form-input>
						</b-form-group>
					</div>

				</div>

				<!-- <div class="row mb-24 mb-md-16">
					<div class="col-12">
						<b-form-group label="Profile Image Location">
							<b-form-radio name="get-orders" v-model="tracklite.profileImageLocation" value="0" class="d-inline-block mr-20">
								Left
							</b-form-radio>
							<b-form-radio name="get-orders" v-model="tracklite.profileImageLocation" value="1" class="d-inline-block mr-20">
								Center
							</b-form-radio>
							<b-form-radio name="get-orders" v-model="tracklite.profileImageLocation" value="2" class="d-inline-block">
								Right
							</b-form-radio>
						</b-form-group>
					</div>
					
				</div> -->

				<b-button type="submit" :disabled="submitting || saveBtn" variant="primary" class="w-sm-100">
					Save <b-spinner v-if="submitting" class="ml-10"></b-spinner>
				</b-button>
			</b-form>
		  </div>
		</div>


		<div class="card profile mb-18">
			<div class="card__title">
				<span class="fs18 fw500">Label Settings
				<i
            		class="icon-disclaimer fs12 text-gray-400"
            		v-b-tooltip.hover.right
            		title="For more information visit FAQ section."
          		></i>
				</span> 

				<b-link href="https://faq.shyplite.com/1004-understanding-customization-settings/" target="_new" class="fs14 lh16">
							Read FAQs <i class="icon-share text-blue ml-4 fs12"></i>
				</b-link>
			</div>

			<div class="card__content">
				<b-form @submit.prevent="submitLabels" class="mw596 mx-auto">
				<div class="row mt-30">
					<div class="col-md-12" >
						<b-form-group class="mb-10">
							<b-form-checkbox v-model="labels.hideCustomerContact">Hide Customer Contact</b-form-checkbox>
						</b-form-group>
						<div class="col d-flex p-0 fs12 text-gray-600">
							<strong>NOTE :</strong>
							<div class="ml-5">This setting is applicable only for <strong>Delhivery</strong> and <strong>Xpressbees</strong></div>
						</div>		
					</div>
				</div>

				<div class="row mt-50">
					<div class="col-md-12">
						<b-form-group class="mb-10">
							<b-form-checkbox v-model="labels.hideProduct">Hide Product Value and Description</b-form-checkbox>
						</b-form-group>
						<div class="col d-flex p-0 fs12 text-gray-600">
							<strong>NOTE :</strong>
							<div class="ml-5">This setting will hide all the <strong>item names</strong> with its corresponding <strong>amount</strong> details from the label</div>
						</div>	
					</div>
				</div>

				<div class="row mt-50">
					<div class="col-md-12">
						<b-form-group class="mb-10">
							<b-form-checkbox v-model="labels.hideRTOaddress">Hide Pickup Address</b-form-checkbox>
						</b-form-group>
						<div class="col d-flex p-0 fs12 text-gray-600">
							<strong>NOTE :</strong>
							<div class="ml-5">This setting is applicable for all carriers except <strong>FedEx</strong> and <strong>SWA</strong></div>
						</div>	
					</div>
				</div>

				<div class="row mt-20" v-if="labels.hideRTOaddress == false">
					<div class="col-md-6 p-0">
						<b-form-group>
							<b-form-radio name="print-contact" v-model="labels.hideWarehouseContact" value="0">Print Pickup Address Contact</b-form-radio>
						</b-form-group>
					</div>
					<div class="col-md-6 p-0">
						<b-form-group>
							<b-form-radio @change="detectSupport(labels.hideWarehouseContact)" name="print-contact" v-model="labels.hideWarehouseContact" value="1">Print Support Contact 
							{{tracklite.supportContact ? `(${tracklite.supportContact})` : ''}}</b-form-radio>
						</b-form-group>
					</div>
				</div>

				<div class="mt-20">
					<strong>Additional Documents</strong>
				</div>

				<div class="row mt-25">
					<div class="col-md-12">
						<b-form-group class="mb-10">
							<b-form-checkbox v-model="labels.showPackingList">
								Print Packing List
							</b-form-checkbox>
						</b-form-group>
						<div class="col d-flex p-0 fs12 text-gray-600">
							<strong>NOTE :</strong>
							<div class="ml-5">This setting will enable you a packing list along with label, if there are "+more" items in the product list.</div>
						</div>	
					</div>
				</div>

				<div class="row mt-40">
					<div class="col-md-12 d-flex align-items-center mt-5">
						<b-form-group class="mb-10">
							<b-form-checkbox v-model="labels.showInvoice">
								Print Invoice
							</b-form-checkbox>
						</b-form-group>
					</div>

					<div class="col d-flex p-0 fs12 text-gray-600 ml-5">
						<strong>NOTE :</strong>
						<div class="ml-5">This is not a GST compliance tax invoice.</div>
					</div>	
				</div>

				<div class="row mt-20">
					<div class="col-md-4" v-if="labels.showInvoice == true">
						<b-form-input class="mt-1" v-model="labels.invoicePrefix" placeholder="Invoice Prefix" title="Invoice Prefix"></b-form-input>
					</div>
					<div class="col-md-8 d-flex align-items-center text-gray-600" v-if="labels.invoicePrefix != '' && labels.showInvoice == true"> <strong>Invoice Number : &nbsp; </strong> {{labels.invoicePrefix}} - 1234</div>
				</div>

				<div class="row mt-20" v-if="labels.showInvoice == true">
					<div class="col-md-6 p-0">
						<b-form-group>
							<b-form-radio v-model="labels.invoicingAddress" value="0" name="default">Print Pickup Address</b-form-radio>
						</b-form-group>
					</div>
					<div class="col-md-6 p-0">
						<b-form-group>
							<b-form-radio v-model="labels.invoicingAddress" value="1" name="default">Print Default Billing Address</b-form-radio>
						</b-form-group>
					</div>
				</div>

				<div class="mt-35">
					<b-button type="submit" :disabled="submittingLabels" variant="primary" class="w-sm-100">
						Save <b-spinner v-if="submittingLabels" class="ml-10"></b-spinner>
					</b-button>
				</div>
				
				</b-form>
			</div>
		</div>


		<div class="card profile mb-18">
			<div class="card__title">
				<span class="fs18 fw500">Notification Settings
				<i
            		class="icon-disclaimer fs12 text-gray-400"
            		v-b-tooltip.hover.right
            		title="Notification Settings"
          		></i>
				</span> 
			</div>

			<div class="card__content" >
				<div class="mw596 mx-auto">
					<div class="d-flex flex-column">
						<div class="d-flex flex-row justify-content-between mt-20 mb-20">
							<span class="fs14 lh16 text-gray-600 mr-20"><strong>Reconciliation</strong> (Notify when new orders are uploaded to reconcile)</span>
							<b-form-checkbox
								class="d-inline"
								name="default-pickup"
								v-model="reconciliation"
								value="enable"
                  				unchecked-value="disable"
								switch
								@change="updateNotification($event, 'reconciliation')"
							></b-form-checkbox>
						</div>

						<div class="d-flex flex-row justify-content-between  mt-20 mb-20">
							<span class="fs14 lh16 text-gray-600 mr-20"><strong>Import Orders</strong> (Notify when new orders are imported from your connectors)</span>
							<b-form-checkbox
								class="d-inline"
								name="default-pickup"
								v-model="importOrder"
								value="enable"
                  				unchecked-value="disable"
								switch
								@change="updateNotification($event, 'importOrder')"
							></b-form-checkbox>
						</div>


						<div class="d-flex flex-row justify-content-between  mt-20 mb-20">
							<span class="fs14 lh16 text-gray-600 mr-20"><strong>NDR</strong> (Notify when new NDR reports are generated)</span>
							<b-form-checkbox
								class="d-inline"
								name="default-pickup"
								v-model="ndr"
								value="enable"
                  				unchecked-value="disable"
								switch
								@change="updateNotification($event, 'ndr')"
							></b-form-checkbox>
						</div>
					</div>
				</div>
			</div>
			<div id="notif-detail"></div>
		</div>

	</div>
</div>
</div>
</template>

<script>
export default {
	name: "SettingsProfile",
	data() {
		return {
			sellerId: this.$store.getters.user.id,
			seller 		: {},
			submitting 	: false,
			reconciliation: "disable",
			importOrder: "disable",
			ndr : "disable",
			submittingLabels : false,
			saveBtn: false,
			customURLTag : '',
			mobile		: '',
			otp 		: '',
			otpsent 	: false,
			isLabel 	: true,
			tfnlabel 	: 'Contact Number',
			profile 	: {},
			labels 	    : {
				hideProduct: false,
				showPackingList : false,
				hideRTOaddress: false,
				hideCustomerContact: false,
				showInvoice : false,
				invoicePrefix : '',
				hideWarehouseContact : 0,
				invoicingAddress : 0

			},
			tracklite 	: {
				sellerBrandColors : []
			},
			passpath 	: '',
			fileerror 	: '',
			logo 		: '',
			valid 		: '',
			supportError: false,
		}
	},
	created() {
		this.getProfile();
		this.notificationToggle();
	},
	methods: {
		async getProfile() {
			const res 					= await this.axios.get('/settings/profile')
			this.seller 				= res.data.seller
			this.logo 					= res.data.logo
			this.mobile 				= this.seller.contact

			this.tracklite = {
				brandName 		: this.seller.sellerBrandName,
				website 		: this.seller.sellerWebsite, 
				supportEmail 	: this.seller.supportEmail,
				supportContact 	: this.seller.supportContact,
				logo 			: this.logo,
				sellerID 		: this.seller.id,
				urltagtracklite	: this.seller.urltagtracklite,
				returnDays		: this.seller.returnDays,
				profileImageLocation : this.seller.profileImageLocation ? this.seller.profileImageLocation : 0,
				sellerBrandColors : this.seller.sellerBrandColors ? JSON.parse(this.seller.sellerBrandColors) : ["#4556FF", "#0096FF", "#2398AE"]
			}

			await this.getLableDetails();
		},

		async validateCustomUrlTag(customValue){

			const result = await this.axios.post('/settings/profile/checkurltag', {urltag: customValue});

			if (result.data.response.success == true){
				this.customURLTag = '<small class="ml-5 text-green"> URL is available.</small>'
				this.saveBtn = false;
			}
			else {
				this.customURLTag = '<small class="ml-5 text-red"> URL is not available.</small>'
				this.saveBtn = true;
			}
		},

		detectSupport(event) {
			if (event == 1 && !this.tracklite.supportContact) {
				this.supportError = true
				this.popToast("failed", "Failed!", 'Please, first Add Support Contact number in Tracklite Settings before Enabling this');
				this.$refs.supportContact.focus()
				this.labels.hideWarehouseContact = 0
			} 
			else if (!this.tracklite.supportContact) {
				this.supportError = true
				this.popToast("failed", "Failed!", 'Please, first Add Support Contact number in Tracklite Settings before Enabling this');
				this.$refs.supportContact.focus()
				this.labels.hideWarehouseContact = 0
			}
			 else {
				this.supportError = false
			}
		},

		async getLableDetails() {
			try {
				const res = await this.axios.get('/settings/profile/labelSettings')
				if (res.data.success == true) {
					this.labels.invoicingAddress = res.data.data.invoicingAddress;
					this.labels.invoicePrefix = res.data.data.invoicePrefix;
					this.labels.showInvoice = res.data.data.invoicing ? true : false;
					if (res.data.data.labelSetting.includes('hideProduct')) {
						this.labels.hideProduct = true; 
					} else {
						this.labels.hideProduct = false;
					}

					if (res.data.data.labelSetting.includes('showPackingList')) {
						this.labels.showPackingList = true;
					} else {
						this.labels.showPackingList = false;
					}

					if (res.data.data.labelSetting.includes('hideRTOaddress')) {
					 	this.labels.hideRTOaddress = true;
					 } else {
					 	this.labels.hideRTOaddress = false;
					 } 

					if (res.data.data.labelSetting.includes('hideCustomerContact')) {
						this.labels.hideCustomerContact = true;
					} else {
						this.labels.hideCustomerContact = false;
					}
					
					if (res.data.data.labelSetting.includes('hideWarehouseContact')) {
						this.labels.hideWarehouseContact = 1;
					} else {
						this.labels.hideWarehouseContact = 0;
					}
				}
			}
			catch(e) {
				console.log(e);
			}
		},
		validate(event) {
			const file = event.target.files[0];

			if (!(file.name.split('.').pop().includes('png') || file.name.split('.').pop().includes('jpeg') || file.name.split('.').pop().includes('jpg') || file.name.split('.').pop().includes('JPG') || file.name.split('.').pop().includes('svg') || file.name.split('.').pop().includes('webp'))) {
				this.popToast('failed','Failed','only PNG, JPG or JPEG file format is supported.')
				this.tracklite.file = null
			}

			if(file.size > 500*1024) {
				this.popToast('failed','Failed',`File size is ${Math.round(file.size/1024)}KB, It should be less than 5000KB.`)
				this.tracklite.file = null
			}

			// Get image dimension...
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = evt => {
				let img = new Image();
				img.onload = () => {
					if(img.width < 96 || img.height < 96) {
						this.fileerror = `Logo size is ${img.width}px X ${img.height}px, It should be at least 96px X 96px big.`
						this.tracklite.file = null
						this.valid = ''
					} else if (img.width > 1024 || img.height > 1024) {
						this.fileerror = `Logo size is ${img.width}px X ${img.height}px, It should be at most 1024px X 1024px big.`
						this.tracklite.file = null
						this.valid = ''
					} else {
						this.logo = evt.target.result
						this.fileerror = ''
						this.valid = 'Click on save button to save logo and profile changes.'
					}
				}
				img.src = evt.target.result;
			}

			reader.onerror = evt => {
				console.error(evt);
			}
		},


		async submitTracklite() {
			try {
				this.submitting = true;

				let fd = new FormData()

				for (const key of Object.keys(this.tracklite)) {
					fd.append(key, this.tracklite[key])
				}

				const res = await this.axios.post('/settings/profile/updateprofile', fd)

				if(res.data.success == true) {
					this.getProfile()
					this.popToast("booked", "Success!", res.data.message);
				}
				else{
					this.popToast("failed", "Failed!",res.data.message);
				}

				this.submitting = false
			} 
			catch(e) {
				console.log(e);
			}
		},

		async submitLabels() {
			this.submittingLabels = true;
			const obj = Object.assign({},this.labels)

			if (obj.hideWarehouseContact == 1) {
				obj.hideWarehouseContact = true
			} 
			else {
				obj.hideWarehouseContact = false
			}

			if (obj.invoicingAddress == 1) {
				obj.invoicingAddress = true
			}
			else {
				obj.invoicingAddress = false
			}

			try {
				const res = await this.axios.post('/settings/profile/labelSettings',obj)

				if (res.data.success) {
					this.$store.commit('setUser', {'invoicing': obj.showInvoice})
					this.popToast("booked", "Success!", "Label Settings Updated Successfully");
					this.submittingLabels = false
				} 
				else {
					this.popToast("failed", "Failed!","Label Settings Updation Failed!! Please Try Again");
					this.submittingLabels = false
				}
			} catch(e) {
				this.submittingLabels = false
				this.popToast("failed", "Failed!","Label Settings Updation Failed!! Please Try Again");
				console.error(e);
			}
		},

		removeSpace(e){
			return String(e).trim();
		},

		copyUrl() {
			let testingCodeToCopy = document.querySelector("#url-tag");
			testingCodeToCopy.setAttribute("type", "text");
			testingCodeToCopy.select();

			try {
				var successful = document.execCommand("copy");
				testingCodeToCopy.setAttribute("type", "hidden");
				this.copySuccess();
			} catch (err) {
				this.copyFailed();
			}
		},

		popInput() {
			this.$refs.file.$el.click();
		},
		copySuccess() {
			this.popToast("booked", "Copy Successful", "Your url was added to clipboard.");
		},
		copyFailed() {
			this.popToast("failed","Copy Failed", "We couldn’t copy this url at the moment.");
		},

		async updateNotification (evt, value) {

			let switchValue = 'off';

			if (evt == 'enable') {
				switchValue = 'on'
			}

			let params = {'notificationTitle': value, 'switchValue': switchValue};

  			let result = await this.axios.put('/v3/set-notification/type', params);

  			if (result.data.response.success == true){
  			this.popToast("booked", "Success!", result.data.response.message);
  				this.notificationToggle();
  			}


  		},

  		async notificationToggle () {

  			let result = await this.axios.get('/v3/manage-notifications');

  			let possibleKey = {'reconciliation': 'enable' , 'importOrder': 'enable', 'ndr' : 'enable'}

  			result.data.response.disabledNotifications.forEach(i => {
  				possibleKey[i] = 'disable'
  			})

  			this.reconciliation = possibleKey.reconciliation;
  			this.importOrder = possibleKey.importOrder;
  			this.ndr = possibleKey.ndr;

  			if (this.$store.state.global.goCustom == true || this.$store.state.global.goCustomfromConn == true) {

				let elmnt = document.getElementById('notif-detail');
	            elmnt.scrollIntoView();

			}
			this.$store.commit('setGlobal', {'goCustom':false});
			this.$store.commit('setGlobal', {'goCustomfromConn':false});

  		}

	},
};
</script>

<style scoped>
.spinner-border{
	height: 1rem;
	width: 1rem;
}

code.pre {
  white-space: pre;
}

.inputField {
	position: absolute;
    right: 5rem;
    bottom: 12.7rem;
}

</style>
