var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "opencartConnector",
      attrs: {
        id: "opencart-connector-modal",
        "modal-class": "opencart-connector-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Opencart Credentials ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "mw596 mx-auto" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col pt-30 pb-16 d-flex justify-content-between align-items-center"
              },
              [
                _c("span", { staticClass: "d-block  text-gray-600 pt-12" }, [
                  _c("strong", [_vm._v("1. ")]),
                  _vm._v(" Go to "),
                  _c("strong", [_vm._v("System >> Users >> API")]),
                  _vm._v(" (in API list, API Name 'Default' click on action). ")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block  text-gray-600" }, [
                _c("strong", [_vm._v("2. ")]),
                _vm._v(" Here is your API Key. ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block  text-gray-600" }, [
                _c("strong", [_vm._v("3. ")]),
                _vm._v(
                  " To Enable it for Shyplite, you have to install our Shyplite plugin into your opencart admin. "
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c(
                "span",
                { staticClass: "d-block  text-gray-600" },
                [
                  _c("strong", [_vm._v("4. ")]),
                  _vm._v(" You can download "),
                  _c(
                    "b-link",
                    {
                      staticClass: "fs16",
                      attrs: {
                        target: "_blank",
                        href:
                          "https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-1.x.x.ocmod.zip"
                      }
                    },
                    [_vm._v(" OpenCart_1.x ")]
                  ),
                  _vm._v(" / "),
                  _c(
                    "b-link",
                    {
                      staticClass: "fs16",
                      attrs: {
                        target: "_blank",
                        href:
                          "https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-2.0.x.ocmod.zip"
                      }
                    },
                    [_vm._v(" OpenCart_2.0 - 2.1 ")]
                  ),
                  _vm._v(" / "),
                  _c(
                    "b-link",
                    {
                      staticClass: "fs16",
                      attrs: {
                        target: "_blank",
                        href:
                          "https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-2.2.x.ocmod.zip"
                      }
                    },
                    [_vm._v(" OpenCart_2.2 ")]
                  ),
                  _vm._v(" / "),
                  _c(
                    "b-link",
                    {
                      staticClass: "fs16",
                      attrs: {
                        target: "_blank",
                        href:
                          "https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-2.3.x.ocmod.zip"
                      }
                    },
                    [_vm._v(" OpenCart_2.3.x ")]
                  ),
                  _vm._v(" / "),
                  _c(
                    "b-link",
                    {
                      staticClass: "fs16",
                      attrs: {
                        target: "_blank",
                        href:
                          "https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-3.x.x.ocmod.zip"
                      }
                    },
                    [_vm._v(" OpenCart_3.x ")]
                  ),
                  _vm._v(" zip. Opencart-1.x requires manual installation. ")
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block  text-gray-600" }, [
                _c("strong", [_vm._v("5. ")]),
                _vm._v(" After login into admin panel. Go to "),
                _c("strong", [_vm._v("Extensions >> Extention Installer")])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block  text-gray-600" }, [
                _c("strong", [_vm._v("6. ")]),
                _vm._v(
                  " Click on upload, and browse the Plugin, after succeful install, goto "
                ),
                _c("strong", [_vm._v("Extensions >> Feeds")])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block  text-gray-600" }, [
                _c("strong", [_vm._v("7. ")]),
                _vm._v(" In (Feed List) install "),
                _c("strong", [_vm._v('"Shyplite Orders API"')]),
                _vm._v(" and "),
                _c("strong", [_vm._v('"Enable"')]),
                _vm._v(" it by clicking edit icon. ")
              ])
            ])
          ]),
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.opencartConnect()
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "seller-id-field",
                          label: "Store Name",
                          "label-for": "seller-id"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: { id: "seller-id", placeholder: "Store Name" },
                          model: {
                            value: _vm.form.storeName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "storeName", $$v)
                            },
                            expression: "form.storeName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-8" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "mode-field",
                          label: "OpenCart Version:",
                          "label-for": "weight"
                        }
                      },
                      [
                        _c("multiselect", {
                          attrs: {
                            id: "mode",
                            options: _vm.options,
                            placeholder: "Select Version",
                            searchable: false,
                            "close-on-select": true,
                            "track-by": "name",
                            label: "name"
                          },
                          model: {
                            value: _vm.form.oCartVersion,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "oCartVersion", $$v)
                            },
                            expression: "form.oCartVersion"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-8" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "mws-token-field",
                          label: "Application Url:",
                          "label-for": "application-url"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            required: "",
                            id: "application-url",
                            placeholder: "Application Url"
                          },
                          model: {
                            value: _vm.form.appurl,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "appurl", $$v)
                            },
                            expression: "form.appurl"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-8" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "mws-token-field",
                          label: "API Key:",
                          "label-for": "api-key"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            required: "",
                            id: "api-key",
                            placeholder: "OpenCart API Key"
                          },
                          model: {
                            value: _vm.form.apikey,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "apikey", $$v)
                            },
                            expression: "form.apikey"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6 py-16" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Get Orders" } },
                      [
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block mr-20",
                            attrs: { name: "auto-fullfilment", value: "1" },
                            model: {
                              value: _vm.form.isactive,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isactive", $$v)
                              },
                              expression: "form.isactive"
                            }
                          },
                          [_vm._v(" On ")]
                        ),
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block",
                            attrs: { name: "auto-fullfilment", value: "0" },
                            model: {
                              value: _vm.form.isactive,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isactive", $$v)
                              },
                              expression: "form.isactive"
                            }
                          },
                          [_vm._v(" Off ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col pt-12 pb-8 d-none d-md-block" },
                  [
                    _vm.sellerDisable
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary mr-16",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Update ")
                          ],
                          1
                        )
                      : _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary mr-16",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Create ")
                          ],
                          1
                        ),
                    _c(
                      "b-button",
                      { attrs: { size: "lg" }, on: { click: _vm.hideModal } },
                      [_vm._v("Cancel")]
                    )
                  ],
                  1
                ),
                _vm.errorFlag
                  ? _c("div", { staticClass: "errorText mt-20" }, [
                      _c("span", [
                        _vm._v(" Please Select an Opencart Version ")
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col pt-12 pb-8 d-flex d-md-none" },
                  [
                    _c(
                      "b-button",
                      { attrs: { size: "lg" }, on: { click: _vm.hideModal } },
                      [_vm._v("Cancel")]
                    ),
                    _vm.sellerDisable
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-16 w-sm-100",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Update ")
                          ],
                          1
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "ml-16 w-sm-100",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Create ")
                          ],
                          1
                        )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }