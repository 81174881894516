<template>
  <div class="container-fluid pb-40 pb-md-0">
    <ul class="connector-wrap row">
      <li
        v-for="connector in activeConnectors"
        :key="connector.title"
        class="col-lg-3 col-md-4 col-sm-6"
      >
        <div class="connector cp">
          <span v-if="connector.marketplaceID == 3 || connector.marketplaceID == 24 || connector.marketplaceID == 5 || connector.marketplaceID == 10" class="status_img" @click="sync_orders(connector.marketplaceID, connector.id)" v-b-tooltip.hover.left :title="`${connector.orderSyncStatus==1 ? 'Syncing orders': 'Sync orders'}`">
            <img class="w-75" src="@/assets/images/sync.png" >
          </span>
            <!-- <img src="@/assets/images/enableconnector.png" v-if="connector.isactive" class="status_img" />
            <img src="@/assets/images/disabledconnector.png" v-if="!connector.isactive" class="status_img" /> -->
          <div class="connector__img">
            <img :src="getImageUrl(connector.marketplaceID)" alt="" />
            <!-- <img src="@/assets/svg/amazon-logo.svg"> -->
          </div>
          <div class="connector__meta">
            <span class="connector__title">
              {{ marketPlace[connector.marketplaceID] | capitalize}}
            </span>
            <span class="connector__subtitle">
              <a class="shopAdd" v-if="connector.marketplaceID == 3 || connector.marketplaceID == 4" target="_blank" :href=" connector.marketplaceID == 3 ? `https://www.amazon.in/s?i=merchant-items&me=${connector.key1 || connector.key4}` : connector.marketplaceID == 4 ? `https://${connector.key4}` :''  ">
                {{connector.key1 || connector.key4}}
              </a>
              <a class="shopAdd" v-if="connector.marketplaceID == 5 || connector.marketplaceID == 7" target="_blank" :href="`${connector.key1}`">
                {{ connector.key1}}
              </a>
              <a class="shopAdd" v-if="connector.marketplaceID == 10" target="_blank" :href="`${connector.key5}`">
                {{ connector.key5}}
              </a>
            </span>
          </div>
          <b-button
            @click="editConnector(connector.id, connector.marketplaceID)"
            variant="tertiary"
            class="w-100 btn-light-green"
          >
            Edit
          </b-button>
        </div>
      </li>
      <li class="col-lg-3 col-md-4">
        <div class="connector add-new cp" @click="openAddConnectorModal">
          <div class="connector__img">
            <img src="@/assets/svg/connector.svg" alt="" />
          </div>
          <div class="connector__meta">
            <span class="connector__title">
              New Connector
            </span>
          </div>
          <b-button
            variant="tertiary"
            class="w-100"
          >
            Add a new connector
          </b-button>
        </div>
      </li>
    </ul>

    <SettingsConnectorsAddNew
      v-on:addConnection="toggleConnector(index)"
      ref="addConnector"
      :ids="activeConnectors"
    />

    <SettingsConnectorsAmazon :editId.sync="editId" ref="amazonConnector" />

    <SettingsConnectorsShopify :editSId.sync="editSId" ref="shopifyConnector" />

    <SettingsConnectorsOpencart :editOId.sync="editOId" ref="opencartConnector" />

    <SettingsConnectorsMagento1 :editMId.sync="editMId" ref="magento1Connector" />

    <SettingsConnectorsGMS :editGId.sync="editGId" ref="gmsConnector" />

    <SettingsWooCom :editWId.sync="editWId" ref="wooConnector" />

    <SettingsZoho :editZId.sync="editZId" ref="zohoConnector" />

    <SettingsConnectorsFlipkart :editFId.sync="editFId" ref="flipkartConnector" />
  </div>
</template>

<script>
import SettingsConnectorsAddNew from "@/views/Connectors/SettingsConnectorsAddNew.vue";
import SettingsConnectorsAmazon from "@/views/Connectors/SettingsConnectorsAmazon.vue";
import SettingsConnectorsShopify from "@/views/Connectors/SettingsConnectorsShopify.vue"
import SettingsConnectorsOpencart from "@/views/Connectors/SettingsConnectorsOpencart.vue"
import SettingsConnectorsMagento1 from "@/views/Connectors/SettingsConnectorsMagento1.vue"
import SettingsConnectorsGMS from "@/views/Connectors/SettingsConnectorsGMS.vue"
import SettingsWooCom from "@/views/Connectors/SettingsWooCom.vue"
import SettingsConnectorsFlipkart from "@/views/Connectors/SettingsConnectorsFlipkart.vue"
import SettingsZoho from "@/views/Connectors/SettingsConnectorsZoho.vue"


export default {
  name: "SettingsConnectors",
  data(){
    return{
      connectors:[],
      marketPlace:["","BULK","SINGLE","amazon","shopify","opencart","magento","WooCommerce","API","MPS","magento2","MANUAL","international","Unicom","omsguru","GMAS","getmeashop","B2X","Nowfloats","PitneyBowes","Cefiro","Ecwid","eVanik","EasyEcom","flipkart","zoho","ZapERP","Vinculum","Abicart","Tracklite","RapidDelivery","Clickpost","Zencommerce","Sleepwell","V2Retail","OGWMS","Ezyslips","B2B"],
      myImage:'',
      editId:null,
      editSId:null,
      editOId:null,
      editGId:null,
      editFId:null,
      editWId:null,
      editMId:null,
      editZId:null,
      sync_status: [],
    }
  },
  components: {
    SettingsConnectorsAddNew,
    SettingsConnectorsAmazon,
    SettingsConnectorsShopify,
    SettingsConnectorsOpencart,
    SettingsConnectorsMagento1,
    SettingsConnectorsGMS,
    SettingsWooCom,
    SettingsZoho,
    SettingsConnectorsFlipkart
  },
  methods: {
    async sync_orders(title, id) {
      try {
        this.sync_status.push(id)
        const res = await this.axios.post(`/settings/connector/${title}/${id}`)
        console.log(res.data)
        if(res.data.success) {
          this.connectors.find(i => i.id == id).orderSyncStatus = 1
          this.popToast("booked", "Success!", res.data.message);
        } else {
          this.popToast("failed", "Info!", res.data.message);
        }
      } catch (err) {
        console.error(err)
      }
    },
    getImageUrl(connector) {
      var images = require(`@/assets/svg/${this.marketPlace[connector]}-logo.svg`);
      if (!images) {
        images = require(`@/assets/images/${this.marketPlace[connector]}.png`);
      }
      return images;
    },
    async getConnectorData() {
      try {
        const res = await this.axios.get('/settings/connectors')
        this.connectors = res.data.result
      } catch(e) {
        // statements
        console.log(e);
      }
    },
    openAddConnectorModal() {
      this.$refs.addConnector.isOpen = !this.$refs.addConnector.isOpen;
    },
    openAmazonConnectorModal() {
      this.$refs.amazonConnector.isOpen = !this.$refs.amazonConnector.isOpen;
    },
    editConnector(id, type){
      if (type === 3) {
        this.editId = id
        this.$refs.amazonConnector.isOpen = !this.$refs.amazonConnector.isOpen;
      } else if(type === 4) {
        this.editSId = id
        this.$refs.shopifyConnector.isOpen = !this.$refs.shopifyConnector.isOpen;
      } else if(type === 5) {
        this.editOId = id
        this.$refs.opencartConnector.isOpen = !this.$refs.opencartConnector.isOpen;
      } else if (type == 16) {
        this.editGId = id
        this.$refs.gmsConnector.isOpen = !this.$refs.gmsConnector.isOpen
      } else if (type == 24) {
        this.editFId = id
        this.$refs.flipkartConnector.isOpen = !this.$refs.flipkartConnector.isOpen
      } else if(type == 7) {
        this.editWId = id
        this.$refs.wooConnector.isOpen = !this.$refs.wooConnector.isOpen
      } else if(type == 10){
        this.editMId = id
        this.$refs.magento1Connector.isOpen = !this.$refs.magento1Connector.isOpen
      } else {
        this.editZId = id
        this.$refs.zohoConnector.isOpen = !this.$refs.zohoConnector.isOpen
      }
    }
  },
  created() {
    this.getConnectorData()
  },
  computed: {
    activeConnectors() {
      return this.connectors.filter(function(u) {
        return u.isactive;
      });
    },
  },
};
</script>

<style lang="scss">
.status_img {
  position: absolute;
  height: 1.5rem;
  top: 0.5rem;
  right: 0.5rem;
}
.shopAdd {
  font-size:0.9rem;
}
.connector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: rem(4px) 0;
  height: rem(272px);
  background-color: $white;
  border-radius: $border-radius;
  padding: rem(32px) rem(24px) rem(24px) rem(24px);
  &-wrap {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
  }
  &__img {
    max-width: rem(200px);
    img {
      width: 100%;
      height: 5rem;
    }
  }
  &__meta {
    text-align: center;
  }
  &__title {
    display: block;
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: 500;
    margin-bottom: rem(8px);
  }
  &__check {
    position: absolute;
    padding: 0;
    top: rem(20px);
    right: rem(10px);
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $green;
      background-color: $green;
    }
  }
  &__subtitle {
    display: block;
    color: $gray-600;
  }
  &.add-new {
    background-color: transparent;
    border: 2px solid $gray-200;
    .connector__title {
      color: $gray-800;
    }
  }
  &.modal {
    border: 2px solid $gray-200;
  }
  .btn-green {
    color: $green;
    background-color: rgba($green, 0.15);
    &:hover,
    &:focus,
    &:active {
      background: $green;
      color: $white;
      border: 1px solid $green;
    }
  }

  &:hover,
  &:focus,
  &:active {
    .btn-tertiary {
      background: $blue;
      color: $white;
      border: 1px solid $blue;
    }
    .btn-light-green {
      color: darken($green, 20%) !important;
      background-color: rgba($green, 0.4) !important;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    &-wrap {
      padding: rem(16px) 0;
    }
  }
}
</style>
