<template>
  <div class="container-fluid pt-10 pt-md-0">
    <div class="row">
        <transition name="slide-left" v-if="!filterResultsToggle">
            <div class="col pb-22 d-flex align-items-center justify-content-between">
                <span class="fs14 lh16 fw700"> System SKUs ({{ totalRows }})</span>
                <b-button @click="openAddModal" class="mr-10" variant="primary">
                  <i class="icon-add fs16 mr-10"></i>
                  Add SKU
                </b-button>
            </div>  
        </transition>
      
        <transition name="slide-right">
         <div class="col pb-22 d-flex align-items-center justify-content-between" v-if="filterResultsToggle">
            <div class="d-flex align-items-center">
                <span
                class="d-none d-md-flex mr-6 text-gray-600 wsnw"
                v-if="this.selFilters.length != '0'"
                >
                Results for:
            </span>
                    <ul class="d-none d-md-flex result-list">
                        <li v-for="(selFilter, index) in selFilters" :key="index">
                            <b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering' && selFilter != '' ">
                                <span class="wsnw text-truncate">
                                    {{typeof selFilter == 'number' ? modeTypes[selFilter] : selFilter}}
                                </span>
                                <i
                                class="icon-close fs6"
                                @click="removeFilter(index)"
                                ></i>
                            </b-button>
                        </li>
                    </ul>

                    <span
                    class="d-flex d-md-none"
                    v-if="this.selFilters.length != '0'"
                    >
                    {{ this.selFilters.length }} Filters selected.
                </span>
                <span class="d-flex d-md-none" v-else>
                    No filters selected.
                </span>
                <b-link @click="clearFilters()" class="ml-24 mr-12 vam">
                    <span class="text-gray-900 wsnw">Clear filters</span>
                    <i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
                </b-link>
            </div>
            </div>

        </transition>
           
      <div class="d-flex pb-24 filterBar">
        <b-button @click="exportSkus" v-if="totalRows != ''">
          <i class="icon-download fs16 mr-10"></i>
          Export
        </b-button>
        <div class="hbreak d-none d-md-inline-flex"></div>
        <div class="divider mx-20 my-auto vam d-md-flex"></div>
        <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
          <span>
            Filters
          </span>
          <i class="icon-filter text-gray-600 pl-10"></i>
        </b-button>
    </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table
          :items="items"
          :fields="fields"
          :per-page="perPage"
          no-border-collapse
          sticky-header
          :busy="isBusy"
          v-if="this.windowWidth > 767"
          class="sku-table billing-table"
        >
          <!-- Table Spinner element -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- A custom formatted data column cell for field 'ProductName' -->
          <template v-slot:cell(productName)="data">
            <span :title="data.item.productName">
              {{ ellipsize(data.item.productName) }}
            </span>
          </template>
          <template v-slot:cell(length)="data">
            {{ data.item.dimensions.length }}
          </template>
          <!-- A custom formatted data column cell for field 'width' -->
          <template v-slot:cell(width)="data">
            {{ data.item.dimensions.width }}
          </template>
          <!-- A custom formatted data column cell for field 'height' -->
          <template v-slot:cell(height)="data">
            {{ data.item.dimensions.height }}
          </template>
          <!-- A custom formatted data column cell for field 'weight' -->
          <template v-slot:cell(weight)="data">
            {{ data.item.dimensions.weight }}
          </template>
          <template v-slot:cell(lastUpdate)="data">
            {{ data.item.lastUpdate | date }}
          </template>
          <!-- A custom formatted data column cell for field 'action' -->
          <template v-slot:cell(action)="data">
            <b-button @click="editSku(data.item)" variant="tertiary" size="xs">
              Edit
            </b-button>
          </template>
        </b-table>

        <!-- Mobile view started here: -->
<!--         <ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
          <li class="" v-for="items in items" :key="items.id">
            
            <div class="d-flex d-flex justify-content-between">
              <span class="mt-8">
                SKU: {{ items.sku }}
              </span>
              <b-button @click="editSku(items)" variant="tertiary" size="xs">
                Edit
              </b-button>
            </div>

            <div class="d-flex">
              <span>
                Product Name: {{ items.productName }}
              </span>
            </div>

            <span>
              Dimenstion(LxWxH):
                {{ items.dimensions.length }}
                {{ items.dimensions.width }}
                {{ items.dimensions.height }}
            </span>

            <div class="d-block">
              <span>
                Weight: {{ items.dimensions.weight }}
              </span>
            </div>

            <div class="d-block">
              <span>
                Mode: {{ items.modeType }}
              </span>
            </div>

            <span>
              {{ items.lastUpdate | date }}
            </span>
          </li>
        </ul> -->

        <div class="pagination-bar">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4">
                <div class="d-inline-flex wsnw align-items-center">
                  <div class="d-block w50px">
                    <multiselect
                      class="pagination-items__pp"
                      v-model="perPage"
                      :searchable="false"
                      :show-labels="false"
                      :allow-empty="false"
                      :options="pageOptions"
                      @input="setPageNumber"
                    ></multiselect>
                  </div>
                  <span
                    class="pl-8 fs12 lh24 text-gray-600 pagination-items"
                  ></span>
                </div>
              </div>
              <div class="col-8 text-right">
                <div class="d-inline-flex ml-auto wsnw align-items-center">
                  <p class="fs12 lh24 text-gray-600 pr-14">
                    Page: {{ currentPage }}
                  </p>

                  <b-button-group>
                    <b-button
                      @click="prevPage"
                      :disabled="prevDisabled"
                      variant="paginate left"
                    >
                      <i class="icon-back fs12"></i>
                    </b-button>

                    <b-button
                      @click="nextPage"
                      :disabled="nextDisabled"
                      variant="paginate right"
                    >
                      <i class="icon-arrow fs12"></i>
                    </b-button>
                  </b-button-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile view started here: -->
    <ul class="table-cards d-flex d-md-none">
      <li class="" v-for="item in items" :key="item.id">
        <div class="d-flex align-items-center justify-content-between">
          <span class="d-block pb-8 mt-10">
            <span class="fw500">SKU</span> : {{ item.sku }}
          </span>
          <div class="d-flex float-right mr-10">
            <b-button @click="editSku(item)" variant="tertiary" size="xs">
                Edit
            </b-button>
          </div>
        </div>
        <div class="d-flex">
          <div class="info">
            <span class="pl-0 mb-4">
              <span class="fw500">Product Name</span> : {{ item.productName | capitalize }}
            </span>

            <span class="carrier-mode mb-4">
              <span class="fw500">Dimensions(LxWxH)</span>: {{ item.dimensions.length }}x{{item.dimensions.width}}x{{item.dimensions.height}} cm
            </span>
            <span class="carrier-mode mb-4">
              <span class="fw500">Weight</span>: {{ item.dimensions.weight }} Kg
            </span>
          </div>
        </div>
        <div class="d-flex">
          <div class="price">
            <span class="d-block mb-4">
              <span class="fw500">Mode Type</span> : {{ item.modeType ||' N/A' }}
            </span>
            <span class="d-block mb-4"><span class="fw500">Last Update</span> : {{ item.lastUpdate | date }}</span>
          </div>
        </div>
      </li>
    </ul>
    <SettingsSkusEdit :editItem="editItem" @updateSku="updateSku" ref="editSkuModal"  />
    <SettingsSkusAdd @addSku="addSku" @fileData="getItems()" ref="addSkuModal" />
    <SettingsSKUfilter ref="filter" />
    <ResultNotFound class="mt-100" v-if="items.length == 0 && !isBusy" />
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import SettingsSkusEdit from "@/components/SettingsSkusEdit.vue";
import SettingsSkusAdd from "@/components/SettingsSkusAdd.vue";
import SettingsSKUfilter from "@/components/SettingsSKUfilter.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default {
  name: "SettingsSkus",
  data() {
    return {
      isBusy: false,
      pageOptions: this.$store.state.pageOptions,
      totalRows: 1,
      currentPage: 1,
      perPage: this.$store.state.perPage,
      totalPages: 1,
      pagination: {},
      prevDisabled: true,
      nextDisabled: false,
      startPage: 1,
      filterResultsToggle:this.$store.getters.filters.sku ? true : false,
      pagesArr: [],
      items: [],
      modeTypes:["","Air","Surface-10kg","Lite-2kg","Rush","Express","Economy","EconomyPlus","Lite-1kg","Lite-0.5kg","Surface-5kg","Surface-25kg","Air-5kg"],
      fields: [
        { key: "sku", label: "SKU", sortable: true },
        {
          key: "productName",
          label: "Product Name",
          sortable: true
        },
        { key: "length", label: "Length (cm)"},
        { key: "width", label: "Width (cm)"},
        { key: "height", label: "Height (cm)"},
        { key: "weight", label: "Weight (kg)"},
        {
          key: "modeType",
          label: "Mode Type",
          tdClass: "wsnw"
        },
        { key: "lastUpdate", label: "Last Update", sortable: true },
        { key: "action", label: "" }
      ],
      editItem:{},
      selFilters:this.$store.getters.filters.sku || {},
      noData:false
    };
  },
  components: {
    Multiselect,
    SettingsSkusAdd,
    SettingsSkusEdit,
    SettingsSKUfilter,
    ResultNotFound
  },
  methods: {
    addSku(item) {
        this.items.unshift(item)
        this.getItems();
    },
    updateSku(item) {
        // const index = this.items.findIndex(i => i.sku === item.sku)
        // this.items[index] = item
        // this.items.push(item)
        // this.items.pop()
        this.getItems();
    },
    async getItems(params) {
      try {
        this.isBusy = true;

        if (!params) {
          let params = {}
        }
        if(Object.keys(this.selFilters).length > 0) {
          params = {...{filtering: true}, ...this.selFilters}
          params.sku = params.sku || ''
          params.mode = params.mode?this.$store.getters.mode.domestic.find(i => i.name == params.mode).value:''
        }
        
        const response = await this.axios.get(`/settings/skus?page=${this.currentPage}&offset=${this.perPage}`, {params:params});
            if (response.data.skuData) {
                this.items = response.data.skuData;
                this.totalRows = this.items.length;
                this.pagination = response.data.pagination;
                this.setPagination();
                if (this.items.length < this.perPage) {
                    this.nextDisabled = true;
                }
                this.isBusy = false
                this.noData = false
              } else  {
                this.isBusy = false
                this.noData = true
              }
            if(response.data.skuData.length == 0) {
              this.noData = true
            }
      } catch (error) {
        this.noData = true
        this.isBusy = false;
        this.nextDisabled = true;
        console.log(error)
        this.popToast("failed", "Failed!", "Data Fetching Failed!! Please Try again Later");
      }
    },
    setPagination() {
      this.nextDisabled = this.pagination.next ? false : true;
      this.prevDisabled = this.pagination.prev ? false : true;
    },
    pushTotalPages() {
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
    },
    setPageNumber() {
      this.currentPage = 1;
      this.getItems();
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
      this.currentPage = 1;
      this.prevDisabled = true;
      if (this.totalPages == 1) {
        this.nextDisabled = true;
      } else if (this.totalPages > 1) {
        this.nextDisabled = false;
      }
    },
    updatePageNumber() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
    },
    nextPage() {
      this.currentPage++;
      this.getItems();
    },
    prevPage() {
      this.currentPage--;
      this.getItems();
    },
    toggleAllRows() {
      if (this.allSelected == false) {
        this.items.forEach(item => {
          this.$set(item, "selected", true);
          this.allSelected = true;
        });
      } else {
        this.selectedRows.forEach(item => {
          this.$delete(item, "selected");
          this.allSelected = false;
        });
      }
    },
    editSku(item) {
      this.editItem = item;
      this.$refs.editSkuModal.isOpen = !this.$refs.editSkuModal.isOpen;
    },
    openAddModal() {
      this.editItem = {}
      this.$refs.addSkuModal.isOpen = !this.$refs.addSkuModal.isOpen;
    },
    getFilters() {
      this.currentPage = 1
      this.selFilters = this.$store.getters.filters.sku
      this.filterResultsToggle = true;
      this.getItems()
    },
    async clearFilters() {
        this.filterResultsToggle = false;
        this.selFilters = {};
        this.$refs.filter.clearFilters()
        this.getItems({filtering: false})
    },
    removeFilter(index) {
        const selFilters = {...this.selFilters}

        this.selFilters = selFilters

        if(this.selFilters.mode == '' || this.selFilters.sku == '') {
            this.selFilters = {}
        }

        delete selFilters[index]
        delete this.$refs.filter.selectedFilters[index]
        delete this.$refs.filter.form[index]

        //delete single SKUs

        if(Object.keys(this.selFilters).length > 0) {
            if(!this.selFilters.hasOwnProperty('mode')){
                this.selFilters['mode'] = ''
            } 
            if(!this.selFilters.hasOwnProperty('sku')) {
                this.selFilters['sku'] = ''
            }
            this.getItems()
            this.$store.commit('setFilter', {'sku': selFilters})
        }else{
            this.clearFilters()
        }

        
    },
    async exportSkus() {
      try {
        
          let params = {filtering: false}
            if(Object.keys(this.selFilters).length > 0) {
              params = {...{filtering: true}, ...this.selFilters}
            }

            this.popToast("booked", "Success!", "Your download has started. Please wait");

            const res = await this.axios.get('settings/skus/export', {params:params})
            if(res.data.path) {
              this.download(res.data.path, 'SKU_export.xlsx')
            }

      } catch(e) {
        // statements
        console.log(e);
      }
    }       
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  watch: {
    currentPage: function() {
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentPage == 1) {
        this.prevDisabled = true;
      }

      if (this.currentPage == this.totalPages) {
        this.nextDisabled = true;
      }
    },
    selectedRows: function() {
      if (this.selectedRows.length == this.items.length) {
        this.allSelected = true;
      }
    }
  },
  mounted() {
    this.totalRows = this.items.length;
    this.totalPages = Math.ceil(this.totalRows / this.perPage);
    for (var i = 1; i <= this.totalPages; i++) {
      this.pagesArr.push(i);
    }

    this.getItems();
  }
};
</script>

<style scoped>
/deep/ .sku-table tbody tr td:first-child {
    overflow: hidden;
    white-space: nowrap;
    padding: 0.75rem 0.75rem 1rem 1.25rem;
    max-width: 150px !important;
    text-overflow: ellipsis;
}

/deep/ .sku-table tbody tr td:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    padding: 0.75rem 0rem 1rem 0rem;
    max-width: 150px !important;
    text-overflow: ellipsis;
}

.productName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1px;
}

.sku-table {
  	max-height: calc(100vh - 250px);
}
.w250px{
	width:250px !important;
}

.no-results {
  top: 40% !important;
}

@media only screen and (max-device-width: 480px) {
    .filterBar {
      justify-content: flex-end;
      position: relative;
      right: 21px;
      width: 100vw;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }
  }
</style>