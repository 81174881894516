var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "magento1Connector",
      attrs: {
        id: "magento1-connector-modal",
        "modal-class": "magento1-connector-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Magento Credentials ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "mw596 mx-auto" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600 pt-12 " }, [
                _c("strong", [_vm._v("1.")]),
                _vm._v(" Log in to Admin and click "),
                _c("strong", [_vm._v("System >> Integrations")]),
                _vm._v(" to display the Integrations page. ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("2.")]),
                _vm._v(" Click "),
                _c("strong", [_vm._v("Add New Integration")]),
                _vm._v(" to display the New Integration page. ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("3.")]),
                _vm._v(" Enter a "),
                _c("strong", [_vm._v("Name")]),
                _vm._v(
                  " for the integration in the Name field. Then enter your admin password in the Your Password field. Leave all other fields blank. "
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("4.")]),
                _vm._v(" Click the "),
                _c("strong", [_vm._v("API")]),
                _vm._v(
                  " tab. Select the Magento resources the integration can access. Select "
                ),
                _c("strong", [_vm._v("Sale Permissions")]),
                _vm._v(" from list. ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("5.")]),
                _vm._v(" Click "),
                _c("strong", [_vm._v("Save")]),
                _vm._v(
                  " to save your changes and return to the Integrations page. "
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("6.")]),
                _vm._v(" Click the "),
                _c("strong", [_vm._v("Activate")]),
                _vm._v(
                  " link in the grid that corresponds to the newly-created integration. "
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("7.")]),
                _vm._v(" Click "),
                _c("strong", [_vm._v("Allow")]),
                _vm._v(". ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("8.")]),
                _vm._v(
                  " After creating integration please enter your Access Token and Site URL below : "
                )
              ])
            ])
          ]),
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.magentoForm($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "seller-id-field",
                          label: "Store Name",
                          "label-for": "seller-id"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: { id: "seller-id", placeholder: "Store Name" },
                          model: {
                            value: _vm.form.storeName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "storeName", $$v)
                            },
                            expression: "form.storeName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-8" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "mws-token-field",
                          label: "Access Token:",
                          "label-for": "consumer-key"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "aconsumer-key",
                            required: "",
                            placeholder: "Access Token"
                          },
                          model: {
                            value: _vm.form.accessToken,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "accessToken", $$v)
                            },
                            expression: "form.accessToken"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-8" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "mws-token-field",
                          label: "Site URL:",
                          "label-for": "consumer-secret"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            required: "",
                            id: "consumer-secret",
                            placeholder: "Site URL"
                          },
                          model: {
                            value: _vm.form.siteUrl,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "siteUrl", $$v)
                            },
                            expression: "form.siteUrl"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 py-16" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Get Orders" } },
                      [
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block mr-20",
                            attrs: { value: "1", name: "get-orders" },
                            model: {
                              value: _vm.form.isactive,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isactive", $$v)
                              },
                              expression: "form.isactive"
                            }
                          },
                          [_vm._v(" Enable ")]
                        ),
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block",
                            attrs: { value: "0", name: "get-orders" },
                            model: {
                              value: _vm.form.isactive,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isactive", $$v)
                              },
                              expression: "form.isactive"
                            }
                          },
                          [_vm._v(" Disable ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-8 " },
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "d-inline-block import_stat",
                        attrs: {
                          id: "mws-token-field",
                          label: "Import Status:",
                          "label-for": "consumer-secret"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "consumer-secret",
                            type: "text",
                            placeholder: "Import Status"
                          },
                          on: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.saveChip($event)
                            }
                          },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-15",
                        attrs: { variant: "primary", size: "sm" },
                        on: { click: _vm.saveChip }
                      },
                      [_vm._v(" Add ")]
                    ),
                    _c(
                      "b-card",
                      {
                        staticClass: "currentInput",
                        model: {
                          value: _vm.form.importStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "importStatus", $$v)
                          },
                          expression: "form.importStatus"
                        }
                      },
                      _vm._l(_vm.form.importStatus, function(chip, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "ml-8 mt-5 chip" },
                          [
                            _vm._v(" " + _vm._s(chip) + " "),
                            _c(
                              "span",
                              {
                                staticClass: "closebtn",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteChip(i)
                                  }
                                }
                              },
                              [_vm._v("×")]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-16" }, [
                  _c("span", { staticClass: "d-block text-gray-600" }, [
                    _vm._v(
                      " Enabling this option will cancel the respective order at your Magento store once its marked as Cancelled/RTO-Received in Shyplite. "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 py-16" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Cancel ON RTO-Received" } },
                      [
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block mr-20",
                            attrs: { value: "1", name: "auto-fullfilment" },
                            model: {
                              value: _vm.form.isrtocancel,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isrtocancel", $$v)
                              },
                              expression: "form.isrtocancel"
                            }
                          },
                          [_vm._v(" ON ")]
                        ),
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block",
                            attrs: { value: "0", name: "auto-fullfilment" },
                            model: {
                              value: _vm.form.isrtocancel,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isrtocancel", $$v)
                              },
                              expression: "form.isrtocancel"
                            }
                          },
                          [_vm._v(" OFF ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col pt-12 pb-8 d-none d-md-block" },
                  [
                    _vm.sellerDisable
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary mr-16",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Update ")
                          ],
                          1
                        )
                      : _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary mr-16",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Create ")
                          ],
                          1
                        ),
                    _c(
                      "b-button",
                      { attrs: { size: "lg" }, on: { click: _vm.hideModal } },
                      [_vm._v("Cancel")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col pt-12 pb-8 d-flex d-md-none" },
                  [
                    _c(
                      "b-button",
                      { attrs: { size: "lg" }, on: { click: _vm.hideModal } },
                      [_vm._v("Cancel")]
                    ),
                    _vm.sellerDisable
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-16 w-sm-100",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Update ")
                          ],
                          1
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "ml-16 w-sm-100",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Create ")
                          ],
                          1
                        )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }