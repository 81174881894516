<template>
  <b-modal
    id="magento1-connector-modal"
    modal-class="magento1-connector-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
    ref="magento1Connector"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block mt-auto fs18 lh28 fw500">
        Magento Credentials
      </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>
    <div class="mw596 mx-auto">
      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600 pt-12 ">
            <strong>1.</strong> Log in to Admin and click <strong>System >> Integrations</strong> to display the Integrations page.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            <strong>2.</strong> Click <strong>Add New Integration</strong> to display the New Integration page.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            <strong>3.</strong> Enter a <strong>Name</strong> for the integration in the Name field. Then enter your admin password in the Your Password field. Leave all other fields blank.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            <strong>4.</strong> Click the <strong>API</strong> tab. Select the Magento resources the integration can access. 
            Select <strong>Sale Permissions</strong> from list.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            <strong>5.</strong> Click <strong>Save</strong> to save your changes and return to the Integrations page.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            <strong>6.</strong> Click the <strong>Activate</strong> link in the grid that corresponds to the newly-created integration.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            <strong>7.</strong> Click <strong>Allow</strong>.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            <strong>8.</strong> After creating integration please enter your Access Token and Site URL below :
          </span>
        </div>
      </div>

      <b-form @submit.prevent="magentoForm">

        <div class="row">
          <div class="col-md-6">
            <b-form-group
            id="seller-id-field"
            label="Store Name"
            label-for="seller-id"
            >
            <b-form-input
            id="seller-id"
            v-model="form.storeName"
            placeholder="Store Name"
            ></b-form-input>
            </b-form-group>
          </div>
        </div>
        
          <div class="row">
        <div class="col-md-6 pt-8">
          <b-form-group
            id="mws-token-field"
            label="Access Token:"
            label-for="consumer-key"
          >
            <b-form-input
              id="aconsumer-key"
              required
              placeholder="Access Token"
              v-model="form.accessToken"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6 pt-8">
          <b-form-group
            id="mws-token-field"
            label="Site URL:"
            label-for="consumer-secret"
          >
            <b-form-input
              required
              id="consumer-secret"
              v-model="form.siteUrl"
              placeholder="Site URL"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 py-16">
          <b-form-group label="Get Orders">
            <b-form-radio value="1" v-model="form.isactive" name="get-orders" class="d-inline-block mr-20">
              Enable
            </b-form-radio>
            <b-form-radio value="0" v-model="form.isactive" name="get-orders" class="d-inline-block">
              Disable
            </b-form-radio>
          </b-form-group>
        </div>
        <div class="col-md-6 pt-8 ">
          <b-form-group
            class="d-inline-block import_stat"
            id="mws-token-field"
            label="Import Status:"
            label-for="consumer-secret"
          >
            <b-form-input
              id="consumer-secret"
              v-model="status"
              type="text"
              @keydown.enter.prevent="saveChip"
              placeholder="Import Status"
            ></b-form-input>
          </b-form-group>
          <b-button variant="primary" @click="saveChip" class="ml-15" size="sm">
            Add
          </b-button>
          <b-card class="currentInput" v-model="form.importStatus">
              <div v-for="(chip, i) of form.importStatus" class="ml-8 mt-5 chip" :key="i">
                {{chip}}
                <span class="closebtn" style="cursor:pointer" @click="deleteChip(i)">&times;</span>
            </div>
          </b-card>
          
        </div>
      </div>


      <div class="row">
        <div class="col py-16">
          <span class="d-block text-gray-600">
            Enabling this option will cancel the respective order at your Magento store once its marked as Cancelled/RTO-Received in Shyplite.
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 py-16">
          <b-form-group label="Cancel ON RTO-Received">
            <b-form-radio v-model="form.isrtocancel" value="1" name="auto-fullfilment" class="d-inline-block mr-20">
              ON
            </b-form-radio>
            <b-form-radio v-model="form.isrtocancel" value="0" name="auto-fullfilment" class="d-inline-block">
              OFF
            </b-form-radio>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col pt-12 pb-8 d-none d-md-block">
          <b-button v-if="sellerDisable" type="submit" variant="primary mr-16" size="lg">
            <b-spinner v-if="updating"></b-spinner>
              Update
        </b-button>
        <b-button v-else type="submit" variant="primary mr-16" size="lg">
          <b-spinner v-if="updating"></b-spinner>
            Create
        </b-button>
        <b-button @click="hideModal" size="lg">Cancel</b-button>
        </div>
        <div class="col pt-12 pb-8 d-flex d-md-none">
          <b-button @click="hideModal" size="lg">Cancel</b-button>
          <b-button v-if="sellerDisable"  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
            <b-spinner v-if="updating"></b-spinner>
            Update
          </b-button>
          <b-button v-else type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
            <b-spinner v-if="updating"></b-spinner>
            Create
          </b-button>
        </div>
      </div>

      </b-form>

      
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "SettingsMagentoConnector",
  props: {
      editMId: Number
  },
  data() {
    return {
      updating:false,
      isOpen: this.modalOpen,
      status:'',
      form:{
        importStatus:['pending']
      },
      sellerDisable:false
    };
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("magento1-connector-modal");
      this.form.importStatus = []
      this.form = {}
    },
    saveChip(){
    if (this.status && String(this.status).trim().length > 0) {
      if(this.form.importStatus.length <= 2){
        this.form.importStatus.push(this.status)
        this.status = ''
      }else {
        this.status = ''
        this.popToast("failed", "Failed!", "Maximum 3 status can be imported");
      }
    }
    else {
        this.popToast("failed", "Failed!", "Please Enter an Import Status");
    }
  },
    deleteChip(index) {
      this.form.importStatus.splice(index, 1);
    },
    async magentoForm() {
      this.updating = true
      let data = Object.assign({}, this.form);
      data.importStatus = data.importStatus.join(',')

      if (this.form.id) {
        try {
          const editres = await this.axios.post('/settings/connectors/magento2', data)
          if (editres.data.success) {
            this.popToast("booked", "Success!", editres.data.message);
            this.updating = false
            setTimeout(() => {
              this.$router.go()
            },1000)
          } else {
            this.updating = false
            this.popToast("failed", "Failed!", editres.data.error)
          }
        } catch(e) {
          this.updating = false
          this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
          console.log(e);
        }
      } else {
          try {
          const res = await this.axios.put('/settings/connectors/magento2', data)
          if(res.data.success) {
            this.popToast("booked", "Success!", res.data.message);
            this.updating = false
            setTimeout(() => {
                this.$router.go()
              },1000)
            
          } else {
            this.updating = false
            this.popToast("failed", "Failed!", `${res.data.error.message}!! Please Try again`);
          }
        } catch(e) {
          this.updating = false
          this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
          console.log(e);
        }
      }
    },
    async formatting(id) {
      try {
        const res = await this.axios.get(`/settings/connector/${id}`)
        const editConn = {
          id:res.data.result[0].id,
          accessToken:res.data.result[0].key3,
          siteUrl:res.data.result[0].key5,
          isactive:res.data.result[0].isactive,
          isrtocancel:res.data.result[0].isrtocancel,
          importStatus:res.data.result[0].importStatus,
          storeName:res.data.result[0].storeName
        }
        editConn.importStatus = editConn.importStatus.split(',')
        this.form = editConn
      } catch(e) {
        // statements
        console.log(e);
      }
    }
  },
  watch:{
    isOpen(newValue) {
        if(newValue) {
          let magentoFlag = false
          console.log(this.editMId)
          this.$root.$on('magento', data => {
            magentoFlag = data
          });

          if (this.editMId) {
            this.formatting(this.editMId)
              this.sellerDisable = true
              this.status = ''
              } else if (magentoFlag) {
                 this.form = {
                  importStatus:['pending'],
                  isactive:"1",
                  isrtocancel:"1"
                 }
                    this.sellerDisable = false
                    this.status = ''
              } else {
                this.form = {
                  importStatus:['pending'],
                  isactive:"1",
                  isrtocancel:"1"
                }
                  this.sellerDisable = false
                  this.status = ''
              }
          } else {
            this.$emit('update:editMId', undefined)
          }
      }
  }
};
</script>

<style scoped type="text/scss">
#btn-disable {
  position: absolute;
  right: rem(-88px);
  @include media-breakpoint-down(sm) {
    right: 0;
  }
}
.import_stat {
  width: 12.5rem;
}

.chip {
  display: inline-block;
    padding: 0 10px;
    font-size: 0.9rem;
    line-height: 26px;
    border-radius: 5px;
    background-color: #006EEF;
    color: white;
}
</style>
