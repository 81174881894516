var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "wooConnector",
      attrs: {
        id: "woo-connector-modal",
        "modal-class": "woo-connector-modal",
        "header-class": "header-pad",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "span",
                {
                  staticClass: "d-block mt-auto fs18 lh28 fw500 head",
                  class: !_vm.toggleWoocom ? "active" : "",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.toggleWoocom = !_vm.toggleWoocom
                    }
                  }
                },
                [_vm._v(" WooCommerce Credentials ")]
              ),
              _c(
                "span",
                {
                  staticClass: "d-block mt-auto fs18 lh28 fw500 head",
                  class: _vm.toggleWoocom ? "active" : "",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.toggleWoocom = !_vm.toggleWoocom
                    }
                  }
                },
                [_vm._v(" WooCommerce Webhooks ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("transition", { attrs: { name: "slide-right" } }, [
        !_vm.toggleWoocom
          ? _c(
              "div",
              { staticClass: "mw596 mx-auto" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col py-10" }, [
                    _c("span", { staticClass: "d-block text-gray-600 pt-12" }, [
                      _c("strong", [_vm._v("1.")]),
                      _vm._v(
                        " To create or manage keys for a specific WordPress user, go to "
                      ),
                      _c("strong", [
                        _vm._v(" Woo-Commerce > Settings > Advanced > REST API")
                      ])
                    ]),
                    _c("span", { staticClass: "d-block text-gray-600 pt-12" }, [
                      _c("strong", [_vm._v("Note:")]),
                      _vm._v(
                        " Keys/Apps was found at WooCommerce > Settings > API > Key/Apps prior to WooCommerce 3.4."
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col py-10" }, [
                    _c("span", { staticClass: "d-block text-gray-600" }, [
                      _c("strong", [_vm._v("2.")]),
                      _vm._v(" To get started, select "),
                      _c("strong", [_vm._v(" Add Key. ")])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col py-10" }, [
                    _c("span", { staticClass: "d-block text-gray-600" }, [
                      _c("strong", [_vm._v("3.")]),
                      _vm._v(
                        " Select the User you would like to generate a key for in the User field and add a Description. Choose the level of access for this API key, which can be "
                      ),
                      _c("strong", [_vm._v("Read")]),
                      _vm._v(" access, "),
                      _c("strong", [_vm._v("Write")]),
                      _vm._v(" access or "),
                      _c("strong", [_vm._v("Read/Write access")]),
                      _vm._v(". Then select the "),
                      _c("strong", [_vm._v("Generate API Key")]),
                      _vm._v(
                        " button and WooCommerce will generate API keys for that user. "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col py-10" }, [
                    _c("span", { staticClass: "d-block text-gray-600" }, [
                      _c("strong", [_vm._v("4.")]),
                      _vm._v(" Here is your Required Keys. ")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col py-10" }, [
                    _c(
                      "span",
                      { staticClass: "d-block text-gray-600" },
                      [
                        _c("strong", [_vm._v("5.")]),
                        _vm._v(" Click "),
                        _c(
                          "b-link",
                          {
                            staticClass: "dochref",
                            attrs: {
                              target: "_blank",
                              href:
                                "https://docs.woocommerce.com/document/woocommerce-rest-api/"
                            }
                          },
                          [_vm._v(" here ")]
                        ),
                        _vm._v(" for more detail. ")
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.woocomConnect()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 pt-8" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mws-token-field",
                                label: "URL:",
                                "label-for": "url"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "url",
                                  pattern: ".{3,}",
                                  placeholder: "URL"
                                },
                                model: {
                                  value: _vm.form.url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "url", $$v)
                                  },
                                  expression: "form.url"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 pt-8" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mws-token-field",
                                label: "Consumer Key:",
                                "label-for": "cons-key"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "cons-key",
                                  placeholder: "Consumer Key"
                                },
                                model: {
                                  value: _vm.form.consumerKey,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "consumerKey", $$v)
                                  },
                                  expression: "form.consumerKey"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 py-10" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mws-token-field",
                                label: "Consumer Secret:",
                                "label-for": "cons-secret"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "cons-secret",
                                  placeholder: "Consumer Secret"
                                },
                                model: {
                                  value: _vm.form.consumerSecret,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "consumerSecret", $$v)
                                  },
                                  expression: "form.consumerSecret"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 py-12" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Get Orders" } },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "d-inline-block mr-20",
                                  attrs: { name: "get-orders", value: "1" },
                                  model: {
                                    value: _vm.form.isactive,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "isactive", $$v)
                                    },
                                    expression: "form.isactive"
                                  }
                                },
                                [_vm._v(" Enable ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: { name: "get-orders", value: "0" },
                                  model: {
                                    value: _vm.form.isactive,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "isactive", $$v)
                                    },
                                    expression: "form.isactive"
                                  }
                                },
                                [_vm._v(" Disable ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "d-inline-block import_stat",
                              attrs: {
                                id: "mws-token-field",
                                label: "Import Status:",
                                "label-for": "consumer-secret"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "consumer-secret",
                                  type: "text",
                                  placeholder: "Import Status"
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                    return _vm.saveChip($event)
                                  }
                                },
                                model: {
                                  value: _vm.status,
                                  callback: function($$v) {
                                    _vm.status = $$v
                                  },
                                  expression: "status"
                                }
                              })
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.right",
                                modifiers: { hover: true, right: true }
                              }
                            ],
                            staticClass:
                              "icon-disclaimer fs12 text-gray-400 ml-12 vam",
                            attrs: {
                              title:
                                "Import status and Fulfillment status are case sensitive"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-15",
                              attrs: { variant: "primary", size: "sm" },
                              on: { click: _vm.saveChip }
                            },
                            [_vm._v(" Add ")]
                          ),
                          _c(
                            "b-card",
                            {
                              staticClass: "currentInput",
                              model: {
                                value: _vm.form.importStatus,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "importStatus", $$v)
                                },
                                expression: "form.importStatus"
                              }
                            },
                            _vm._l(_vm.form.importStatus, function(chip, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "ml-8 mt-5 chip" },
                                [
                                  _vm._v(" " + _vm._s(chip) + " "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "closebtn",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteChip(i)
                                        }
                                      }
                                    },
                                    [_vm._v("×")]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mws-token-field",
                                label: "Fulfillment Status:",
                                "label-for": "fulfil-stat"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "fulfil-stat",
                                  placeholder: "Fulfillment Status"
                                },
                                model: {
                                  value: _vm.form.fulfillStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "fulfillStatus", $$v)
                                  },
                                  expression: "form.fulfillStatus"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 pt-15" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "seller-id-field",
                                label: "Store Name",
                                "label-for": "seller-id"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "seller-id",
                                  placeholder: "Store Name"
                                },
                                model: {
                                  value: _vm.form.storeName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "storeName", $$v)
                                  },
                                  expression: "form.storeName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row pt-12" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("span", { staticClass: "d-block text-gray-600" }, [
                          _vm._v(
                            " Enabling this option will update order status on your woocommerce to "
                          ),
                          _c("strong", [_vm._v("Completed")]),
                          _vm._v(" on downloading manifest. ")
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 pt-8" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Update Order Status" } },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "d-inline-block mr-20",
                                  attrs: { value: "1", name: "order-status" },
                                  model: {
                                    value: _vm.form.updateStatus,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "updateStatus", $$v)
                                    },
                                    expression: "form.updateStatus"
                                  }
                                },
                                [_vm._v(" Enable ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: { value: "0", name: "order-status" },
                                  model: {
                                    value: _vm.form.updateStatus,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "updateStatus", $$v)
                                    },
                                    expression: "form.updateStatus"
                                  }
                                },
                                [_vm._v(" Disable ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row pt-12" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("span", { staticClass: "d-block text-gray-600" }, [
                          _vm._v(
                            " Enabling this option will cancel the respective order at your WooCommerce store once its marked as RTO-Received in Shyplite "
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 pt-8" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Cancel ON RTO-Received" } },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "d-inline-block mr-20",
                                  attrs: { value: "1", name: "is-rto-cancel" },
                                  model: {
                                    value: _vm.form.isrtocancel,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "isrtocancel", $$v)
                                    },
                                    expression: "form.isrtocancel"
                                  }
                                },
                                [_vm._v(" ON ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: { value: "0", name: "is-rto-cancel" },
                                  model: {
                                    value: _vm.form.isrtocancel,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "isrtocancel", $$v)
                                    },
                                    expression: "form.isrtocancel"
                                  }
                                },
                                [_vm._v(" OFF ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col pt-12 pb-8 d-none d-md-block" },
                        [
                          _vm.sellerDisable
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    variant: "primary mr-16",
                                    size: "lg"
                                  }
                                },
                                [
                                  _vm.updating ? _c("b-spinner") : _vm._e(),
                                  _vm._v(" Update ")
                                ],
                                1
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    variant: "primary mr-16",
                                    size: "lg"
                                  }
                                },
                                [
                                  _vm.updating ? _c("b-spinner") : _vm._e(),
                                  _vm._v(" Create ")
                                ],
                                1
                              ),
                          _c(
                            "b-button",
                            {
                              attrs: { size: "lg" },
                              on: { click: _vm.hideModal }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col pt-12 pb-8 d-flex d-md-none" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "lg" },
                              on: { click: _vm.hideModal }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm.sellerDisable
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "ml-16 w-sm-100",
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "lg"
                                  }
                                },
                                [
                                  _vm.updating ? _c("b-spinner") : _vm._e(),
                                  _vm._v(" Update ")
                                ],
                                1
                              )
                            : _c(
                                "b-button",
                                {
                                  staticClass: "ml-16 w-sm-100",
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "lg"
                                  }
                                },
                                [
                                  _vm.updating ? _c("b-spinner") : _vm._e(),
                                  _vm._v(" Create ")
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "slide-left" } }, [
        _vm.toggleWoocom
          ? _c("div", { staticClass: "mw596 mx-auto" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-10" }, [
                  _c("span", { staticClass: "d-block text-gray-600 pt-12 " }, [
                    _c("strong", [_vm._v("1.")]),
                    _vm._v(" Log in to Admin and click "),
                    _c("strong", [
                      _vm._v("WooCommerce >> Settings >> Advanced >> Webhooks.")
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-10" }, [
                  _c("span", { staticClass: "d-block text-gray-600" }, [
                    _c("strong", [_vm._v("2.")]),
                    _vm._v(" Click On "),
                    _c("strong", [_vm._v("Create a new webhook")]),
                    _vm._v(" or "),
                    _c("strong", [_vm._v("Add webhook")]),
                    _vm._v(".The Webhook Data box appears. ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-10" }, [
                  _c("span", { staticClass: "d-block text-gray-600" }, [
                    _c("strong", [_vm._v("3.")]),
                    _vm._v(" Select "),
                    _c("strong", [
                      _vm._v("Name, Status, Topic, Delivery URL ")
                    ]),
                    _vm._v(" and "),
                    _c("strong", [_vm._v("API Version")]),
                    _vm._v(" as per details below: ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-10" }, [
                  _c("span", { staticClass: "d-block text-gray-600" }, [
                    _c("strong", [_vm._v("For Real-Time Import of COD orders")])
                  ]),
                  _c("ul", { staticClass: "pt-8 " }, [
                    _c("li", [_vm._v("Name: create_order")]),
                    _c("li", [_vm._v("Status: Active")]),
                    _c("li", [_vm._v("Topic: Order Created")]),
                    _c("li", [
                      _vm._v("Delivery URL: "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.urL,
                              expression: "urL",
                              arg: "copy"
                            },
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass:
                            "bg-orange-light d-inline-block ml-8 px-12 py-4 rounded",
                          attrs: { title: "Click to copy!" },
                          on: { click: _vm.copySuccess }
                        },
                        [
                          _vm._v(
                            " https://api.shyplite.com/woocommerce/import/" +
                              _vm._s(_vm.$store.getters.user.id)
                          )
                        ]
                      )
                    ]),
                    _c("li", [
                      _vm._v("API Version: WP REST API Integration v2")
                    ])
                  ]),
                  _c("span", { staticClass: "pt-6 d-block text-gray-600" }, [
                    _vm._v(
                      "This webhook will import your WooCommerce COD orders into Shyplite as soon as they are placed at your store."
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-10" }, [
                  _c("span", { staticClass: "d-block text-gray-600" }, [
                    _c("strong", [_vm._v("4.")]),
                    _vm._v(
                      " After creating webhook please enter your Webhook Secret Key below: "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-8" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "mws-token-field",
                          label: "Webhook Secret:",
                          "label-for": "webhook"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "webhook",
                            placeholder: "Webhook Secret"
                          },
                          model: {
                            value: _vm.key5,
                            callback: function($$v) {
                              _vm.key5 = $$v
                            },
                            expression: "key5"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col pt-30 text-right d-none d-md-block" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary", size: "lg" },
                        on: {
                          click: function($event) {
                            return _vm.saveWebhook()
                          }
                        }
                      },
                      [
                        _vm.updating ? _c("b-spinner") : _vm._e(),
                        _vm._v(" Save ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-10" }, [
                  _c("span", { staticClass: "d-block text-gray-600" }, [
                    _c("strong", [_vm._v("5.")]),
                    _vm._v(
                      " After adding (create_order), create another webhook (update_order) with setting. "
                    )
                  ]),
                  _c("span", { staticClass: "d-block text-gray-600" }, [
                    _c("strong", [
                      _vm._v(
                        "To: Import prepaid orders, cancel order from woocommerce panel (processed order can't be cancelled.)"
                      )
                    ])
                  ]),
                  _c("ul", { staticClass: "pt-8 " }, [
                    _c("li", [_vm._v("Name: update_order")]),
                    _c("li", [_vm._v("Status: Active")]),
                    _c("li", [_vm._v("Topic: Order update")]),
                    _c("li", [
                      _vm._v("Delivery URL: "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.urL,
                              expression: "urL",
                              arg: "copy"
                            },
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass:
                            "bg-orange-light d-inline-block ml-8 px-12 py-4 rounded",
                          attrs: { title: "Click to copy!" },
                          on: { click: _vm.copySuccess }
                        },
                        [
                          _vm._v(
                            " https://api.shyplite.com/woocommerce/update/" +
                              _vm._s(_vm.$store.getters.user.id)
                          )
                        ]
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Secret: " +
                          _vm._s(
                            _vm.key5
                              ? _vm.key5
                              : "Create webhook 'create_order' first."
                          )
                      )
                    ]),
                    _c("li", [
                      _vm._v("API Version: WP REST API Integration v2")
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }