<template>
  <ul class="info-card">
    <li>
      <span>
        For
        <b>Cheapest Setting</b>,
         Shyplite will assign the cheapest carrier of selected mode for your
        order.
      </span>
    </li>
    <li>
      <span>
        For
        <b>Priority Setting</b>,
         Shyplite will assign the chosen Priority Carrier for the order's
        respective mode and zone. If the Priority carrier's service is not
        available or is Disabled by you, Shyplite will assign the cheapest
        carrier for selected mode.
      </span>
    </li>
     <li>
      <span>
        For
        <b>Modeless Cheapest Setting</b>,
         Shyplite will assign the cheapest carrier irrespective of selected mode for your order.
      </span>
    </li>
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss">
.info-card {
  background-color: rgba($orange, 0.2);
  border-radius: $border-radius;
  padding: rem(20px) rem(28px) rem(8px) rem(28px);
  list-style: none;
  li {
      margin-bottom: rem(12px);
      line-height: rem(21px);
      &:before {
          content: "-";
          text-indent: 5px;
      }
  }
}
</style>
