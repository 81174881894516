<template>
  <div class="manifests">
    <CustomerTable></CustomerTable>
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import CustomerTable from "@/views/customerbook/CustomerTable.vue";

export default {
  name: "CustomerBook",
  title: "Shyplite - CustomerBook",
  components: {
    AppTopbar,
    CustomerTable,
  },
};
</script>
