  <template>
  	<b-modal
  	id="woo-connector-modal"
  	modal-class="woo-connector-modal"
  	header-class="header-pad"
  	size="lg"
  	hide-footer
  	v-model="isOpen"
  	ref="wooConnector"
  	>
  	<template v-slot:modal-header="{ close }">
  		<span class="d-block mt-auto fs18 lh28 fw500 head" :class="!toggleWoocom ? 'active' :'' " 
  		@click.stop="toggleWoocom = !toggleWoocom">
  			WooCommerce Credentials
  		</span>
  		<span class="d-block mt-auto fs18 lh28 fw500 head" :class="toggleWoocom ? 'active' :'' " 
  		@click.stop="toggleWoocom = !toggleWoocom">
  			WooCommerce Webhooks
  		</span>
  		<b-button variant="rounded" @click="close()">
  			<i class="icon-close-variant"></i>
  		</b-button>
  	</template>
  	<transition name="slide-right">
  		<div class="mw596 mx-auto" v-if="!toggleWoocom">
  			<div class="row">
  				<div class="col py-10">
  					<span class="d-block text-gray-600 pt-12">
  						<strong>1.</strong> To create or manage keys for a specific WordPress user, go to <strong> Woo-Commerce > Settings > Advanced > REST API</strong>
  					</span>
  					<span class="d-block text-gray-600 pt-12"><strong>Note:</strong> Keys/Apps was found at WooCommerce > Settings > API > Key/Apps prior to WooCommerce 3.4.</span>
  				</div>
  			</div>

  			<div class="row">
  				<div class="col py-10">
  					<span class="d-block text-gray-600">
  						<strong>2.</strong> To get started, select <strong> Add Key. </strong>
  					</span>
  				</div>
  			</div>

  			<div class="row">
  				<div class="col py-10">
  					<span class="d-block text-gray-600">
  						<strong>3.</strong> Select the User you would like to generate a key for in the User field and add a Description. Choose the level of access for this API key, which can be <strong>Read</strong> access, <strong>Write</strong> access or <strong>Read/Write access</strong>. Then select the <strong>Generate API Key</strong> button and WooCommerce will generate API keys for that user.
  					</span>
  				</div>
  			</div>

  			<div class="row">
  				<div class="col py-10">
  					<span class="d-block text-gray-600">
  						<strong>4.</strong> Here is your Required Keys.
  					</span>
  				</div>
  			</div>

  			<div class="row">
  				<div class="col py-10">
  					<span class="d-block text-gray-600">
  						<strong>5.</strong> Click <b-link target="_blank" class="dochref" href="https://docs.woocommerce.com/document/woocommerce-rest-api/"> here </b-link> for more detail.
  					</span>
  				</div>
  			</div>

  			<b-form @submit.prevent="woocomConnect()">

  				<div class="row">
  					<div class="col-md-6 pt-8">
  						<b-form-group
  						id="mws-token-field"
  						label="URL:"
  						label-for="url"
  						>
  						<b-form-input
  						required
  						id="url"
  						pattern=".{3,}"
  						v-model="form.url"
  						placeholder="URL"
  						></b-form-input>
  					</b-form-group>
  				</div>
  				<div class="col-md-6 pt-8">
  					<b-form-group
  					id="mws-token-field"
  					label="Consumer Key:"
  					label-for="cons-key"
  					>
  					<b-form-input
  					required
  					id="cons-key"
  					v-model="form.consumerKey"
  					placeholder="Consumer Key"
  					></b-form-input>
  				</b-form-group>
  			</div>
  		</div>


  		<div class="row">
  			<div class="col-md-6 py-10">
  				<b-form-group
  				id="mws-token-field"
  				label="Consumer Secret:"
  				label-for="cons-secret"
  				>
  				<b-form-input
  				required
  				id="cons-secret"
  				v-model="form.consumerSecret"
  				placeholder="Consumer Secret"
  				></b-form-input>
  			</b-form-group>
  		</div>
  		<div class="col-md-6 py-12">
  			<b-form-group label="Get Orders">
  				<b-form-radio name="get-orders" v-model="form.isactive" value="1" class="d-inline-block mr-20">
  					Enable
  				</b-form-radio>
  				<b-form-radio name="get-orders" v-model="form.isactive" value="0" class="d-inline-block">
  					Disable
  				</b-form-radio>
  			</b-form-group>
  		</div>
  	</div>

  	<div class="row">
  		<div class="col-md-6">
  			<b-form-group
  			class="d-inline-block import_stat"
  			id="mws-token-field"
  			label="Import Status:"
  			label-for="consumer-secret"
  			>
  			<b-form-input
  			id="consumer-secret"
  			v-model="status"
  			type="text"
  			@keydown.enter.prevent='saveChip'
  			placeholder="Import Status"
  			></b-form-input>
  		</b-form-group>
  		<i
  		class="icon-disclaimer fs12 text-gray-400 ml-12 vam"
  		v-b-tooltip.hover.right
  		title="Import status and Fulfillment status are case sensitive"
  		></i>
  		<b-button variant="primary" @click="saveChip" class="ml-15" size="sm">
  			Add
  		</b-button>
  		<b-card class="currentInput" v-model="form.importStatus">
  			<div v-for="(chip, i) of form.importStatus" class="ml-8 mt-5 chip" :key="i">
  				{{chip}}
  				<span class="closebtn" style="cursor:pointer" @click="deleteChip(i)">&times;</span>
  			</div>
  		</b-card>
  	</div>
  	<div class="col-md-6">
  		<b-form-group
  		id="mws-token-field"
  		label="Fulfillment Status:"
  		label-for="fulfil-stat"
  		>
  		<b-form-input
  		id="fulfil-stat"
  		v-model="form.fulfillStatus"
  		placeholder="Fulfillment Status"
  		></b-form-input>
  	</b-form-group>
  </div>
</div>

		<div class="row" >
			<div class="col-md-6 pt-15">
				<b-form-group
				id="seller-id-field"
				label="Store Name"
				label-for="seller-id"
				>
				<b-form-input
				id="seller-id"
				v-model="form.storeName"
				placeholder="Store Name"
				></b-form-input>
				</b-form-group>
			</div>
		</div>


<div class="row pt-12">
	<div class="col">
		<span class="d-block text-gray-600">
			Enabling this option will update order status on your woocommerce to <strong>Completed</strong> on downloading manifest.
		</span>
	</div>
</div>

<div class="row">
	<div class="col-md-6 pt-8">
		<b-form-group label="Update Order Status">
			<b-form-radio v-model="form.updateStatus" value="1" name="order-status" class="d-inline-block mr-20">
				Enable
			</b-form-radio>
			<b-form-radio v-model="form.updateStatus" value="0" name="order-status" class="d-inline-block">
				Disable
			</b-form-radio>
		</b-form-group>
	</div>
</div>


<div class="row pt-12">
	<div class="col">
		<span class="d-block text-gray-600">
			Enabling this option will cancel the respective order at your WooCommerce store once its marked as RTO-Received in Shyplite
		</span>
	</div>
</div>

<div class="row">
	<div class="col-md-6 pt-8">
		<b-form-group label="Cancel ON RTO-Received">
			<b-form-radio v-model="form.isrtocancel" value="1" name="is-rto-cancel" class="d-inline-block mr-20">
				ON
			</b-form-radio>
			<b-form-radio v-model="form.isrtocancel" value="0" name="is-rto-cancel" class="d-inline-block">
				OFF
			</b-form-radio>
		</b-form-group>
	</div>
</div>


<div class="row">
	<div class="col pt-12 pb-8 d-none d-md-block">
		<b-button v-if="sellerDisable" type="submit" variant="primary mr-16" size="lg">
			<b-spinner v-if="updating"></b-spinner>
          Update
        </b-button>
        <b-button v-else type="submit" variant="primary mr-16" size="lg">
        	<b-spinner v-if="updating"></b-spinner>
          Create
        </b-button>
        <b-button @click="hideModal" size="lg">Cancel</b-button>
	</div>
	<div class="col pt-12 pb-8 d-flex d-md-none">
		<b-button @click="hideModal" size="lg">Cancel</b-button>
	      <b-button v-if="sellerDisable"  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
	      	<b-spinner v-if="updating"></b-spinner>
	        Update
	      </b-button>
	      <b-button v-else type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
	      	<b-spinner v-if="updating"></b-spinner>
	        Create
	      </b-button>
	</div>
</div>

</b-form>
</div>



</transition>
<transition name="slide-left">
	<div class="mw596 mx-auto" v-if="toggleWoocom">
		<div class="row">
			<div class="col py-10">
				<span class="d-block text-gray-600 pt-12 ">
					<strong>1.</strong> Log in to Admin and click <strong>WooCommerce >> Settings >> Advanced >> Webhooks.</strong>
				</span>
			</div>
		</div>

		<div class="row">
			<div class="col py-10">
				<span class="d-block text-gray-600">
					<strong>2.</strong> Click On <strong>Create a new webhook</strong> or <strong>Add webhook</strong>.The Webhook Data box appears.
				</span>
			</div>
		</div>

		<div class="row">
			<div class="col py-10">
				<span class="d-block text-gray-600">
					<strong>3.</strong> Select <strong>Name, Status, Topic, Delivery URL </strong> and <strong>API Version</strong> as per details below:
				</span>
			</div>
		</div>

		<div class="row">
			<div class="col py-10">
				<span class="d-block text-gray-600">
					<strong>For Real-Time Import of COD orders</strong>
				</span>
				<ul class="pt-8 ">
					<li>Name: create_order</li>
					<li>Status: Active</li>
					<li>Topic: Order Created</li>
					<li>Delivery URL: <span @click="copySuccess" v-clipboard:copy="urL" v-b-tooltip.hover.right title="Click to copy!" class="bg-orange-light d-inline-block ml-8 px-12 py-4 rounded">
					https://api.shyplite.com/woocommerce/import/{{$store.getters.user.id}}</span></li>
					<li>API Version: WP REST API Integration v2</li>
				</ul>
				<span class="pt-6 d-block text-gray-600">This webhook will import your WooCommerce COD orders into Shyplite as soon as they are placed at your store.</span>
			</div>
		</div>

		<div class="row">
			<div class="col py-10">
				<span class="d-block text-gray-600">
					<strong>4.</strong> After creating webhook please enter your Webhook Secret Key below:
				</span>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6 pt-8">
				<b-form-group
				id="mws-token-field"
				label="Webhook Secret:"
				label-for="webhook"
				>
				<b-form-input
				v-model="key5"
				id="webhook"
				placeholder="Webhook Secret"
				></b-form-input>
			</b-form-group>
		</div>
		<div class="col pt-30 text-right d-none d-md-block">
			<b-button @click="saveWebhook()" variant="primary" size="lg">
				<b-spinner v-if="updating"></b-spinner>
				Save
			</b-button>
		</div>
	</div>

	<div class="row">
		<div class="col py-10">
			<span class="d-block text-gray-600">
				<strong>5.</strong> After adding (create_order), create another webhook (update_order) with setting.
			</span>
			<span class="d-block text-gray-600">
				<strong>To: Import prepaid orders, cancel order from woocommerce panel (processed order can't be cancelled.)</strong>
			</span>
			<ul class="pt-8 ">
				<li>Name: update_order</li>
				<li>Status: Active</li>
				<li>Topic: Order update</li>
				<li>Delivery URL: <span @click="copySuccess" v-clipboard:copy="urL" v-b-tooltip.hover.right title="Click to copy!" class="bg-orange-light d-inline-block ml-8 px-12 py-4 rounded">
					https://api.shyplite.com/woocommerce/update/{{$store.getters.user.id}}</span></li>
				<li>Secret: {{ key5 ? key5 : `Create webhook 'create_order' first.` }}</li>
				<li>API Version: WP REST API Integration v2</li>
			</ul>
		</div>
	</div>


</div>
</transition>
</b-modal>
</template>

<script>
export default {
	name: "SettingsWooCom",
	props:{
		editWId: Number
	},
	data() {
		return {
			updating:false,
			isOpen: this.modalOpen,
			form:{
				url:'https://',
				fulfillStatus:'completed',
				importStatus:['processing']
			},
			urL:'https://api.shyplite.com/woocommerce/import/51454',
			status:'',
        // importStatus:[],
        toggleWoocom:false,
        key5:'',
        sellerDisable:false
    };
},
methods: {
	hideModal() {
		this.$bvModal.hide("woo-connector-modal");
		this.form.importStatus = []
		this.form = {}
	},
	saveChip(){
		if (this.status && String(this.status).trim().length > 0) {
			if(this.form.importStatus.length <= 2){
				this.form.importStatus.push(this.status)
				this.status = ''
			}else {
				this.status = ''
				this.popToast("failed", "Failed!", "Maximum 3 status can be imported");
			}
		}
		else {
				this.popToast("failed", "Failed!", "Please Enter an Import Status");
		}
	},
	deleteChip(index) {
		this.form.importStatus.splice(index, 1);
	},
	async woocomConnect(){
		this.updating = true
		let data = Object.assign({}, this.form);
		data.importStatus = data.importStatus.join(',')
		if (data.fulfillStatus == "") {
			data.fulfillStatus = "completed"
		}
		if (this.form.id) {
			try {
				const editres = await this.axios.post('/settings/connectors/woocommerce', data)
				if (editres.data.success) {
					this.popToast("booked", "Success!", editres.data.message);
					this.updating = false
					setTimeout(() => {
						this.$router.go()
					},1000)
				} else {
					this.updating = false
					this.popToast("failed", "Failed!", `${editres.data.error}!! Please Try again`);
				}
			} catch(e) {
				this.updating = false
				this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
				console.log(e);
			}
		} else {
			try {
			const res = await this.axios.put('/settings/connectors/woocommerce', data)
			if(res.data.success) {
				this.popToast("booked", "Success!", res.data.message);
				this.updating = false
				this.insertId = res.data.insertedID
				setTimeout(() => {
						this.toggleWoocom = true
					},1000)
				
			} else {
				this.updating = false
				this.popToast("failed", "Failed!", `${res.data.error}!! Please Try again`);
			}
			} catch(e) {
				this.updating = false
				this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
				console.log(e);
			}
		}
		
	},
	async saveWebhook() {
		this.updating = true
        let post = {}
		if(this.form.id) {
			this.insertId = this.form.id
            post = Object.assign({}, this.form)
            post.key5 = this.key5
            delete post.importStatus
		}
		try {
			const res = await this.axios.post('settings/connectors/woocommerce', post)
			if (res.data.success) {
				this.popToast("booked", "Success!", "WooCom Webhook Updated Successfully");
				this.updating = false
				setTimeout(() => {
						this.$router.go()
					},1000)
			} else {
				this.updating = false
				this.popToast("failed", "Failed!", "WooCom Webhook Updation Failed!! Please Try again Later");
			}
		} catch(e) {
			this.updating = false
			this.popToast("failed", "Failed!", "WooCom Webhook Updation Failed!! Please Try again Later");
			console.log(e);
		}
	},
	copySuccess() {
          this.popToast("booked", "Success", "Successfully copied to clipboard!");
        },
	async formatting(id){
		try {
			const res = await this.axios.get(`/settings/connector/${id}`)
			if (res.data.success) {
				const editConn = {
					id:res.data.result[0].id,
					url:res.data.result[0].key1,
					consumerKey:res.data.result[0].key2,
					consumerSecret:res.data.result[0].key3,
					isactive:res.data.result[0].isactive,
					isrtocancel:res.data.result[0].isrtocancel,
					storeName:res.data.result[0].storeName,
					updateStatus:res.data.result[0].updateStatus,
					fulfillStatus:res.data.result[0].fulfillStatus,
					importStatus:res.data.result[0].importStatus
				}
				editConn.importStatus = editConn.importStatus.split(',')
				this.form = editConn
				this.key5 = res.data.result[0].key5
				this.urL = 'https://api.shyplite.com/woocommerce/import/51454'
			} else {
				this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
			}
		} catch(e) {
			this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
			console.log(e);
		}
	}
},
		watch:{
			isOpen(newValue) {
				if(newValue) {
					let woocomFlag = false

					this.$root.$on('woocom', data => {
						woocomFlag = data
					});

					if (this.editWId) {
						this.formatting(this.editWId)
		          		this.sellerDisable = true
		          		this.toggleWoocom = false
		          		this.status = ''
			        } else if (woocomFlag) {
			      	   this.form = {
			      	   		url:'https://',
							fulfillStatus:'completed',
							importStatus:['processing'],
							isactive:"1",
							updateStatus:"1",
							isrtocancel:"1"
			      	   }
				            this.sellerDisable = false
				            this.toggleWoocom = false
				            this.status= ''
				            this.key5 =''
			        } else {
			        	this.form = {
			        		url:'https://',
							fulfillStatus:'completed',
							importStatus:['processing'],
							isactive:"1",
							updateStatus:"1",
							isrtocancel:"1"
			        	}
			            this.sellerDisable = false
			            this.toggleWoocom = false
			            this.status= ''
			            this.key5 =''
			        }
			    } else {
			    	this.$emit('update:editWId', undefined)
			    }
			}
		}
};
</script>



<style scoped type="text/scss">

/deep/ .dochref {
	font-size: 0.9rem;
}

/deep/ .header-pad {
	padding: 1.75rem 2.5rem 1rem 7.4rem !important;
	border-bottom: 2px solid #f5f7fa;
}

.import_stat {
	width: 11.5rem;
}

.chip {
	display: inline-block;
	padding: 0 10px;
	font-size: 0.9rem;
	line-height: 26px;
	border-radius: 5px;
	background-color: #006EEF;
	color: white;
}

.active {
	color: #006eff;
	border-bottom: 2px solid #006eff !important;
}

.head{
	cursor: pointer;
}
</style>
