<template>
  <b-modal
    id="add-connector-modal"
    modal-class="add-connector-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
    ref="addConnector"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block mt-auto fs18 lh28 fw500">
        Add Connectors
      </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <ul class="connector-wrap row">
      <li
        v-for="connector in connectors"
        :key="connector.id"
        class="col-md-4 col-sm-6"
        v-if="connector.id != 6 ? true : (($store.state.user.id == 48184 || $store.state.user.id == 66510) && connector.id == 6 && !hideZoho) ? true : false"
      >
        <div class="connector modal cp" @click="addShop(connector.id)">
          <div class="connector__img">
            <img :src="connector.img" alt="" />
          </div>
          <div class="connector__meta">
            <span class="connector__title">
              {{ connector.title }}
            </span>
            <span class="connector__subtitle">
              {{ connector.subtitle }}
            </span>
          </div>
          <b-button
            variant="tertiary"
            class="w-100"
          >
            Connect
          </b-button>
        </div>
      </li>
    </ul>

    <div class="row">
      <div class="col mt-0 mt-md-28 mb-26 mb-md-0">
        <i class="icon-support text-yellow"></i>
        <span class="fs14 ml-6">
          Select a connector to setup.
        </span>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "SettingsAddConnector",
  data() {
    return {
      isOpen: this.modalOpen,
      selectedConnectors: [],
      hideZoho:false
    };
  },
  props: {
    ids: Array
  },
  methods: {
    toggleConnector(id) {
      this.connectors[id].status = !this.connectors[id].status;
      this.$parent.$refs.addConnector.isOpen = !this.$parent.$refs.addConnector
        .isOpen;
      this.$parent.$refs.amazonConnector.isOpen = !this.$parent.$refs
        .amazonConnector.isOpen;
    },
    hideModal() {
      this.$bvModal.hide("add-connector-modal");
    },
    addShop(id) {
      if (id == 0) {
        this.$parent.$refs.shopifyConnector.isOpen = true
        this.$root.$emit('shopify', true)
      } else if(id == 1){
        this.$parent.$refs.amazonConnector.isOpen = true
        this.$root.$emit('amazon', true)
      } else if(id == 2){
        this.$parent.$refs.opencartConnector.isOpen = true
        this.$root.$emit('opencart', true)
      } else if(id == 3){
        this.$parent.$refs.magento1Connector.isOpen = true
        this.$root.$emit('magento', true)
      } else if(id == 4) {
        this.$parent.$refs.wooConnector.isOpen = true
        this.$root.$emit('woocom', true)
      } else if (id == 5){
        this.$parent.$refs.flipkartConnector.isOpen = true
        this.$root.$emit('flipkart', true)
      } else {
        this.$parent.$refs.zohoConnector.isOpen = true
        this.$root.$emit('zoho', true)
      }
    },
  },
  computed: {
    connectors() {
      return this.$store.getters.connectors;
    },
  },
  watch: {
    ids(newValue) {
      this.hideZoho = newValue.some(el => el.marketplaceID === 25)
    }
  }
};
</script>
