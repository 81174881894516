<template>
    <div class="container-fluid pb-80 pb-md-60 pb-lg-0">
        <div class="row">
            <div class="col-lg-8">
                <b-skeleton-wrapper :loading="isBusy">
                    <template #loading>
                        <b-card class="mb-12">
                            <div class="card service content-active">
                                <div class="card__title">
                                    <b-skeleton width="25%" class="p-15"></b-skeleton>
                                </div>
                                <div class="card__content">
                                    <b-skeleton width="85%" class="mb-10"></b-skeleton>
                                    <b-skeleton width="55%" class="mb-10"></b-skeleton>
                                    <b-skeleton width="70%" class="mb-10"></b-skeleton>
                                </div>
                            </div>
                        </b-card>
                        <b-card class="mb-12">
                            <div class="card service content-active">
                                <div class="card__title">
                                    <b-skeleton width="20%" class="p-15"></b-skeleton>
                                </div>
                                <div class="card__content">
                                    <b-skeleton width="85%" class="mb-10"></b-skeleton>
                                    <b-skeleton width="55%" class="mb-10"></b-skeleton>
                                    <b-skeleton width="70%" class="mb-10"></b-skeleton>
                                </div>
                            </div>
                        </b-card>
                    </template>
                </b-skeleton-wrapper>
                <div v-for="carrier in carriers" :key="carrier.carrierID" class="mb-12">
                    <div v-if="carrier.status" class="card service" :class="{ 'content-active': carrier.content }">
                        <div class="card__title">

                                <div class="col"><img :src="require(`@/assets/images/${carrier.carrierCode}.png`)" class="service-logo" :alt="carrier.carrierCode"/>
                                <span class="fs20 lh24 fw500 ml-10 ml-md-16">
                                    {{ carrier.carrierName | capitalize }}
                                </span></div>
                                <div class="col">
                                    <b-button class="float-right" @click="disableCarrier(carrier.carrierName,carrier.carrierCode,carrier.modeData,carrier.carrierenabledisable && carrier.carrierenabledisable == 1 ? 1 : 0)" variant="primary">{{carrier.carrierenabledisable && carrier.carrierenabledisable == 1 ? 'Disable' : 'Enable'}}</b-button>
                                </div>


                            <!-- <div class="ml-auto">
                                <template v-if="!carrier.disabled">
                                    <b-button variant="tertiary" class="px-20 px-md-32" @click="carrier.disabled = !carrier.disabled">Enable</b-button>
                                </template>

                                <template v-else>
                                    <b-button variant="disable" class="px-18 px-md-30" @click="carrier.disabled=!carrier.disabled">
                                        Disable
                                    </b-button>
                                </template>

                                <b-button @click="carrier.content = !carrier.content" variant="icon-transparent pr-0 pl-20 pl-md-30">
                                    <i class="icon-dropdown fs11"></i>
                                </b-button>
                            </div> -->
                        </div>

                        <div class="card__content">
                            <div class="mw596 mx-auto">
                                <ul class="service">
                                    <div v-for="(mode, index) in carrier.modeData" :key="index">
                                        <li v-if="mode.status < 2">

                                            <span :class="{disabled: carrier.disabled,}">
                                                {{ mode.name }}
                                            </span>

                                            <b-form-group class="mb-0">
                                                <span class="d-inline-block text-gray-900 w20" :class="{disabled:carrier.disabled,}">{{ mode.status > 0 ? "" : "Off" }}
                                                </span>
                                                <b-form-checkbox unchecked-value="1" value="0" @change="onChange(mode,carrier.carrierCode, carrier.carrierName)" v-model="mode.status" class="d-inline mr-20 ml-20" name="default-pickup" :disabled="carrier.disabled" switch></b-form-checkbox>
                                                <span class="d-inline-block text-gray-900 w20" :class="{disabled:carrier.disabled,}">{{ mode.status > 0 ? "On" : "" }}
                                                </span>
                                            </b-form-group>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="card content-active sticky-top mt-10 top100">
                    <div class="card__content pt-36 pb-40 px-24">
                        <img src="@/assets/svg/cube.svg" alt="" />
                        <span class="d-block fs18 lh28 fw500 mt-20">
                            How carrier modes work?
                        </span>
                        <span class="d-block fs14 lh20 text-gray-800 mt-8">
                            Disabling a carrier modes, disables the service for
                            all order types (Prepaid, COD & Reverse) for that
                            carrier.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SettingsService",
    data() {
        return {
            carriers: [],
            isBusy: false,
            carrierenabledisable:null
        };
    },
    created() {
        this.getItems();
    },
    methods: {
        async disableCarrier(name,code,data,carrieraction) {
            let carrierModes = []

            if (carrieraction == 1) {
                carrierModes = data.filter(i => {return i.status == 0}).map(i => {return i.mode})
            } else {
                carrierModes = data.filter(i => {return i.status == 1}).map(i => {return i.mode})
            }
            

            let objData = {
                action : carrieraction,
                mode : carrierModes,
                carrierID : code
            }

            try {
                const res = await this.axios.post(`/settings/carriersettings`, objData);
                if(carrieraction == 1) {
                    this.popToast("booked", "Success!",name + " has been disabled.");
                }else {
                    this.popToast("booked", "Success!",name + " has been enabled.");
                }
                await this.getItems();
            } catch (error) {
                console.log(error);
            }

        },
        async getItems() {
            try {
                this.isBusy = true
                const res = await this.axios.get(`/settings/service`);
                this.carriers = res.data;
                this.carriers.forEach(i => {
                    i.modeData.forEach(j => {
                        if (j.status == 0) {
                            i['carrierenabledisable'] = 1
                            return false
                        }
                    })
                })
            } catch (error) {
                console.log(error);
            }
            this.isBusy = false
        },
        async onChange(mode, carrierCode, name) {
            const post = {
                carrierID: carrierCode,
                mode: [mode.mode],
                action: mode.status,
            };
            try {
                const res = await this.axios.post(`/settings/carriersettings`, post);
                if(mode.status == 1) {
                    this.popToast("booked", "Success!",name + " " + mode.name + " has been disabled.");
                }else {
                    this.popToast("booked", "Success!",name + " " + mode.name + " has been enabled.");
                }
                await this.getItems();
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
