<template>
	<div class="card mt-4 mb-24">
		<div class="card__title">
			<span class="fs16 fw500  d-block">
				Priority Options
			</span>
		</div>
		<div class="card__content">
			<b-table
			:items="items"
			:fields="fields"
			sort-by="zoneMode"
			:busy="isBusy"
			no-border-collapse
			id="priority-options-table"
			class="dark-header"
			responsive
			>
			<template #table-busy>
				<div class="text-center text-danger my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
			<!-- A custom formatted data column cell for field 'within city' -->
			<template v-slot:cell(withinCity)="data">
				<multiselect
				open-direction="bottom"
				class="h36-select"
				:searchable="false"
				:show-labels="false"
				label="carrierName"
				@input="setPriority(data.item.withinCity)"
				v-model="data.item.withinCity.name"
				:options="data.item.options"
				></multiselect>
			</template>
			<!-- A custom formatted data column cell for field 'within city' -->
			<template v-slot:cell(withinZone)="data">
				<multiselect
				open-direction="bottom"
				class="h36-select"
				:searchable="false"
				:show-labels="false"
				label="carrierName"
				@input="setPriority(data.item.withinZone)"
				v-model="data.item.withinZone.name"
				:options="data.item.options"
				></multiselect>
			</template>
			<!-- A custom formatted data column cell for field 'within city' -->
			<template v-slot:cell(betweenMetro)="data">
				<multiselect
				open-direction="bottom"
				class="h36-select"
				:searchable="false"
				:show-labels="false"
				label="carrierName"
				@input="setPriority(data.item.betweenMetro)"
				v-model="data.item.betweenMetro.name"
				:options="data.item.options"
				></multiselect>
			</template>
			<!-- A custom formatted data column cell for field 'within city' -->
			<template v-slot:cell(restOfIndia)="data">
				<multiselect
				open-direction="bottom"
				class="h36-select"
				:searchable="false"
				:show-labels="false"
				label="carrierName"
				@input="setPriority(data.item.restOfIndia)"
				v-model="data.item.restOfIndia.name"
				:options="data.item.options"
				></multiselect>
			</template>
			<!-- A custom formatted data column cell for field 'within city' -->
			<template v-slot:cell(special)="data">
				<multiselect
				open-direction="bottom"
				class="h36-select"
				:searchable="false"
				:show-labels="false"
				label="carrierName"
				@input="setPriority(data.item.special)"
				v-model="data.item.special.name"
				:options="data.item.options"
				></multiselect>
			</template>
		</b-table>
		<PriorityInfoCard />
	</div>
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PriorityInfoCard from "@/components/PriorityInfoCard.vue";

export default {
	name: "Priority",
	data() {
		return {
			items: [],
			isBusy: false,
			fields: [
			{ key: "zoneMode", label: "Zone / Mode", sortable: true, tdClass: "wsnw" },
			{ key: "withinCity", label: "Within City"},
			{ key: "withinZone", label: "Within Zone"},
			{ key: "betweenMetro", label: "Between Metro"},
			{ key: "restOfIndia", label: "Rest of India"},
			{ key: "special"},
			],
			carrierList: [],
			priority: [],
		};
	},
	components: {
		Multiselect,
		PriorityInfoCard
	},
	created() {
		this.getPriority()
	},
	methods: {
		async getPriority() {
			try {
				this.isBusy = true
				const res = await this.axios.get('/settings/priority')
				const modes = [1, 9, 8, 3, 10, 2, 20]
				let p = []
				for(let i of modes) {
					p.push({"sellerID": this.$store.getters.user.id, "modeID": i, "zone1": null, "zone2": null, "zone3": null, "zone4": null, "zone5": null})
				}
				if(res.data.pt) {
					let priority = res.data.priority
					let finalPirority = [];
					p.map(val => {
						priority.map( value => {
							if ( val.modeID == value.modeID )
								Object.assign(val, value)
						})
						finalPirority.push(val);
					})
					const items = finalPirority.map(item => {
						const mode =  this.staticName(this.$store.getters.modes, item.modeID)
						const options = res.data.carrierList.filter(item => item.modeData[mode]==0)

						return {
							zoneMode 	: mode,
							withinCity	: {name: options.filter(i=>i.carrierID==item.zone1)[0], oldCarrier:item.zone1, modeID:item.modeID, zone:'zone1'},
							withinZone	: {name: options.filter(i=>i.carrierID==item.zone2)[0], oldCarrier:item.zone2, modeID:item.modeID, zone:'zone2'},
							betweenMetro: {name: options.filter(i=>i.carrierID==item.zone3)[0], oldCarrier:item.zone3, modeID:item.modeID, zone:'zone3'},
							restOfIndia	: {name: options.filter(i=>i.carrierID==item.zone4)[0], oldCarrier:item.zone4, modeID:item.modeID, zone:'zone4'},
							special		: {name: options.filter(i=>i.carrierID==item.zone5)[0], oldCarrier:item.zone5, modeID:item.modeID, zone:'zone5'},
							options 	: options
						}

					})
					this.items = items
					this.carrierList = res.data.carrierList
					this.priority 	 = res.data.priority
					this.isBusy = false
				}
			} catch(e) {
				console.error(e);
			}
		},

		async setPriority(input) {
			let post = {}
			if(input.name) {
				post[input.zone] = input.name.carrierID
			}else{
				post[input.zone] = null
			}
			post.modeID = input.modeID
			post.oldCarrier = input.oldCarrier
			try {
				const res = await this.axios.post(`/settings/setpriority`, post)
				if(res.data.success == true) {
					this.popToast('booked', 'Success!', 'Priority option has been updated.')
				}
			} catch(e) {
				console.log(e);
			}
		},

		staticName(statics, index) {
			try {
				if(index===null)
					return ''
				return statics.filter(i => i.value == index)[0].name
			} catch(e) {
				console.log(e);
			}
		},
	}
};
</script>

<style lang="css" scoped="">
/deep/ .multiselect__content-wrapper{
	top: -0.5rem;
    position: absolute;
    max-height: 200px !important;
}

/deep/ .b-table-sticky-header {
	max-height: 500px !important;
}
</style>