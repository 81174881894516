<template>
	<div class="warehouse-sidebar">
		<b-sidebar
			id="sidebarFilters"
			right
			backdrop
			backdrop-variant="dark"
			sidebar-class="filter-side"
			no-header
			shadow
			v-model="sidebarVisible"
		>
			<div class="d-flex flex-column h100vh px-36 pb-32 pt-36">
				<div class="header d-flex align-items-center pb-40">
					<i class="icon-filter text-gray-600 fs24 pr-16"></i>
					<h2 class="text-gray-900">Filters</h2>
					<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
						<i class="icon-close-variant"></i>
					</b-button>
				</div>

				<b-form-group>
					<multiselect
			            id="warehouse"
			            v-model="form.warehouseID"
			            :options="$store.getters.warehouses"
			            :searchable="true"
			            title="Warehouse"
			            :show-labels="false"
			            placeholder="Warehouse"
			            class="mb-17"
			            label="name"
			            @input="addFilter({warehouseID: form.warehouseID})"
			        ></multiselect>
				</b-form-group>

				<div class="footer mt-auto text-right pb-32 pt-8">
					<b-link
						v-if="this.hasFilters"
						class="secondary fw500 mr-20"
						@click="clearFilters()"
					>
					Clear filters
					</b-link>
					<b-button
						:disabled="!this.hasFilters"
						variant="primary"
						@click="applyFilter"
					>
					Apply filter
					</b-button>
				</div>
			</div>
		</b-sidebar>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	name: "FilterSidebar",
	props: [
		'noText'
	],

	data() {
		return {
			sidebarVisible: false,
			selectedFilter: 1,
			form: {},
			selectedFilters: this.$store.getters.filters.warehouse || {},
		};
	},

	components: {
		Multiselect,
	},

	created() {
		let form = Object.assign({}, this.$store.getters.filters.warehouse);
		this.form = form
	},

	methods: {
		async applyFilter(event) {
			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'warehouse': this.selectedFilters})
			this.$parent.getFilters()
		},
		addFilter(filter) {
			if(Object.values(filter)[0]) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}
			else {
				let temp = this.selectedFilters
				delete temp[Object.keys(filter)[0]]
				this.selectedFilters = Object.assign({}, temp)
			}
		},
		clearFilters() {
			let filter = this.form
			this.form = {}
			this.selectedFilters = [];
			this.$store.commit('setFilter', {})
			if(Object.keys(filter).length>0) {
				this.$parent.clearFilters();
			}
		},
	},

	computed: {
		hasFilters() {
			if (Object.keys(this.selectedFilters).length>0) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.warehouse-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	z-index: 10;
	.elem-outer {
		z-index: 10;
	}
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		position: fixed;
		top: rem(85px);
		right: rem(20px);
		z-index: 10;
	}
	input.multiselect__input{
		height:auto;
		padding:0;
	}
}

.filter-div {
	position: absolute;
	bottom: 5rem;
	right: 2rem;
}

</style>
