var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pt-10 pt-md-0" },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          !_vm.filterResultsToggle
            ? _c("transition", { attrs: { name: "slide-left" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col pb-22 d-flex align-items-center justify-content-between"
                  },
                  [
                    _c("span", { staticClass: "fs14 lh16 fw700" }, [
                      _vm._v(" System SKUs (" + _vm._s(_vm.totalRows) + ")")
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-10",
                        attrs: { variant: "primary" },
                        on: { click: _vm.openAddModal }
                      },
                      [
                        _c("i", { staticClass: "icon-add fs16 mr-10" }),
                        _vm._v(" Add SKU ")
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("transition", { attrs: { name: "slide-right" } }, [
            _vm.filterResultsToggle
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col pb-22 d-flex align-items-center justify-content-between"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        this.selFilters.length != "0"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "d-none d-md-flex mr-6 text-gray-600 wsnw"
                              },
                              [_vm._v(" Results for: ")]
                            )
                          : _vm._e(),
                        _c(
                          "ul",
                          { staticClass: "d-none d-md-flex result-list" },
                          _vm._l(_vm.selFilters, function(selFilter, index) {
                            return _c(
                              "li",
                              { key: index },
                              [
                                index != "filtering" && selFilter != ""
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "mx-4 my-1",
                                        attrs: { variant: "tag" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "wsnw text-truncate" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  typeof selFilter == "number"
                                                    ? _vm.modeTypes[selFilter]
                                                    : selFilter
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass: "icon-close fs6",
                                          on: {
                                            click: function($event) {
                                              return _vm.removeFilter(index)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        this.selFilters.length != "0"
                          ? _c("span", { staticClass: "d-flex d-md-none" }, [
                              _vm._v(
                                " " +
                                  _vm._s(this.selFilters.length) +
                                  " Filters selected. "
                              )
                            ])
                          : _c("span", { staticClass: "d-flex d-md-none" }, [
                              _vm._v(" No filters selected. ")
                            ]),
                        _c(
                          "b-link",
                          {
                            staticClass: "ml-24 mr-12 vam",
                            on: {
                              click: function($event) {
                                return _vm.clearFilters()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "text-gray-900 wsnw" }, [
                              _vm._v("Clear filters")
                            ]),
                            _c("i", {
                              staticClass:
                                "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "d-flex pb-24 filterBar" },
            [
              _vm.totalRows != ""
                ? _c("b-button", { on: { click: _vm.exportSkus } }, [
                    _c("i", { staticClass: "icon-download fs16 mr-10" }),
                    _vm._v(" Export ")
                  ])
                : _vm._e(),
              _c("div", { staticClass: "hbreak d-none d-md-inline-flex" }),
              _c("div", { staticClass: "divider mx-20 my-auto vam d-md-flex" }),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.sidebarFilters",
                      modifiers: { sidebarFilters: true }
                    }
                  ],
                  staticClass: "ndr-side-btn text-gray-900"
                },
                [
                  _c("span", [_vm._v(" Filters ")]),
                  _c("i", { staticClass: "icon-filter text-gray-600 pl-10" })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            this.windowWidth > 767
              ? _c("b-table", {
                  staticClass: "sku-table billing-table",
                  attrs: {
                    items: _vm.items,
                    fields: _vm.fields,
                    "per-page": _vm.perPage,
                    "no-border-collapse": "",
                    "sticky-header": "",
                    busy: _vm.isBusy
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-danger my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "cell(productName)",
                        fn: function(data) {
                          return [
                            _c(
                              "span",
                              { attrs: { title: data.item.productName } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.ellipsize(data.item.productName)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(length)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.dimensions.length) + " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(width)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.dimensions.width) + " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(height)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.dimensions.height) + " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(weight)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.dimensions.weight) + " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(lastUpdate)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("date")(data.item.lastUpdate)) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(action)",
                        fn: function(data) {
                          return [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "tertiary", size: "xs" },
                                on: {
                                  click: function($event) {
                                    return _vm.editSku(data.item)
                                  }
                                }
                              },
                              [_vm._v(" Edit ")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    716249405
                  )
                })
              : _vm._e(),
            _c("div", { staticClass: "pagination-bar" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      { staticClass: "d-inline-flex wsnw align-items-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-block w50px" },
                          [
                            _c("multiselect", {
                              staticClass: "pagination-items__pp",
                              attrs: {
                                searchable: false,
                                "show-labels": false,
                                "allow-empty": false,
                                options: _vm.pageOptions
                              },
                              on: { input: _vm.setPageNumber },
                              model: {
                                value: _vm.perPage,
                                callback: function($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage"
                              }
                            })
                          ],
                          1
                        ),
                        _c("span", {
                          staticClass:
                            "pl-8 fs12 lh24 text-gray-600 pagination-items"
                        })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col-8 text-right" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-inline-flex ml-auto wsnw align-items-center"
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "fs12 lh24 text-gray-600 pr-14" },
                          [_vm._v(" Page: " + _vm._s(_vm.currentPage) + " ")]
                        ),
                        _c(
                          "b-button-group",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: _vm.prevDisabled,
                                  variant: "paginate left"
                                },
                                on: { click: _vm.prevPage }
                              },
                              [_c("i", { staticClass: "icon-back fs12" })]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: _vm.nextDisabled,
                                  variant: "paginate right"
                                },
                                on: { click: _vm.nextPage }
                              },
                              [_c("i", { staticClass: "icon-arrow fs12" })]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "ul",
        { staticClass: "table-cards d-flex d-md-none" },
        _vm._l(_vm.items, function(item) {
          return _c("li", { key: item.id }, [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-between"
              },
              [
                _c("span", { staticClass: "d-block pb-8 mt-10" }, [
                  _c("span", { staticClass: "fw500" }, [_vm._v("SKU")]),
                  _vm._v(" : " + _vm._s(item.sku) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex float-right mr-10" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "tertiary", size: "xs" },
                        on: {
                          click: function($event) {
                            return _vm.editSku(item)
                          }
                        }
                      },
                      [_vm._v(" Edit ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "info" }, [
                _c("span", { staticClass: "pl-0 mb-4" }, [
                  _c("span", { staticClass: "fw500" }, [
                    _vm._v("Product Name")
                  ]),
                  _vm._v(
                    " : " + _vm._s(_vm._f("capitalize")(item.productName)) + " "
                  )
                ]),
                _c("span", { staticClass: "carrier-mode mb-4" }, [
                  _c("span", { staticClass: "fw500" }, [
                    _vm._v("Dimensions(LxWxH)")
                  ]),
                  _vm._v(
                    ": " +
                      _vm._s(item.dimensions.length) +
                      "x" +
                      _vm._s(item.dimensions.width) +
                      "x" +
                      _vm._s(item.dimensions.height) +
                      " cm "
                  )
                ]),
                _c("span", { staticClass: "carrier-mode mb-4" }, [
                  _c("span", { staticClass: "fw500" }, [_vm._v("Weight")]),
                  _vm._v(": " + _vm._s(item.dimensions.weight) + " Kg ")
                ])
              ])
            ]),
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "price" }, [
                _c("span", { staticClass: "d-block mb-4" }, [
                  _c("span", { staticClass: "fw500" }, [_vm._v("Mode Type")]),
                  _vm._v(" : " + _vm._s(item.modeType || " N/A") + " ")
                ]),
                _c("span", { staticClass: "d-block mb-4" }, [
                  _c("span", { staticClass: "fw500" }, [_vm._v("Last Update")]),
                  _vm._v(" : " + _vm._s(_vm._f("date")(item.lastUpdate)))
                ])
              ])
            ])
          ])
        }),
        0
      ),
      _c("SettingsSkusEdit", {
        ref: "editSkuModal",
        attrs: { editItem: _vm.editItem },
        on: { updateSku: _vm.updateSku }
      }),
      _c("SettingsSkusAdd", {
        ref: "addSkuModal",
        on: {
          addSku: _vm.addSku,
          fileData: function($event) {
            return _vm.getItems()
          }
        }
      }),
      _c("SettingsSKUfilter", { ref: "filter" }),
      _vm.items.length == 0 && !_vm.isBusy
        ? _c("ResultNotFound", { staticClass: "mt-100" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }