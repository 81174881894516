var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "addConnector",
      attrs: {
        id: "add-connector-modal",
        "modal-class": "add-connector-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Add Connectors ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "ul",
        { staticClass: "connector-wrap row" },
        _vm._l(_vm.connectors, function(connector) {
          return (connector.id != 6
          ? true
          : (_vm.$store.state.user.id == 48184 ||
              _vm.$store.state.user.id == 66510) &&
            connector.id == 6 &&
            !_vm.hideZoho
          ? true
          : false)
            ? _c(
                "li",
                { key: connector.id, staticClass: "col-md-4 col-sm-6" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "connector modal cp",
                      on: {
                        click: function($event) {
                          return _vm.addShop(connector.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "connector__img" }, [
                        _c("img", { attrs: { src: connector.img, alt: "" } })
                      ]),
                      _c("div", { staticClass: "connector__meta" }, [
                        _c("span", { staticClass: "connector__title" }, [
                          _vm._v(" " + _vm._s(connector.title) + " ")
                        ]),
                        _c("span", { staticClass: "connector__subtitle" }, [
                          _vm._v(" " + _vm._s(connector.subtitle) + " ")
                        ])
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "w-100",
                          attrs: { variant: "tertiary" }
                        },
                        [_vm._v(" Connect ")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        }),
        0
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col mt-0 mt-md-28 mb-26 mb-md-0" }, [
          _c("i", { staticClass: "icon-support text-yellow" }),
          _c("span", { staticClass: "fs14 ml-6" }, [
            _vm._v(" Select a connector to setup. ")
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }