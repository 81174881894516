<template>
  <div class="container-fluid position-relative">
    <transition name="slide-left" v-if="!filterResultsToggle">
      <div class="d-flex pb-22 align-items-center justify-content-between">
      <span class="fw700 pb-md-0"></span>
      <span class="d-flex">
        <b-button v-b-tooltip.hover.left variant="primary" @click="addNewUser">
          <svg class="editsvg mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z"/></svg>
        Add New Consignor</b-button>
        <div class="divider mx-20 my-auto vam d-md-flex"></div>
        <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
          <span>
            Filters
          </span>
          <i class="icon-filter text-gray-600 pl-10"></i>
        </b-button>
      </span>
    </div>
    </transition>   
    


    <transition name="slide-right">
         <div class="col pb-22 pt-md-20 pt-20 d-flex align-items-center justify-content-between" v-if="filterResultsToggle">
            <div class="d-flex align-items-center">
                <span
                class="d-none d-md-flex mr-6 text-gray-600 wsnw"
                v-if="this.selFilters.length != '0'"
                >
                Results for:
            </span>
                    <ul class="d-none d-md-flex result-list">
                        <li v-for="(selFilter, index) in selFilters" :key="index">
                            <b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering' && selFilter != '' ">
                                <span class="wsnw text-truncate">
                                    {{typeof selFilter == 'number' ? modeTypes[selFilter] : selFilter}}
                                </span>
                                <i
                                class="icon-close fs6"
                                @click="removeFilter(index)"
                                ></i>
                            </b-button>
                        </li>
                    </ul>

                    <span
                    class="d-flex d-md-none"
                    v-if="this.selFilters.length != '0'"
                    >
                    {{ this.selFilters.length }} Filters selected.
                </span>
                <span class="d-flex d-md-none" v-else>
                    No filters selected.
                </span>
                <b-link @click="clearFilters()" class="ml-24 mr-12 vam">
                    <span class="text-gray-900 wsnw">Clear filters</span>
                    <i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
                </b-link>
            </div>
            <div class="d-flex">
              <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
                <span>
                  Filters
                </span>
                <i class="icon-filter text-gray-600 pl-10"></i>
              </b-button>
            </div>
            </div>

        </transition>

<div v-if="this.windowWidth > 767">
  <b-table
  :items="items"
  :fields="fields"
  :per-page="perPage"
  :busy="isBusy"
  sticky-header
  no-border-collapse
  class="warehouse-table billing-table consignor-table"
  id="manifests-table"
  >
  <!-- Table Busy loader -->
  <template #table-busy>
    <div class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </template>
  <template v-slot:cell(name)="data">
    <span :title="data.item.name">{{ ellipsize(capitalizeFirstLetter(data.item.name)) }}</span>
  </template>
  <!-- A custom formatted data column cell for field 'Date' -->
  <template v-slot:cell(bookingType)="data">
      {{ capitalizeFirstLetter(data.item.bookingType) }}
  </template>

  <template v-slot:cell(csbType)="data">
      {{ capitalizeFirstLetter(data.item.csbType) }}
  </template>
  <!-- A custom formatted header cell for field 'selected' -->

  <template v-slot:cell(status)="data">
    <div class="d-flex flex-column">
      <span class="text-truncated">
        <b-form-checkbox
        class="d-inline"
        name="default-pickup"
        v-model="data.item.status"
        switch
        @change="userStatus(data.item.id,data.item.status)"
      ></b-form-checkbox>
      </span>
      <span class="fs12 mt-2 lh16 text-gray-600 text-truncate">
            <span style="color: #65d678;" v-if="data.item.status">Active</span>
            <span v-else>Inactive</span>
          </span>
    </div>
  </template>

  <!-- Example scoped slot for select state illustrative purposes -->

  <!-- A custom formatted data column cell for field 'Action' -->
  <template v-slot:cell(action)="data">
    <b-button variant="primary btn-xs" @click="editConsignor(data.item.id)">
      <svg class="editsvg mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"/></svg>
      Edit
    </b-button>
  </template>
</b-table>
</div>
<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
  <li class="" v-for="item in items" :key="item.id">
    <div class="d-flex justify-content-between w-100">
      <span class="d-block fw500 pb-8 cp" @click="goShipment(item.id)">
        Consignor :
        {{ item.name }}
      </span>
      <div class="d-flex align-items-end justify-content-between">
        <b-button variant="primary btn-xs" @click="editConsignor(item.id)">
          <svg class="editsvg mr-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"/></svg>
          Edit
        </b-button>
      </div>
    </div>
    <div class="d-flex align-items-end justify-content-between w-100 mb-12">
      <div class="info">
        <span class="carrier-mode">
          Consignor id : {{ item.id }}
        </span>
        <span class="carrier-mode">
          {{ item.phone }}
        </span>
        <span class="carrier-mode">
          {{ item.csbType }}
        </span>
      </div>
    </div>
    <span>
      {{ item.pickupAddress }}
    </span>
    <div class="d-flex w-100 align-items-end justify-content-between">
      <span></span>

      <span class="" :class="item.status">
        <div class="d-flex flex-column">
          <span class="text-truncated">
            <b-form-checkbox
            class="d-inline"
            name="default-pickup"
            v-model="item.status"
            switch
            @change="userStatus(item.id,item.status)"
          ></b-form-checkbox>
          </span>
          <span class="fs12 mt-2 lh16 text-gray-600 text-truncate">
                <span style="color: #65d678;" v-if="item.status">Enabled</span>
                <span v-else>Disabled</span>
              </span>
        </div>
      </span>
    </div>
  </li>
</ul>

<div class="pagination-bar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="d-inline-flex wsnw align-items-center">
          <div class="d-block w50px">
            <multiselect
            class="pagination-items__pp"
            v-model="perPage"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            :options="pageOptions"
            @input="setPageNumber"
            ></multiselect>
          </div>
          <span
          class="pl-8 fs12 lh24 text-gray-600 pagination-items"
          ></span>
        </div>
      </div>
      <div class="col-8 text-right">
        <div class="d-inline-flex ml-auto wsnw align-items-center">
          <p class="fs12 lh24 text-gray-600 pr-14">
            Page: {{ currentPage }}
          </p>

          <b-button-group>
            <b-button
            @click="prevPage"
            :disabled="prevDisabled"
            variant="paginate left"
            >
            <i class="icon-back fs12"></i>
          </b-button>

          <b-button
          @click="nextPage"
          :disabled="nextDisabled"
          variant="paginate right"
          >
          <i class="icon-arrow fs12"></i>
        </b-button>
      </b-button-group>
    </div>
  </div>
</div>
</div>
</div>
<ResultNotFound v-if="items.length == 0 && !isBusy" />
<ConsignorFilter ref="filter" />
<AddNewConsignor :editUserflag.sync="editUserflag" :opencloseflag.sync="opencloseflag" :editId.sync="editId"  ref="addNewConsignor" />
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ResultNotFound from "@/components/ResultNotFound.vue";
import ConsignorFilter from "@/views/consignor/ConsignorFilter.vue";
import AddNewConsignor from "@/views/consignor/AddNewConsignor.vue";

export default {
  name: "ConsignorTable",
  data() {
    return {
      editId:null,
      pageOptions: this.$store.state.pageOptions,
      totalRows: 1,
      currentPage: parseInt(this.$route.query.page) || 1,
      perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
      totalPages: 1,
      actionOptionsname:null,
      prevDisabled: true,
      nextDisabled: false,
      startPage: 1,
      disableAdd:false,
      pagesArr: [],
      actionOptions: [
          {id:0,name:"Single"}, 
          {id:3,name:"International"}
      ],  
      pagination: {},
      items: [],
      opencloseflag : false,
      reconciliation:false,
      isBusy: true,
      filterResultsToggle: this.$store.getters.filters.consignor ? true : false,
      allSelected: false,
      selFilters: this.$store.getters.filters.consignor || {},
      warehouses: this.$store.getters.warehouses || {},
      downloading: false,
      editUserflag:false,
      fields: [
      { key: "id", label : "Consignor ID",thClass: "",tdClass: "w-10" },
      { key: "name", label : "Name",thClass: "",tdClass: " w-25" },
      { key: "phone", label: "Contact No.",thClass: "",tdClass: " w-25"  },
      { key: "csbType", label: "Custom Type",thClass: "w-20",tdClass: "w-20"  },
      { key: "status",thClass: "",tdClass: "w-25" },
      { key: "action", thClass: "",tdClass: " w-50" },
      ],
    };
  },
  components: {
    Multiselect,
    ResultNotFound,
    AddNewConsignor,
    ConsignorFilter
  },
  created() {
    this.getItems()
    if(this.$store.getters.user.mpsStatus == 1) {
      this.actionOptions.push({id:2,name:"MPS"})
    }
    if(this.$store.getters.user.b2bIsActive == 1) {
      this.actionOptions.push({id:4,name:"B2B"})
    }
  },
  methods: {
    async userStatus(Id,flag) {
      const body = {
        id : Id,
        status : flag == true ? true : false
      }
      try {
        const res = await this.axios.post('consignor/update/status',body)
        if (res.data.status) {
          this.popToast('booked','Success',res.data.message)
        } else {
          this.popToast('failed','Failed',res.data.message)
        }
      } catch(e) {
        this.popToast('failed','Failed','Updation Failed!!. Please Try Again Later')
        console.log(e);
      }
    },
    editConsignor(id) {
      this.editId = id
      this.editUserflag = true
      this.$refs.addNewConsignor.isOpen = !this.$refs.addNewConsignor.isOpen
    },
    capitalizeFirstLetter(str) {

        const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

        return capitalized;
    },
    goNeworder(obj,Customerobj) {
      this.$store.commit('setTab', obj.id)
      this.$router.push('/new')
      this.$store.commit('setCustomerObj',Customerobj)
    },
    addNewUser() {
      this.$refs.addNewConsignor.isOpen = !this.$refs.addNewConsignor.isOpen
    },

    async getItems(params) {

      try {

        this.isBusy = true

        if (!params) {
          let params = {}
        }
        if(Object.keys(this.selFilters).length > 0) {
          params = {...{filtering: true},...this.selFilters}
        } else {
          params = {...{filtering: false}}
        }

        const res = await this.axios.get(`/consignor/getData?page=${this.currentPage}&offset=${this.perPage}`, {params:params})

        if (res.data.status) {

          this.items = res.data.data ? res.data.data.map(item => {

          let returnItems = {
              id:item.id,
              name:item.name,
              phone:item.phone,
              csbType:item.csbType == 'csbV' ? 'CSB V' : 'CSB IV',
              role:item.role,
              status: item.status == 1 ? true : item.status == 2 ? false : "",
            };
            return returnItems;
          }) : []

          this.totalRows = this.items.length;
          // this.pagination = res.data.response.data.pagination;
          this.setPagination()
          if(this.items.length < this.perPage) {
            this.nextDisabled = true
          } else {
            this.nextDisabled = false
          }
        } else {
          this.items = []
        }

        this.isBusy = false

      } catch (error) {
        this.isBusy = false
        this.nextDisabled = true
        console.log(error)
        // this.popToast('failed', 'Failed!', error)
      }
    },
    setPagination() {
     // this.nextDisabled = this.pagination.next ? false : true
     // this.prevDisabled = this.pagination.prev ? false : true
     // this.$router.push(`?page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
    },
    pushTotalPages() {
     this.pagesArr = [];
     for (var i = 1; i <= this.totalPages; i++) {
       this.pagesArr.push(i);
     }
    },
    setPageNumber() {
     this.currentPage = 1
     this.getItems()
     this.totalPages = Math.ceil(this.totalRows / this.perPage);
     this.pagesArr = [];
     for (var i = 1; i <= this.totalPages; i++) {
       this.pagesArr.push(i);
     }

     this.prevDisabled = true;
     if (this.totalPages == 1) {
       this.nextDisabled = true;
     } else if (this.totalPages > 1) {
       this.nextDisabled = false;
     }
    },
    updatePageNumber() {
     this.totalPages = Math.ceil(this.totalRows / this.perPage);
     this.pagesArr = [];
     for (var i = 1; i <= this.totalPages; i++) {
       this.pagesArr.push(i);
     }
    },
    nextPage() {
     this.currentPage++;
     this.getItems()
    },
    prevPage() {
     this.currentPage--
     this.getItems()
    },
    toggleAllRows() {
     if (this.allSelected == true) {
       this.items.forEach((item) => {
         if(item.shipments > 0 && item.carrier != 'Shypmax') {
           this.$set(item, "selected", true);
         }
         this.allSelected = true;
       });
     } else {
       this.selectedRows.forEach((item) => {
         // this.$delete(item, "selected");
         this.$set(item, "selected", false);
         this.allSelected = false;
       });
     }
    },
    clearSelected() {
     this.selectedRows.forEach((item) => {
       this.$delete(item, "selected");
     });
     this.allSelected = false;
    },
    async downloadManifests() {
      try {
        this.downloading = true
        this.popToast("requested", "Download requested", "Your download should start now.");
        let ids = ''
        let closedIds = []
        this.selectedRows.map(item => {
          closedIds.push(item.id)
          ids +='-'+ item.id
        })
        
        const res = await this.axios.get(`/manifestpdf/${ids.substring(1)}`)
        if(res.data.path) {
          this.items.filter(i => closedIds.indexOf(i.id)>=0).map(item => item.status='close')
          this.download(res.data.path, res.data.name)
        }
        this.clearSelected();
      } catch(e) {
        console.log(e);
      }
      this.downloading = false
    },
    getFilters() {
      this.currentPage = 1
      this.filterResultsToggle = true;
      this.selFilters = this.$store.getters.filters.consignor
      this.getItems()
    },
    removeFilter(index) {
      const selFilters = {...this.selFilters}
      
      delete selFilters[index]
      delete this.$refs.filter.selectedFilters[index]
      
      this.selFilters = selFilters
      this.$store.commit('setFilter', {'consignor': selFilters})
      delete this.$refs.filter.form[index]
      if(Object.keys(this.selFilters).length > 0) {
        this.getItems()
      }else{
        this.clearFilters()
      }
    },
    clearFilters() {
      this.filterResultsToggle = false;
      this.selFilters = {};
      this.$refs.filter.clearFilters()
      this.getItems()
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    selectedRows() {
      const items = this.items.filter((item) => item.selected)
      if(items.length>0) {
        this.allSelected = true
      }
      return items
    },
    // filters() {
    //   return this.$store.getters.orderFilters;
    // }
},
watch: {
  currentPage: function() {
    this.prevDisabled = false;
    this.nextDisabled = false;

    if (this.currentPage == 1) {
      this.prevDisabled = true;
    }

    if (this.currentPage == this.totalPages) {
      this.nextDisabled = true;
    }
  },
  selectedRows: function() {
    if (this.selectedRows.length == this.items.length) {
      this.allSelected = true;
    }
  },
},
mounted() {
  this.totalRows = this.items.length;
  this.totalPages = Math.ceil(this.totalRows / this.perPage);
  for (var i = 1; i <= this.totalPages; i++) {
    this.pagesArr.push(i);
  }
},
};
</script>

<style lang="scss" scoped>
  .tag {
    font-weight: 800;
  }
  /deep/.correct {
    padding: 1rem 1.125rem 0.75rem 0 !important;
  }
  /deep/ .correct-pad {
      padding-top: 1rem;
  } 
  .adduser {
    height: 1rem;
      width: 1rem;
  }

  .editsvg {
    height: 0.85rem;
    width:1rem;

    path {
      fill:white !important;
    }
  } 
  /deep/ .paddingfirst {
      padding: 0 0.75rem 1rem 0.5rem !important;
  }

  /deep/ .consignor-table {
    max-height: calc(108vh - 320px);
  }

  /deep/ .w-20 {
    width: 20% !important;
  }
  /deep/ .w-10 {
    width: 10% !important;
  }
</style>
