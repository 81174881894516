<template>
    <div class="webhooks container-fluid position-relative">
        <div class="col d-flex align-items-center justify-content-between webhooks_mobile pb-20">
            <span class="fs18 fw700">Webhooks Table</span>
            <div>
            	<b-button @click="$router.push('webhook/sandbox')" class="mr-20" >
            		<img class="goto mr-5" src="@/assets/svg/settings.svg">  Test Webhook 
            	</b-button> 
            	<b-button variant="primary" class="" @click="addWebhook">
            		<i class="icon-add fs16 mr-10"></i> Add Endpoint 
            	</b-button> 
            </div>
        </div>

        <div v-if="this.windowWidth > 767">
            <b-table
                :items="webhookItems"
                :fields="webhookFields"
                sticky-header
                no-border-collapse
                id="air-mode"
                @row-clicked="tbodyRowAttr"
                class="price-calculator-table"
            >
                <template class="d-none" v-slot:cell(id)="data">
                  <span class="d-block">{{ data.item.id }}</span>
                </template>
                <template v-slot:cell(description)="data">
                  <span class="d-block description">{{ data.item.description ? data.item.description : 'N/A' }}</span>
                </template>
                <template v-slot:cell(endpoint)="data">
                  <span class="d-block endpoint">{{ data.item.endpoint }} </span>
                </template>
                <template class="d-none" v-slot:cell(status)="data">
                  <span class="d-block enabled ml-15" v-if="data.item.status == 1">Enabled</span>
                  <span class="d-block disabled ml-15" v-else>Disabled</span>
                </template>
                <template v-slot:cell(last7)="data">
                  <span class="d-block ">{{ data.item.last7 ? data.item.last7 : 'N/A' }}</span>
                </template>
                <template v-slot:cell(error)="data">
                  <span class="d-block ">{{ data.item.error ? data.item.error : 'N/A' }}</span>
                </template>
            </b-table>
        </div>
        <!-- <div v-else>
          <div class="no-results">
            <div class="d-flex flex-column align-items-center">
              <i class="icon-empty-state text-blue pb-24 fs30"></i>
              <span class="fs14 fw500 pb-8 text-blue">
                Couldn't find any Webhooks
              </span>
              <span class="fs14 fw500 pb-8 text-gray-600">
                Please Add a Webhook!!
              </span>
            </div>
          </div>
        </div> -->

        <ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
            <li v-for="item in webhookItems" :key="item.id">
                <div class="d-flex align-items-center justify-content-between w-100 mb-4">
                    <span class="d-block fw500 pb-8 cp" @click="tbodyRowAttr(item)">
                        {{ item.endpoint }}
                    </span>
                    <div class="d-flex justify-content-end">
                        <span class="d-block enabled ml-15" v-if="item.status == 1">Enabled</span>
                        <span class="d-block disabled ml-15" v-else>Disabled</span>
                    </div>
                </div>

                <div class="info">
                    <div class="d-flex w-100">
                        <span class="carrier-name">
                            Description: {{ item.description ? item.description : 'N/A' }}
                        </span>
                    </div>

                    <div class="d-flex w-100 mt-4">
                        <span class="carrier-name">
                            Version: {{item.version}}
                        </span>
                    </div>
                    <div class="d-flex w-100 mt-4">
                        <span class="carrier-name">
                            Last 7 Days: {{ item.last7 ? item.last7 : 'N/A' }}
                        </span>
                    </div>
                    <div class="d-flex w-100 mt-4">
                        <span class="carrier-name">
                            Error Rate: {{ item.error ? item.error : 'N/A' }}
                        </span>
                    </div>
                </div>
            </li>
        </ul>
        <AddWebhook ref="addWebhook" />
        <ResultNotFound v-if="!webhookItems.length" />
    </div>
</template>

    <!-- <div class="container-fluid pt-30 pt-md-30">
            <div class="card">
              <div class="card__title title">
                <div>
                  <span class="fs20 lh24 fw500">
                    <strong> Devices receiving event with the stripe CLI</strong>
                  </span>
                  <p class="fs14">Use the Stripe CLI to listen and trigger test mode events</p> 
                </div>
                
                <div class="text-right">
                  <b-button variant="primary"><img class="goto mr-10" src="@/assets/svg/goto.svg"> Install CLI </b-button>  
                </div>
              </div>
          <div class="card__content overflow-visible">
              <span class="text-gray-600 d-flex justify-content-center">No active connections to the stripe CLI</span> 
          </div>
    </div>

    </div> -->




<script>
    import Multiselect from "vue-multiselect";
    import AppTopbar from "@/components/layout/AppTopbar.vue";
    import AddWebhook from "@/components/AddWebhook.vue";
    import ResultNotFound from "@/components/ResultNotFound.vue";


    export default {
      name: "Webhooks",
      title: "Shyplite - Webhooks",
      data() {
        return {
          webhookFields: [
            {key:'id',thClass: "d-none",tdClass: "d-none"},
            { key: "endpoint",label: "URL",},
            {
              key: "description",
              label: "Description"
            },
            {
              key: "version",
              label: "Version",
              thClass: "text-center",
              tdClass: "text-center",
            },
            {
              key: "status",
              label: "Status",
              thClass: "text-center",
              tdClass: "text-center",
            },
            {
              key: "last7",
              label: "Last 7 Days",
              thClass: "text-center",
              tdClass: "text-center",
            },
            {
              key: "error",
              label: "Error Rate",
              thClass: "text-center",
              tdClass: "text-center",
            }
          ],
          webhookItems : [
             
          ] 
          
      };
    },
    components: {
        Multiselect,
        AppTopbar,
        AddWebhook,
        ResultNotFound
    },
    computed:{
      windowWidth() {
        return this.$store.state.windowWidth;
      }
    },
    async created() {
      await this.getWebhooks()
      this.getData()
    },
    methods: {
      addWebhook() {
        this.$refs.addWebhook.isOpen = !this.$refs.addWebhook.isOpen;
      },
      async getWebhooks() {
        try {
          const res = await this.axios.get('/webhooks')
          if (res.data.status) {

            this.webhookItems = res.data.body.webhooks.filter(i => i.status != 0).reverse()

          } else {
          }
        } catch(e) {
          // statements
          console.log(e);
        }
      },
      async getData() {
        try {
          const res = await this.axios.get('/v3/wh/log/stats')

          if (res.data.success) {
            res.data.data = Object.entries(res.data.data)

            if (this.webhookItems.length) {
              this.webhookItems.forEach((i,j) => {
                res.data.data.forEach((k,l) => {

                  if (k[0] == i.id) {
                    i['last7'] = k[1].total
                    i['error'] = `${((k[1].fail/k[1].total)*100).toFixed(2)} %`
                    this.webhookItems.push(i)

                  } else {

                  }
                })
              })

              let jsonObject = this.webhookItems.map(JSON.stringify);
      
              let uniqueSet = new Set(jsonObject);
              let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        
              this.webhookItems = uniqueArray
            }
          }
          

          
          
        } catch(e) {
          // statements
          console.log(e);
        }
      },
      tbodyRowAttr(record) {

        let url = `/webhooks/detail/${record.id}`

        let newTab = event.metaKey || event.ctrlKey;
          if (newTab) {

            window.open(url);
          }
          else {

            this.$router.push(url)
          }

        }
    },
    };
    </script>

    <style scoped>
    .price-calculator-table {
      max-height: unset;
      min-height: unset;
      height: max-content;
    }
    .enabled {
      color: #345d34;
      background: #6ce080;
      width: 4rem;
      border-radius: 3px;
      padding: 0rem 0.5rem 0rem 0.4rem;
    }
    .disabled {
      color: #d60909;
      background: #f17272;
      width: 4rem;
      border-radius: 3px;
      padding: 0rem 0.5rem 0rem 0.3rem;
    }
    .title {
      justify-content: space-between !important;
    }
    .goto {
      height: 1rem;
      width: 1rem;
    }
    .endpoint {
      width: 20rem;
    }
    .description {
      white-space: nowrap;
      width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    /deep/ .price-calculator-table tbody tr td {
      cursor: pointer !important;
    }
    .no-results {
      top: 40% !important;
    }
    </style>