	<template>
		<b-modal
		id="flipkart-connector-modal"
		modal-class="flipkart-connector-modal"
		size="lg"
		hide-footer
		v-model="isOpen"
		ref="flipkartConnector"
		>
		<template v-slot:modal-header="{ close }">
			<span class="d-block mt-auto fs18 lh28 fw500">
				Flipkart Credentials
			</span>
			<b-button variant="rounded" @click="close()">
				<i class="icon-close-variant"></i>
			</b-button>
		</template>
		
		<div class="mw596 mx-auto">
			
			<div class="row">
				<div class="col pt-30 pb-16 d-flex justify-content-between align-items-center">
					<span class="d-block  text-gray-600 pt-12">
						<strong>1.</strong> To Generate <strong>Application ID</strong> and <strong>Application Secret</strong>, Click 
						<b-link target="_blank" href="https://api.flipkart.net/oauth-register/login" class="fs16" >
							here
						</b-link>
					</span>
				</div>
			</div>

			<div class="row">
				<div class="col py-16 d-flex justify-content-between align-items-center">
					<span class="d-block  text-gray-600 pt-12">
						<strong>2. </strong> Input your registered Email address and Password to log in.
					</span>
				</div>
			</div>

			<div class="row">
				<div class="col py-16 d-flex justify-content-between align-items-center">
					<span class="d-block  text-gray-600 pt-12">
						<strong>3. </strong>After successful Login, click on Register New Application button. 
					</span>
				</div>
			</div>

			<div class="row">
				<div class="col py-16 d-flex justify-content-between align-items-center">
					<span class="d-block  text-gray-600 pt-12">
						<strong>4. </strong> A new screen will open as below. Enter Application name –<strong>Shyplite</strong> , Description and select Application type as <strong>Self-access application</strong> . Click on Register Application.
					</span>
				</div>
			</div>

		

		<b-form class="pt-15" @submit.prevent="flipkartConnect()">


			<div class="row">
		      <div class="col-md-6">
		        <b-form-group
		        id="seller-id-field"
		        label="Store Name"
		        label-for="seller-id"
		        >
		        <b-form-input
		        id="seller-id"
		        v-model="form.storeName"
		        placeholder="Store Name"
		        ></b-form-input>
		        </b-form-group>
		      </div>
		    </div>

			<div class="row">
				<div class="col-md-6 pt-8">
					<b-form-group
					id="mws-token-field"
					label="Application ID:"
					label-for="username"
					>
					<b-form-input
					required
					v-model="form.applicationID"
					id="username"
					placeholder="Application ID"
					></b-form-input>
				</b-form-group>
			</div>
			<div class="col-md-6 pt-8">
				<b-form-group
				id="mws-token-field"
				label="Application Secret:"
				label-for="password"
				>
				<b-form-input
				required
				id="password"
				type="password"
				v-model="form.applicationSecret"
				placeholder="Application Secret"
				></b-form-input>
			</b-form-group>
		</div>
	</div>


	<div class="row">
		<div class="col-md-6 py-16">
			<b-form-group label="Get Orders">
				<b-form-radio name="get-orders" v-model="form.isactive" value="1" class="d-inline-block mr-20">
					Enable
				</b-form-radio>
				<b-form-radio name="get-orders" v-model="form.isactive" value="0" class="d-inline-block">
					Disable
				</b-form-radio>
			</b-form-group>
		</div>
	</div>


	<div class="row">
		<div class="col pt-12 pb-8 d-none d-md-block">
			<b-button v-if="sellerDisable" type="submit" variant="primary mr-16" size="lg">
				<b-spinner v-if="updating"></b-spinner>
		          Update
	        </b-button>
	        <b-button v-else type="submit" variant="primary mr-16" size="lg">
	        	<b-spinner v-if="updating"></b-spinner>
		          Create
		        </b-button>
	        <b-button @click="hideModal" size="lg">Cancel</b-button>
		</div>
		<div class="col pt-12 pb-8 d-flex d-md-none">
			<b-button @click="hideModal" size="lg">Cancel</b-button>
		      <b-button v-if="sellerDisable"  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
		      	<b-spinner v-if="updating"></b-spinner>
		        Update
		      </b-button>
		      <b-button v-else type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
		      	<b-spinner v-if="updating"></b-spinner>
		        Create
		      </b-button>
		</div>
	</div>
</b-form>
</div>
</div>
</b-modal>
</template>

<script>
export default {
	name: "SettingsShopifyConnector",
	props:{
		editFId : Number
	},
	data() {
		return {
			updating:false,
			isOpen: this.modalOpen,
			form:{},
			sellerDisable:false
		}
	},
	methods: {
		hideModal() {
			this.$bvModal.hide("flipkart-connector-modal");
		},
		async flipkartConnect(){
			this.updating = true
			if (this.form.id) {
				try {
					const editres = await this.axios.post('/settings/connectors/flipkart', this.form)
					if (editres.data.success) {
						this.popToast("booked", "Success!", editres.data.message);
						this.updating = false
						setTimeout(() => {
							this.$router.go()
						},2000)
					} else {
						this.updating = false
						this.popToast("failed", "Failed!", editres.data.error)
					}
				} catch(e) {
					this.updating = false
					this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
					console.log(e);
				}
			} else {
				try {
					const res = await this.axios.put('/settings/connectors/flipkart', this.form)
					if (res.data.response.success) {
						this.popToast("booked", "Success!", res.data.response.message);
						this.updating = false
						setTimeout(() => {
							this.$router.go()
						},2000)
					} else {
						this.updating = false
						this.popToast("failed", "Failed!", res.data.response.error)
					}
				} catch(e) {
					this.updating = false
					this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
					console.log(e);
				}
			}},
			async formatting(id) {
				try {
					const res = await this.axios.get(`/settings/connector/${id}`)
					if (res.data.success) {
						const editConn = {
							id:res.data.result[0].id,
							applicationID:res.data.result[0].key1,
							applicationSecret:res.data.result[0].key2,
							isactive:res.data.result[0].isactive,
							storeName:res.data.result[0].storeName
						}
						this.form = editConn
					} else {
						this.form = {}
						this.popToast("failed", "Failed!", "Data Fetching Failed Please try again later!!!");
					}
				} catch(e) {
					this.popToast("failed", "Failed!", "Data Fetching Failed Please try again later!!!");
					console.log(e);
				}
			}
		},
		watch:{
			isOpen(newValue) {
				if(newValue) {
					let flipkartFlag = false

					this.$root.$on('flipkart', data => {
						flipkartFlag = data
					});

					if (this.editFId) {
						this.formatting(this.editFId)
						this.sellerDisable = true
					} else if (flipkartFlag) {
						this.form = {
							isactive:"1"
						}
						this.sellerDisable = false
					} else {
						this.form = {
							isactive:"1"
						}
						this.sellerDisable = false
					}
			} else {
				this.$emit('update:editFId', undefined)
			}
		}
	}
	};
	</script>

	<style lang="scss">
	
	</style>
