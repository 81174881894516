<template>
	<div class="settings">
		<AppTopbar
		title="Masters"
		buttonText="New Order"
		buttonLink="new"
		buttonIconClass="icon-add"
		></AppTopbar>

		<div class="container-fluid pt-60 pt-md-94 pb-14">
			<ul class="filter-list settings">
				<li
				v-for="tab in computedtabs"
				:key="tab.id"
				:class="[{ active: selected.id === tab.id }]"
				>
				<b-link @click="selected = tab;$router.replace('#'+tab.title)" :class="tab.title" class="btn-filter wsnw p-7">
					{{ tab.title }}
				</b-link>
			</li>
		</ul>
	</div>

	<component :is="selected.component"></component>
</div>
</template>

<script>
import UserManagement from "@/views/userManagement/userManagement.vue";
import SettingsSkus from "@/components/SettingsSkus.vue";
import AppTopbar from "@/components/layout/AppTopbar.vue";
import Consignor from "@/views/consignor/Consignor.vue";
import CustomerBook from "@//views/customerbook/CustomerBook.vue"


import SettingsWarehouses from "@/components/SettingsWarehouses.vue";





export default {
	name: "Masters",
	title: "Shyplite - Masters",
	data() {
		return {
			choice: this.$store.getters.user.choice,
			sellerId: this.$store.getters.user.id,
			webhookArr: [165,76330,72511,126364,137306,113663,146845,142144,123865,120822,164256,149087],
			selected: {
				id: 0,
				component: "SettingsSkus",
				title: "SKUs",
			},
			tabs: [
			{
				id: 0,
				component: "SettingsSkus",
				title: "SKUs",
			},
			{
				id: 1,
				component: "UserManagement",
				title: "Users",
			},
			{
				id: 2,
				component: "SettingsWarehouses",
				title: "Warehouses",
			},
			{
				id: 3,
				component: "Consignor",
				title: "Consignor",
			},
			{
				id: 4,
				component: "CustomerBook",
				title: "CustomerBook",
			}
			],
			financearr: [1,2,3,4,5,6,7,8],
			opserr:[7,8],
		};
	},
	components: {
		SettingsSkus,
		SettingsWarehouses,
		UserManagement,
		AppTopbar,
		Consignor,
		CustomerBook
	},
	mounted() {

	},
	created() {

		const tab = this.tabs.find(item => '#'+item.title.toLowerCase() == location.hash.toLowerCase())
		if(tab) {
			this.selected = tab
		}else{
			this.selected = this.tabs[0]
		}
	},
	computed: {
		activeUser() {
			return this.$store.getters.user;
		},
		checkFlag() {
			return this.$store.state.global.goCustom;
		},
		computedtabs() {
			let tabs = this.tabs
			if (this.$store.state.global.showUsers == false) {
				tabs =  tabs.filter(i => i.id != 1)
			}

			if (this.$store.state.global.showCustomers == false) {
				tabs = tabs.filter(i => i.id != 4)
			}

			if (this.$store.state.global.showConsignor == false || this.$store.state.global.showConsignorrequest == false) {
				tabs = tabs.filter(i => i.id != 3)
			}

			if (this.activeUser.childRole == 'OPERATIONS') {
				tabs = tabs.filter(i => !this.opserr.includes(i.id))
			} else {
				tabs = tabs 
			}

			return tabs
			
		}
	},
	watch: {
		checkFlag:function(newValue) {
			if (newValue == true) {
				this.selected = {
					component: "Customization",
					id: 1,
					title: "Customization"
				}
				this.$store.commit('setGlobal', {'goCustomfromConn':true});
				this.$store.commit('setGlobal', {'goCustom':false});
			}
		}
	}
};
</script>

<style scoped>
	.filter-list.settings {
		padding-bottom: 0.5rem !important;
	}
</style>
