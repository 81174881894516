<template>
	<b-modal
	id="amazon-connector-modal"
	modal-class="amazon-connector-modal"
	size="lg"
	hide-footer
	v-model="isOpen"
	ref="amazonConnector"
	>
	<template v-slot:modal-header="{ close }">
		<span class="d-block mt-auto fs18 lh28 fw500">
			Zoho Credentials
		</span>
		<b-button variant="rounded" @click="close()">
			<i class="icon-close-variant"></i>
		</b-button>
	</template>
	<div class="mw644 mx-auto">
      <!-- <div class="row">
        <div
          class="col pt-30 pb-16 d-flex justify-content-between align-items-center"
        >
          <span class="fs16 fw500">
            1. Visit
            <b-link href="https://sellercentral.amazon.in/apps/store/dp/amzn1.sellerapps.app.bc94d92d-7f66-46d1-b867-836cc6668ee7" class="fs16">
              Amazon MWS
              <i class="icon-arrow-long ml-8 fs12"></i>
            </b-link>
          </span>
        </div>
    </div> -->

    <div class="row">
    	<div class="col pt-30">

    		<span class="d-block pt-12 text-gray-600">
    			<strong>1.</strong> To Generate <strong>clientID</strong> and <strong>clientSecret</strong>, <b-link class="fs16" target="_blank" href="https://accounts.zoho.com/developerconsole"> Click Here</b-link>
    		</span>
    	</div>
    </div>

    <div class="row">
    	<div class="col pt-10">
    		<span class="d-block pt-12 text-gray-600">
    			<strong>2.</strong> Now choose <strong> Add Client.</strong>
    		</span>
    	</div>
    </div>

    <div class="row">
    	<div class="col pt-10">
    		<span class="d-block pt-12 text-gray-600">
    			<strong>3.</strong> Choose Server-based Application and click <strong>Create Now.</strong>
    		</span>
    	</div>
    </div>

    <div class="row">
    	<div class="col pt-10">
    		<span class="d-block pt-12 text-gray-600">
    			<strong>4.</strong> Fill the <strong>Client Name</strong>, <strong>Homepage URL</strong> and <strong>Authorized Redirect URIs</strong> as per details below:
    		</span>
    		<ul class="pt-10 text-gray-600">
    			<li><strong>Client Name : </strong>
    				<span
	    				class="cp underline-dashed text-gray-800 text-decoration-none" 
	    				v-b-tooltip.hover.right
						title="Click to copy!"
						@click="copySuccess('shyplite')">
							{{shyplite}}
					</span>
                </li>

    			<li><strong>StHomepage URL : </strong>
    				<span 
                        class="cp underline-dashed text-gray-800 text-decoration-none" 
                        v-b-tooltip.hover.right
                        title="Click to copy!"
                        @click="copySuccess('homeUrl')">
                            {{homeUrl}}
                    </span>
                </li>

    			<li><strong>Authorized Redirect URIs : </strong>

                    <span class="cp underline-dashed text-gray-800 text-decoration-none" 
                        v-b-tooltip.hover.right
                        title="Click to copy!" 
                        @click="copySuccess('callback')">
                        {{callback}}
                    </span>
                </li>
    		</ul>
    	</div>
    </div>

    <div class="row">
        <div class="col pt-10">
            <span class="d-block pt-12 text-gray-600">
                <strong>5.</strong> Now Click on the <strong>CREATE</strong>, And this will generate clientID and clientSecret.
            </span>
        </div>
    </div>

    <div class="row">
        <div class="col pt-10">
            <span class="d-block pt-12 text-gray-600">
                <strong>6.</strong> The above details should be added in shyplite connector zoho and click <strong>CREATE</strong>.
            </span>
        </div>
    </div>

    <div class="row">
        <div class="col pt-10">
            <span class="d-block pt-12 text-gray-600">
                <strong>7.</strong> And finally click <strong>VERIFY</strong>, It will redirect to zoho and you are done with integration.
            </span>
        </div>
    </div>


<b-form @submit.prevent="zohoConnect()" >

	<div class="row">
      <div class="col-md-6 pt-15">
        <b-form-group
        id="seller-id-field"
        label="Store Name"
        label-for="seller-id"
        >
        <b-form-input
        id="seller-id"
        v-model="form.storeName"
        placeholder="Store Name"
        ></b-form-input>
        </b-form-group>
      </div>
    </div>

	<div class="row">
		<div class="col-md-6 pt-5">
			<b-form-group
			id="seller-id-field"
			label="Client ID"
			label-for="seller-id"
			>
			<b-form-input
			id="seller-id"
			required
			v-model="form.clientID"
			placeholder="clientID"
			></b-form-input>
		</b-form-group>
	</div>
	<div class="col-md-6 pt-5">
		<b-form-group
		id="mws-token-field"
		label="Client Secret"
		label-for="mws-token"
		>
		<b-form-input
		required
		id="mws-token"
		v-model="form.clientSecret"
		placeholder="clientSecret"
		></b-form-input>
	</b-form-group>
</div>
</div>

<div class="row">
	<div class="col-md-6 py-16">
		<b-form-group label="Get Orders">
			<b-form-radio name="get-orders" v-model="form.isactive" value="1" class="d-inline-block mr-20">
				Enable
			</b-form-radio>
			<b-form-radio name="get-orders" v-model="form.isactive" value="0" class="d-inline-block">
				Disable
			</b-form-radio>
		</b-form-group>
	</div>
</div>

<div class="row">
	<div class="col pt-12 pb-8 d-none d-md-block">

		<b-button v-if="sellerDisable" target="_blank" :href="verifyURL" variant="primary mr-16" size="lg">
			Verify
		</b-button>
		
		<b-button v-if="sellerDisable" type="submit" variant="primary mr-16" size="lg">
			<b-spinner v-if="updating"></b-spinner>
			Update
		</b-button>
		<b-button v-else type="submit" variant="primary mr-16" size="lg">
			<b-spinner v-if="updating"></b-spinner>
			Create
		</b-button>
		<b-button @click="hideModal" size="lg">Cancel</b-button>
	</div>
	<div class="col pt-12 pb-8 d-flex d-md-none">
		
		<b-button @click="hideModal" size="lg">Cancel</b-button>
		<b-button v-if="sellerDisable"  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			<b-spinner v-if="updating"></b-spinner>
			Update
		</b-button>
		<b-button v-else type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			<b-spinner v-if="updating"></b-spinner>
			Create
		</b-button>
	</div>
</div>

</b-form>


</div>
</b-modal>
</template>

<script>
export default {
	name: "SettingsZoho",
	props: {
		editZId: Number
	},
	data() {
		return {
			updating:false,
			isOpen: this.modalOpen,
			hover:false,
			hover1:false,
			form:{
				isactive:"1"
			},
			sellerDisable:false,
			verifyURL:null,
            shyplite:'Shyplite',
            homeUrl:'https://app1.slv.shyplite.in/zoho',
            callback:'https://app1.slv.shyplite.in/zoho/callback'
		};
	},
	methods: {

		copySuccess(type) {
            if (type == 'shyplite') {
                let text = 'Shyplite'
                navigator.clipboard.writeText(text).then(function() {
                }, function(err) {
                });
            } else if(type == 'homeUrl') {
                let text = 'https://app1.slv.shyplite.in/zoho'
                navigator.clipboard.writeText(text).then(function() {
                }, function(err) {
                });
            } else {
                let text = 'https://app1.slv.shyplite.in/zoho/callback'
                navigator.clipboard.writeText(text).then(function() {
                }, function(err) {
                });
            }
          this.popToast("booked", "Success", "Successfully copied to clipboard!");
        },

		async zohoConnect() {
			this.updating = true
			if(this.form.id) {
				try {
					const editres = await this.axios.post('/settings/connectors/zoho', this.form)
					if (editres.data.success) {
						this.popToast("booked", "Success!", editres.data.message);
						this.updating = false
						setTimeout(() => {
							this.$router.go()
						},1000)
					} else {
						this.updating = false
						this.popToast("failed", "Failed!", editres.data.error);
					}
				} catch(e) {
					// statements
					this.updating = false
					console.log(e);
					this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
				}

			} else {
				try {
					const res = await this.axios.post('/settings/connectors/zoho',this.form)
					if (res.data.success) {
						this.popToast("booked", "Success!", res.data.message);
						this.updating = false
						setTimeout(() => {
							this.$router.go()
						},1000)
					} else {
						this.updating = false
						this.popToast("failed", "Failed!", res.data.error);
					}
				} catch(e) {
					this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
					this.updating = false
					console.log(e);
			}

		}
	},
	hideModal() {
		this.$bvModal.hide("amazon-connector-modal");
		this.form = {}
	},
	async formatting(id) {
		try {
			const res = await this.axios.get(`/settings/connector/${id}`)
			const editConn = {
				id:res.data.result[0].id,
				clientID:res.data.result[0].key1,
				clientSecret:res.data.result[0].key2,
				isactive:res.data.result[0].isactive,
				autoFullFill:res.data.result[0].autoFullFill,
				storeName:res.data.result[0].storeName
			}
			this.form = editConn

			// let host = window.location.href.split('/')[2]

			let callbackUrl = process.env.NODE_ENV == "development" ? `http://localhost:4000/zoho/callback` : `https://app1.slv.shyplite.in/zoho/callback`

            

        this.verifyURL = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoInventory.salesorders.READ&client_id=${this.form.clientID}&state=testing&response_type=code&redirect_uri=${callbackUrl}&access_type=offline`

			
		} catch(e) {
				// statements
				console.log(e);
			}
		}
	},
	watch:{
		isOpen(newValue) {
			if(newValue) {
				let zoho = false

				this.$root.$on('zoho', data => {
					zoho = data
				});

				if (this.editZId) {
					this.formatting(this.editZId)
					this.sellerDisable = true
				} else if (zoho) {
					this.form = {
						isactive:"1",
						autoFullFill:"1"
					}
					this.sellerDisable = false
				} else {
					this.form = {
						isactive:"1",
						autoFullFill:"1"
					}
					this.sellerDisable = false
				}
			} else {
				this.$emit('update:editZId', undefined)
			}
		}
	}
};
</script>

<style lang="scss">

</style>
