var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "add-sku-modal",
        "modal-class": "add-sku-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editId ? "Edit Consignor" : "Add New Consignor"
                    ) +
                    " "
                )
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("div", { staticClass: "mw596 mx-auto" }, [
        _c("div", { staticClass: "pt-md-25 pt-25 pb-md-10 pb-10" }, [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "sku-field",
                              label: "Name",
                              "label-for": "sku"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "sku",
                                type: "text",
                                maxlength: "35",
                                pattern: "[a-zA-Z0-9\\s]+",
                                oninvalid:
                                  "this.setCustomValidity('Please enter a valid Consignor name')",
                                oninput: "this.setCustomValidity('')",
                                placeholder: "Enter consignor name"
                              },
                              model: {
                                value: _vm.form.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-weight-field",
                              label: "Contact Number",
                              "label-for": "item-weight"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "item-weight",
                                type: "text",
                                maxlength: "10",
                                minlength: "10",
                                formatter: _vm.numbers,
                                pattern: "[6-9]{1}[0-9]{9}",
                                placeholder: "8888888888"
                              },
                              model: {
                                value: _vm.form.phone,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "phone", $$v)
                                },
                                expression: "form.phone"
                              }
                            }),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c("div", { staticClass: "input-group-text" }, [
                                _c("i", {
                                  staticClass: "icon-phone fs16 text-gray-600"
                                })
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "py-12 fs16" }, [
                    _c("strong", [_vm._v("Documents Required")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-6  col-9" },
                        [
                          _c("b-form-group", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var ariaDescribedby = ref.ariaDescribedby
                                  return [
                                    _c(
                                      "b-form-radio-group",
                                      {
                                        attrs: {
                                          id: "radio-group-2",
                                          "aria-describedby": ariaDescribedby,
                                          name: "radio-sub-component",
                                          required: "",
                                          disabled: _vm.editId
                                        },
                                        model: {
                                          value: _vm.form.csbType,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "csbType", $$v)
                                          },
                                          expression: "form.csbType"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-form-radio",
                                          { attrs: { value: "CSB IV" } },
                                          [_vm._v("CSB IV")]
                                        ),
                                        _c(
                                          "b-form-radio",
                                          { attrs: { value: "CSB V" } },
                                          [_vm._v("CSB V")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-6  col-9" }),
                      _c("transition", { attrs: { name: "slide-left" } }, [
                        _vm.form.csbType == "CSB IV"
                          ? _c("div", { staticClass: "col d-flex" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6  col-9" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "wsnw",
                                      attrs: {
                                        id: "panCardId",
                                        label: "ID Proof",
                                        "label-for": "panCard"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          disabled: _vm.editId,
                                          maxlength: "30",
                                          pattern: "^[a-zA-Z0-9]*$",
                                          required: "",
                                          id: "panCard",
                                          type: "text",
                                          placeholder:
                                            "AADHAR NO/ Licence No. / PAN No."
                                        },
                                        model: {
                                          value: _vm.form.idProof,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "idProof", $$v)
                                          },
                                          expression: "form.idProof"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-6 col-9 d-flex align-items-center mt-10"
                                },
                                [
                                  _c("b-form-file", {
                                    ref: "idfile",
                                    staticClass: "d-none",
                                    attrs: {
                                      accept:
                                        ".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx",
                                      plain: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.validate(
                                          _vm.form.idProofFile,
                                          "idproof"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.idProofFile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "idProofFile", $$v)
                                      },
                                      expression: "form.idProofFile"
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "w-100",
                                      attrs: {
                                        disabled: _vm.editId,
                                        variant: "tertiary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.idfile.$el.click()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.idProofFile
                                              ? _vm.form.idProofFile.name
                                              : "Choose File"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.editId && _vm.idFile
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            href: _vm.idFile,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "ml-5 d-flex align-items-center",
                                              attrs: {
                                                variant: "primary",
                                                title: "Click to View"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "editsvg",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 512 512"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("transition", { attrs: { name: "slide-left" } }, [
                        _vm.form.csbType == "CSB V"
                          ? _c("div", { staticClass: "col-md-12 row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6  col-9" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "wsnw",
                                      attrs: {
                                        id: "panCardId",
                                        label: "GST",
                                        "label-for": "gst"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          disabled: _vm.editId,
                                          maxlength: "15",
                                          minlength: "15",
                                          required: "",
                                          pattern: "^[a-zA-Z0-9]*$",
                                          oninvalid:
                                            "this.setCustomValidity('Enter a Valid GST Number')",
                                          oninput: "this.setCustomValidity('')",
                                          id: "gst",
                                          type: "text",
                                          placeholder: "Enter GST No."
                                        },
                                        model: {
                                          value: _vm.form.gst,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "gst", $$v)
                                          },
                                          expression: "form.gst"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-6 col-9 d-flex align-items-center mt-10"
                                },
                                [
                                  _c("b-form-file", {
                                    ref: "gstfile",
                                    staticClass: "d-none",
                                    attrs: {
                                      accept:
                                        ".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx",
                                      plain: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.validate(
                                          _vm.form.gstFile,
                                          "gst"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.gstFile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "gstFile", $$v)
                                      },
                                      expression: "form.gstFile"
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "w-100",
                                      attrs: {
                                        disabled: _vm.editId,
                                        variant: "tertiary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.gstfile.$el.click()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.gstFile
                                              ? _vm.form.gstFile.name
                                              : "Choose File"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.editId && _vm.gstFile
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            href: _vm.gstFile,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "ml-5 d-flex align-items-center",
                                              attrs: {
                                                variant: "primary",
                                                title: "Click to View"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "editsvg",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 512 512"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6  col-9" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "wsnw",
                                      attrs: {
                                        id: "panCardId",
                                        label: "AD Code",
                                        "label-for": "adcode"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          disabled: _vm.editId,
                                          oninvalid:
                                            "this.setCustomValidity('Enter a Valid AD Code')",
                                          oninput: "this.setCustomValidity('')",
                                          maxlength: "14",
                                          pattern: "^[0-9]*$",
                                          minlength: "14",
                                          id: "adcode",
                                          type: "text",
                                          placeholder: "Enter AD Code",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.adcode,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "adcode", $$v)
                                          },
                                          expression: "form.adcode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-6 col-9 d-flex align-items-center mt-10"
                                },
                                [
                                  _c("b-form-file", {
                                    ref: "adcodefile",
                                    staticClass: "d-none",
                                    attrs: {
                                      accept:
                                        ".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx",
                                      plain: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.validate(
                                          _vm.form.adcodeFile,
                                          "adcode"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.adcodeFile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "adcodeFile", $$v)
                                      },
                                      expression: "form.adcodeFile"
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "w-100",
                                      attrs: {
                                        disabled: _vm.editId,
                                        variant: "tertiary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.adcodefile.$el.click()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.adcodeFile
                                              ? _vm.form.adcodeFile.name
                                              : "Choose File"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.editId && _vm.adFile
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            href: _vm.adFile,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "ml-5 d-flex align-items-center",
                                              attrs: {
                                                variant: "primary",
                                                title: "Click to View"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "editsvg",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 512 512"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6  col-9" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "wsnw",
                                      attrs: {
                                        id: "panCardId",
                                        label: "IEC No",
                                        "label-for": "iec"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          disabled: _vm.editId,
                                          oninvalid:
                                            "this.setCustomValidity('Enter a Valid IEC Number')",
                                          oninput: "this.setCustomValidity('')",
                                          maxlength: "10",
                                          pattern: "^[a-zA-Z0-9]*$",
                                          minlength: "10",
                                          id: "iec",
                                          type: "text",
                                          placeholder: "Enter your IEC number",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.iec,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "iec", $$v)
                                          },
                                          expression: "form.iec"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-6 col-9 d-flex align-items-center mt-10"
                                },
                                [
                                  _c("b-form-file", {
                                    ref: "iecFile",
                                    staticClass: "d-none",
                                    attrs: {
                                      accept:
                                        ".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx",
                                      plain: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.validate(
                                          _vm.form.iecFile,
                                          "iec"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.iecFile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "iecFile", $$v)
                                      },
                                      expression: "form.iecFile"
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "w-100",
                                      attrs: {
                                        disabled: _vm.editId,
                                        variant: "tertiary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.iecFile.$el.click()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.iecFile
                                              ? _vm.form.iecFile.name
                                              : "Choose File"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.editId && _vm.iecFile
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            href: _vm.iecFile,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "ml-5 d-flex align-items-center",
                                              attrs: {
                                                variant: "primary",
                                                title: "Click to View"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "editsvg",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 512 512"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-6 col-9 d-flex align-items-center mt-10"
                                },
                                [
                                  _c("b-form-file", {
                                    ref: "panfile",
                                    staticClass: "d-none",
                                    attrs: {
                                      accept:
                                        ".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx",
                                      plain: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.validate(
                                          _vm.form.panFile,
                                          "pan"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.form.panFile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "panFile", $$v)
                                      },
                                      expression: "form.panFile"
                                    }
                                  }),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticStyle: { width: "100% !important" },
                                      attrs: {
                                        id: "panCardId",
                                        label: "PAN",
                                        "label-for": "panfile"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            disabled: _vm.editId,
                                            id: "panfile",
                                            variant: "tertiary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.panfile.$el.click()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.panFile
                                                  ? _vm.form.panFile.name
                                                  : "Choose File"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.editId && _vm.panFile
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            href: _vm.panFile,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "ml-5 mt-10 d-flex align-items-center",
                                              attrs: {
                                                variant: "primary",
                                                title: "Click to View"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "editsvg",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 512 512"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-6 col-9 d-flex align-items-center mt-10"
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticStyle: { width: "100% !important" },
                                      attrs: {
                                        id: "panCardId",
                                        label:
                                          "Letter of Undertaking (Optional)",
                                        "label-for": "lutfile"
                                      }
                                    },
                                    [
                                      _c("b-form-file", {
                                        ref: "lutfile",
                                        staticClass: "d-none",
                                        attrs: {
                                          accept:
                                            ".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx",
                                          plain: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.validate(
                                              _vm.form.lutFile,
                                              "lou"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.form.lutFile,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "lutFile", $$v)
                                          },
                                          expression: "form.lutFile"
                                        }
                                      }),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            disabled: _vm.editId,
                                            id: "lutfile",
                                            variant: "tertiary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.lutfile.$el.click()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.lutFile
                                                  ? _vm.form.lutFile.name
                                                  : "Choose File"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.editId && _vm.lutFile
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            href: _vm.lutFile,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "ml-5 mt-10 d-flex align-items-center",
                                              attrs: {
                                                variant: "primary",
                                                title: "Click to View"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "editsvg",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox: "0 0 512 512"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-6 col-9" })
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _vm.form.csbType
                    ? _c(
                        "div",
                        {
                          staticClass: "col row fs16 pt-15",
                          staticStyle: { "font-weight": "500 !important" }
                        },
                        [
                          _c("strong", [_vm._v("NOTE ")]),
                          _vm._v(": Maximum file size allowed is 5 Mb ")
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "row pt-15" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              disabled:
                                (!this.editId && !this.form.csbType) ||
                                (!this.editId &&
                                  this.form.csbType == "CSB IV" &&
                                  this.form.idProofFile == null) ||
                                (!this.editId &&
                                  this.form.csbType == "CSB V" &&
                                  (this.form.gstFile == null ||
                                    this.form.adcodeFile == null ||
                                    this.form.iecFile == null ||
                                    this.form.panFile == null)),
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.submitting ? _c("b-spinner") : _vm._e(),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.editId ? "Update" : "Add") +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }