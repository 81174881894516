var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid pb-80 pb-md-60 pb-lg-0" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-lg-8" }, [
          _c(
            "div",
            { staticClass: "card profile mb-16" },
            [
              _vm._m(0),
              _vm.childProfile
                ? _c("div", { staticClass: "card__content" }, [
                    _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "lh16 ml-16 text-gray-600 d-none d-md-block w-100"
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.seller.childName) + " "),
                              !_vm.seller.id
                                ? _c(
                                    "span",
                                    { staticClass: "d-inline-block w-25" },
                                    [
                                      _c("b-skeleton", {
                                        attrs: { animation: "wave" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                            },
                            [_vm._v(" " + _vm._s(_vm.seller.childName) + " ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: "",
                                variant: "icon-transparent mr-22 px-0"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-lock-alt text-gray-400 fs12"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "lh16 ml-16 text-gray-600 d-none d-md-block w-100"
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.seller.childEmail) + " "),
                              !_vm.seller.id
                                ? _c(
                                    "span",
                                    { staticClass: "d-inline-block w-25" },
                                    [
                                      _c("b-skeleton", {
                                        attrs: { animation: "wave" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                            },
                            [_vm._v(" " + _vm._s(_vm.seller.childEmail) + " ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: "",
                                variant: "icon-transparent mr-22 px-0"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-lock-alt text-gray-400 fs12"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "lh16 ml-16 text-gray-600 d-none d-md-block w-100"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.seller.childContact) + " "
                              ),
                              !_vm.seller.id
                                ? _c(
                                    "span",
                                    { staticClass: "d-inline-block w-25" },
                                    [
                                      _c("b-skeleton", {
                                        attrs: { animation: "wave" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.seller.childContact) + " "
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: "",
                                variant: "icon-transparent mr-22 px-0"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-lock-alt text-gray-400 fs12"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _c(
                    "b-form",
                    {
                      staticClass: "card__content",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submitProfile($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("span", { staticClass: "lh16 text-gray-600" }, [
                            _vm._v(" Seller Name ")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "lh16 ml-16 text-gray-600 d-none d-md-block w-100"
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.seller.sellerName) + " "
                                ),
                                !_vm.seller.id
                                  ? _c(
                                      "span",
                                      { staticClass: "d-inline-block w-25" },
                                      [
                                        _c("b-skeleton", {
                                          attrs: { animation: "wave" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.seller.sellerName) + " "
                                )
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: "",
                                  variant: "icon-transparent mr-22 px-0"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-lock-alt text-gray-400 fs12"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row py-13 mb-6 mb-md-6" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-5 mt-5",
                            class: { "my-auto": _vm.emailotpsent }
                          },
                          [
                            _c("span", { staticClass: "text-gray-600" }, [
                              _vm._v(" Registered Email "),
                              !_vm.seller.emailVerified
                                ? _c("img", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "warning1 ml-8",
                                    attrs: {
                                      title:
                                        "Please verify e-mail to complete your profile.",
                                      src: require("@/assets/images/warning1.png"),
                                      alt: ""
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]
                        ),
                        !_vm.emailotpsent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "lh16 my-auto ml-16 text-gray-600 d-none d-md-block w-100"
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.seller.username) + " "
                                    ),
                                    !_vm.seller.id
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "d-inline-block w-50"
                                          },
                                          [
                                            _c("b-skeleton", {
                                              attrs: { animation: "wave" }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.seller.username) + " "
                                    )
                                  ]
                                ),
                                _vm.seller.emailVerified
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "icon-transparent mr-22 px-0"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-lock-alt text-gray-400 fs12"
                                        })
                                      ]
                                    )
                                  : _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          size: "xxs",
                                          variant: "tertiary mr-5"
                                        },
                                        on: { click: _vm.sendemailOtp }
                                      },
                                      [
                                        _vm.sendingemail
                                          ? _c("b-spinner")
                                          : _c("span", [_vm._v("Verify")])
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.emailotpsent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "otp",
                                        placeholder: "Six digits OTP",
                                        maxlength: "6",
                                        minlength: "6",
                                        formatter: _vm.numbers,
                                        type: "text"
                                      },
                                      model: {
                                        value: _vm.emailotp,
                                        callback: function($$v) {
                                          _vm.emailotp = $$v
                                        },
                                        expression: "emailotp"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "input-group-text z-99"
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  size: "xxs",
                                                  variant: "tertiary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.verifyemailOTP(
                                                      _vm.emailotp
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm.sumbitemailotp
                                                  ? _c("b-spinner")
                                                  : _c("span", [
                                                      _vm._v("Submit")
                                                    ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _c("span", { staticClass: "text-gray-600" }, [
                            _vm._v(" Legal ")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "lh16 ml-16 text-gray-600 d-none d-md-block w-100"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.seller.sellerCompanyName) +
                                    " "
                                ),
                                !_vm.seller.id
                                  ? _c(
                                      "span",
                                      { staticClass: "d-inline-block w-75" },
                                      [
                                        _c("b-skeleton", {
                                          attrs: { animation: "wave" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.seller.sellerCompanyName) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "icon-transparent mr-22 px-0"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-lock-alt text-gray-400 fs12"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row py-6 mb-6 mb-md-12" }, [
                        _c("div", { staticClass: "col-5 my-auto" }, [
                          _c("span", { staticClass: "d-block text-gray-600" }, [
                            _vm._v(" " + _vm._s(_vm.tfnlabel) + " ")
                          ])
                        ]),
                        _vm.isLabel && _vm.otpsent == false
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "lh16 my-auto ml-16 text-gray-600 d-none d-md-block w-100"
                                  },
                                  [
                                    _vm._v(" " + _vm._s(_vm.mobile) + " "),
                                    !_vm.seller.id
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "d-inline-block w120px"
                                          },
                                          [
                                            _c("b-skeleton", {
                                              attrs: { animation: "wave" }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                                  },
                                  [_vm._v(" " + _vm._s(_vm.mobile) + " ")]
                                ),
                                _vm.seller.id
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          size: "xxs",
                                          variant: "tertiary"
                                        },
                                        on: { click: _vm.sendOTP }
                                      },
                                      [_vm._v("Update")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm.isLabel == false && _vm.otpsent == false
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "mb-0 w-100",
                                    attrs: { id: "phone-field" }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "phone-number",
                                            maxlength: "10",
                                            minlength: "10",
                                            placeholder: "8888888888",
                                            formatter: _vm.numbers,
                                            required: "",
                                            type: "text"
                                          },
                                          model: {
                                            value: _vm.mobile,
                                            callback: function($$v) {
                                              _vm.mobile = $$v
                                            },
                                            expression: "mobile"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "input-group-append" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group-text z-99"
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      size: "xxs",
                                                      variant: "tertiary"
                                                    },
                                                    on: {
                                                      click: _vm.submitNumber
                                                    }
                                                  },
                                                  [_vm._v(" Submit ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.otpsent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "otp",
                                        placeholder: "Six digits OTP",
                                        maxlength: "6",
                                        minlength: "6",
                                        formatter: _vm.numbers,
                                        type: "text"
                                      },
                                      model: {
                                        value: _vm.otp,
                                        callback: function($$v) {
                                          _vm.otp = $$v
                                        },
                                        expression: "otp"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "input-group-text z-99"
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  size: "xxs",
                                                  variant: "tertiary"
                                                },
                                                on: { click: _vm.verifyOTP }
                                              },
                                              [_vm._v(" Verify ")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _c("span", { staticClass: "text-gray-600" }, [
                            _vm._v(" GST Number ")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "lh16 ml-16 text-gray-600 d-none d-md-block"
                              },
                              [_vm._v(" " + _vm._s(_vm.seller.gstno) + " ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                              },
                              [_vm._v(" " + _vm._s(_vm.seller.gstno) + " ")]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "icon-transparent mr-22 px-0"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-lock-alt text-gray-400 fs12"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _c("span", { staticClass: "text-gray-600" }, [
                            _vm._v(" PAN Number ")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "lh16 ml-16 text-gray-600 d-none d-md-block"
                              },
                              [_vm._v(" " + _vm._s(_vm.seller.panNo) + " ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                              },
                              [_vm._v(" " + _vm._s(_vm.seller.panNo) + " ")]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "icon-transparent mr-22 px-0"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-lock-alt text-gray-400 fs12"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row py-6 mb-6 mb-md-12" }, [
                        _c("div", { staticClass: "col-5 my-auto" }, [
                          _c("span", { staticClass: "d-block text-gray-600" }, [
                            _vm._v(" Finance Email ")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0 w-100",
                                attrs: { id: "finance-email-field" }
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "finance-email",
                                        placeholder: "finance@example.com",
                                        type: "email"
                                      },
                                      model: {
                                        value: _vm.profile.financeEmail,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profile,
                                            "financeEmail",
                                            $$v
                                          )
                                        },
                                        expression: "profile.financeEmail"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "icon-mail fs16 text-gray-600"
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row py-6 mb-6 mb-md-12" }, [
                        _c("div", { staticClass: "col-5 my-auto" }, [
                          _c("span", { staticClass: "d-block text-gray-600" }, [
                            _vm._v(" Operation Email ")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0 w-100",
                                attrs: { id: "operation-email-field" }
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "operation-email",
                                        placeholder: "operation@example.com",
                                        type: "email"
                                      },
                                      model: {
                                        value: _vm.profile.operationEmail,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profile,
                                            "operationEmail",
                                            $$v
                                          )
                                        },
                                        expression: "profile.operationEmail"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "icon-mail fs16 text-gray-600"
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "d-md-block w-sm-100 profile_submit_btn_mobile",
                          attrs: {
                            type: "submit",
                            disabled: _vm.submitting,
                            variant: "primary"
                          }
                        },
                        [
                          _vm._v(" Save "),
                          _vm.submitting
                            ? _c("b-spinner", { staticClass: "ml-10" })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm.$store.getters.user.childRole != "OPERATIONS"
            ? _c("div", { staticClass: "card profile mb-16" }, [
                _vm._m(4),
                _c("div", { staticClass: "card__content" }, [
                  _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                    _vm._m(5),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "lh16 ml-16 text-gray-600 d-none d-md-block"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.bankName) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.bankName) + " ")]
                        ),
                        _c(
                          "b-button",
                          { attrs: { variant: "icon-transparent mr-22 px-0" } },
                          [
                            _c("i", {
                              staticClass: "icon-lock-alt text-gray-400 fs12"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                    _vm._m(6),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "lh16 ml-16 text-gray-600 d-none d-md-block"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.accountNo) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.accountNo) + " ")]
                        ),
                        _c(
                          "b-button",
                          { attrs: { variant: "icon-transparent mr-22 px-0" } },
                          [
                            _c("i", {
                              staticClass: "icon-lock-alt text-gray-400 fs12"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                    _vm._m(7),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "lh16 ml-16 text-gray-600 d-none d-md-block"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.accountName) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.accountName) + " ")]
                        ),
                        _c(
                          "b-button",
                          { attrs: { variant: "icon-transparent mr-22 px-0" } },
                          [
                            _c("i", {
                              staticClass: "icon-lock-alt text-gray-400 fs12"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                    _vm._m(8),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "lh16 ml-16 text-gray-600 d-none d-md-block"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.ifscCode) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                          },
                          [_vm._v(" " + _vm._s(_vm.seller.ifscCode) + " ")]
                        ),
                        _c(
                          "b-button",
                          { attrs: { variant: "icon-transparent mr-22 px-0" } },
                          [
                            _c("i", {
                              staticClass: "icon-lock-alt text-gray-400 fs12"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                    _vm._m(9),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "lh16 ml-16 text-gray-600 d-none d-md-block"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.seller.accountType
                                    ? _vm.seller.accountType
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.seller.accountType
                                    ? _vm.seller.accountType
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          { attrs: { variant: "icon-transparent mr-22 px-0" } },
                          [
                            _c("i", {
                              staticClass: "icon-lock-alt text-gray-400 fs12"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row py-13 mb-6 mb-md-12" }, [
                    _vm._m(10),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "lh16 ml-16 text-gray-600 d-none d-md-block"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.seller.branchDetail
                                    ? _vm.seller.branchDetail
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.seller.branchDetail
                                    ? _vm.seller.branchDetail
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          { attrs: { variant: "icon-transparent mr-22 px-0" } },
                          [
                            _c("i", {
                              staticClass: "icon-lock-alt text-gray-400 fs12"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card top100 sticky-top mb-12" }, [
            _c(
              "div",
              { staticClass: "card__content" },
              [
                _c("i", {
                  staticClass: "d-block icon-lock fs46 text-gray-600 mb-20"
                }),
                _c("span", { staticClass: "d-block fs18 lh28 fw500 mb-8" }, [
                  _vm._v(" Change Password? ")
                ]),
                _c("span", { staticClass: "d-block fs14 lh20 text-gray-800" }, [
                  _vm._v(
                    " You’re information is safe and sound with us. You should change your password at least once a year. "
                  )
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-20",
                    attrs: { variant: "tertiary" },
                    on: {
                      click: function($event) {
                        return _vm.openChangePasswordModal("updatepassword")
                      }
                    }
                  },
                  [_vm._v("Login Password")]
                ),
                _vm.adminUser
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mt-20 ml-10",
                        attrs: { variant: "tertiary" },
                        on: {
                          click: function($event) {
                            return _vm.openChangePasswordModal(
                              "profilepassword"
                            )
                          }
                        }
                      },
                      [_vm._v("Profile Password")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "card top360 sticky-top" }, [
            _c(
              "div",
              { staticClass: "card__content" },
              [
                _c("i", {
                  staticClass: "d-block icon-file-logo fs46 text-gray-600 mb-20"
                }),
                _c("span", { staticClass: "d-block fs18 lh28 fw500 mb-8" }, [
                  _vm._v(" Accepted Agreements ")
                ]),
                _c("span", { staticClass: "d-block fs14 lh20 text-gray-800" }, [
                  _vm._v(
                    " Your agreement with Shyplite can be downloaded here. It’s in the PDF format. "
                  )
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-20",
                    attrs: { variant: "tertiary" },
                    on: { click: _vm.downloadAgreement }
                  },
                  [_vm._v(" Download Agreement ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-20 ml-10 logs-btn",
                    attrs: { href: "/user-agreements", variant: "tertiary" }
                  },
                  [_vm._v(" Acceptance Logs ")]
                )
              ],
              1
            )
          ])
        ]),
        _c("SettingsPasswordModal", {
          ref: "changePassword",
          attrs: { passpath: _vm.passpath }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs18 fw500" }, [_vm._v(" Your Profile ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [_vm._v(" Name ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [_vm._v(" Email ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [_vm._v(" Contact ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs18 fw500" }, [_vm._v(" Your Bank Detail ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [_vm._v(" Bank Name ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [
        _vm._v(" Account No. ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [
        _vm._v(" Account Name ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [_vm._v(" IFSC Code ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [
        _vm._v(" Account Type ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-5" }, [
      _c("span", { staticClass: "lh16 text-gray-600" }, [
        _vm._v(" Branch Detail ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }