var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid pb-80 pb-md-0" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-8" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card__title" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _c(
                  "span",
                  { staticClass: "vam fs16 lh24 text-gray-900 mr-12" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.api.status == "enable" ? "Enabled" : "Disabled"
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "b-form-group",
                  { staticClass: "d-inline mb-0" },
                  [
                    _c("b-form-checkbox", {
                      staticClass: "d-inline",
                      attrs: {
                        value: "enable",
                        "unchecked-value": "disable",
                        name: "default-pickup",
                        switch: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.createPublicKey($event, "default")
                        }
                      },
                      model: {
                        value: _vm.api.status,
                        callback: function($$v) {
                          _vm.$set(_vm.api, "status", $$v)
                        },
                        expression: "api.status"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "card__content" }, [
            _c("p", { staticClass: "mb-22" }, [
              _vm._v(
                " The Shyplite API is a powerful way to expand and automate your online business. "
              )
            ]),
            _c(
              "span",
              [
                _vm._v(" You can read the documentation "),
                _c(
                  "b-link",
                  {
                    staticClass: "fs14 lh16",
                    attrs: {
                      href: "https://app.shyplite.com/api",
                      target: "_blank"
                    }
                  },
                  [_vm._v(" here")]
                )
              ],
              1
            )
          ])
        ]),
        _vm.api.status == "enable"
          ? _c("div", { staticClass: "card mt-4" }, [
              _c("div", { staticClass: "card__content" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col d-flex" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { value: "refresh", variant: "tertiary" },
                            on: {
                              click: function($event) {
                                return _vm.createPublicKey($event, "refresh")
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-refresh fs12 mr-12" }),
                            _vm._v(" Regenerate ")
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "row pt-22 pb-14" }, [
                  _c("div", { staticClass: "col-md-auto" }, [
                    _c("span", { staticClass: "d-block my-4" }, [
                      _c("strong", [_vm._v("Seller ID:")]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.user.id,
                              expression: "user.id",
                              arg: "copy"
                            },
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass:
                            "d-inline-block px-12 py-10 ml-8 bg-orange-light rounded cp",
                          attrs: { title: "Click to copy!" },
                          on: { click: _vm.copySuccess }
                        },
                        [_vm._v(" " + _vm._s(_vm.user.id) + " ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-auto" }, [
                    _c("span", { staticClass: "d-block my-4" }, [
                      _c("strong", [_vm._v("Public Key :")]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.api.key,
                              expression: "api.key",
                              arg: "copy"
                            },
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass:
                            "d-inline-block px-12 py-10 ml-8 bg-orange-light rounded cp",
                          attrs: { title: "Click to copy!" },
                          on: { click: _vm.copySuccess }
                        },
                        [_vm._v(" " + _vm._s(_vm.api.key) + " ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-auto" }, [
                    _c("span", { staticClass: "d-block my-4" }, [
                      _c("strong", [_vm._v("App's ID :")]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.api.appID,
                              expression: "api.appID",
                              arg: "copy"
                            },
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass:
                            "d-inline-block px-12 py-10 ml-8 bg-orange-light rounded cp",
                          attrs: { title: "Click to copy!" },
                          on: { click: _vm.copySuccess }
                        },
                        [_vm._v(" " + _vm._s(_vm.api.appID) + " ")]
                      )
                    ])
                  ])
                ]),
                _vm.showPrivateKey
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-auto" }, [
                        _c("span", { staticClass: "d-block my-4" }, [
                          _c("strong", [_vm._v("Private Key :")]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.privateKey,
                                  expression: "privateKey",
                                  arg: "copy"
                                },
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true }
                                }
                              ],
                              staticClass:
                                "d-inline-block px-12 py-10 mt-10 bg-orange-light rounded cp",
                              attrs: { title: "Click to copy!" },
                              on: { click: _vm.copySuccess }
                            },
                            [_vm._v(" " + _vm._s(_vm.privateKey) + " ")]
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("span", { staticClass: "fs20 lh24 fw500" }, [_vm._v(" Shyplite API ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("span", { staticClass: "fs20 lh24 fw500" }, [
        _vm._v(" Your API Credentials ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }