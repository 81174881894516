<template>
  <div class="container-fluid position-relative">
    <transition name="slide-left" v-if="!filterResultsToggle">
      <div class="d-flex pb-22 align-items-center justify-content-between">
      <span class="fw700 pb-md-0"></span>
      <span class="d-flex">
        <b-button v-b-tooltip.hover.left variant="primary" @click="addNewUser">
        Add New Customer</b-button>
        <div class="divider mx-20 my-auto vam d-md-flex"></div>
        <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
          <span>
            Filters
          </span>
          <i class="icon-filter text-gray-600 pl-10"></i>
        </b-button>
      </span>
    </div>
    </transition>   
    


    <transition name="slide-right">
         <div class="col pb-22 pt-md-20 pt-20 d-flex align-items-center justify-content-between" v-if="filterResultsToggle">
            <div class="d-flex align-items-center">
                <span
                class="d-none d-md-flex mr-6 text-gray-600 wsnw"
                v-if="this.selFilters.length != '0'"
                >
                Results for:
            </span>
                    <ul class="d-none d-md-flex result-list">
                        <li v-for="(selFilter, index) in selFilters" :key="index">
                            <b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering' && selFilter != '' ">
                                <span class="wsnw text-truncate">
                                    {{typeof selFilter == 'number' ? modeTypes[selFilter] : selFilter}}
                                </span>
                                <i
                                class="icon-close fs6"
                                @click="removeFilter(index)"
                                ></i>
                            </b-button>
                        </li>
                    </ul>

                    <span
                    class="d-flex d-md-none"
                    v-if="this.selFilters.length != '0'"
                    >
                    {{ this.selFilters.length }} Filters selected.
                </span>
                <span class="d-flex d-md-none" v-else>
                    No filters selected.
                </span>
                <b-link @click="clearFilters()" class="ml-24 mr-12 vam">
                    <span class="text-gray-900 wsnw">Clear filters</span>
                    <i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
                </b-link>
            </div>
            <div class="d-flex">
              <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
                <span>
                  Filters
                </span>
                <i class="icon-filter text-gray-600 pl-10"></i>
              </b-button>
            </div>
            </div>

        </transition>

<div v-if="this.windowWidth > 767">
  <b-table
  :items="items"
  :fields="fields"
  :per-page="perPage"
  :busy="isBusy"
  sticky-header
  no-border-collapse
  class="shipments-table table-sm-cards"
  id="manifests-table"
  >
  <!-- Table Busy loader -->
  <template #table-busy>
    <div class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </template>
  <template v-slot:cell(id)="data">
    {{ data.item.id }}
  </template>
  <!-- A custom formatted data column cell for field 'Date' -->
  <!-- A custom formatted header cell for field 'selected' -->
  <template v-slot:cell(email)="data">
    <span class="text-truncate">{{ellipsize(data.item.email)}}</span>
  </template>

  <template v-slot:cell(address)="data">
    <span class="text-truncate">{{ellipsize(data.item.address)}}</span>
  </template>

  <template v-slot:cell(city)="data">
    <span class="text-truncate">{{ellipsize(data.item.city)}}</span>
  </template>
  <!-- Example scoped slot for select state illustrative purposes -->

  <!-- A custom formatted data column cell for field 'Action' -->
  <template v-slot:cell(action)="data">
    <multiselect
      id="action"
      v-model="data.item.selected"
      :options="actionOptions"
      :searchable="false"
      :show-labels="false"
      placeholder="- Select -"
      class="h36-select w120px mx-auto"
      track-by="name"
      label="name"
      @input="goNeworder(data.item.selected,data.item)"
      ></multiselect>
  </template>
</b-table>
</div>
<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
  <li class="" v-for="item in items" :key="item.id">
    <div class="d-flex justify-content-between w-100">
      <span class="d-block fw500 pb-8 cp" @click="goShipment(item.id)">
        Customer :
        {{ item.name }}
      </span>
      <div class="d-flex align-items-end justify-content-between">
        <multiselect
          id="action"
          v-model="item.selected"
          :options="actionOptions"
          :searchable="false"
          :show-labels="false"
          placeholder="- Select -"
          class="h36-select w120px mx-auto"
          track-by="name"
          label="name"
          @input="goNeworder(item.selected,item)"
          ></multiselect>
      </div>
    </div>
    <div class="d-flex align-items-end justify-content-between w-100 mb-12">
      <div class="info">
        <span class="carrier-mode">
          {{ item.email }}
        </span>
        <span class="carrier-mode">
          {{ item.contact }}
        </span>
        <span class="carrier-mode">{{ item.city }}</span>
      </div>
    </div>
    <span>
      {{ item.address }}
    </span>
    
    <div class="d-flex mt-5 mt-md-5 w-100 align-items-end justify-content-between">
      <span class="fs12 lh16">
        {{ item.pincode}}
      </span>
      <span class="tag" >
        {{ item.sourceName }}
      </span>
    </div>
  </li>
</ul>

<div class="pagination-bar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="d-inline-flex wsnw align-items-center">
          <div class="d-block w50px">
            <multiselect
            class="pagination-items__pp"
            v-model="perPage"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            :options="pageOptions"
            @input="setPageNumber"
            ></multiselect>
          </div>
          <span
          class="pl-8 fs12 lh24 text-gray-600 pagination-items"
          ></span>
        </div>
      </div>
      <div class="col-8 text-right">
        <div class="d-inline-flex ml-auto wsnw align-items-center">
          <p class="fs12 lh24 text-gray-600 pr-14">
            Page: {{ currentPage }}
          </p>

          <b-button-group>
            <b-button
            @click="prevPage"
            :disabled="prevDisabled"
            variant="paginate left"
            >
            <i class="icon-back fs12"></i>
          </b-button>

          <b-button
          @click="nextPage"
          :disabled="nextDisabled"
          variant="paginate right"
          >
          <i class="icon-arrow fs12"></i>
        </b-button>
      </b-button-group>
    </div>
  </div>
</div>
</div>
</div>
<ResultNotFound v-if="items.length == 0 && !isBusy" />
<CustomerBookFilter ref="filter" />
<AddNewCustomer  ref="addNewCustomer" />
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ResultNotFound from "@/components/ResultNotFound.vue";
import CustomerBookFilter from "@/views/customerbook/CustomerBookFilter.vue";
import AddNewCustomer from "@/views/customerbook/Addnewcustomer.vue";

export default {
  name: "userTable",
  data() {
    return {
      userEmail:null,
      pageOptions: this.$store.state.pageOptions,
      totalRows: 1,
      currentPage: parseInt(this.$route.query.page) || 1,
      perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
      totalPages: 1,
      actionOptionsname:null,
      prevDisabled: true,
      nextDisabled: false,
      startPage: 1,
      disableAdd:false,
      pagesArr: [],
      actionOptions: [
          {id:0,name:"Single"}, 
          {id:3,name:"International"}
      ],  
      pagination: {},
      items: [],
      reconciliation:false,
      isBusy: true,
      filterResultsToggle: this.$store.getters.filters.customerbook ? true : false,
      allSelected: false,
      selFilters: this.$store.getters.filters.customerbook || {},
      warehouses: this.$store.getters.warehouses || {},
      downloading: false,
      editUserflag:false,
      fields: [
      { key: "name", label : "Name",thClass: "",tdClass: "" },
      { key: "email", label: "Email ID",thClass: "",tdClass: "" },
      { key: "contact", label: "Contact No.",thClass: "",tdClass: ""  },
      { key: "address",thClass: "",tdClass: ""  },
      { key: "city",thClass: "",tdClass: "" },
      { key: "pincode", thClass: "",tdClass: "" },
      { key: "sourceName",label:"Source", thClass: "",tdClass: "" },
      { key: "action", thClass: "",tdClass: "" },
      ],
    };
  },
  components: {
    Multiselect,
    ResultNotFound,
    CustomerBookFilter,
    AddNewCustomer
  },
  created() {
    this.getItems()
    if(this.$store.getters.user.mpsStatus == 1) {
      this.actionOptions.push({id:2,name:"MPS"})
    }
    if(this.$store.getters.user.b2bIsActive == 1) {
      this.actionOptions.push({id:4,name:"B2B"})
    }
  },
  methods: {
    goNeworder(obj,Customerobj) {
      this.$store.commit('setTab', obj.id)
      this.$router.push('/new')
      this.$store.commit('setCustomerObj',Customerobj)
    },
    addNewUser() {
      this.$refs.addNewCustomer.isOpen = !this.$refs.addNewCustomer.isOpen
    },

    async getItems(params) {

      try {

        this.isBusy = true

        if (!params) {
          let params = {}
        }
        if(Object.keys(this.selFilters).length > 0) {
          params = {...this.selFilters}
          // params.sku = params.sku || ''
          // params.mode = params.mode?this.$store.getters.mode.domestic.find(i => i.name == params.mode).value:''
        }

        const res = await this.axios.get(`/v4/customerAddressBook?page=${this.currentPage}&offset=${this.perPage}`, {params:params})

        if (res.data.response.success) {
          this.items = res.data.response.data

          this.totalRows = this.items.length;
          // this.pagination = res.data.response.data.pagination;
          this.setPagination()
          if(this.items.length < this.perPage) {
            this.nextDisabled = true
          }
        } else {
          this.items = []
        }

        this.isBusy = false

      } catch (error) {
        this.isBusy = false
        this.nextDisabled = true
        console.log(error)
        // this.popToast('failed', 'Failed!', error)
      }
    },
    setPagination() {
     // this.nextDisabled = this.pagination.next ? false : true
     // this.prevDisabled = this.pagination.prev ? false : true
     // this.$router.push(`?page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
    },
    pushTotalPages() {
     this.pagesArr = [];
     for (var i = 1; i <= this.totalPages; i++) {
       this.pagesArr.push(i);
     }
    },
    setPageNumber() {
     this.currentPage = 1
     this.getItems()
     this.totalPages = Math.ceil(this.totalRows / this.perPage);
     this.pagesArr = [];
     for (var i = 1; i <= this.totalPages; i++) {
       this.pagesArr.push(i);
     }

     this.prevDisabled = true;
     if (this.totalPages == 1) {
       this.nextDisabled = true;
     } else if (this.totalPages > 1) {
       this.nextDisabled = false;
     }
    },
    updatePageNumber() {
     this.totalPages = Math.ceil(this.totalRows / this.perPage);
     this.pagesArr = [];
     for (var i = 1; i <= this.totalPages; i++) {
       this.pagesArr.push(i);
     }
    },
    rowClicked(item) {
     this.$router.push(`/shipments?manifestID=${item.id}`)
    }, 
    nextPage() {
     this.currentPage++;
     this.getItems()
    },
    prevPage() {
     this.currentPage--
     this.getItems()
    },
    toggleAllRows() {
     if (this.allSelected == true) {
       this.items.forEach((item) => {
         if(item.shipments > 0 && item.carrier != 'Shypmax') {
           this.$set(item, "selected", true);
         }
         this.allSelected = true;
       });
     } else {
       this.selectedRows.forEach((item) => {
         // this.$delete(item, "selected");
         this.$set(item, "selected", false);
         this.allSelected = false;
       });
     }
    },
    clearSelected() {
     this.selectedRows.forEach((item) => {
       this.$delete(item, "selected");
     });
     this.allSelected = false;
    },
    async downloadManifests() {
      try {
        this.downloading = true
        this.popToast("requested", "Download requested", "Your download should start now.");
        let ids = ''
        let closedIds = []
        this.selectedRows.map(item => {
          closedIds.push(item.id)
          ids +='-'+ item.id
        })
        
        const res = await this.axios.get(`/manifestpdf/${ids.substring(1)}`)
        if(res.data.path) {
          this.items.filter(i => closedIds.indexOf(i.id)>=0).map(item => item.status='close')
          this.download(res.data.path, res.data.name)
        }
        this.clearSelected();
      } catch(e) {
        console.log(e);
      }
      this.downloading = false
    },
    async downloadOne(id, carrier, status) {
      if (carrier == 'DHL') {
        if (status == 'open') {
          try {
            let result = await this.axios.get(`/manifestpdfdhl/${id}`);

            if(result) {
              this.popToast("booked", "Manifest has been successfully generated.", "You will receive an email from DHL shortly.");
            }
          }
          catch(err) {
            console.log(err)
            this.popToast("failed", "Failed.", "DHL Manifest Failed.");
          }
        }
        else {
          this.popToast("booked", "Manifest is already generated.", 'Please check for an email from DHL on your registered email ID');
        }
      }
      else {
        try {
          this.popToast("requested", "Download requested", "Your download should start now.");
          const res = await this.axios.get(`/manifestpdf/${id}`)
          if(res.data.path) {
            const index = this.items.findIndex(i => i.id == id)
            this.items[index].status = 'close'
            this.download(res.data.path, res.data.name)
          }
        } catch(e) {
          console.log(e);
        }
      }
    },
    downloadOrders() {
      this.popToast("requested", "Download requested", "Your download should start now.");
      this.filterResultsToggle = false;
    },
    getFilters() {
      this.currentPage = 1
      this.filterResultsToggle = true;
      this.selFilters = this.$store.getters.filters.customerbook
      this.getItems()
    },
    removeFilter(index) {
      const selFilters = {...this.selFilters}
      if(index == 'dateEnd' || index == 'dateStart') {
        delete selFilters.dateEnd
        delete selFilters.dateStart
        delete this.$refs.filter.selectedFilters.dateEnd
        delete this.$refs.filter.selectedFilters.dateStart
        delete this.$refs.filter.form.date
      }else{
        delete selFilters[index]
        delete this.$refs.filter.selectedFilters[index]
      }
      this.selFilters = selFilters
      this.$store.commit('setFilter', {'customerbook': selFilters})
      delete this.$refs.filter.form[index]
      if(Object.keys(this.selFilters).length > 0) {
        this.getItems()
      }else{
        this.clearFilters()
      }
    },
    clearFilters() {
      this.filterResultsToggle = false;
      this.selFilters = {};
      this.$refs.filter.clearFilters()
      this.getItems()
    },
    openCancelModal() {
      this.$refs.cancelOrderModal.isOpen = !this.$refs.cancelOrderModal.isOpen;
    },
    goShipment(id) {
      this.$router.push(`/shipments?manifestID=${id}`)
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    selectedRows() {
      const items = this.items.filter((item) => item.selected)
      if(items.length>0) {
        this.allSelected = true
      }
      return items
    },
    // filters() {
    //   return this.$store.getters.orderFilters;
    // }
},
watch: {
  currentPage: function() {
    this.prevDisabled = false;
    this.nextDisabled = false;

    if (this.currentPage == 1) {
      this.prevDisabled = true;
    }

    if (this.currentPage == this.totalPages) {
      this.nextDisabled = true;
    }
  },
  selectedRows: function() {
    if (this.selectedRows.length == this.items.length) {
      this.allSelected = true;
    }
  },
},
mounted() {
  this.totalRows = this.items.length;
  this.totalPages = Math.ceil(this.totalRows / this.perPage);
  for (var i = 1; i <= this.totalPages; i++) {
    this.pagesArr.push(i);
  }
},
};
</script>

<style lang="scss" scoped>
  .tag {
    font-weight: 800;
  }
  /deep/.correct {
    padding: 1rem 1.125rem 0.75rem 0 !important;
  }
  /deep/ .correct-pad {
      padding-top: 1rem;
  } 
  .adduser {
    height: 1rem;
      width: 1rem;
  }

  .editsvg {
    height: 0.85rem;
    width:1rem;

    path {
      fill:white !important;
    }
  } 
  /deep/ .paddingfirst {
      padding: 0 0.75rem 1rem 0.5rem !important;
  }

  /deep/.shipments-table tbody tr td:last-child {
    border-left:unset !important;
    width: 11rem !important;
  }
  /deep/ .shipments-table tbody tr td:nth-child(2) {
    border-right:unset !important;
    width: 15rem !important;
  }

  /deep/ .shipments-table tbody tr td:first-child {
    padding: 0 0.75rem 0 2rem;
  }

  /deep/ .shipments-table thead tr th:first-child {
    padding: 0 0.75rem 0 2rem;
  }
</style>
