<template>
	<div class="container-fluid pb-80 pb-md-60 pb-lg-0">
		<div class="row">
			<div class="col-lg-8">
				<div class="card profile mb-16">
					<div class="card__title">
						<span class="fs18 fw500">
							Your Profile
						</span>
					</div>



					<div v-if="childProfile" class="card__content">
						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-md-5">
								<span class="lh16 text-gray-600">
									Name
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 ml-16 text-gray-600 d-none d-md-block w-100">
									{{ seller.childName }}
									<span v-if="!seller.id" class="d-inline-block w-25">
										<b-skeleton animation="wave"></b-skeleton>
									</span>
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.childName }}
								</span>
								<b-button disabled variant="icon-transparent mr-22 px-0">
									<i class="icon-lock-alt text-gray-400 fs12"></i>
								</b-button>
							</div>
						</div>

						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-md-5">
								<span class="lh16 text-gray-600">
									Email
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 ml-16 text-gray-600 d-none d-md-block w-100">
									{{ seller.childEmail }}
									<span v-if="!seller.id" class="d-inline-block w-25">
										<b-skeleton animation="wave"></b-skeleton>
									</span>
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.childEmail }}
								</span>
								<b-button disabled variant="icon-transparent mr-22 px-0">
									<i class="icon-lock-alt text-gray-400 fs12"></i>
								</b-button>
							</div>
						</div>


						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-md-5">
								<span class="lh16 text-gray-600">
									Contact
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 ml-16 text-gray-600 d-none d-md-block w-100">
									{{ seller.childContact }}
									<span v-if="!seller.id" class="d-inline-block w-25">
										<b-skeleton animation="wave"></b-skeleton>
									</span>
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.childContact }}
								</span>
								<b-button disabled variant="icon-transparent mr-22 px-0">
									<i class="icon-lock-alt text-gray-400 fs12"></i>
								</b-button>
							</div>
						</div>

					</div>

					<b-form v-else @submit.prevent="submitProfile" class="card__content">
						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-md-5">
								<span class="lh16 text-gray-600">
									Seller Name
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 ml-16 text-gray-600 d-none d-md-block w-100">
									{{ seller.sellerName }}
									<span v-if="!seller.id" class="d-inline-block w-25">
										<b-skeleton animation="wave"></b-skeleton>
									</span>
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.sellerName }}
								</span>
								<b-button disabled variant="icon-transparent mr-22 px-0">
									<i class="icon-lock-alt text-gray-400 fs12"></i>
								</b-button>
							</div>
						</div>

						<div class="row py-13 mb-6 mb-md-6">
							<div class="col-5 mt-5" :class=" {'my-auto':emailotpsent} ">
								<span class="text-gray-600">
									Registered Email
									<img v-if="!seller.emailVerified" v-b-tooltip.hover.top title="Please verify e-mail to complete your profile." src="@/assets/images/warning1.png" class="warning1 ml-8" alt="">
								</span>
							</div>
							<div v-if="!emailotpsent" class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 my-auto ml-16 text-gray-600 d-none d-md-block w-100">
									{{ seller.username }}
									<span v-if="!seller.id" class="d-inline-block w-50">
										<b-skeleton animation="wave"></b-skeleton>
									</span>
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.username }}
								</span>
									<b-button v-if="seller.emailVerified" variant="icon-transparent mr-22 px-0">
									<i  class="icon-lock-alt text-gray-400 fs12"></i>
									</b-button>
									<b-button  v-else size="xxs" variant="tertiary mr-5" @click="sendemailOtp" >
									<b-spinner v-if="sendingemail"></b-spinner><span v-else>Verify</span>
									</b-button>
									
									

							</div>


							<div v-if="emailotpsent" class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
									<b-input-group>
										<b-form-input
										id="otp"
										v-model="emailotp"
										placeholder="Six digits OTP"
										maxlength="6"
										minlength="6"
										:formatter="numbers"
										type="text"
										></b-form-input>
										<div class="input-group-append">
											<div class="input-group-text z-99">
												<b-button size="xxs" @click="verifyemailOTP(emailotp)" variant="tertiary"> 
												<b-spinner v-if="sumbitemailotp"></b-spinner><span v-else>Submit</span>
												</b-button>
											</div>
										</div>
									</b-input-group>
								</div>
						</div>

						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-5">
								<span class="text-gray-600">
									Legal
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 ml-16 text-gray-600 d-none d-md-block w-100">
									{{ seller.sellerCompanyName }}
									<span v-if="!seller.id" class="d-inline-block w-75">
										<b-skeleton animation="wave"></b-skeleton>
									</span>
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.sellerCompanyName }}
								</span>
								<b-button variant="icon-transparent mr-22 px-0">
									<i class="icon-lock-alt text-gray-400 fs12"></i>
								</b-button>
							</div>
						</div>


						<div class="row py-6 mb-6 mb-md-12">
							<div class="col-5 my-auto">
								<span class="d-block text-gray-600">
									{{ tfnlabel }}
								</span>
							</div>

							<div v-if="isLabel && otpsent==false" class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 my-auto ml-16 text-gray-600 d-none d-md-block w-100">
									{{ mobile }}
									<span v-if="!seller.id" class="d-inline-block w120px">
										<b-skeleton animation="wave"></b-skeleton>
									</span>
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ mobile }}
								</span>

								<b-button @click="sendOTP" v-if="seller.id" size="xxs" variant="tertiary">Update</b-button>

							</div>

							<div v-else-if="isLabel==false && otpsent==false" class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<b-form-group id="phone-field" class="mb-0 w-100">
									<b-input-group>
										<b-form-input
										id="phone-number"
										v-model="mobile"
										maxlength="10"
										minlength="10"
										placeholder="8888888888"
										:formatter="numbers"
										required
										type="text"
										></b-form-input>
										<div class="input-group-append">
											<div class="input-group-text z-99">
												<b-button size="xxs" @click="submitNumber" variant="tertiary"> Submit </b-button>
											</div>
										</div>
									</b-input-group>
								</b-form-group>
							</div>

							<div v-if="otpsent" class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<b-input-group>
									<b-form-input
									id="otp"
									v-model="otp"
									placeholder="Six digits OTP"
									maxlength="6"
									minlength="6"
									:formatter="numbers"
									type="text"
									></b-form-input>
									<div class="input-group-append">
										<div class="input-group-text z-99">
											<b-button size="xxs" @click="verifyOTP" variant="tertiary"> Verify </b-button>
										</div>
									</div>
								</b-input-group>
							</div>
						</div>


						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-5">
								<span class="text-gray-600">
									GST Number
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
									{{ seller.gstno }}
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.gstno }}
								</span>
								<b-button variant="icon-transparent mr-22 px-0">
									<i class="icon-lock-alt text-gray-400 fs12"></i>
								</b-button>
							</div>
						</div>

						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-5">
								<span class="text-gray-600">
									PAN Number
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
									{{ seller.panNo }}
								</span>
								<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
									{{ seller.panNo }}
								</span>
								<b-button variant="icon-transparent mr-22 px-0">
									<i class="icon-lock-alt text-gray-400 fs12"></i>
								</b-button>
							</div>
						</div>

						<div class="row py-6 mb-6 mb-md-12">
							<div class="col-5 my-auto">
								<span class="d-block text-gray-600">
									Finance Email
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<b-form-group id="finance-email-field" class="mb-0 w-100">
									<b-input-group>
										<b-form-input
										id="finance-email"
										placeholder="finance@example.com"
										type="email"
										v-model="profile.financeEmail"
										></b-form-input>
										<div class="input-group-append">
											<div class="input-group-text">
												<i class="icon-mail fs16 text-gray-600"></i>
											</div>
										</div>
									</b-input-group>
								</b-form-group>
							</div>
						</div>

						<div class="row py-6 mb-6 mb-md-12">
							<div class="col-5 my-auto">
								<span class="d-block text-gray-600">
									Operation Email
								</span>
							</div>
							<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
								<b-form-group id="operation-email-field" class="mb-0 w-100">
									<b-input-group>
										<b-form-input
										id="operation-email"
										placeholder="operation@example.com"
										type="email"
										v-model="profile.operationEmail"
										></b-form-input>
										<div class="input-group-append">
											<div class="input-group-text">
												<i class="icon-mail fs16 text-gray-600"></i>
											</div>
										</div>
									</b-input-group>
								</b-form-group>
							</div>
						</div>
						<b-button type="submit" :disabled="submitting" variant="primary" class="d-md-block w-sm-100 profile_submit_btn_mobile">
							Save <b-spinner v-if="submitting" class="ml-10"></b-spinner>
						</b-button>
					</b-form>
				</div>

				<div class="card profile mb-16" v-if="$store.getters.user.childRole != 'OPERATIONS' ">
					<div class="card__title">
						<span class="fs18 fw500">
							Your Bank Detail
						</span>
						<!-- <b-link class="fs14 lh16 d-none d-sm-block">
							Change bank details
						</b-link> -->
					</div>
					<div class="card__content">
						<div class="row py-13 mb-6 mb-md-12">
							<div class="col-5">
								<span class="lh16 text-gray-600">
									Bank Name
								</span>
							</div>
							<div
							class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between"
							>
							<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
								{{ seller.bankName }}
							</span>
							<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
								{{ seller.bankName }}
							</span>
							<b-button variant="icon-transparent mr-22 px-0">
								<i class="icon-lock-alt text-gray-400 fs12"></i>
							</b-button>
						</div>
					</div>
					<div class="row py-13 mb-6 mb-md-12">
						<div class="col-5">
							<span class="lh16 text-gray-600">
								Account No.
							</span>
						</div>
						<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
							<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
								{{ seller.accountNo }}
							</span>
							<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
								{{ seller.accountNo }}
							</span>
							<b-button variant="icon-transparent mr-22 px-0">
								<i class="icon-lock-alt text-gray-400 fs12"></i>
							</b-button>
						</div>
					</div>
					<div class="row py-13 mb-6 mb-md-12">
						<div class="col-5">
							<span class="lh16 text-gray-600">
								Account Name
							</span>
						</div>
						<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
							<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
								{{ seller.accountName }}
							</span>
							<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
								{{ seller.accountName }}
							</span>
							<b-button variant="icon-transparent mr-22 px-0">
								<i class="icon-lock-alt text-gray-400 fs12"></i>
							</b-button>
						</div>
					</div>
					<div class="row py-13 mb-6 mb-md-12">
						<div class="col-5">
							<span class="lh16 text-gray-600">
								IFSC Code
							</span>
						</div>
						<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
							<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
								{{ seller.ifscCode }}
							</span>
							<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
								{{ seller.ifscCode }}
							</span>
							<b-button variant="icon-transparent mr-22 px-0">
								<i class="icon-lock-alt text-gray-400 fs12"></i>
							</b-button>
						</div>
					</div>
					<div class="row py-13 mb-6 mb-md-12">
						<div class="col-5">
							<span class="lh16 text-gray-600">
								Account Type
							</span>
						</div>
						<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
							<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
								{{ seller.accountType ? seller.accountType : 'N/A' }}
							</span>
							<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
								{{ seller.accountType ? seller.accountType : 'N/A' }}
							</span>
							<b-button variant="icon-transparent mr-22 px-0">
								<i class="icon-lock-alt text-gray-400 fs12"></i>
							</b-button>
						</div>
					</div>
					<div class="row py-13 mb-6 mb-md-12">
						<div class="col-5">
							<span class="lh16 text-gray-600">
								Branch Detail
							</span>
						</div>
						<div class="col-md-7 pt-20 pt-md-0 d-flex justify-content-between">
							<span class="lh16 ml-16 text-gray-600 d-none d-md-block">
								{{ seller.branchDetail ? seller.branchDetail : 'N/A' }}
							</span>
							<span class="fs16 lh20 ml-0 text-gray-900 d-sm-block d-md-none">
								{{ seller.branchDetail ? seller.branchDetail : 'N/A' }}
							</span>
							<b-button variant="icon-transparent mr-22 px-0">
								<i class="icon-lock-alt text-gray-400 fs12"></i>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	
		<div class="col-lg-4">
			<div class="card top100 sticky-top mb-12">
				<div class="card__content">
					<i class="d-block icon-lock fs46 text-gray-600 mb-20"></i>
					<span class="d-block fs18 lh28 fw500 mb-8">
						Change Password?
					</span>
					<span class="d-block fs14 lh20 text-gray-800">
						You’re information is safe and sound with us. You should change
						your password at least once a year.
					</span>
					<b-button @click="openChangePasswordModal('updatepassword')" variant="tertiary" class="mt-20">Login Password</b-button>
					<b-button v-if="adminUser" @click="openChangePasswordModal('profilepassword')" variant="tertiary" class="mt-20 ml-10">Profile Password</b-button>
				</div>
			</div>

			<div class="card top360 sticky-top">
				<div class="card__content">
					<i class="d-block icon-file-logo fs46 text-gray-600 mb-20"></i>
					<span class="d-block fs18 lh28 fw500 mb-8">
						Accepted Agreements
					</span>
					<span class="d-block fs14 lh20 text-gray-800">
						Your agreement with Shyplite can be downloaded here. It’s in the
						PDF format.
					</span>
					<b-button @click="downloadAgreement" variant="tertiary" class="mt-20">
						Download Agreement
					</b-button>
					<b-button href="/user-agreements" variant="tertiary" class="mt-20 ml-10 logs-btn">
						Acceptance Logs
					</b-button>
				</div>
			</div>
		</div>
		<SettingsPasswordModal :passpath="passpath" ref="changePassword" />
	</div>
	</div>
</template>

<script>
import SettingsPasswordModal from "@/components/SettingsPasswordModal.vue";

export default {
	name: "SettingsProfile",
	components: {
		SettingsPasswordModal,
	},
	data() {
		return {
			sellerId: this.$store.getters.user.id,
			seller 		: {},
			submitting 	: false,
			submittingLabels : false,
			mobile		: '',
			otp 		: '',
			otpsent 	: false,
			emailotp 	: null,
			isLabel 	: true,
			emailotpsent: false,
			sendingemail: false,
			sumbitemailotp: false,
			tfnlabel 	: 'Contact Number',
			profile 	: {},
			labels 	    : {
				hideProduct: false,
				showPackingList : false,
				hideRTOaddress: false,
				hideCustomerContact: false,
				showInvoice : false,
				invoicePrefix : '',
				hideWarehouseContact : 0,
				invoicingAddress : 0

			},
			tracklite 	: {
				sellerBrandColors : []
			},
			passpath 	: '',
			fileerror 	: '',
			logo 		: '',
			valid 		: '',
			supportError: false,
		}
	},
	created() {
		this.getProfile();
	},
	computed:{
		adminUser() {
			return this.$store.getters.user.childRole == 'FINANCE' || this.$store.getters.user.childRole == 'OPERATIONS' ? false : true 
		},
		childProfile() {
			return this.$store.getters.user.childRole == 'FINANCE' || this.$store.getters.user.childRole == 'OPERATIONS' || this.$store.getters.user.childRole == 'ADMIN' ? true : false 
		}
	},
	methods: {
		async verifyemailOTP(otp) {
			this.sumbitemailotp = true
			try {
				const res = await this.axios.post('/email/verifyOtp',{evCode : otp})
				if (res.data.response) {
					if (res.data.response.status) {
						this.emailotpsent = false
						this.popToast('booked','Success',res.data.response.message)
						this.getProfile()
					} else {
						this.popToast('failed','Failed',res.data.response.message)
					}
				} else {
					this.popToast('failed','Failed',res.data.message)
				}
				
			} catch(e) {
				this.popToast('failed','Failed',e.message)
				console.log(e);
			}
			this.sumbitemailotp = false
		},
		async sendemailOtp() {
			this.sendingemail = true

			try {
				const res = await this.axios.post('/email/sendOtp')
				if (res.data.response) {
					if (res.data.response.status) {
						this.emailotpsent = true
						this.popToast('booked', 'Success', `An OTP has been sent to ${this.seller.username} `)
					} else {
						this.emailotpsent = false
						this.popToast('failed', 'Failed', res.data.response.message)
					}
				} else {
					this.popToast('failed','Failed',res.data.message)
				}
				
			} catch(e) {
				this.popToast('failed', 'Failed', res.data.response.message)
				console.log(e);
			}

			
			this.sendingemail = false
		},
		async getProfile() {
			const res 					= await this.axios.get('/settings/profile')
			this.seller 				= res.data.seller
			this.logo 					= res.data.logo
			this.mobile 				= this.seller.contact
			this.profile.financeEmail 	= this.seller.financeEmail
			this.profile.operationEmail = this.seller.operationEmail
		},

		async sendOTP(){
			try {
				const res = await this.axios.post('/settings/profile/sendcontactotp', {contact: this.mobile})
				if(res.data.success == true) {
					this.popToast("booked", "OTP Sent!",res.data.message);
					this.otpsent 	= true
					this.tfnlabel 	= 'Enter OTP'
				}else{
					this.popToast("failed", "Failed",res.data.message);
				}
			} catch(e) {
				console.log(e);
			}
		},

		async verifyOTP() {
			try {
				const res = await this.axios.post('/settings/profile/verifyotp', {otp: this.otp})
				if(res.data.success == true) {
					this.otpsent 	= false
					this.isLabel 	= false
					this.mobile 	= ''
					this.tfnlabel 	= 'New Contact Number'
					this.popToast("booked", "Verified!",res.data.message);
				}else{
					this.popToast("failed", "Incorrect OTP!",res.data.message);
				}
			} catch(e) {
				console.log(e);
			}
		},

		async submitNumber() {

			if (this.mobile.length != 10) {
				this.popToast("failed", "Failed!","Contact Number must be 10 digit");
				return false;
			}

			try {
				const res = await this.axios.post('/settings/profile/updatecontact', {contact: this.mobile})
				if(res.data.success == true) {
					this.tfnlabel 	= 'Contact Number'
					this.isLabel 	= true
					this.popToast("booked", "Updated!", res.data.message);
				}else{
					this.popToast("failed", "Failed!",res.data.message);
				}
			} catch(e) {
				console.log(e);
			}
		},

		async submitProfile(){
			try {
				this.submitting = true
				const res = await this.axios.post('/settings/profile/updateprofile?email=true', this.profile)
				if(res.data.success == true) {
					this.popToast("booked", "Success!", res.data.message);
				}else{
					this.popToast("failed", "Failed!",res.data.message);
				}
				this.submitting = false

				
			} catch(e) {
				console.log(e);
			}
		},

		async downloadAgreement() {
			const res = await this.axios.get('/agreement')
			this.download(res.data.path, 'Agreement.pdf')
			this.popToast("requested", "Download requested", "Your download should start now.");
		},
		
		openChangePasswordModal(passpath) {
			this.passpath = passpath
			this.$refs.changePassword.isOpen = !this.$refs.changePassword.isOpen;
		},
	},
};
</script>

<style scoped>
.spinner-border{
	height: 1rem;
	width: 1rem;
}

code.pre {
  white-space: pre;
}

.inputField {
	position: absolute;
    right: 5rem;
    bottom: 12.7rem;
}
.warning1{
      left: 1.5rem;
      width: 1.5rem;
}

</style>