var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pb-40 pb-md-0" },
    [
      _c(
        "ul",
        { staticClass: "connector-wrap row" },
        [
          _vm._l(_vm.activeConnectors, function(connector) {
            return _c(
              "li",
              {
                key: connector.title,
                staticClass: "col-lg-3 col-md-4 col-sm-6"
              },
              [
                _c(
                  "div",
                  { staticClass: "connector cp" },
                  [
                    connector.marketplaceID == 3 ||
                    connector.marketplaceID == 24 ||
                    connector.marketplaceID == 5 ||
                    connector.marketplaceID == 10
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.left",
                                modifiers: { hover: true, left: true }
                              }
                            ],
                            staticClass: "status_img",
                            attrs: {
                              title:
                                "" +
                                (connector.orderSyncStatus == 1
                                  ? "Syncing orders"
                                  : "Sync orders")
                            },
                            on: {
                              click: function($event) {
                                return _vm.sync_orders(
                                  connector.marketplaceID,
                                  connector.id
                                )
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "w-75",
                              attrs: {
                                src: require("@/assets/images/sync.png")
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "connector__img" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.getImageUrl(connector.marketplaceID),
                          alt: ""
                        }
                      })
                    ]),
                    _c("div", { staticClass: "connector__meta" }, [
                      _c("span", { staticClass: "connector__title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("capitalize")(
                                _vm.marketPlace[connector.marketplaceID]
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("span", { staticClass: "connector__subtitle" }, [
                        connector.marketplaceID == 3 ||
                        connector.marketplaceID == 4
                          ? _c(
                              "a",
                              {
                                staticClass: "shopAdd",
                                attrs: {
                                  target: "_blank",
                                  href:
                                    connector.marketplaceID == 3
                                      ? "https://www.amazon.in/s?i=merchant-items&me=" +
                                        (connector.key1 || connector.key4)
                                      : connector.marketplaceID == 4
                                      ? "https://" + connector.key4
                                      : ""
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(connector.key1 || connector.key4) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        connector.marketplaceID == 5 ||
                        connector.marketplaceID == 7
                          ? _c(
                              "a",
                              {
                                staticClass: "shopAdd",
                                attrs: {
                                  target: "_blank",
                                  href: "" + connector.key1
                                }
                              },
                              [_vm._v(" " + _vm._s(connector.key1) + " ")]
                            )
                          : _vm._e(),
                        connector.marketplaceID == 10
                          ? _c(
                              "a",
                              {
                                staticClass: "shopAdd",
                                attrs: {
                                  target: "_blank",
                                  href: "" + connector.key5
                                }
                              },
                              [_vm._v(" " + _vm._s(connector.key5) + " ")]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "w-100 btn-light-green",
                        attrs: { variant: "tertiary" },
                        on: {
                          click: function($event) {
                            return _vm.editConnector(
                              connector.id,
                              connector.marketplaceID
                            )
                          }
                        }
                      },
                      [_vm._v(" Edit ")]
                    )
                  ],
                  1
                )
              ]
            )
          }),
          _c("li", { staticClass: "col-lg-3 col-md-4" }, [
            _c(
              "div",
              {
                staticClass: "connector add-new cp",
                on: { click: _vm.openAddConnectorModal }
              },
              [
                _vm._m(0),
                _vm._m(1),
                _c(
                  "b-button",
                  { staticClass: "w-100", attrs: { variant: "tertiary" } },
                  [_vm._v(" Add a new connector ")]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _c("SettingsConnectorsAddNew", {
        ref: "addConnector",
        attrs: { ids: _vm.activeConnectors },
        on: {
          addConnection: function($event) {
            return _vm.toggleConnector(_vm.index)
          }
        }
      }),
      _c("SettingsConnectorsAmazon", {
        ref: "amazonConnector",
        attrs: { editId: _vm.editId },
        on: {
          "update:editId": function($event) {
            _vm.editId = $event
          },
          "update:edit-id": function($event) {
            _vm.editId = $event
          }
        }
      }),
      _c("SettingsConnectorsShopify", {
        ref: "shopifyConnector",
        attrs: { editSId: _vm.editSId },
        on: {
          "update:editSId": function($event) {
            _vm.editSId = $event
          },
          "update:edit-s-id": function($event) {
            _vm.editSId = $event
          }
        }
      }),
      _c("SettingsConnectorsOpencart", {
        ref: "opencartConnector",
        attrs: { editOId: _vm.editOId },
        on: {
          "update:editOId": function($event) {
            _vm.editOId = $event
          },
          "update:edit-o-id": function($event) {
            _vm.editOId = $event
          }
        }
      }),
      _c("SettingsConnectorsMagento1", {
        ref: "magento1Connector",
        attrs: { editMId: _vm.editMId },
        on: {
          "update:editMId": function($event) {
            _vm.editMId = $event
          },
          "update:edit-m-id": function($event) {
            _vm.editMId = $event
          }
        }
      }),
      _c("SettingsConnectorsGMS", {
        ref: "gmsConnector",
        attrs: { editGId: _vm.editGId },
        on: {
          "update:editGId": function($event) {
            _vm.editGId = $event
          },
          "update:edit-g-id": function($event) {
            _vm.editGId = $event
          }
        }
      }),
      _c("SettingsWooCom", {
        ref: "wooConnector",
        attrs: { editWId: _vm.editWId },
        on: {
          "update:editWId": function($event) {
            _vm.editWId = $event
          },
          "update:edit-w-id": function($event) {
            _vm.editWId = $event
          }
        }
      }),
      _c("SettingsZoho", {
        ref: "zohoConnector",
        attrs: { editZId: _vm.editZId },
        on: {
          "update:editZId": function($event) {
            _vm.editZId = $event
          },
          "update:edit-z-id": function($event) {
            _vm.editZId = $event
          }
        }
      }),
      _c("SettingsConnectorsFlipkart", {
        ref: "flipkartConnector",
        attrs: { editFId: _vm.editFId },
        on: {
          "update:editFId": function($event) {
            _vm.editFId = $event
          },
          "update:edit-f-id": function($event) {
            _vm.editFId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "connector__img" }, [
      _c("img", {
        attrs: { src: require("@/assets/svg/connector.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "connector__meta" }, [
      _c("span", { staticClass: "connector__title" }, [
        _vm._v(" New Connector ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }