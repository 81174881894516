var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "add-sku-modal",
        "modal-class": "add-sku-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Add New Customer ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("div", { staticClass: "mw596 mx-auto" }, [
        _c("div", { staticClass: "pt-md-25 pt-25 pb-md-10 pb-10" }, [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "sku-field",
                              label: "Name",
                              "label-for": "sku"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "sku",
                                type: "text",
                                maxlength: "35",
                                placeholder: "Enter customer name"
                              },
                              model: {
                                value: _vm.form.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-name-field",
                              label: "Email (Optional)",
                              "label-for": "item-name"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "item-name",
                                type: "text",
                                title: "Only valid email id is allowed.",
                                pattern:
                                  "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                                placeholder: "Enter customer email",
                                maxlength: "100"
                              },
                              model: {
                                value: _vm.form.email,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "email", $$v)
                                },
                                expression: "form.email"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-weight-field",
                              label: "Contact Number",
                              "label-for": "item-weight"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "item-weight",
                                type: "text",
                                maxlength: "10",
                                minlength: "10",
                                formatter: _vm.numbers,
                                pattern: "[6-9]{1}[0-9]{9}",
                                placeholder: "8888888888"
                              },
                              model: {
                                value: _vm.form.contact,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "contact", $$v)
                                },
                                expression: "form.contact"
                              }
                            }),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c("div", { staticClass: "input-group-text" }, [
                                _c("i", {
                                  staticClass: "icon-phone fs16 text-gray-600"
                                })
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-city-field",
                              label: "City",
                              "label-for": "item-weight"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "item-city",
                                type: "text",
                                maxlength: "35",
                                placeholder: "Enter city"
                              },
                              model: {
                                value: _vm.form.city,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "city", $$v)
                                },
                                expression: "form.city"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-pincode-field",
                              label: "Pincode",
                              "label-for": "item-pincode"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "item-pincode",
                                type: "text",
                                minlength: "6",
                                formatter: _vm.numbers,
                                maxlength: "6",
                                pattern: "[0-9]{6,}",
                                placeholder: "110001"
                              },
                              model: {
                                value: _vm.form.pincode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "pincode", $$v)
                                },
                                expression: "form.pincode"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-source-field",
                              label: "Source",
                              "label-for": "item-source"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "item-source",
                                type: "text",
                                disabled: ""
                              },
                              model: {
                                value: _vm.source,
                                callback: function($$v) {
                                  _vm.source = $$v
                                },
                                expression: "source"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-address-field",
                              label: "Address",
                              "label-for": "item-address",
                              description:
                                "Characters: " +
                                _vm.form.address.length +
                                " / " +
                                this.maxLength
                            }
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                required: "",
                                id: "item-address",
                                type: "text",
                                placeholder: "Enter customer address",
                                rows: "3",
                                "max-rows": "6",
                                maxlength: "140"
                              },
                              model: {
                                value: _vm.form.address,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "address", $$v)
                                },
                                expression: "form.address"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col py-16" }, [
                      _c("span", [
                        _c("i", {
                          staticClass: "icon-support text-yellow mr-8 vam fs16"
                        }),
                        _vm._v(
                          " Please add the Customer Details carefully. It cannot be changed later. "
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row pt-15" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              disabled: _vm.errorFlag,
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.submitting ? _c("b-spinner") : _vm._e(),
                            _c("span", [_vm._v("Add")])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }