var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "changePassword",
      attrs: {
        id: "password-modal",
        "modal-class": "password-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Change " + _vm._s(_vm.type) + " Password ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "b-form",
        {
          staticClass: "mw596 mx-auto",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validatePassword($event)
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 col-12" }, [
              _c("span", { staticClass: "d-block fs16 lh19 fw500 mb-12" }, [
                _vm._v(" 1. Current Password ")
              ]),
              _c("span", { staticClass: "fs14 lh16 text-gray-600" }, [
                _vm._v(" Type your current password below ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 col-12" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "mt-36 mb-52",
                    attrs: {
                      id: "current-password-field",
                      label: "Current Password",
                      "label-for": "current-password"
                    }
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "current-password",
                            placeholder: "********",
                            type: "password",
                            autofocus: "",
                            required: ""
                          },
                          model: {
                            value: _vm.password.password,
                            callback: function($$v) {
                              _vm.$set(_vm.password, "password", $$v)
                            },
                            expression: "password.password"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "d-block fs16 lh19 fw500 mb-12" }, [
                _vm._v(" 2. New Password ")
              ]),
              _c("span", { staticClass: "fs14 lh16 text-gray-600" }, [
                _vm._v(" Type your new password below and confirm ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 col-12" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "mt-36 mb-48",
                    attrs: {
                      id: "new-password-field",
                      label: "New Password",
                      "label-for": "new-password"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "new-password",
                        type: "password",
                        placeholder: "********",
                        minlength: "8",
                        state: _vm.valid,
                        required: ""
                      },
                      on: { keyup: _vm.validate },
                      model: {
                        value: _vm.password.newPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.password, "newPassword", $$v)
                        },
                        expression: "password.newPassword"
                      }
                    }),
                    !_vm.valid && _vm.password.newPassword.length > 2
                      ? _c("span", { staticClass: "text-red small" }, [
                          _vm._v("Minimum 8 characters required")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-12" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "mt-36 mb-48",
                    attrs: {
                      id: "confirm-password-field",
                      label: "Confirm new Password",
                      "label-for": "confirm-password"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "confirm-password",
                        type: "password",
                        placeholder: "********",
                        minlength: "8",
                        state: _vm.match,
                        required: ""
                      },
                      on: { keyup: _vm.matching },
                      model: {
                        value: _vm.password.verifyPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.password, "verifyPassword", $$v)
                        },
                        expression: "password.verifyPassword"
                      }
                    }),
                    !_vm.match && _vm.password.verifyPassword.length > 2
                      ? _c("span", { staticClass: "text-red small" }, [
                          _vm._v("New and confirm password doesn't match")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col text-right text-md-left" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: !_vm.match,
                      variant: "primary",
                      type: "submit",
                      size: "lg"
                    }
                  },
                  [_vm._v(" Update ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }