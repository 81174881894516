			<template>
				<b-modal
				id="opencart-connector-modal"
				modal-class="opencart-connector-modal"
				size="lg"
				hide-footer
				v-model="isOpen"
				ref="opencartConnector"
				>
				<template v-slot:modal-header="{ close }">
					<span class="d-block mt-auto fs18 lh28 fw500">
						Opencart Credentials
					</span>
					<b-button variant="rounded" @click="close()">
						<i class="icon-close-variant"></i>
					</b-button>
				</template>
				<div class="mw596 mx-auto">
					<div class="row">
						<div
						class="col pt-30 pb-16 d-flex justify-content-between align-items-center"
						>
						<span class="d-block  text-gray-600 pt-12">
							<strong>1. </strong> Go to <strong>System >> Users >> API</strong> (in API list, API
							Name 'Default' click on action).
						</span>
					</div>
				</div>

				<div class="row">
					<div class="col py-16">
						<span class="d-block  text-gray-600"> <strong>2. </strong> Here is your API Key. </span>
					</div>
				</div>

				<div class="row">
					<div class="col py-16">
						<span class="d-block  text-gray-600">
							<strong>3. </strong> To Enable it for Shyplite, you have to install our Shyplite
							plugin into your opencart admin.
						</span>
					</div>
				</div>

				<div class="row">
					<div class="col py-16">
						<span class="d-block  text-gray-600">
							<strong>4. </strong> You can download
							<b-link
							target="_blank" href="https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-1.x.x.ocmod.zip"
							class="fs16"
							>
							OpenCart_1.x
						</b-link>
						/
						<b-link
						target="_blank" href="https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-2.0.x.ocmod.zip"
						class="fs16"
						>
						OpenCart_2.0 - 2.1
					</b-link>
					/
					<b-link
					target="_blank" href="https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-2.2.x.ocmod.zip"
					class="fs16"
					>
					OpenCart_2.2
				</b-link>
				/
				<b-link
				target="_blank" href="https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-2.3.x.ocmod.zip"
				class="fs16"
				>
				OpenCart_2.3.x
			</b-link>
			/
			<b-link
			target="_blank" href="https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/plugins/shyplite_opencart-3.x.x.ocmod.zip"
			class="fs16"
			>
			OpenCart_3.x
		</b-link>
		  zip. Opencart-1.x requires manual installation.
	</span>
</div>
</div>

<div class="row">
	<div class="col py-16">
		<span class="d-block  text-gray-600">
			<strong>5. </strong> After login into admin panel. Go to
			<strong>Extensions >> Extention Installer</strong>
		</span>
	</div>
</div>

<div class="row">
	<div class="col py-16">
		<span class="d-block  text-gray-600">
			<strong>6. </strong> Click on upload, and browse the Plugin, after succeful install,
			goto <strong>Extensions >> Feeds</strong>
		</span>
	</div>
</div>

<div class="row">
	<div class="col py-16">
		<span class="d-block  text-gray-600">
			<strong>7. </strong> In (Feed List) install <strong>"Shyplite Orders API"</strong> and
			<strong>"Enable"</strong> it by clicking edit icon.
		</span>
	</div>
</div>

<b-form @submit.prevent="opencartConnect()">


	<div class="row">
      <div class="col-md-6">
        <b-form-group
        id="seller-id-field"
        label="Store Name"
        label-for="seller-id"
        >
        <b-form-input
        id="seller-id"
        v-model="form.storeName"
        placeholder="Store Name"
        ></b-form-input>
        </b-form-group>
      </div>
    </div>

	<div class="row">
		<div class="col-md-6 pt-8">
			<b-form-group id="mode-field" label="OpenCart Version:" label-for="weight">
				<multiselect
				id="mode"
				:options="options"
				placeholder="Select Version"
				:searchable="false"
				v-model="form.oCartVersion"
				:close-on-select="true"
				track-by="name"
				label="name"
				>
			</multiselect>
		</b-form-group>
	</div>
	<div class="col-md-6 pt-8">
		<b-form-group
		id="mws-token-field"
		label="Application Url:"
		label-for="application-url"
		>
		<b-form-input
		required
		id="application-url"
		v-model="form.appurl"
		placeholder="Application Url"
		></b-form-input>
	</b-form-group>
</div>
</div>

<div class="row">
	<div class="col-md-6 pt-8">
		<b-form-group
		id="mws-token-field"
		label="API Key:"
		label-for="api-key"
		>
		<b-form-input
		required
		id="api-key"
		v-model="form.apikey"
		placeholder="OpenCart API Key"
		></b-form-input>
	</b-form-group>
</div>
<div class="col-md-6 py-16">
	<b-form-group label="Get Orders">
		<b-form-radio name="auto-fullfilment" value="1" v-model="form.isactive" class="d-inline-block mr-20">
			On
		</b-form-radio>
		<b-form-radio name="auto-fullfilment" value="0" v-model="form.isactive" class="d-inline-block">
			Off
		</b-form-radio>
	</b-form-group>
</div>
</div>

<div class="row">
	<div class="col pt-12 pb-8 d-none d-md-block">
		
		<b-button v-if="sellerDisable" type="submit" variant="primary mr-16" size="lg">
			<b-spinner v-if="updating"></b-spinner>
				Update
			</b-button>
		<b-button v-else type="submit" variant="primary mr-16" size="lg">
			<b-spinner v-if="updating"></b-spinner>
				Create
			</b-button>
		<b-button @click="hideModal" size="lg">Cancel</b-button>
	</div>
	<div class="errorText mt-20" v-if="errorFlag ">
		<span>
			Please Select an Opencart Version
		</span>
	</div>
	<div class="col pt-12 pb-8 d-flex d-md-none">
		
		<b-button @click="hideModal" size="lg">Cancel</b-button>
		<b-button v-if="sellerDisable"  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			<b-spinner v-if="updating"></b-spinner>
				Update
		</b-button>
		<b-button v-else type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			<b-spinner v-if="updating"></b-spinner>
				Create
		</b-button>	
	</div>
</div>
</b-form>
</div>
</b-modal>
</template>

<script>

import Multiselect from "vue-multiselect";
export default {
	name: "SettingsOpencartConnector",
	props:{
		editOId : Number
	},
	data() {
		return {
			updating:false,
			isOpen: this.modalOpen,
			value:"",
			options:[
			{name:'1.0.X', value:'1.1'},
			{name:'2.0.X', value:'2.0'},
			{name:'2.2', value:'2.2'},
			{name:'2.3.X', value:'2.3'},
			{name:'3.X.X', value:'3.0'},
			],
			form:{},
			sellerDisable:false,
			errorFlag:false
		};
	},
	components: {
		Multiselect,
	},
	methods: {
		hideModal() {
			this.$bvModal.hide("opencart-connector-modal");
		},
		async opencartConnect(){
			this.updating = true
			if(!this.form.oCartVersion) {
				this.errorFlag = true
			} else {
					let data = Object.assign({}, this.form)
					data.oCartVersion = data.oCartVersion.value
					if (this.form.id) {
						
						const editres = await this.axios.post('/settings/connectors/opencart', data)
						try {
							if(editres.data.success){
								this.popToast("booked", "Success!", editres.data.message);
								this.updating = false
								setTimeout(() => {
									this.$router.go()
								},1000)
							} else {
								this.updating = false
								this.popToast("failed", "Failed!", editres.data.error)
							}

						} catch(e) {
							this.updating = false
							this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
							console.log(e);
						}
					} else {
						const res = await this.axios.put('/settings/connectors/opencart', data)
						try {
							if (res.data.success) {
								this.popToast("booked", "Success!", res.data.message);
								this.updating = false
								setTimeout(() => {
									this.$router.go()
								},1000)
							} else {
								this.updating = false
								this.popToast("failed", "Failed!", res.data.error)
							}
						} catch(e) {
							this.updating = false
							this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
							console.log(e);
					}
				}
			}
		

		},
		async formatting(id) {
			try {
				const res = await this.axios.get(`/settings/connector/${id}`)
					if (res.data.success) {
						const editConn = {
						id:res.data.result[0].id,
						appurl:res.data.result[0].key1,
						apikey:res.data.result[0].key2,
						isactive:res.data.result[0].isactive,
						oCartVersion:res.data.result[0].key3,
						storeName:res.data.result[0].storeName
					}
					let vers = this.options.find(item => item.value == editConn.oCartVersion);
					if (vers && vers.name) {
						editConn.oCartVersion = {
							name: vers.name,
							value: vers.value
						};
						this.form = editConn;
					}
				} else {
					this.popToast("failed", "Failed!", "Data Fetching Failed Please try again later!!!");
				}
				
			} catch(e) {
					// statements
					this.popToast("failed", "Failed!", "Data Fetching Failed Please try again later!!!");
				}
			}
		},
		watch:{
			isOpen(newValue) {
				if(newValue) {
					let opencartFlag = false

					this.$root.$on('opencart', data => {
						opencartFlag = data
					});

					if (this.editOId) {
						this.formatting(this.editOId)
						this.sellerDisable = true
						this.errorFlag = false
					} else if (opencartFlag) {
						this.form = {
							isactive:"1"
						}
						this.sellerDisable = false
						this.errorFlag = false
					} else {
						this.form = {
							isactive:"1"
						}
						this.sellerDisable = false
						this.errorFlag = false
					}
			} else {
				this.$emit('update:editOId', undefined)
			}
		}
	}
};
</script>

<style scoped>
.errorText{
	height: 2.5rem;
    width: 16rem;
  background-color: #ffa6a6;
  color: red;
  border: 1px #ffa6a6;
  border-radius: 5px;
}

.errorText span {
  margin: 0px;
  position: relative;
  top: 30%;
  left: 8%;		
  transform: translate(0,-50%);
  text-align: center;
}
</style>
