<template>
  <b-modal
    id="add-sku-modal"
    modal-class="add-sku-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
  >
    <template v-slot:modal-header="{ close }">

      <span  class="d-block mt-auto fs18 lh28 fw500"> {{editId ? 'Edit Consignor' : 'Add New Consignor'}} </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <div class="mw596 mx-auto">
      <div class="pt-md-25 pt-25 pb-md-10 pb-10">
        <div class="form">
          <b-form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group id="sku-field" label="Name" label-for="sku">
                  <b-form-input
                    required
                    id="sku"
                    type="text"
                    maxlength="35"
                    v-model="form.name"
                    pattern="[a-zA-Z0-9\s]+" oninvalid="this.setCustomValidity('Please enter a valid Consignor name')"
                    oninput="this.setCustomValidity('')"
                    placeholder="Enter consignor name"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-weight-field"
                  label="Contact Number"
                  label-for="item-weight"
                >
                  <b-form-input
                    required
                    id="item-weight"
                    type="text"
                    maxlength="10"
                    minlength="10"
                    :formatter="numbers"
                    pattern="[6-9]{1}[0-9]{9}"
                    v-model="form.phone"
                    placeholder="8888888888"
                  ></b-form-input>
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <i class="icon-phone fs16 text-gray-600"></i>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>

            
            	
        	<div class="py-12 fs16"><strong>Documents Required</strong></div>            


	            	<div class="row">

	            		<div class="col-md-6  col-9">
				            <b-form-group v-slot="{ ariaDescribedby }">
      								<b-form-radio-group
      									id="radio-group-2"
      									v-model="form.csbType"
      									:aria-describedby="ariaDescribedby"
      									name="radio-sub-component"
      									required
                        :disabled="editId"
      								>
      									<b-form-radio value="CSB IV" >CSB IV</b-form-radio>
      									<b-form-radio value="CSB V">CSB V</b-form-radio>
      								</b-form-radio-group>
      							</b-form-group>
      						</div>

						<div class="col-md-6  col-9"></div>

						<transition name="slide-left">
							<div class="col d-flex" v-if="form.csbType == 'CSB IV' ">
								<div class="col-md-6  col-9">
						            <b-form-group id="panCardId" label="ID Proof" label-for="panCard" class="wsnw">
						              	<b-form-input :disabled="editId" v-model="form.idProof" maxlength="30" pattern="^[a-zA-Z0-9]*$" required  id="panCard" type="text" placeholder="AADHAR NO/ Licence No. / PAN No." ></b-form-input>
						            </b-form-group>
								</div>

								<div class="col-md-6 col-9 d-flex align-items-center mt-10">
									<b-form-file ref="idfile" accept=".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx" @input="validate(form.idProofFile,'idproof')" v-model="form.idProofFile" class="d-none" plain>
									</b-form-file>

									<b-button :disabled="editId" @click="$refs.idfile.$el.click();" class="w-100" variant="tertiary">
										{{form.idProofFile ? form.idProofFile.name : 'Choose File'}}
									</b-button>

                  <b-link v-if="editId && idFile" :href="idFile" target="_blank">
                    <b-button variant="primary" class="ml-5 d-flex align-items-center" v-b-tooltip.hover.top 
                          title="Click to View">
                    <svg class="editsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
                  </b-button>
                  </b-link>
								</div>
							</div>
							
						</transition>

	            		<transition name="slide-left">
	            			<div class="col-md-12 row" v-if="form.csbType == 'CSB V' ">
	            				<div class="col-md-6  col-9">
						            <b-form-group id="panCardId" label="GST" label-for="gst" class="wsnw">
						              	<b-form-input :disabled="editId" v-model="form.gst" maxlength="15" minlength="15" required pattern="^[a-zA-Z0-9]*$" oninvalid="this.setCustomValidity('Enter a Valid GST Number')"
                            oninput="this.setCustomValidity('')" id="gst" type="text" placeholder="Enter GST No." ></b-form-input>
						            </b-form-group>
								      </div>

								<div class="col-md-6 col-9 d-flex align-items-center mt-10">
									<b-form-file ref="gstfile" accept=".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx" @input="validate(form.gstFile,'gst')" v-model="form.gstFile" class="d-none" plain>
									</b-form-file>

									<b-button :disabled="editId" @click="$refs.gstfile.$el.click();" class="w-100" variant="tertiary">
										{{form.gstFile ? form.gstFile.name : 'Choose File'}}
									</b-button>

                  <b-link v-if="editId && gstFile" :href="gstFile" target="_blank">
                    <b-button variant="primary" class="ml-5 d-flex align-items-center" v-b-tooltip.hover.top 
                          title="Click to View">
                    <svg class="editsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
                  </b-button>
                  </b-link>
								</div>


								<div class="col-md-6  col-9">
				            <b-form-group id="panCardId" label="AD Code" label-for="adcode" class="wsnw">
			              	<b-form-input :disabled="editId" v-model="form.adcode" oninvalid="this.setCustomValidity('Enter a Valid AD Code')"
                        oninput="this.setCustomValidity('')" maxlength="14" pattern="^[0-9]*$" minlength="14"  id="adcode" type="text" placeholder="Enter AD Code" required></b-form-input>
				            </b-form-group>
								</div>

								<div class="col-md-6 col-9 d-flex align-items-center mt-10">
									<b-form-file ref="adcodefile" accept=".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx" @input="validate(form.adcodeFile,'adcode')" v-model="form.adcodeFile" class="d-none" plain>
									</b-form-file>

									<b-button :disabled="editId" @click="$refs.adcodefile.$el.click();" class="w-100" variant="tertiary">
										{{form.adcodeFile ? form.adcodeFile.name : 'Choose File'}}
									</b-button>

                  <b-link v-if="editId && adFile" :href="adFile" target="_blank">
                    <b-button variant="primary" class="ml-5 d-flex align-items-center" v-b-tooltip.hover.top 
                          title="Click to View">
                    <svg class="editsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
                  </b-button>
                  </b-link>
								</div>

								<div class="col-md-6  col-9">
						            <b-form-group id="panCardId" label="IEC No" label-for="iec" class="wsnw">
						              	<b-form-input :disabled="editId" oninvalid="this.setCustomValidity('Enter a Valid IEC Number')"
                            oninput="this.setCustomValidity('')" maxlength="10" pattern="^[a-zA-Z0-9]*$" minlength="10" v-model="form.iec"  id="iec" type="text" placeholder="Enter your IEC number" required></b-form-input>
						            </b-form-group>
								</div>

								<div class="col-md-6 col-9 d-flex align-items-center mt-10">
									<b-form-file ref="iecFile" accept=".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx" @input="validate(form.iecFile,'iec')" v-model="form.iecFile" class="d-none" plain>
									</b-form-file>

									<b-button :disabled="editId" @click="$refs.iecFile.$el.click();" class="w-100" variant="tertiary">
										{{form.iecFile ? form.iecFile.name : 'Choose File'}}
									</b-button>

                   <b-link v-if="editId && iecFile" :href="iecFile" target="_blank">
                    <b-button variant="primary" class="ml-5 d-flex align-items-center" v-b-tooltip.hover.top 
                          title="Click to View">
                    <svg class="editsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
                  </b-button>
                  </b-link>
								</div>

                <div class="col-md-6 col-9 d-flex align-items-center mt-10">
                  <b-form-file ref="panfile" accept=".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx" @input="validate(form.panFile,'pan')" v-model="form.panFile" class="d-none" plain>
                    </b-form-file>
                  <b-form-group style="width:100% !important" id="panCardId" label="PAN" label-for="panfile" class="">
                    <b-button :disabled="editId" id="panfile" @click="$refs.panfile.$el.click();" class="w-100" variant="tertiary">
                      {{form.panFile ? form.panFile.name : 'Choose File'}}
                    </b-button>
                  </b-form-group>

                   <b-link v-if="editId && panFile" :href="panFile" target="_blank">
                    <b-button variant="primary" class="ml-5 mt-10 d-flex align-items-center" v-b-tooltip.hover.top 
                          title="Click to View">
                    <svg class="editsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
                  </b-button>
                  </b-link>
                </div>

								<div class="col-md-6 col-9 d-flex align-items-center mt-10">
									
									<b-form-group style="width:100% !important" id="panCardId" label="Letter of Undertaking (Optional)" label-for="lutfile" class="">
                    <b-form-file ref="lutfile" accept=".jpg, .png, .jpeg, .pdf, .pdf, .doc, .docx" @input="validate(form.lutFile,'lou')" v-model="form.lutFile" class="d-none" plain>
                    </b-form-file>
										<b-button :disabled="editId" id="lutfile" @click="$refs.lutfile.$el.click();" class="w-100" variant="tertiary">
											{{form.lutFile ? form.lutFile.name : 'Choose File'}}
										</b-button>
									</b-form-group>

									 <b-link v-if="editId && lutFile" :href="lutFile" target="_blank">
                    <b-button variant="primary" class="ml-5 mt-10 d-flex align-items-center" v-b-tooltip.hover.top 
                          title="Click to View">
                    <svg class="editsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
                  </b-button>
                  </b-link>
								</div>

								<div class="col-md-6 col-9">
									
								</div>
	            			</div>
	            			
	            		</transition>
						
	            	</div>

            <div v-if="form.csbType" class="col row fs16 pt-15" style="font-weight:500 !important">
              <strong>NOTE &nbsp;</strong>:&nbsp;Maximum file size allowed is 5 Mb
            </div>

            <div class="row pt-15">
              <div class="col-12">
                <b-button type="submit" :disabled="(!this.editId && !this.form.csbType) || (!this.editId && this.form.csbType == 'CSB IV' && this.form.idProofFile == null) || (!this.editId && this.form.csbType == 'CSB V' && (this.form.gstFile == null || this.form.adcodeFile == null || this.form.iecFile == null  || this.form.panFile == null))" variant="primary" size="lg">
                  <b-spinner v-if="submitting"></b-spinner>
                  <span >
	                  {{editId ? 'Update' :'Add'}}
	              </span>
                </b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>

    
    </div>

  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";

export default {
  name: "AddNewConsignor",
  props:['editId','editUserflag'],
  data() {
    return {
      maxLength:140,
      submitting:false,
      idFile:null,
      gstFile:null,
      adFile:null,
      iecFile:null,
      lutFile:null,
      source:'Manually Created',
      form: {
      },
      generatPassword:false,
      files: null,
      active: false,
      browseText: "Browse",
      isOpen: this.modalOpen,
      itemAdded: false,
      isbulkactive: false,
      uploading: false,
      fileUpload: false,
      bookings:["Domestic","International"],
      options: ['ADMIN','OPERATIONS','FINANCE'],
      error:"",
      errorFlag:false,
      errormode:false,
      };
  },
  components: {
    Multiselect,
    draggable,
  },
  beforeCreate() {
    this.form = {};
  },
  methods: {
  	capitalizeFirstLetter(str) {

        const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

        return capitalized;
    },
  	validate(file,type) {

			if (file && (!(file.name.split('.').pop().toLowerCase().includes('png') || file.name.split('.').pop().toLowerCase().includes('jpeg') || file.name.split('.').pop().toLowerCase().includes('jpg') || file.name.split('.').pop().toLowerCase().includes('pdf') || file.name.split('.').pop().toLowerCase().includes('doc') || file.name.split('.').pop().toLowerCase().includes('pdf') || file.name.split('.').pop().toLowerCase().includes('docx')))) {
				this.popToast('failed','Failed','Unsupported File Format. Please try uploading again')
				if (type == 'idproof') {
					this.form.idProofFile = null
				} else if (type == 'gst') {
					this.form.gstFile = null
				} else if (type == 'iec') {
					this.form.iecFile = null
				} else if (type == 'adcode') {
          this.form.adcodeFile = null
        } else if (type == 'lou') {
          this.form.lutFile = null
        } else if (type == 'pan') {
          this.form.panFile = null
        } else {}

				return
			}

      if(file && file.size > 5001*1024) {
          this.popToast('failed','Failed',`File size is ${Math.round((file.size/1024)/1000)}MB, It should be less than or equal to 5MB.`)
          if (type == 'idproof') {
            this.form.idProofFile = null
          } else if (type == 'gst') {
            this.form.gstFile = null
          } else if (type == 'iec') {
            this.form.iecFile = null
          } else if (type == 'adcode') {
            this.form.adcodeFile = null
          } else if (type == 'lou') {
            this.form.lutFile = null
          } else if (type == 'pan') {
            this.form.panFile = null
          } else {}

          return
        }


        // Matching GST File
        if (this.form.gstFile && this.form.adcodeFile && this.form.gstFile.name == this.form.adcodeFile.name && type == "gst") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.gstFile = null
          return false
        }

        if (this.form.gstFile && this.form.iecFile && this.form.gstFile.name == this.form.iecFile.name && type == "gst") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.gstFile = null
          return false
        }

        if (this.form.gstFile && this.form.lutFile && this.form.gstFile.name == this.form.lutFile.name && type == "gst") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.gstFile = null
          return false
        }

        if (this.form.gstFile && this.form.panFile && this.form.gstFile.name == this.form.panFile.name && type == "gst") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.gstFile = null
          return false
        }

        // Matching AdCode File
        if (this.form.gstFile && this.form.adcodeFile && this.form.gstFile.name == this.form.adcodeFile.name && type == "adcode") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.adcodeFile = null
          return false
        }

        if (this.form.adcodeFile && this.form.iecFile && this.form.adcodeFile.name == this.form.iecFile.name && type == "adcode") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.adcodeFile = null
          return false
        }

        if (this.form.adcodeFile && this.form.lutFile && this.form.adcodeFile.name == this.form.lutFile.name && type == "adcode") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.adcodeFile = null
          return false
        }

        if (this.form.adcodeFile && this.form.panFile && this.form.adcodeFile.name == this.form.panFile.name && type == "adcode") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.adcodeFile = null
          return false
        }

        // Matching IEC File
        if (this.form.gstFile && this.form.iecFile && this.form.gstFile.name == this.form.iecFile.name && type == "iec") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.iecFile = null
          return false
        }

        if (this.form.adcodeFile && this.form.iecFile && this.form.adcodeFile.name == this.form.iecFile.name && type == "iec") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.iecFile = null
          return false
        }

        if (this.form.iecFile && this.form.lutFile && this.form.iecFile.name == this.form.lutFile.name && type == "iec") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.iecFile = null
          return false
        }

        if (this.form.iecFile && this.form.panFile && this.form.iecFile.name == this.form.panFile.name && type == "iec") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.iecFile = null
          return false
        }

        // Matching LUT File
        if (this.form.gstFile && this.form.lutFile && this.form.gstFile.name == this.form.lutFile.name && type == "lou") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.lutFile = null
          return false
        }

        if (this.form.adcodeFile && this.form.lutFile && this.form.adcodeFile.name == this.form.lutFile.name && type == "lou") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.lutFile = null
          return false
        }

        if (this.form.iecFile && this.form.lutFile && this.form.iecFile.name == this.form.lutFile.name && type == "lou") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.lutFile = null
          return false
        }

        if (this.form.lutFile && this.form.panFile && this.form.lutFile.name == this.form.panFile.name && type == "lou") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.lutFile = null
          return false
        }

        // Matching LUT File
        if (this.form.gstFile && this.form.panFile && this.form.gstFile.name == this.form.panFile.name && type == "pan") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.panFile = null
          return false
        }

        if (this.form.adcodeFile && this.form.panFile && this.form.adcodeFile.name == this.form.panFile.name && type == "pan") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.panFile = null
          return false
        }

        if (this.form.iecFile && this.form.panFile && this.form.iecFile.name == this.form.panFile.name && type == "pan") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.panFile = null
          return false
        }

        if (this.form.lutFile && this.form.panFile && this.form.lutFile.name == this.form.panFile.name && type == "pan") {
          this.popToast('failed','Failed',`File Already Added!! Please select a different file`)
          this.form.panFile = null
          return false
        }

  	},
    async onSubmit() {
      const data = Object.assign({}, this.form)

      if(this.editId) {
      	data['id'] = this.editId
      }

      let fd = new FormData();

		for (const key of Object.keys(data)) {
			fd.append(key, data[key]);
		}

      this.submitting = true

      if (this.editId) {
      	try {
	      const res = await this.axios.post('/edit/consignor',fd)
	      if (res.data.status) {
	        this.popToast("booked", "Success", res.data.message);
	        this.isOpen = false;
	        this.$parent.getItems()
	      } else {
	        this.popToast("failed", "Failed!!", res.data.message);
	      }
	    } catch(e) {
	      this.popToast("failed", "Failed!!", "Something went wrong!! Please Try Later");
	      console.log(e);
    	}
      } else {
      	try {
	      const res = await this.axios.post('/add/consignor',fd)
	      if (res.data.status) {
	        this.popToast("booked", "Success", res.data.message);
	        this.isOpen = false;
	        this.$parent.getItems()
	      } else {
	        this.popToast("failed", "Failed!!", res.data.message);
	      }
	    } catch(e) {
	      this.popToast("failed", "Failed!!", "Something went wrong!! Please Try Later");
	      console.log(e);
    	}
      }

      	      

      this.submitting = false
    },
    hideModal() {
      this.form = {};
      this.$bvModal.hide("add-user-modal");
      this.$emit('update:editId', null)
      this.$emit('update:editUserflag', false)
    },
    async formatting(id) {
      try {
      	const res = await this.axios.get(`/consignor/getDataByID/${id}`)
        const editabledata = {
        	name:res.data.name,
        	phone:res.data.phone,
        	idProof:res.data.idProof,
        	gst:res.data.gst,
        	adcode:res.data.adcode,
        	iec:res.data.iec,
        	csbType:res.data.csbType == 'csbIV' ? 'CSB IV' : 'CSB V',
        }

        this.idFile = res.data.idProofFile && res.data.idProofFile.includes('https://') ? res.data.idProofFile : null
        this.gstFile = res.data.gstFile &&  res.data.gstFile.includes('https://') ? res.data.gstFile : null
        this.adFile = res.data.adcodeFile && res.data.adcodeFile.includes('https://') ? res.data.adcodeFile : null
        this.iecFile = res.data.iecFile &&  res.data.iecFile.includes('https://') ? res.data.iecFile : null
        this.lutFile = res.data.lutFile &&  res.data.lutFile.includes('https://') ? res.data.lutFile : null
        this.panFile = res.data.panFile &&  res.data.panFile.includes('https://') ? res.data.panFile : null

        this.form = editabledata
      } catch(e) {
        // statements
        console.log(e)
        this.popToast('failed','Failed',"Date Fetching Failed")
      }
    }
  },
  watch: {
    isOpen: function (newValue) {
        if (newValue == false) {
          this.hideModal();
        } else {
          if (this.editId) {
            this.formatting(this.editId)
          } 
          
        }
    }
  },
};
</script>

<style scoped>
.input-group-text{
  height: 2.75rem !important;
  top: 26px !important;
  right: 7px !important;
}
.file_upload {
  display: none;
}
.file_upload_active {
  display: block;
}
.manual_upload_inactive {
  display: none;
}

.editsvg {
    height: 0.85rem;
    width:1rem;
  } 

.editsvg path {
	  fill:white !important;
	}  

.error{
  background: #ffa6a6;
  height: 2.1rem;
  color: red;
  border: 1px #ffa6a6;
  border-radius: 5px;
}
.addUser {
  border-bottom: 2px solid #c8ced6;
}

.add-user-modal .modal-header{
  padding: 1rem 1.5rem 1rem 2rem !important;
  border-bottom: 2px solid #f5f7fa !important;
}

/deep/ .form-control:disabled, .form-control[readonly] {
    color: #7a828f !important;
  }

@media only screen and (max-device-width: 400px) {
    .error {
      height: 3.1rem !important;
    }
  }
</style>
