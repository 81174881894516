import { render, staticRenderFns } from "./Masters.vue?vue&type=template&id=78661fa9&scoped=true&"
import script from "./Masters.vue?vue&type=script&lang=js&"
export * from "./Masters.vue?vue&type=script&lang=js&"
import style0 from "./Masters.vue?vue&type=style&index=0&id=78661fa9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78661fa9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/slv/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78661fa9')) {
      api.createRecord('78661fa9', component.options)
    } else {
      api.reload('78661fa9', component.options)
    }
    module.hot.accept("./Masters.vue?vue&type=template&id=78661fa9&scoped=true&", function () {
      api.rerender('78661fa9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Masters/Masters.vue"
export default component.exports