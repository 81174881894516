var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Settings",
          buttonText: "New Order",
          buttonLink: "new",
          buttonIconClass: "icon-add"
        }
      }),
      _c("div", { staticClass: "container-fluid pt-60 pt-md-94 pb-14" }, [
        _c(
          "ul",
          { staticClass: "filter-list settings" },
          _vm._l(_vm.computedtabs, function(tab) {
            return _c(
              "li",
              { key: tab.id, class: [{ active: _vm.selected.id === tab.id }] },
              [
                _c(
                  "b-link",
                  {
                    staticClass: "btn-filter wsnw p-7",
                    class: tab.title,
                    on: {
                      click: function($event) {
                        _vm.selected = tab
                        _vm.$router.replace("#" + tab.title)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(tab.title) + " ")]
                )
              ],
              1
            )
          }),
          0
        )
      ]),
      _c(_vm.selected.component, { tag: "component" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }