<template>
	<div class="container-fluid pt-10 pt-md-0">
		<div class="row">
			 <transition name="slide-left" v-if="!filterResultsToggle">
				<div class="col pb-22 d-flex align-items-right justify-content-between">
					<span class="fs14 lh16 fw700 d-flex align-items-center"> Warehouses ({{ totalRows }}) 
					</span>

					<div>
						<b-button @click="openAddWarehouse" variant="primary">
							<i class="icon-add fs16 mr-10"></i>
							Add Warehouse
						</b-button>
						<b-button  v-if="this.windowWidth > 767" @click="$router.push('/settings/serviceability')" class="ml-10" variant="primary">
							Check
						</b-button>
						<div class="divider mx-20 vam"></div>
					</div>

				</div>
			</transition>

			<transition name="slide-right">
				<div class="col pb-22 d-flex align-items-center justify-content-between" v-if="filterResultsToggle">
					<div class="d-flex align-items-center">

							<div class="d-flex align-items-center">
								<span
									class="d-none d-md-flex mr-6 text-gray-600 wsnw"
									v-if="this.selFilters.length != '0'"
								>
								Results for:
								</span>
								<ul class="d-none d-md-flex result-list">
									<li v-for="(selFilter, index) in selFilters" :key="index">
										<b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering' && selFilter">
											<span v-if="selFilter.hasOwnProperty('name')" class="wsnw">
												{{ selFilters.warehouseID.name }}
											</span>
											<i class="icon-close fs6" @click="clearFilters()"></i>
										</b-button>
									</li>
								</ul>

								<span
									class="d-flex d-md-none"
									v-if="this.selFilters.length != '0'"
									>
									{{ this.selFilters.length }} Filters selected.
								</span>
								<span class="d-flex d-md-none" v-else>
									No filters selected.
								</span>
								<b-link @click="clearFilters()" class="ml-24 mr-12 vam">
									<span class="text-gray-900 wsnw">Clear filters</span>
									<i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
								</b-link>
							</div>
						
					</div>
				</div>
			</transition>

			<div class="d-flex pb-24 filterBar">
		        <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
		          	<span>
		            	Filters
		          	</span>
		          	<i class="icon-filter text-gray-600 pl-10"></i>
		        </b-button>
			</div>

		</div>

		<div class="row">
			<div class="col">
				<b-table :items="items" :fields="fields" :per-page="perPage" :busy="isBusy" no-border-collapse sticky-header class="warehouse-table billing-table" v-if="this.windowWidth > 767">
					
					<!-- Table Busy loader -->
					<template #table-busy>
						<div class="text-center text-danger my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Loading...</strong>
						</div>
					</template>

					<!-- A custom formatted data column cell for field 'address' -->
					<template v-slot:cell(addressName)="data">
						<div class="d-flex align-items-center">
							<i v-if="data.item.lat>0" class="icon-pin-check text-blue fs16 mr-8"></i>
							<div class>
								<span class="d-block lh20" :title="data.item.addressName.name">
									{{ data.item.addressName.name }}
								</span>
								<span v-if="data.item.defaultPickup==1 || data.item.defaultBilling==1" class="d-block fs12 lh14 text-gray-600"> Default - {{data.item.defaultPickup==1?'Pickup':''}}{{data.item.defaultBilling==1?' Billing':''}} </span>
							</div>
						</div>
					</template>

					<!-- A custom formatted data column cell for field 'city' -->
					<template v-slot:cell(address)="data">
						<div class="text-truncate w250px" :title="data.item.address.number+' '+data.item.address.street+'\n'+data.item.address.region">
							<span class="fs14 lh20"
							>{{ data.item.address.number+' '+data.item.address.street+' '+data.item.address.region }} </span
							>
							<span class="d-block fs14 lh20">
								{{ data.item.address.city | capitalize }}
							</span>
						</div>
					</template>

					<!-- A custom formatted data column cell for field 'city' -->
					<template v-slot:cell(city)="data">
						<span class="d-block">
							{{ data.item.city.name | capitalize }}
						</span>
						<span class="d-block fs12 lh16 text-gray-600">
							{{ data.item.city.postcode }}
						</span>
					</template>

					<!-- A custom formatted data column cell for field 'contact' -->
					<template v-slot:cell(contact)="data">
						<div class="d-flex align-items-center">
							<span class="avatar mr-8">
								{{ data.item.contact.name | initials }}
							</span>
							<div>
								<span class="d-block">
									{{ data.item.contact.name }}
								</span>
								<span class="d-block fs12 lh16 text-gray-600">
									{{ data.item.contact.id }}
								</span>
							</div>
						</div>
					</template>
					<!-- A custom formatted data column cell for field 'status' -->
					<template v-slot:cell(status)="data">
						<span class="tag" :class="data.item.status">
							{{ data.item.status | capitalize }}
						</span>
					</template>

					<!-- A custom formatted data column cell for field 'action' -->
					<template v-slot:cell(action)="data">
						<div class="d-flex">
							<b-button @click="editWarehouse(data.item)" variant="tertiary" size="xs" class="mr-4">
								Edit
							</b-button>
							<!-- <b-button variant="tertiary" size="xs"> Check </b-button> -->
						</div>
					</template>
				</b-table>

				<!-- Mobile view started here: -->
				<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
		         	<li class="" v-for="items in items" :key="items.id">
		         		<div class="d-flex justify-content-between">
		            		<span class="d-block fw500 pb-8 cp mt-7">
		              			Address ID: {{items.addressId}}
		            		</span>

							<b-button @click="editWarehouse(items)" variant="tertiary" size="xs" class="mr-4">
								Edit
							</b-button>
						</div>
		            	
						<!-- <i v-if="items.lat>0" class="icon-pin-check text-blue fs16 mr-8"></i> -->
						<span class="d-block lh20" :title="items.addressName.name">
							{{ items.addressName.name }}
						</span>
						<span v-if="items.defaultPickup==1 || items.defaultBilling==1" class="d-block fs12 lh14 text-gray-600"> Default - {{items.defaultPickup==1?'Pickup':''}}{{items.defaultBilling==1?' Billing':''}} 
						</span>

						<span>
							<div class="text-truncate w250px" :title="items.address.number+' '+items.address.street+'\n'+items.address.region">
								<span class="fs14 lh20"
								>{{ items.address.number+' '+items.address.street+' '+items.address.region }} </span
								>
								<span class="d-block fs14 lh20">
									{{ items.address.city | capitalize }}
								</span>
							</div>
						</span>

						<span>
							<div class="d-flex align-items-center justify-content-between mt-8">
								<div class="d-flex">
									<span class="avatar mr-8">
										{{ items.contact.name | initials }}
									</span>
									<div>
										<span class="d-block">
											{{ items.contact.name }}
										</span>
										<span class="d-block fs12 lh16 text-gray-600">
											{{ items.contact.id }}
										</span>
									</div>
								</div>

								<div>
									<span class="tag d-flex align-items-center" :class="items.status">
										{{ items.status | capitalize }}
									</span>
								</div>

							</div>
						</span>
		          	</li>
		        </ul>

				<div class="pagination-bar">
					<div class="container-fluid">
						<div class="row">
							<div class="col-4">
								<div class="d-inline-flex wsnw align-items-center">
									<div class="d-block w50px">
										<multiselect class="pagination-items__pp" v-model="perPage" :searchable="false" :show-labels="false" :allow-empty="false" :options="pageOptions" @input="setPageNumber"></multiselect>
									</div>
									<span class="pl-8 fs12 lh24 text-gray-600 pagination-items"></span>
								</div>
							</div>
							<div class="col-8 text-right">
								<div class="d-inline-flex ml-auto wsnw align-items-center">
									<p class="fs12 lh24 text-gray-600 pr-14">
										Page: {{ currentPage }}
									</p>
									<b-button-group>
										<b-button @click="prevPage" :disabled="prevDisabled" variant="paginate left">
											<i class="icon-back fs12"></i>
										</b-button>

										<b-button @click="nextPage" :disabled="nextDisabled" variant="paginate right">
											<i class="icon-arrow fs12"></i>
										</b-button>
									</b-button-group>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<SettingsWarehousesAddNew :editItem.sync="editItem" @updateItem="updateItem" ref="addNewWarehouse" />
		<FilterSidebar ref="filter" />
		<ResultNotFound v-if="items.length == 0 && !isBusy" />
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import SettingsWarehousesAddNew from "@/components/SettingsWarehousesAddNew.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";
import FilterSidebar from "@/components/filterWarehouse";

export default {
	name: "SettingsWarehouses",
	data() {
		return {
			pageOptions: this.$store.state.pageOptions,
			totalRows: 1,
			currentPage: 1,
			perPage: this.$store.state.perPage,
			totalPages: 1,
			warehouseID: null,
			prevDisabled: true,
			nextDisabled: false,
			startPage: 1,
			pagesArr: [],
			isBusy: true,
			pagination: {},
			form: {},
			editItem: {},
			selFilters: this.$store.getters.filters.warehouse || {},
			filterResultsToggle: this.$store.getters.filters.warehouse ? true : false,
			items: [],
			fields: [
			{
				key: "addressId",
				label: "Address ID",
				sortable: true,
				tdClass: "wsnw",
			},
			{
				key: "addressName",
				label: "Address Name",
				sortable: true,
				tdClass: "wsnw",
			},
			{ key: "address", label: "Address", sortable: true, tdClass: "wsnw" },
			{ key: "city", label: "City", sortable: true },
			{ key: "contact", label: "Contact", sortable: true },
			{ key: "status", label: "Status", sortable: true },
			{ key: "action", label: "" },
			],
		};
	},
	components: {
		Multiselect,
		FilterSidebar,
		ResultNotFound,
		SettingsWarehousesAddNew,
	},
	created() {
		this.getItems();
	},
	methods: {
		updateItem(data) {
			const item = {
				addressId: data.id,
				addressName: { name: data.addressName, default: false },
				address: {
					number: "",
					street: data.sellerAddressLine1,
					region: "",
					city: data.sellerCity,
				},
				city: {
					name: data.sellerCity,
					postcode: data.sellerPinCode,
				},
				contact: {
					name: data.contactName,
					id: data.contactNumber1,
				},
				status: data.status ? 'active' : 'inactive',
			}
			const index = this.items.findIndex(i => i.addressId == data.id)
			console.log(index, item)
			this.items[index] = item
			this.items.push(item)
			this.items.pop()
		},
		async getItems() {

			let params = '';

			if(this.selFilters && this.selFilters.warehouseID) {
				params = {filtering: true, warehouseID: this.selFilters.warehouseID.id}
			}
			else {
				params = {}
			}

			try {
				this.isBusy = true;
				const response = await this.axios.get(`/settings/warehouses?page=${this.currentPage}&offset=${this.perPage}`, {params:params});
				
				if (response.data.error) {
					this.popToast("failed", "Empty!", response.data.error.error);
				}
				
				if (response.data.warehouseData) {
					this.items = response.data.warehouseData;
					this.totalRows = this.items.length;
					this.pagination = response.data.pagination;
					this.setPagination();
					if (this.items.length < this.perPage) {
						this.nextDisabled = true;
					}
				}
				this.isBusy = false;

			} catch (error) {
				this.isBusy = false;
				this.nextDisabled = true;
				console.log(error);
			}
		},

		setPagination() {
			this.nextDisabled = this.pagination.next ? false : true;
			this.prevDisabled = this.pagination.prev ? false : true;
		},

		// removeFilter(index) {
	 //        const selFilters = {...this.selFilters}

	 //        console.log(selFilters)

		// 	this.selFilters = selFilters
		// 	this.$store.commit('setFilter', {'warehouse': selFilters})

		// 	delete this.$refs.filter.form[index]

		// 	if(Object.keys(this.selFilters).length > 0) {
		// 		this.getItems()
		// 	}
		// 	else{
		// 		this.clearFilters()
		// 	}	        
	 //    },

		clearFilters() {
			this.filterResultsToggle = false;
			this.selFilters = {};
			this.$refs.filter.clearFilters()
			this.getItems({filtering: false})
		},

		getFilters() {
			this.currentPage = 1
			this.filterResultsToggle = true;
			this.selFilters = this.$store.getters.filters.warehouse
			this.getItems()
		},

		pushTotalPages() {
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},
		setPageNumber() {
			this.currentPage = 1;
			this.getItems();
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
			this.currentPage = 1;
			this.prevDisabled = true;
			if (this.totalPages == 1) {
				this.nextDisabled = true;
			} else if (this.totalPages > 1) {
				this.nextDisabled = false;
			}
		},
		updatePageNumber() {
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},
		nextPage() {
			this.currentPage++;
			this.getItems();
		},
		prevPage() {
			this.currentPage--;
			this.getItems();
		},
		toggleAllRows() {
			if (this.allSelected == false) {
				this.items.forEach((item) => {
					this.$set(item, "selected", true);
					this.allSelected = true;
				});
			} else {
				this.selectedRows.forEach((item) => {
					this.$delete(item, "selected");
					this.allSelected = false;
				});
			}
		},
		openEditModal() {
			this.$refs.editSkuModal.isOpen = !this.$refs.editSkuModal.isOpen;
		},
		openAddModal() {
			this.$refs.addSkuModal.isOpen = !this.$refs.addSkuModal.isOpen;
		},
		openAddWarehouse() {
			this.editItem = {}
			this.editId = null
			this.$refs.addNewWarehouse.isOpen = !this.$refs.addNewWarehouse.isOpen;
		},
		editWarehouse(editItem) {
			this.editItem = editItem
			this.$refs.addNewWarehouse.isOpen = true
		}
	},
	computed: {
		windowWidth() {
        	return this.$store.state.windowWidth;
        }
	},
	watch: {
		currentPage: function () {
			this.prevDisabled = false;
			this.nextDisabled = false;

			if (this.currentPage == 1) {
				this.prevDisabled = true;
			}

			if (this.currentPage == this.totalPages) {
				this.nextDisabled = true;
			}
		},
		selectedRows: function () {
			if (this.selectedRows.length == this.items.length) {
				this.allSelected = true;
			}
		},
	},
	mounted() {
		this.totalRows = this.items.length;
		this.totalPages = Math.ceil(this.totalRows / this.perPage);
		for (var i = 1; i <= this.totalPages; i++) {
			this.pagesArr.push(i);
		}
	},
};
</script>

<style lang="scss" scoped>
/deep/ .warehouse-table tbody tr td:first-child {
    overflow: hidden;
    white-space: nowrap;
    padding: 0.75rem 0.75rem 1rem 1.25rem;
    max-width: 150px !important;
    text-overflow: ellipsis;
}

/deep/ .warehouse-table tbody tr td:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    padding: 0.75rem 0rem 1rem 0rem;
    max-width: 150px !important;
    text-overflow: ellipsis;
}

.warehouse-table {
	max-height: calc(100vh - 250px);
}

.w250px{
	width:250px
}
@media only screen and (max-device-width: 480px) {
    .filterBar {
      justify-content: flex-end;
      position: relative;
      right: 21px;
      width: 100vw;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }
  }
</style>