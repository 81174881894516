<template>
	<div class="priority">
		<div class="container-fluid">
			<div class="card">
				<div class="card__title">
					<span class="fs18 fw500  d-block">
						Bulk & API Booking Settings
					</span>
				</div>
				<div class="card__content overflow-auto">
					<ul class="switch-list bg-white">
						<li
						v-for="tab in tabs"
						:key="tab.id"
						:class="[{ active: selected.id === tab.id }]"
						>
						<b-link @click="changePriority(tab)" class="btn-switch btn ml-10 mr-10">
							{{ tab.title }}
						</b-link>
					</li>
				</ul>
			</div>
		</div>

		<component :is="selected.component"></component>
	</div>
</div>
</template>

<script>
import PrioritySettingsCheapest from "./PrioritySettingsCheapest.vue";
import PrioritySettingsPriority from "./PrioritySettingsPriority.vue";
import ModelessCheapest from "./PrioritySettingsModelessCheapest.vue";

export default {
	name: "Priority",
	title: "Shyplite - Priority",
	data() {
		return {
			tabs: [
			{
				id: 1,
				component: "PrioritySettingsCheapest",
				title: "Cheapest",
			},
			{
				id: 2,
				component: "PrioritySettingsPriority",
				title: "Priority",
			},
			{
				id: 3,
				component: "ModelessCheapest",
				title: "Modeless Cheapest",
			}
			],
			selected: {
				id: 1,
				component: "PrioritySettingsCheapest",
				title: "Cheapest",
			},
			priority: this.$store.getters.user.priority
		};
	},
	components: {
		PrioritySettingsCheapest,
		PrioritySettingsPriority,
		ModelessCheapest
	},
	created() {
		if(this.priority == 1) {
			this.selected = this.tabs[0]
		}
		else if(this.priority == 2) {
			this.selected = this.tabs[1]
		}
		else if(this.priority == 3) {
			this.selected = this.tabs[2]
		}
		else {
			this.selected = []
		}
		// this.selected = this.tabs.filter(item => item.id == this.$store.getters.user.priority)[0]

		if(this.$store.getters.user.childRole == 'FINANCE') {
	      this.$router.push('/unauthorized')
	    }
	},
	methods: {
		async changePriority(tab) {
			if (tab.id !== this.selected.id) {
				try {
					const res = await this.axios.post('/settings/prioritysetting', {priority: tab.id})
					if(res.data.success == true) {
						const user = this.$store.getters.user
						user.priority = tab.id
						this.$store.commit('setUser', user)
						this.selected = tab
						this.popToast("booked","Success",`The Priority setting has been updated to ${tab.title} on your Account`);
					}
				} catch(e) {
					console.log(e);
				}
			} else {
				
			}
		},
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>


<style scoped>
	.card__content .switch-list li.active .btn-switch {
		cursor: auto !important;
	}
</style>