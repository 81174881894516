var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "webhooks container-fluid position-relative" },
    [
      _c(
        "div",
        {
          staticClass:
            "col d-flex align-items-center justify-content-between webhooks_mobile pb-20"
        },
        [
          _c("span", { staticClass: "fs18 fw700" }, [_vm._v("Webhooks Table")]),
          _c(
            "div",
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-20",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("webhook/sandbox")
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "goto mr-5",
                    attrs: { src: require("@/assets/svg/settings.svg") }
                  }),
                  _vm._v(" Test Webhook ")
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _vm.addWebhook }
                },
                [
                  _c("i", { staticClass: "icon-add fs16 mr-10" }),
                  _vm._v(" Add Endpoint ")
                ]
              )
            ],
            1
          )
        ]
      ),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "price-calculator-table",
                attrs: {
                  items: _vm.webhookItems,
                  fields: _vm.webhookFields,
                  "sticky-header": "",
                  "no-border-collapse": "",
                  id: "air-mode"
                },
                on: { "row-clicked": _vm.tbodyRowAttr },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(id)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(data.item.id))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(description)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block description" }, [
                            _vm._v(
                              _vm._s(
                                data.item.description
                                  ? data.item.description
                                  : "N/A"
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(endpoint)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block endpoint" }, [
                            _vm._v(_vm._s(data.item.endpoint) + " ")
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          data.item.status == 1
                            ? _c(
                                "span",
                                { staticClass: "d-block enabled ml-15" },
                                [_vm._v("Enabled")]
                              )
                            : _c(
                                "span",
                                { staticClass: "d-block disabled ml-15" },
                                [_vm._v("Disabled")]
                              )
                        ]
                      }
                    },
                    {
                      key: "cell(last7)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block " }, [
                            _vm._v(
                              _vm._s(data.item.last7 ? data.item.last7 : "N/A")
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(error)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block " }, [
                            _vm._v(
                              _vm._s(data.item.error ? data.item.error : "N/A")
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3813723721
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.webhookItems, function(item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between w-100 mb-4"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "d-block fw500 pb-8 cp",
                        on: {
                          click: function($event) {
                            return _vm.tbodyRowAttr(item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.endpoint) + " ")]
                    ),
                    _c("div", { staticClass: "d-flex justify-content-end" }, [
                      item.status == 1
                        ? _c("span", { staticClass: "d-block enabled ml-15" }, [
                            _vm._v("Enabled")
                          ])
                        : _c(
                            "span",
                            { staticClass: "d-block disabled ml-15" },
                            [_vm._v("Disabled")]
                          )
                    ])
                  ]
                ),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "d-flex w-100" }, [
                    _c("span", { staticClass: "carrier-name" }, [
                      _vm._v(
                        " Description: " +
                          _vm._s(item.description ? item.description : "N/A") +
                          " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "d-flex w-100 mt-4" }, [
                    _c("span", { staticClass: "carrier-name" }, [
                      _vm._v(" Version: " + _vm._s(item.version) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "d-flex w-100 mt-4" }, [
                    _c("span", { staticClass: "carrier-name" }, [
                      _vm._v(
                        " Last 7 Days: " +
                          _vm._s(item.last7 ? item.last7 : "N/A") +
                          " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "d-flex w-100 mt-4" }, [
                    _c("span", { staticClass: "carrier-name" }, [
                      _vm._v(
                        " Error Rate: " +
                          _vm._s(item.error ? item.error : "N/A") +
                          " "
                      )
                    ])
                  ])
                ])
              ])
            }),
            0
          ),
      _c("AddWebhook", { ref: "addWebhook" }),
      !_vm.webhookItems.length ? _c("ResultNotFound") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }