<template>
  <b-modal
    id="add-sku-modal"
    modal-class="add-sku-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block mt-auto fs18 lh28 fw500"> Add SKU </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <div class="mw596 mx-auto">
      <div :class="{ file_upload_active: isbulkactive, file_upload: true }">
        <div class="row">
          <div
            class="col py-16 py-md-24 d-flex align-items-center justify-content-between"
          >
            <span class="d-block fs18 lh28 fw500"> Import SKU's file 
              <i class="icon-disclaimer text-gray-400 fs12 ml-4"
                v-b-tooltip.hover.right
                title="Please Download the Template from right for Correct Format">
              </i>
            </span>
            <b-button
              @click="downloadToast"
              variant="tertiary"
              class="d-none d-md-inline-flex"
            >
              <i class="icon-download mr-11"></i>
              Download Template
            </b-button>
            <b-button
              @click="downloadToast"
              variant="tertiary"
              class="d-inline-flex d-md-none"
            >
              <i class="icon-download"></i>
            </b-button>
          </div>
        </div>
        <b-form @submit.prevent="uploadFile">
          <div class="row">
            <div class="col pb-32">
              <!-- <draggable> -->
                <b-form-file
                  accept=".xlsx, .xls"
                  v-model="files"
                  ref="bulkUpload"
                  placeholder="Drop your template here or click on the button below"
                  drop-placeholder="Drop your template here or click on the button below"
                  id="bulk-input"
                  class="bulk-input"
                  :disabled="uploading"
                  :class="{ 'has-file': active }"
                ></b-form-file>
              <!-- </draggable> -->
            </div>
          </div>
          <div class="row">
            <div class="col pt-8 text-right">
              <b-button :disabled="uploading" type="submit" variant="primary" size="lg">
                Submit <b-spinner v-if="uploading" class="sm"></b-spinner>
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
      <div
        :class="{ manual_upload_inactive: isbulkactive, manual_upload: true }"
      >
        <div class="row">
          <div
            class="col py-16 py-md-24 d-flex align-items-center justify-content-between"
          >
            <span class="d-block fs18 lh28 fw500"> New SKU Detail </span>
            <b-button
              variant="tertiary"
              class="d-none d-md-inline-flex"
              @click="isbulkactive = !isbulkactive"
            >
              <i class="icon-upload mr-11"></i>
              Import Bulk
            </b-button>
            <b-button
              variant="tertiary"
              class="d-inline-flex d-md-none"
              @click="isbulkactive = !isbulkactive"
            >
              <i class="icon-upload"></i>
            </b-button>
          </div>
        </div>
        <div class="form">
          <b-form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group id="sku-field" label="SKU" label-for="sku">
                  <b-form-input
                    required
                    id="sku"
                    v-model="form.sku"
                    placeholder="- Type SKU Name -"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-name-field"
                  label="Item Name"
                  label-for="item-name"
                >
                  <b-form-input
                    required
                    id="item-name"
                    v-model="form.name"
                    placeholder="Type Item Name"
                    maxlength="100"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-weight-field"
                  label="Item Weight(Kg)"
                  label-for="item-weight"
                >
                  <b-form-input
                    required
                    id="item-weight"
                    :min="this.$store.state.minW"
                    :max="this.$store.state.maxW"
                    type="number"
                    step=".01"
                    v-model="form.weight"
                    placeholder="0.00"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group id="mode-field" label="Mode" label-for="weight">
                  <multiselect
                    id="mode"
                    v-model="form.mode"
                    :options="options"
                    placeholder="Select mode type"
                    :searchable="false"
                    track-by="name"
                    label="name"
                  >
                  </multiselect>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 col">
                <b-form-group
                  id="length-field"
                  label="Length (cm)"
                  label-for="length"
                >
                  <b-form-input
                    required
                    type="number"
                    id="length"
                    :min="this.$store.state.minD"
                    :max="this.$store.state.maxD"
                    v-model="form.length"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col">
                <b-form-group
                  id="width-field"
                  label="Width (cm)"
                  label-for="width"
                >
                  <b-form-input
                    required
                    type="number"
                    :min="this.$store.state.minD"
                    :max="this.$store.state.maxD"
                    id="width"
                    v-model="form.width"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col">
                <b-form-group
                  id="height-field"
                  label="Height (cm)"
                  label-for="height"
                >
                  <b-form-input
                    required
                    type="number"
                    v-model="form.height"
                    :min="this.$store.state.minD"
                    :max="this.$store.state.maxD"
                    id="height"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col py-16">
                <span>
                  <i class="icon-support text-yellow mr-8 vam fs16"></i>
                  Please add the value SKU carefully. It cannot be changed
                  later.
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col pt-8 mt-22 text-left error" v-if="errorFlag || errormode">
                {{error}}
              </div>
              <div class="col pt-8">
                <b-button type="submit" :disabled="errorFlag" variant="primary" size="lg">
                  Submit
                </b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";

export default {
  name: "SettingsSkusAdd",
  data() {
    return {
      form: {
        mode: "",
        sku: "",
        name: "",
        length: "",
        width: "",
        height: "",
        weight: "",
      },
      files: null,
      active: false,
      browseText: "Browse",
      isOpen: this.modalOpen,
      itemAdded: false,
      isbulkactive: false,
      uploading: false,
      fileUpload: false,
      options: [
        { name: "Domestic", $isDisabled: true },
        { name: "Air", id: "1" },
        { name: "Surface-30kg", id: "20" },
        { name: "Surface-10kg", id: "2" },
        { name: "Surface-5kg", id: "10" },
        { name: "Lite-2kg", id: "3" },
        { name: "Lite-1kg", id: "8" },
        { name: "Lite-0.5kg", id: "9" },
        { name: "International", $isDisabled: true },
        { name: "Express", id: "5" },
        { name: "Economy", id: "6" },
        { name: "EconomyPlus", id: "7" },
      ],
      error:"",
      errorFlag:false,
      errormode:false
    };
  },
  components: {
    Multiselect,
    draggable,
  },
  beforeCreate() {
    this.form = {};
  },
  methods: {
    async uploadFile() {
      if(!this.$refs.bulkUpload.files[0]) {
        this.popToast("failed", "Failed!", "Please Upload a File");
      } else {
        this.uploading = true
        console.log(this.$refs.bulkUpload.files[0]);
        let fd = new FormData();
        fd.append("file", this.files);
        try {
          const response = await this.axios.post("/settings/skus/upload", fd);
          if (response.data.result.response.path) {
            this.popToast(
              "requested",
              "Partial Success!",
              "New SKUs imported and file with error enteries is downloaded"
            );
            this.download(response.data.result.response.path, "Error Entries.xlsx");
          } else {
            this.popToast("booked", "Success!", "New SKUs Imported Successfully");
          }
          this.fileUpload = true;
          this.$emit("fileData", this.fileUpload);
          this.hideModal();
          console.log(response);
        } catch (error) {
          this.popToast("failed", "Failed!", "Data Fetching failed !!! Please Try again Later");
        }
      } 
      this.uploading = false
    },
    async onSubmit(event) {
      event.preventDefault();
      let data = Object.assign({}, this.form);
      if (!data.mode) {
        this.error = "Please Select a Mode Type"
        this.errormode = true
      } else {
        this.error = ""
        this.errorFlag = false
        data.mode = this.form.mode.id;
          try {
          const res = await this.axios.post("/settings/skus/new", data);
          console.log(res);
          let creation_date = new Date();
          if (res.data.success) {
            const skuData = {
              dimensions: {
                length: this.form.length,
                width: this.form.width,
                height: this.form.height,
                weight: this.form.weight,
              },
              modeType: this.form.mode.name,
              productName: this.form.name,
              sku: this.form.sku,
              lastUpdate: creation_date,
            };
            console.log(skuData);
            this.$emit("addSku", skuData);
            this.popToast("booked", "Success!", res.data.message);
            this.hideModal();
          } else {
            this.popToast("failed", "Failed!", res.data.error);
          }
        } catch (error) {
          console.log(error, "error");
        }
      }
    },
    hideModal() {
      this.form = {};
      this.$bvModal.hide("add-sku-modal");
    },
    downloadToast() {
      window.location.href =
        "https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/uploadFormats/addeditskus.xlsx";
      this.popToast(
        "requested",
        "Download requested",
        "Your download should start now."
      );
    },
  },
  watch: {
    isOpen: function (newValue) {
      if (newValue == false) {
        this.hideModal();
        this.isbulkactive = false;
        this.errorFlag=false;
        this.errormode=false;
      }
    },
    files: function (newValue) {
      console.log(newValue);
    },
  },
};
</script>

<style scoped>
.file_upload {
  display: none;
}
.file_upload_active {
  display: block;
}
.manual_upload_inactive {
  display: none;
}

.error{
  background: #ffa6a6;
  height: 2.1rem;
  color: red;
  border: 1px #ffa6a6;
  border-radius: 5px;
}

@media only screen and (max-device-width: 400px) {
    .error {
      height: 3.1rem !important;
    }
  }
</style>
