var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "amazonConnector",
      attrs: {
        id: "amazon-connector-modal",
        "modal-class": "amazon-connector-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Amazon Credentials ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "mw644 mx-auto" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-30 py-16" }, [
              _c(
                "span",
                { staticClass: "d-block pt-12 text-gray-600" },
                [
                  _c("strong", [_vm._v("1.")]),
                  _vm._v(" Click here to "),
                  _c(
                    "b-link",
                    {
                      staticClass: "fs16",
                      on: {
                        click: function($event) {
                          return _vm.connectAmazon(_vm.$store.getters.user.id)
                        }
                      }
                    },
                    [
                      _vm._v(" Install Amazon App "),
                      _c("i", { staticClass: "icon-arrow-long ml-8 fs12" })
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("2.")]),
                _vm._v(
                  " You will get redirected to Authorize Shyplite Page. Click on Login to Shyplite now button. "
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("3.")]),
                _vm._v(
                  " After providing authorization, you will get redirected back to Shyplite App. "
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col py-16" }, [
              _c("span", { staticClass: "d-block text-gray-600" }, [
                _c("strong", [_vm._v("Note")]),
                _vm._v(
                  " : During installation you may be prompted to login to your Amazon Seller account. "
                )
              ])
            ])
          ]),
          _vm.editId != undefined
            ? _c(
                "b-form",
                {
                  staticClass: "py-16",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.amazonConnect()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "seller-id-field",
                              label: "Store Name",
                              "label-for": "seller-id"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "seller-id",
                                placeholder: "Store Name"
                              },
                              model: {
                                value: _vm.form.storeName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "storeName", $$v)
                                },
                                expression: "form.storeName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 py-16" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Get Orders" } },
                          [
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "d-inline-block mr-20",
                                attrs: { name: "get-orders", value: "3" },
                                model: {
                                  value: _vm.form.isactive,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "isactive", $$v)
                                  },
                                  expression: "form.isactive"
                                }
                              },
                              [_vm._v(" Enable ")]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "d-inline-block",
                                attrs: { name: "get-orders", value: "0" },
                                model: {
                                  value: _vm.form.isactive,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "isactive", $$v)
                                  },
                                  expression: "form.isactive"
                                }
                              },
                              [_vm._v(" Disable ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 py-16" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Auto Fullfilment" } },
                          [
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "d-inline-block mr-20",
                                attrs: { name: "auto-fullfilment", value: 1 },
                                model: {
                                  value: _vm.form.autoFullFill,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "autoFullFill", $$v)
                                  },
                                  expression: "form.autoFullFill"
                                }
                              },
                              [_vm._v(" On ")]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "d-inline-block",
                                attrs: { name: "auto-fullfilment", value: 0 },
                                model: {
                                  value: _vm.form.autoFullFill,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "autoFullFill", $$v)
                                  },
                                  expression: "form.autoFullFill"
                                }
                              },
                              [_vm._v(" Off ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col pt-12 pb-8 d-none d-md-block" },
                      [
                        _vm.sellerDisable
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "submit",
                                  variant: "primary mr-16",
                                  size: "lg"
                                }
                              },
                              [
                                _vm.updating ? _c("b-spinner") : _vm._e(),
                                _vm._v(" Update ")
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col pt-12 pb-8 d-flex d-md-none" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "lg" },
                            on: { click: _vm.hideModal }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _vm.sellerDisable
                          ? _c(
                              "b-button",
                              {
                                staticClass: "ml-16 w-sm-100",
                                attrs: {
                                  type: "submit",
                                  variant: "primary",
                                  size: "lg"
                                }
                              },
                              [
                                _vm.updating ? _c("b-spinner") : _vm._e(),
                                _vm._v(" Update ")
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                staticClass: "ml-16 w-sm-100",
                                attrs: {
                                  type: "submit",
                                  variant: "primary",
                                  size: "lg"
                                }
                              },
                              [
                                _vm.updating ? _c("b-spinner") : _vm._e(),
                                _vm._v(" Create ")
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }