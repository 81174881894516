<template>
	<div class="settings">
		<AppTopbar
		title="Settings"
		buttonText="New Order"
		buttonLink="new"
		buttonIconClass="icon-add"
		></AppTopbar>

		<div class="container-fluid pt-60 pt-md-94 pb-14">
			<ul class="filter-list settings">
				<li
				v-for="tab in computedtabs"
				:key="tab.id"
				:class="[{ active: selected.id === tab.id }]"
				>
				<b-link @click="selected = tab;$router.replace('#'+tab.title)" :class="tab.title" class="btn-filter wsnw p-7">
					{{ tab.title }}
				</b-link>
			</li>
		</ul>
	</div>

	<component :is="selected.component"></component>
</div>
</template>

<script>
import SettingsApi from "@/components/SettingsApi.vue";
import SettingsSkus from "@/components/SettingsSkus.vue";
import AppTopbar from "@/components/layout/AppTopbar.vue";
import SettingsProfile from "@/components/SettingsProfile.vue";
import SettingsService from "@/components/SettingsService.vue";
import SettingsWarehouses from "@/components/SettingsWarehouses.vue";
import SettingsConnectors from "@/components/SettingsConnectors.vue";
import Customization from "@/components/AllSettings.vue";
import SettingsPriority from "@/components/SettingsPriority.vue";
import Webhooks from "@/views/webhooks/Webhooks.vue"

export default {
	name: "Settings",
	title: "Shyplite - Settings",
	data() {
		return {
			choice: this.$store.getters.user.choice,
			sellerId: this.$store.getters.user.id,
			selected: {
				id: 0,
				component: "SettingsProfile",
				title: "Profile",
			},
			tabs: [
			{
				id: 0,
				component: "SettingsProfile",
				title: "Profile",
			},
			{
				id: 1,
				component: "Customization",
				title: "Customization",
			},
			{
				id: 2,
				component: "SettingsConnectors",
				title: "Connectors",
			},
			{
				id: 5,
				component: "SettingsService",
				title: "Service",
			},
			{
				id: 6,
				component: "SettingsPriority",
				title: "Priority",
			},
			{
				id: 7,
				component: "SettingsApi",
				title: "API",
			},
			{
				id: 8,
				component: "Webhooks",
				title: "Webhooks",
			}
			],
			financearr: [1,2,3,4,5,6,7,8],
			opserr:[7,8],
			autoarray:[5,6]
		};
	},
	components: {
		AppTopbar,
		SettingsProfile,
		SettingsConnectors,
		Customization,
		SettingsSkus,
		SettingsWarehouses,
		SettingsService,
		SettingsApi,
		SettingsPriority,
		Webhooks
	},
	mounted() {
	},
	created() {
		if(this.choice == 0){
			this.tabs = this.tabs.filter(i => !this.autoarray.includes(i.id))
		}
		const tab = this.tabs.find(item => '#'+item.title.toLowerCase() == location.hash.toLowerCase())
		if(tab) {
			this.selected = tab
		}else{
			this.selected = this.tabs[0]
		}
	},
	computed: {
		activeUser() {
			return this.$store.getters.user;
		},
		checkFlag() {
			return this.$store.state.global.goCustom;
		},
		computedtabs() {
			if (this.activeUser.childRole == 'FINANCE') {
				return this.tabs.filter(i => !this.financearr.includes(i.id))
			} else if (this.activeUser.childRole == 'OPERATIONS') {
				return this.tabs.filter(i => !this.opserr.includes(i.id))
			} else {
				return this.tabs 
			}
		}
	},
	watch: {
		checkFlag:function(newValue) {
			if (newValue == true) {
				this.selected = {
					component: "Customization",
					id: 1,
					title: "Customization"
				}
				this.$store.commit('setGlobal', {'goCustomfromConn':true});
				this.$store.commit('setGlobal', {'goCustom':false});
			}
		}
	}
};
</script>
