<template>
  <b-modal
    id="edit-sku-modal"
    modal-class="add-connector-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block mt-auto fs18 lh28 fw500">
        Edit SKU
      </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>
    <div class="form">
      <b-form @submit.prevent="editSkuform">
        <div class="mw596 mx-auto">
          <div class="row">
            <div class="col">
              <b-form-group id="sku-field" label="SKU" label-for="sku">
                <b-form-input
                  id="sku"
                  v-model="form.sku"
                  value=""
                  disabled=""
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b-form-group
                id="product-name-field"
                label="Product Name"
                label-for="product-name"
              >
                <b-form-input
                  required
                  id="product-name"
                  v-model="form.name"
                  maxlength="100"
                  value="BreatheFree 6x Oxygen Ampule Package"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col">
              <b-form-group
                id="length-field"
                label="Length (cm)"
                label-for="length"
              >
                <b-form-input
                  required
                  type="number"
                  :min="this.$store.state.minD"
                  :max="this.$store.state.maxD"   
                  id="length"
                  v-model="form.length"
                  placeholder="0"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col">
              <b-form-group
                id="width-field"
                label="Width (cm)"
                label-for="width"
              >
                <b-form-input
                  required
                  type="number"
                  :min="this.$store.state.minD"
                  :max="this.$store.state.maxD"
                  id="width"
                  v-model="form.width"
                  placeholder="0"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col">
              <b-form-group
                id="height-field"
                label="Height (cm)"
                label-for="height"
              >
                <b-form-input
                  required
                  type="number"
                  id="height"
                  :min="this.$store.state.minD"
                  :max="this.$store.state.maxD"
                  v-model="form.height"
                  placeholder="0"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col">
              <b-form-group
                id="weight-field"
                label="Weight (kg)"
                label-for="weight"
              >
                <b-form-input
                  required
                  type="number"
                  id="weight"
                  step=".01"
                  :min="this.$store.state.minW"
                  :max="this.$store.state.maxW"
                  v-model="form.weight"
                  placeholder="0.00"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col">
              <b-form-group id="mode-field" label="Mode" label-for="mode">
                <multiselect
                  id="mode"
                  v-model="form.mode"
                  :options="options"
                  :searchable="false"
                  track-by="name"
                  label="name"
                ></multiselect>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col pt-8 mt-26 text-left error" v-if="errorFlag || errormode">
                {{error}}
            </div>      
            <div class="col pt-16 text-right">
              <b-button variant="primary" size="lg" :disabled="errorFlag" type="submit">Update SKU</b-button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "SettingsSkusEdit",
  props: {
    editItem: Object
  },
  data() {
    return {
      isOpen: this.modalOpen,
      modeValue: null,
      skuId: "",
      form: {},
      options: [
        { name: "Domestic", $isDisabled: true },
        { name: "Air", id: "1" },
        { name: "Surface-30kg", id: "20" },
        { name: "Surface-10kg", id: "2" },
        { name: "Surface-5kg", id: "10" },
        { name: "Lite-2kg", id: "3" },
        { name: "Lite-1kg", id: "8" },
        { name: "Lite-0.5kg", id: "9" },
        { name: "International", $isDisabled: true },
        { name: "Express", id: "5" },
        { name: "Economy", id: "6" },
        { name: "EconomyPlus", id: "7" }
      ],
      error:"",
      errorFlag:false,
      errormode:false
    };
  },
  components: {
    Multiselect
  },
  methods: {
    async editSkuform() {
      let data = Object.assign({}, this.form);
      if (!data.mode || !data.mode.id || data.mode.id == 0) {
        this.error = "Please Select a Mode Type"
        this.errormode = true
      } else {
          this.error = ""
          this.errorFlag = false
          data.mode = data.mode.id;
          try {
            const response = await this.axios.post(`/settings/skus/${this.form.id}`,data);
            let creation_date = new Date();
            if (response.data.message) {
              const skuData = {
                dimensions: {
                  length: this.form.length,
                  width: this.form.width,
                  height: this.form.height,
                  weight: this.form.weight
                },
                modeType: this.form.mode.name,
                productName: this.form.name,
                sku: this.form.sku,
                lastUpdate: creation_date
              };
              this.$emit('updateSku',skuData)
              this.popToast("booked", "Success!", response.data.message);
              this.hideModal();
            } else {
              this.popToast("failed", "Failed!", response.data.error);
            }
        } catch (error) {
          this.popToast("failed", "Failed!", error);
        }
      }
    },
    async formatting(id) {
      const res = await this.axios.get(`/settings/skus/${id}`);
      const editItem = {
        id: res.data.sku.id,
        sku: res.data.sku.sku,
        name: res.data.sku.name,
        length: res.data.sku.length,
        width: res.data.sku.width,
        height: res.data.sku.height,
        weight: res.data.sku.weight,
        mode: res.data.sku.mode
      };
      if(editItem.mode == 0) {
        editItem.mode = {
          id: "0",
          name: "Select Option"
        };
      } else {
          let mode = this.options.find(item => item.id == editItem.mode);
          if (mode.id && mode.name) {
            editItem.mode = {
              id: mode.id,
              name: mode.name
            };
          } else {
            editItem.mode = {
              id: "0",
              name: "Select Option"
            };
          }
      }
      this.form = editItem;
    },
    hideModal() {
      this.$bvModal.hide("edit-sku-modal");
    }
  },
  watch: {
    editItem: function(newValue) {
      if (newValue.id) {
        this.skuId = newValue.id;
        this.formatting(newValue.id);
      }
    },
    isOpen: function (newValue) {
      if (newValue == false) {
        this.hideModal();
        this.$emit('update:editItem', undefined)
        // this.$parent.editItem = {}
        this.isbulkactive = false;
        this.errorFlag=false;
        this.errormode=false;
      } else {
        this.formatting(this.skuId);
      }
    }
  }
};
</script>

<style scoped>

.error{
  background: #ffa6a6;
  height: 2.1rem;
  color: red;
  border: 1px #ffa6a6;
  border-radius: 5px;
}
@media only screen and (max-device-width: 400px) {
    .error {
      height: 3.1rem !important;
    }
  }
</style>
