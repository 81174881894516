		<template>
			<b-modal
			id="shopify-connector-modal"
			modal-class="shopify-connector-modal"
			size="lg"
			hide-footer
			v-model="isOpen"
			ref="shopifyConnector"
			>
			<template v-slot:modal-header="{ close }">
				<span class="d-block mt-auto fs18 lh28 fw500">
					Shopify Credentials
				</span>
				<b-button variant="rounded" @click="close()">
					<i class="icon-close-variant"></i>
				</b-button>
			</template>
			<div class="mw596 mx-auto">
				<div class="row">
					<div
					class="col pt-30 pb-16 d-flex justify-content-between align-items-center"
					>
					<span class="d-block pt-12 text-gray-600">
						<strong>1.</strong> Click here to 
						<b-link target="_blank" href="https://apps.shopify.com/shyplite-1" class="fs16">
							Install Shopify App
							<i class="icon-arrow-long ml-8 fs12"></i>
						</b-link>
					</span>
				</div>
			</div>

			<div class="row">
				<div class="col py-16">
					<span class="d-block text-gray-600">
						<strong>2.</strong> Click on <strong>Add App</strong> button
					</span>
				</div>
			</div>

			<div class="row">
				<div class="col py-16">
					<span class="d-block text-gray-600">
						<strong>3.</strong> Further, Click on <strong>Install App</strong> button.
					</span>
				</div>
			</div>


			<div class="row">
				<div class="col py-16">
					<span class="d-block text-gray-600">
						<strong>Note</strong> : During installation you may be prompted to login to your Shopify Store.
					</span>
				</div>
			</div>

			<div class="form" v-if="shopify">
				<div class="row">
					<div class="col py-16">
						<span class="d-block text-gray-600">
							App Status : <span class="installed">Installed</span>
						</span>
					</div>
				</div>

				<div class="row">
					<div class="col py-10">
						<span class="d-block text-gray-600">
							Store URL : <strong>{{store}}</strong>
						</span>
					</div>
				</div>


				<b-form @submit.prevent="shopifyConnect()">

					<div class="row">
						<div class="col-md-6 pt-15">
							<b-form-group
							id="seller-id-field"
							label="Store Name"
							label-for="seller-id"
							>
							<b-form-input
							id="seller-id"
							v-model="form.storeName"
							placeholder="Store Name"
							></b-form-input>
							</b-form-group>
						</div>
					</div>	

					<div class="row">
						<div class="col-md-6 py-16">
							<b-form-group label="Get Orders">
								<b-form-radio name="get-orders" value="1" v-model="form.isactive" class="d-inline-block mr-20">
									Enable
								</b-form-radio>
								<b-form-radio name="get-orders" value="0" v-model="form.isactive" class="d-inline-block">
									Disable
								</b-form-radio>
							</b-form-group>
						</div>
						<div class="col-md-6 py-16">
							<b-form-group label="Cancel ON RTO-Received">
								<b-form-radio name="auto-fullfilment" value="1" v-model="form.isrtocancel" class="d-inline-block mr-20">
									On
								</b-form-radio>
								<b-form-radio name="auto-fullfilment" value="0" v-model="form.isrtocancel" class="d-inline-block">
									Off
								</b-form-radio>
							</b-form-group>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6 py-16">
							<b-form-group label="Auto FullFillment">
								<b-form-radio name="get-fulfill" value="1" v-model="form.autoFullFill" class="d-inline-block mr-20">
									Enable
								</b-form-radio>
								<b-form-radio name="get-fulfill" value="0" v-model="form.autoFullFill" class="d-inline-block">
									Disable
								</b-form-radio>
							</b-form-group>
						</div>
						<div class="col-md-6 py-16">
							<b-form-group label="Email Notification">
								<b-form-radio name="notif" value="1" v-model="form.notifyEmail" class="d-inline-block mr-20">
									On
								</b-form-radio>
								<b-form-radio name="notif" value="0" v-model="form.notifyEmail" class="d-inline-block">
									Off
								</b-form-radio>
							</b-form-group>
						</div>
					</div>


					<div class="row">
						<div class="col-md-6 py-16">
							<b-form-group label="COD Remittance">
								<b-form-radio name="get-cod" value="1" v-model="form.codPaid" class="d-inline-block mr-20">
									Enable
								</b-form-radio>
								<b-form-radio name="get-cod" value="0" v-model="form.codPaid" class="d-inline-block">
									Disable
								</b-form-radio>
							</b-form-group>
						</div>
					</div>


					<div class="row">
						<div class="col pt-12 pb-8 d-none d-md-block">

							<b-button type="submit" variant="primary mr-16" size="lg">
								<b-spinner v-if="updating"></b-spinner>
								Update
							</b-button>
							<b-button @click="hideModal" size="lg">Cancel</b-button>
						</div>
						<div class="col pt-12 pb-8 d-flex d-md-none">
							<b-button @click="hideModal"  size="lg">Cancel</b-button>
							<b-button  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
								<b-spinner v-if="updating"></b-spinner>
								Update
							</b-button>
							
						</div>
					</div>

				</b-form>
			</div>
			
		</div>
	</b-modal>
	</template>

	<script>
	export default {
		name: "SettingsShopifyConnector",
		props:{
			editSId : Number
		},
		data() {
			return {
				updating:false,
				isOpen: this.modalOpen,
				form:{},
				shopify:false,
				store:''
			};
		},
		methods: {
			hideModal() {
				this.$bvModal.hide("shopify-connector-modal");
			},
			async formatting(id) {
				try {
					const res = await this.axios.get(`/settings/connector/${id}`)
					const editConn = {
						id:res.data.result[0].id,
						isrtocancel:res.data.result[0].isrtocancel,
						autoFullFill:res.data.result[0].autoFullFill,
						isactive:res.data.result[0].isactive,
						notifyEmail:res.data.result[0].notifyEmail,
						storeName:res.data.result[0].storeName,
						codPaid:res.data.result[0].codPaid
					}
					this.store = res.data.result[0].key4
					this.form = editConn
				} catch(e) {
		        // statements
		        console.log(e);
		    }
		},
		async shopifyConnect(){
			this.updating = true
			let data = Object.assign({}, this.form)
			const editres = await this.axios.post('/settings/connectors/shopify', data)
			try {
				if(editres.data.status){
					this.popToast("booked", "Success!", editres.data.message);
					this.updating = false
					setTimeout(() => {
						this.$router.go()
					},1000)
				} else {
					this.updating = false
					this.popToast("failed", "Failed!", editres.data.error)
				}

			} catch(e) {
				this.updating = false
				this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
				console.log(e);
			}
		}
	},
	watch:{
		isOpen(newValue) {
			if(newValue) {
				let shopifyFlag = false

				this.$root.$on('amazon', data => {
					shopifyFlag = data
				});

				if (this.editSId) {
					this.formatting(this.editSId)
					this.shopify = true
				} else if (shopifyFlag) {
					this.form = {}
					this.shopify = false
				} else {
					this.form = {}
					this.shopify = false
				}
			} else {
				this.$emit('update:editSId', undefined)
			}
		}
	}
	};
	</script>

	<style scoped type="text/scss">

	.installed {
		color: #2a962a;
		font-weight: 600;
	}
	</style>
