var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "info-card" }, [
      _c("li", [
        _c("span", [
          _vm._v(" For "),
          _c("b", [_vm._v("Cheapest Setting")]),
          _vm._v(
            ", Shyplite will assign the cheapest carrier of selected mode for your order. "
          )
        ])
      ]),
      _c("li", [
        _c("span", [
          _vm._v(" For "),
          _c("b", [_vm._v("Priority Setting")]),
          _vm._v(
            ", Shyplite will assign the chosen Priority Carrier for the order's respective mode and zone. If the Priority carrier's service is not available or is Disabled by you, Shyplite will assign the cheapest carrier for selected mode. "
          )
        ])
      ]),
      _c("li", [
        _c("span", [
          _vm._v(" For "),
          _c("b", [_vm._v("Modeless Cheapest Setting")]),
          _vm._v(
            ", Shyplite will assign the cheapest carrier irrespective of selected mode for your order. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }