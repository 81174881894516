<template>
  <b-modal
    id="add-webhook-modal"
    modal-class="add-sku-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block mt-auto fs18 lh28 fw500"> Add Webhook Endpoint </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <div class="mw596 mx-auto">
        <div class="form">
          <b-form @submit.prevent="onSubmit">
            <div class="row pt-30">
              <div class="col">
                <b-form-group id="sku-field" label="Endpoint URL (required)" label-for="endpoint_url">
                  <b-form-input
                    required
                    id="endpoint_url"
                    v-model="form.endpoint"
                    placeholder="- Endpoint URL -"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b-form-group
                  id="item-weight-field"
                  label="Description (optional)"
                  label-for="endpoint_desc"
                >
                  <b-form-textarea
                    v-model="form.description"
                    id="endpoint_desc"
                    placeholder="An optional description of what this webhook endpoint is used for"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <div class="title">
              <div class="row">
              <div class="col-md-8 col">
                <b-form-group id="mode-field" label="Events to Send (required)" label-for="events">
                  <multiselect
                    id="events"
                    :options="options"
                    placeholder="Select Events..."
                    :searchable="true"
                    :multiple="true"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="form.events"
                    @input="selected(form.events)"
                  >
                  <template slot="selection" slot-scope="{ values, search, isOpen }" ><span class="multiselect__single" v-if="!isOpen &&form.events && form.events.length">{{ form.events.length }} options selected</span></template>
                  </multiselect>
                </b-form-group>
              </div>
              <div v-if="form.events && form.events.length" class="col-md-4 my-auto text-right">
                <b-button size="xs" class="mt-10" @click="clearAll">Clear All</b-button>
              </div>
            </div>
            </div>

            <div class="col-md-12 " :class="{ bordered : form.events && form.events.length }">
              <div class="row events_row" v-for="item in form.events" :key="item.id">
                <div class="col-md-8 my-auto">{{ item.name }}</div>
                <div class="col-md-4 text-right pt-10 pb-10">
                  <img @click="removeEvent(item.id)" class="goto mr-5" src="@/assets/svg/cancel.svg">
                </div>
              </div>
            </div>
            

            <div class="row mt-10">
              <div class="col pt-8 text-right">
                <b-button type="submit" variant="primary" size="lg" :disabled="!form.events.length">
                  Add Endpoint
                </b-button>
              </div>
            </div>
          </b-form>
        </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";
import * as _ from 'lodash'

export default {
  name: "WebhookAdd",
  data() {
    return {
      form: {
        events: []
      },
      files: null,
      active: false,
      browseText: "Browse",
      isOpen: this.modalOpen,
      itemAdded: false,
      isbulkactive: false,
      fileUpload: false,
      options: [
        { name: "ORDER", $isDisabled: true },
        { name: "order.created", id: "1" },
        { name: "order.updated", id: "2", $isDisabled: true,description:"Work In Progress" },
        { name: "order.cancelled", id: "3" },
        { name: "order.booked", id: "5" },
        { name: "TRACKING", $isDisabled: true },
        { name: "tracking.pickedup", id: "6" },
        { name: "tracking.returnOrReverse", id: "7" },
        { name: "tracking.outfordelivery", id: "8" },
        { name: "tracking.delivered", id: "9" }
        // { name: "BILLING", $isDisabled: true },
        // { name: "billing.estimatedamount", id: "10" },
        // { name: "billing.estimatedamount", id: "11" },
        // { name: "PAYMENT", $isDisabled: true },
        // { name: "payment.completed", id: "12" },
        // { name: "payment.error", id: "13" },
        // { name: "payment.pending", id: "14" },
        // { name: "payment.failed", id: "15" },
        // { name: "SERVICEABILITY", $isDisabled: true },
        // { name: "serviceability.updated", id: "16" },
      ],
      error:"",
      errorFlag:false,
      errormode:false
    };
  },
  components: {
    Multiselect,
    draggable,
  },
  methods: {
    removeEvent(eventId) {
      // console.log(eventId)
      this.form.events = this.form.events.filter(i => i.id != eventId)
    },
    selected(events) {
      // console.log(events)
    },
    clearAll() {
      if (this.form.events && this.form.events.length) {
        this.form.events = []
      } 
    },
    async onSubmit() {
      const obj = Object.assign({}, this.form)
   
      obj['topics'] = obj.events.map(i => i.name)
      delete obj.events
      
      try {
        const res = await this.axios.put('/add/webhook',obj)
        if (res.data.status) {
          this.popToast("booked", "Success!", "New Webhook Added Successfully");
          let url = `/webhooks/detail/${res.data.body.webhookID}`
          setTimeout(() => {
            this.isOpen = false
            this.$router.push(url)
          },1000)
        } else {
          this.popToast("failed", "Failed!", res.data.body.message);
        }
      } catch(e) {
        this.popToast("failed", "Failed!", "Server Issue!! Please try again Later");
      }
    }
  },
  watch: {
    isOpen: function (newValue) {
      if (newValue) {
        this.form = {
          events : []
        }
      }
    }
  },
};
</script>

<style scoped>
/deep/ .multiselect__tags-wrap {
  display: none;
}
.bordered {
  border: 1px solid darkgrey;
  border-radius: 5px !important;
}
.events_row {
  border-bottom: 1px solid darkgrey;
}
.events_row:last-child {
  border-bottom: unset !important;
}
.goto {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }
.file_upload {
  display: none;
}
.file_upload_active {
  display: block;
}
.title {
  justify-content: space-between !important;
}
.manual_upload_inactive {
  display: none;
}

.error{
  background: #ffa6a6;
  height: 2.1rem;
  color: red;
  border: 1px #ffa6a6;
  border-radius: 5px;
}
</style>
