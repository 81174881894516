var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid pb-80 pb-md-60 pb-lg-0" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-8" }, [
        _c("div", { staticClass: "card profile mb-18" }, [
          _c(
            "div",
            { staticClass: "card__title" },
            [
              _c("span", { staticClass: "fs18 fw500" }, [
                _vm._v(" Tracklite Settings ")
              ]),
              _c(
                "b-link",
                {
                  staticClass: "fs14 lh16",
                  attrs: { href: "https://tracklite.in/", target: "_new" }
                },
                [
                  _vm._v(" Visit Tracklite "),
                  _c("i", { staticClass: "icon-share text-blue ml-4 fs12" })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card__content" },
            [
              _c(
                "b-form",
                {
                  staticClass: "mw596 mx-auto",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitTracklite($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row mt-8 mb-32 mb-md-40" }, [
                    _c("div", { staticClass: "col-md-4 pb-md-0 pb-32" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "rounded border text-center d-inline-block w-sm-100"
                        },
                        [
                          _c("img", {
                            staticClass: "p-44 w-100",
                            attrs: { src: _vm.logo }
                          })
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-8 d-flex flex-column justify-content-center"
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "d-block fs16 lh19 mb-12 fw500" },
                          [_vm._v(" Upload Logo ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "d-block fs14 lh20 mb-20 text-gray-600"
                          },
                          [
                            _vm._v(
                              " The logo size should be at least 96px x 96px and at most 1024px x 1024px. The recommended ratio is 3:1 (288px x 96px). The allowed file formats are "
                            ),
                            _c("code", { pre: true }, [
                              _vm._v("jpg, png, webp and svg.")
                            ])
                          ]
                        ),
                        _c("b-form-file", {
                          ref: "file",
                          staticClass: "d-none",
                          attrs: {
                            accept: ".jpg, .png, .webp, .svg",
                            plain: ""
                          },
                          on: { change: _vm.validate },
                          model: {
                            value: _vm.tracklite.file,
                            callback: function($$v) {
                              _vm.$set(_vm.tracklite, "file", $$v)
                            },
                            expression: "tracklite.file"
                          }
                        }),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "tertiary" },
                            on: { click: _vm.popInput }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tracklite.file
                                    ? _vm.tracklite.file.name
                                    : "Choose File"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.fileerror
                          ? _c("small", { staticClass: "mt-5 text-danger" }, [
                              _vm._v(" " + _vm._s(_vm.fileerror) + " ")
                            ])
                          : _vm._e(),
                        _vm.valid
                          ? _c("small", { staticClass: "mt-5 text-orange" }, [
                              _vm._v(" " + _vm._s(_vm.valid) + " ")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mb-24 mb-md-16" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-24 mb-md-0" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0 w-100",
                            attrs: {
                              id: "brand-name-field",
                              label: "Brand Name",
                              "label-for": "brand"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "brand",
                                    type: "text",
                                    minlength: "3",
                                    maxlength: "30",
                                    trim: "",
                                    placeholder:
                                      "Must be less than and equal to 30 characters"
                                  },
                                  model: {
                                    value: _vm.tracklite.brandName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.tracklite, "brandName", $$v)
                                    },
                                    expression: "tracklite.brandName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0 w-100",
                            attrs: {
                              id: "website-field",
                              label: "Your Website URL",
                              "label-for": "website"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "website",
                                    type: "url",
                                    maxlength: "250",
                                    placeholder: "https://example.com",
                                    pattern:
                                      "^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$"
                                  },
                                  model: {
                                    value: _vm.tracklite.website,
                                    callback: function($$v) {
                                      _vm.$set(_vm.tracklite, "website", $$v)
                                    },
                                    expression: "tracklite.website"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-text" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-url fs16 text-gray-600"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mb-24 mb-md-16" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0 w-100",
                            attrs: {
                              id: "support-email-field",
                              label: "Support Email",
                              "label-for": "support-email"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "support-email",
                                    type: "email",
                                    placeholder: "support@example.com",
                                    pattern:
                                      "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                                    autocomplete: "off"
                                  },
                                  model: {
                                    value: _vm.tracklite.supportEmail,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tracklite,
                                        "supportEmail",
                                        $$v
                                      )
                                    },
                                    expression: "tracklite.supportEmail"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-text" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-mail fs16 text-gray-600"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mb-24 mb-md-16" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0 w-100",
                            attrs: {
                              id: "support-contact-field",
                              label: "Support Contact",
                              "label-for": "support-contact"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  ref: "supportContact",
                                  attrs: {
                                    id: "support-contact",
                                    type: "tel",
                                    maxlength: "10",
                                    placeholder: "8888888888",
                                    oninvalid:
                                      "this.setCustomValidity('Enter valid mobile number.')",
                                    oninput: "this.setCustomValidity('')",
                                    pattern: "^[6-9]{1}[0-9]{9}$"
                                  },
                                  model: {
                                    value: _vm.tracklite.supportContact,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tracklite,
                                        "supportContact",
                                        $$v
                                      )
                                    },
                                    expression: "tracklite.supportContact"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-text" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-phone fs16 text-gray-600"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mb-24 mb-md-16" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0 w-100",
                            attrs: {
                              id: "url-tag-field",
                              label: "Custom URL Tag",
                              "label-for": "url-tag"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    minlength: "3",
                                    maxlength: "30",
                                    formatter: _vm.removeSpace,
                                    trim: "",
                                    placeholder:
                                      "Must be less than and equal to 30 characters"
                                  },
                                  on: {
                                    keyup: function($event) {
                                      return _vm.validateCustomUrlTag(
                                        _vm.tracklite.urltagtracklite
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.tracklite.urltagtracklite,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tracklite,
                                        "urltagtracklite",
                                        $$v
                                      )
                                    },
                                    expression: "tracklite.urltagtracklite"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-text" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              size: "xxs",
                                              variant: "tertiary"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.copyUrl($event)
                                              }
                                            }
                                          },
                                          [_vm._v("Copy")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("input", {
                          attrs: { type: "hidden", id: "url-tag" },
                          domProps: {
                            value:
                              "https://tracklite.in/c/" +
                              _vm.tracklite.urltagtracklite
                          }
                        }),
                        _c("small", [
                          _vm._v(
                            "https://tracklite.in/c/" +
                              _vm._s(_vm.tracklite.urltagtracklite)
                          )
                        ]),
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.customURLTag) }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mb-24 mb-md-16" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "accept-return-field",
                              label: "Accept Return For",
                              "label-for": "accept-return"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    min: "0",
                                    max: "180",
                                    id: "accept-return",
                                    placeholder: "No of days",
                                    type: "number"
                                  },
                                  model: {
                                    value: _vm.tracklite.returnDays,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tracklite,
                                        "returnDays",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "tracklite.returnDays"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6 my-auto" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "ml-10",
                            attrs: { label: "Logo Location (Mobile only)" }
                          },
                          [
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "d-inline-block mr-20",
                                attrs: { name: "get-orders", value: "0" },
                                model: {
                                  value: _vm.tracklite.profileImageLocation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tracklite,
                                      "profileImageLocation",
                                      $$v
                                    )
                                  },
                                  expression: "tracklite.profileImageLocation"
                                }
                              },
                              [_vm._v(" Left ")]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "d-inline-block mr-15",
                                attrs: { name: "get-orders", value: "1" },
                                model: {
                                  value: _vm.tracklite.profileImageLocation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tracklite,
                                      "profileImageLocation",
                                      $$v
                                    )
                                  },
                                  expression: "tracklite.profileImageLocation"
                                }
                              },
                              [_vm._v(" Center ")]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "d-inline-block",
                                attrs: { name: "get-orders", value: "2" },
                                model: {
                                  value: _vm.tracklite.profileImageLocation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tracklite,
                                      "profileImageLocation",
                                      $$v
                                    )
                                  },
                                  expression: "tracklite.profileImageLocation"
                                }
                              },
                              [_vm._v(" Right ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mb-24 mb-md-16" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Left Color",
                              "label-for": "rightColor"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "rightColor", type: "color" },
                              model: {
                                value: _vm.tracklite.sellerBrandColors[2],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tracklite.sellerBrandColors,
                                    2,
                                    $$v
                                  )
                                },
                                expression: "tracklite.sellerBrandColors[2]"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Center Color",
                              "label-for": "centerColor"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "centerColor", type: "color" },
                              model: {
                                value: _vm.tracklite.sellerBrandColors[1],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tracklite.sellerBrandColors,
                                    1,
                                    $$v
                                  )
                                },
                                expression: "tracklite.sellerBrandColors[1]"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Right Color",
                              "label-for": " leftColor"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "leftColor", type: "color" },
                              model: {
                                value: _vm.tracklite.sellerBrandColors[0],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tracklite.sellerBrandColors,
                                    0,
                                    $$v
                                  )
                                },
                                expression: "tracklite.sellerBrandColors[0]"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-sm-100",
                      attrs: {
                        type: "submit",
                        disabled: _vm.submitting || _vm.saveBtn,
                        variant: "primary"
                      }
                    },
                    [
                      _vm._v(" Save "),
                      _vm.submitting
                        ? _c("b-spinner", { staticClass: "ml-10" })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "card profile mb-18" }, [
          _c(
            "div",
            { staticClass: "card__title" },
            [
              _c("span", { staticClass: "fs18 fw500" }, [
                _vm._v("Label Settings "),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right",
                      modifiers: { hover: true, right: true }
                    }
                  ],
                  staticClass: "icon-disclaimer fs12 text-gray-400",
                  attrs: { title: "For more information visit FAQ section." }
                })
              ]),
              _c(
                "b-link",
                {
                  staticClass: "fs14 lh16",
                  attrs: {
                    href:
                      "https://faq.shyplite.com/1004-understanding-customization-settings/",
                    target: "_new"
                  }
                },
                [
                  _vm._v(" Read FAQs "),
                  _c("i", { staticClass: "icon-share text-blue ml-4 fs12" })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card__content" },
            [
              _c(
                "b-form",
                {
                  staticClass: "mw596 mx-auto",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitLabels($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row mt-30" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-10" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.labels.hideCustomerContact,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "hideCustomerContact",
                                      $$v
                                    )
                                  },
                                  expression: "labels.hideCustomerContact"
                                }
                              },
                              [_vm._v("Hide Customer Contact")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col d-flex p-0 fs12 text-gray-600" },
                          [
                            _c("strong", [_vm._v("NOTE :")]),
                            _c("div", { staticClass: "ml-5" }, [
                              _vm._v("This setting is applicable only for "),
                              _c("strong", [_vm._v("Delhivery")]),
                              _vm._v(" and "),
                              _c("strong", [_vm._v("Xpressbees")])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mt-50" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-10" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.labels.hideProduct,
                                  callback: function($$v) {
                                    _vm.$set(_vm.labels, "hideProduct", $$v)
                                  },
                                  expression: "labels.hideProduct"
                                }
                              },
                              [_vm._v("Hide Product Value and Description")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col d-flex p-0 fs12 text-gray-600" },
                          [
                            _c("strong", [_vm._v("NOTE :")]),
                            _c("div", { staticClass: "ml-5" }, [
                              _vm._v("This setting will hide all the "),
                              _c("strong", [_vm._v("item names")]),
                              _vm._v(" with its corresponding "),
                              _c("strong", [_vm._v("amount")]),
                              _vm._v(" details from the label")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mt-50" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-10" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.labels.hideRTOaddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.labels, "hideRTOaddress", $$v)
                                  },
                                  expression: "labels.hideRTOaddress"
                                }
                              },
                              [_vm._v("Hide Pickup Address")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col d-flex p-0 fs12 text-gray-600" },
                          [
                            _c("strong", [_vm._v("NOTE :")]),
                            _c("div", { staticClass: "ml-5" }, [
                              _vm._v(
                                "This setting is applicable for all carriers except "
                              ),
                              _c("strong", [_vm._v("FedEx")]),
                              _vm._v(" and "),
                              _c("strong", [_vm._v("SWA")])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.labels.hideRTOaddress == false
                    ? _c("div", { staticClass: "row mt-20" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 p-0" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: {
                                      name: "print-contact",
                                      value: "0"
                                    },
                                    model: {
                                      value: _vm.labels.hideWarehouseContact,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.labels,
                                          "hideWarehouseContact",
                                          $$v
                                        )
                                      },
                                      expression: "labels.hideWarehouseContact"
                                    }
                                  },
                                  [_vm._v("Print Pickup Address Contact")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 p-0" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: {
                                      name: "print-contact",
                                      value: "1"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detectSupport(
                                          _vm.labels.hideWarehouseContact
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.labels.hideWarehouseContact,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.labels,
                                          "hideWarehouseContact",
                                          $$v
                                        )
                                      },
                                      expression: "labels.hideWarehouseContact"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Print Support Contact " +
                                        _vm._s(
                                          _vm.tracklite.supportContact
                                            ? "(" +
                                                _vm.tracklite.supportContact +
                                                ")"
                                            : ""
                                        )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "mt-20" }, [
                    _c("strong", [_vm._v("Additional Documents")])
                  ]),
                  _c("div", { staticClass: "row mt-25" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-10" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.labels.showPackingList,
                                  callback: function($$v) {
                                    _vm.$set(_vm.labels, "showPackingList", $$v)
                                  },
                                  expression: "labels.showPackingList"
                                }
                              },
                              [_vm._v(" Print Packing List ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col d-flex p-0 fs12 text-gray-600" },
                          [
                            _c("strong", [_vm._v("NOTE :")]),
                            _c("div", { staticClass: "ml-5" }, [
                              _vm._v(
                                'This setting will enable you a packing list along with label, if there are "+more" items in the product list.'
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row mt-40" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12 d-flex align-items-center mt-5"
                      },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-10" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.labels.showInvoice,
                                  callback: function($$v) {
                                    _vm.$set(_vm.labels, "showInvoice", $$v)
                                  },
                                  expression: "labels.showInvoice"
                                }
                              },
                              [_vm._v(" Print Invoice ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col d-flex p-0 fs12 text-gray-600 ml-5" },
                      [
                        _c("strong", [_vm._v("NOTE :")]),
                        _c("div", { staticClass: "ml-5" }, [
                          _vm._v("This is not a GST compliance tax invoice.")
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row mt-20" }, [
                    _vm.labels.showInvoice == true
                      ? _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c("b-form-input", {
                              staticClass: "mt-1",
                              attrs: {
                                placeholder: "Invoice Prefix",
                                title: "Invoice Prefix"
                              },
                              model: {
                                value: _vm.labels.invoicePrefix,
                                callback: function($$v) {
                                  _vm.$set(_vm.labels, "invoicePrefix", $$v)
                                },
                                expression: "labels.invoicePrefix"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.labels.invoicePrefix != "" &&
                    _vm.labels.showInvoice == true
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-md-8 d-flex align-items-center text-gray-600"
                          },
                          [
                            _c("strong", [_vm._v("Invoice Number : ")]),
                            _vm._v(
                              " " + _vm._s(_vm.labels.invoicePrefix) + " - 1234"
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm.labels.showInvoice == true
                    ? _c("div", { staticClass: "row mt-20" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 p-0" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", name: "default" },
                                    model: {
                                      value: _vm.labels.invoicingAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.labels,
                                          "invoicingAddress",
                                          $$v
                                        )
                                      },
                                      expression: "labels.invoicingAddress"
                                    }
                                  },
                                  [_vm._v("Print Pickup Address")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 p-0" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", name: "default" },
                                    model: {
                                      value: _vm.labels.invoicingAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.labels,
                                          "invoicingAddress",
                                          $$v
                                        )
                                      },
                                      expression: "labels.invoicingAddress"
                                    }
                                  },
                                  [_vm._v("Print Default Billing Address")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mt-35" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "w-sm-100",
                          attrs: {
                            type: "submit",
                            disabled: _vm.submittingLabels,
                            variant: "primary"
                          }
                        },
                        [
                          _vm._v(" Save "),
                          _vm.submittingLabels
                            ? _c("b-spinner", { staticClass: "ml-10" })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "card profile mb-18" }, [
          _c("div", { staticClass: "card__title" }, [
            _c("span", { staticClass: "fs18 fw500" }, [
              _vm._v("Notification Settings "),
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.right",
                    modifiers: { hover: true, right: true }
                  }
                ],
                staticClass: "icon-disclaimer fs12 text-gray-400",
                attrs: { title: "Notification Settings" }
              })
            ])
          ]),
          _c("div", { staticClass: "card__content" }, [
            _c("div", { staticClass: "mw596 mx-auto" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-content-between mt-20 mb-20"
                  },
                  [
                    _vm._m(0),
                    _c("b-form-checkbox", {
                      staticClass: "d-inline",
                      attrs: {
                        name: "default-pickup",
                        value: "enable",
                        "unchecked-value": "disable",
                        switch: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateNotification(
                            $event,
                            "reconciliation"
                          )
                        }
                      },
                      model: {
                        value: _vm.reconciliation,
                        callback: function($$v) {
                          _vm.reconciliation = $$v
                        },
                        expression: "reconciliation"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-content-between  mt-20 mb-20"
                  },
                  [
                    _vm._m(1),
                    _c("b-form-checkbox", {
                      staticClass: "d-inline",
                      attrs: {
                        name: "default-pickup",
                        value: "enable",
                        "unchecked-value": "disable",
                        switch: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateNotification($event, "importOrder")
                        }
                      },
                      model: {
                        value: _vm.importOrder,
                        callback: function($$v) {
                          _vm.importOrder = $$v
                        },
                        expression: "importOrder"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-content-between  mt-20 mb-20"
                  },
                  [
                    _vm._m(2),
                    _c("b-form-checkbox", {
                      staticClass: "d-inline",
                      attrs: {
                        name: "default-pickup",
                        value: "enable",
                        "unchecked-value": "disable",
                        switch: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateNotification($event, "ndr")
                        }
                      },
                      model: {
                        value: _vm.ndr,
                        callback: function($$v) {
                          _vm.ndr = $$v
                        },
                        expression: "ndr"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { attrs: { id: "notif-detail" } })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fs14 lh16 text-gray-600 mr-20" }, [
      _c("strong", [_vm._v("Reconciliation")]),
      _vm._v(" (Notify when new orders are uploaded to reconcile)")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fs14 lh16 text-gray-600 mr-20" }, [
      _c("strong", [_vm._v("Import Orders")]),
      _vm._v(" (Notify when new orders are imported from your connectors)")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fs14 lh16 text-gray-600 mr-20" }, [
      _c("strong", [_vm._v("NDR")]),
      _vm._v(" (Notify when new NDR reports are generated)")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }