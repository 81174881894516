var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid pb-80 pb-md-60 pb-lg-0" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-8" },
        [
          _c("b-skeleton-wrapper", {
            attrs: { loading: _vm.isBusy },
            scopedSlots: _vm._u([
              {
                key: "loading",
                fn: function() {
                  return [
                    _c("b-card", { staticClass: "mb-12" }, [
                      _c(
                        "div",
                        { staticClass: "card service content-active" },
                        [
                          _c(
                            "div",
                            { staticClass: "card__title" },
                            [
                              _c("b-skeleton", {
                                staticClass: "p-15",
                                attrs: { width: "25%" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card__content" },
                            [
                              _c("b-skeleton", {
                                staticClass: "mb-10",
                                attrs: { width: "85%" }
                              }),
                              _c("b-skeleton", {
                                staticClass: "mb-10",
                                attrs: { width: "55%" }
                              }),
                              _c("b-skeleton", {
                                staticClass: "mb-10",
                                attrs: { width: "70%" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _c("b-card", { staticClass: "mb-12" }, [
                      _c(
                        "div",
                        { staticClass: "card service content-active" },
                        [
                          _c(
                            "div",
                            { staticClass: "card__title" },
                            [
                              _c("b-skeleton", {
                                staticClass: "p-15",
                                attrs: { width: "20%" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card__content" },
                            [
                              _c("b-skeleton", {
                                staticClass: "mb-10",
                                attrs: { width: "85%" }
                              }),
                              _c("b-skeleton", {
                                staticClass: "mb-10",
                                attrs: { width: "55%" }
                              }),
                              _c("b-skeleton", {
                                staticClass: "mb-10",
                                attrs: { width: "70%" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._l(_vm.carriers, function(carrier) {
            return _c("div", { key: carrier.carrierID, staticClass: "mb-12" }, [
              carrier.status
                ? _c(
                    "div",
                    {
                      staticClass: "card service",
                      class: { "content-active": carrier.content }
                    },
                    [
                      _c("div", { staticClass: "card__title" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("img", {
                            staticClass: "service-logo",
                            attrs: {
                              src: require("@/assets/images/" +
                                carrier.carrierCode +
                                ".png"),
                              alt: carrier.carrierCode
                            }
                          }),
                          _c(
                            "span",
                            { staticClass: "fs20 lh24 fw500 ml-10 ml-md-16" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("capitalize")(carrier.carrierName)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "float-right",
                                attrs: { variant: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.disableCarrier(
                                      carrier.carrierName,
                                      carrier.carrierCode,
                                      carrier.modeData,
                                      carrier.carrierenabledisable &&
                                        carrier.carrierenabledisable == 1
                                        ? 1
                                        : 0
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    carrier.carrierenabledisable &&
                                      carrier.carrierenabledisable == 1
                                      ? "Disable"
                                      : "Enable"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "card__content" }, [
                        _c("div", { staticClass: "mw596 mx-auto" }, [
                          _c(
                            "ul",
                            { staticClass: "service" },
                            _vm._l(carrier.modeData, function(mode, index) {
                              return _c("div", { key: index }, [
                                mode.status < 2
                                  ? _c(
                                      "li",
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              disabled: carrier.disabled
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(mode.name) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "b-form-group",
                                          { staticClass: "mb-0" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block text-gray-900 w20",
                                                class: {
                                                  disabled: carrier.disabled
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    mode.status > 0 ? "" : "Off"
                                                  ) + " "
                                                )
                                              ]
                                            ),
                                            _c("b-form-checkbox", {
                                              staticClass:
                                                "d-inline mr-20 ml-20",
                                              attrs: {
                                                "unchecked-value": "1",
                                                value: "0",
                                                name: "default-pickup",
                                                disabled: carrier.disabled,
                                                switch: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.onChange(
                                                    mode,
                                                    carrier.carrierCode,
                                                    carrier.carrierName
                                                  )
                                                }
                                              },
                                              model: {
                                                value: mode.status,
                                                callback: function($$v) {
                                                  _vm.$set(mode, "status", $$v)
                                                },
                                                expression: "mode.status"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block text-gray-900 w20",
                                                class: {
                                                  disabled: carrier.disabled
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    mode.status > 0 ? "On" : ""
                                                  ) + " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4" }, [
      _c(
        "div",
        { staticClass: "card content-active sticky-top mt-10 top100" },
        [
          _c("div", { staticClass: "card__content pt-36 pb-40 px-24" }, [
            _c("img", {
              attrs: { src: require("@/assets/svg/cube.svg"), alt: "" }
            }),
            _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-20" }, [
              _vm._v(" How carrier modes work? ")
            ]),
            _c(
              "span",
              { staticClass: "d-block fs14 lh20 text-gray-800 mt-8" },
              [
                _vm._v(
                  " Disabling a carrier modes, disables the service for all order types (Prepaid, COD & Reverse) for that carrier. "
                )
              ]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }