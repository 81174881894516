var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid position-relative" },
    [
      !_vm.filterResultsToggle
        ? _c("transition", { attrs: { name: "slide-left" } }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex pb-22 align-items-center justify-content-between"
              },
              [
                _c("span", { staticClass: "fw700 pb-md-0" }),
                _c(
                  "span",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.left",
                            modifiers: { hover: true, left: true }
                          }
                        ],
                        attrs: { variant: "primary" },
                        on: { click: _vm.addNewUser }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "editsvg mr-5",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 640 512"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z"
                              }
                            })
                          ]
                        ),
                        _vm._v(" Add New Consignor")
                      ]
                    ),
                    _c("div", {
                      staticClass: "divider mx-20 my-auto vam d-md-flex"
                    }),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.sidebarFilters",
                            modifiers: { sidebarFilters: true }
                          }
                        ],
                        staticClass: "ndr-side-btn text-gray-900"
                      },
                      [
                        _c("span", [_vm._v(" Filters ")]),
                        _c("i", {
                          staticClass: "icon-filter text-gray-600 pl-10"
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.filterResultsToggle
          ? _c(
              "div",
              {
                staticClass:
                  "col pb-22 pt-md-20 pt-20 d-flex align-items-center justify-content-between"
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    this.selFilters.length != "0"
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "d-none d-md-flex mr-6 text-gray-600 wsnw"
                          },
                          [_vm._v(" Results for: ")]
                        )
                      : _vm._e(),
                    _c(
                      "ul",
                      { staticClass: "d-none d-md-flex result-list" },
                      _vm._l(_vm.selFilters, function(selFilter, index) {
                        return _c(
                          "li",
                          { key: index },
                          [
                            index != "filtering" && selFilter != ""
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mx-4 my-1",
                                    attrs: { variant: "tag" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "wsnw text-truncate" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              typeof selFilter == "number"
                                                ? _vm.modeTypes[selFilter]
                                                : selFilter
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "icon-close fs6",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFilter(index)
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    this.selFilters.length != "0"
                      ? _c("span", { staticClass: "d-flex d-md-none" }, [
                          _vm._v(
                            " " +
                              _vm._s(this.selFilters.length) +
                              " Filters selected. "
                          )
                        ])
                      : _c("span", { staticClass: "d-flex d-md-none" }, [
                          _vm._v(" No filters selected. ")
                        ]),
                    _c(
                      "b-link",
                      {
                        staticClass: "ml-24 mr-12 vam",
                        on: {
                          click: function($event) {
                            return _vm.clearFilters()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "text-gray-900 wsnw" }, [
                          _vm._v("Clear filters")
                        ]),
                        _c("i", {
                          staticClass:
                            "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.sidebarFilters",
                            modifiers: { sidebarFilters: true }
                          }
                        ],
                        staticClass: "ndr-side-btn text-gray-900"
                      },
                      [
                        _c("span", [_vm._v(" Filters ")]),
                        _c("i", {
                          staticClass: "icon-filter text-gray-600 pl-10"
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "warehouse-table billing-table consignor-table",
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  "sticky-header": "",
                  "no-border-collapse": "",
                  id: "manifests-table"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(name)",
                      fn: function(data) {
                        return [
                          _c("span", { attrs: { title: data.item.name } }, [
                            _vm._v(
                              _vm._s(
                                _vm.ellipsize(
                                  _vm.capitalizeFirstLetter(data.item.name)
                                )
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(bookingType)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.capitalizeFirstLetter(data.item.bookingType)
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(csbType)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.capitalizeFirstLetter(data.item.csbType)
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "span",
                              { staticClass: "text-truncated" },
                              [
                                _c("b-form-checkbox", {
                                  staticClass: "d-inline",
                                  attrs: { name: "default-pickup", switch: "" },
                                  on: {
                                    change: function($event) {
                                      return _vm.userStatus(
                                        data.item.id,
                                        data.item.status
                                      )
                                    }
                                  },
                                  model: {
                                    value: data.item.status,
                                    callback: function($$v) {
                                      _vm.$set(data.item, "status", $$v)
                                    },
                                    expression: "data.item.status"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "fs12 mt-2 lh16 text-gray-600 text-truncate"
                              },
                              [
                                data.item.status
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#65d678" } },
                                      [_vm._v("Active")]
                                    )
                                  : _c("span", [_vm._v("Inactive")])
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(action)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary btn-xs" },
                              on: {
                                click: function($event) {
                                  return _vm.editConsignor(data.item.id)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "editsvg mr-5",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 512 512"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" Edit ")
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2324841246
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.items, function(item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between w-100" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "d-block fw500 pb-8 cp",
                        on: {
                          click: function($event) {
                            return _vm.goShipment(item.id)
                          }
                        }
                      },
                      [_vm._v(" Consignor : " + _vm._s(item.name) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-end justify-content-between"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary btn-xs" },
                            on: {
                              click: function($event) {
                                return _vm.editConsignor(item.id)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "editsvg mr-5",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 512 512"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" Edit ")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-end justify-content-between w-100 mb-12"
                  },
                  [
                    _c("div", { staticClass: "info" }, [
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(" Consignor id : " + _vm._s(item.id) + " ")
                      ]),
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(" " + _vm._s(item.phone) + " ")
                      ]),
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(" " + _vm._s(item.csbType) + " ")
                      ])
                    ])
                  ]
                ),
                _c("span", [_vm._v(" " + _vm._s(item.pickupAddress) + " ")]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 align-items-end justify-content-between"
                  },
                  [
                    _c("span"),
                    _c("span", { class: item.status }, [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "span",
                          { staticClass: "text-truncated" },
                          [
                            _c("b-form-checkbox", {
                              staticClass: "d-inline",
                              attrs: { name: "default-pickup", switch: "" },
                              on: {
                                change: function($event) {
                                  return _vm.userStatus(item.id, item.status)
                                }
                              },
                              model: {
                                value: item.status,
                                callback: function($$v) {
                                  _vm.$set(item, "status", $$v)
                                },
                                expression: "item.status"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs12 mt-2 lh16 text-gray-600 text-truncate"
                          },
                          [
                            item.status
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#65d678" } },
                                  [_vm._v("Enabled")]
                                )
                              : _c("span", [_vm._v("Disabled")])
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            }),
            0
          ),
      _c("div", { staticClass: "pagination-bar" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c(
                "div",
                { staticClass: "d-inline-flex wsnw align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-block w50px" },
                    [
                      _c("multiselect", {
                        staticClass: "pagination-items__pp",
                        attrs: {
                          searchable: false,
                          "show-labels": false,
                          "allow-empty": false,
                          options: _vm.pageOptions
                        },
                        on: { input: _vm.setPageNumber },
                        model: {
                          value: _vm.perPage,
                          callback: function($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "pl-8 fs12 lh24 text-gray-600 pagination-items"
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "col-8 text-right" }, [
              _c(
                "div",
                {
                  staticClass: "d-inline-flex ml-auto wsnw align-items-center"
                },
                [
                  _c("p", { staticClass: "fs12 lh24 text-gray-600 pr-14" }, [
                    _vm._v(" Page: " + _vm._s(_vm.currentPage) + " ")
                  ]),
                  _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.prevDisabled,
                            variant: "paginate left"
                          },
                          on: { click: _vm.prevPage }
                        },
                        [_c("i", { staticClass: "icon-back fs12" })]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.nextDisabled,
                            variant: "paginate right"
                          },
                          on: { click: _vm.nextPage }
                        },
                        [_c("i", { staticClass: "icon-arrow fs12" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm.items.length == 0 && !_vm.isBusy ? _c("ResultNotFound") : _vm._e(),
      _c("ConsignorFilter", { ref: "filter" }),
      _c("AddNewConsignor", {
        ref: "addNewConsignor",
        attrs: {
          editUserflag: _vm.editUserflag,
          opencloseflag: _vm.opencloseflag,
          editId: _vm.editId
        },
        on: {
          "update:editUserflag": function($event) {
            _vm.editUserflag = $event
          },
          "update:edit-userflag": function($event) {
            _vm.editUserflag = $event
          },
          "update:opencloseflag": function($event) {
            _vm.opencloseflag = $event
          },
          "update:editId": function($event) {
            _vm.editId = $event
          },
          "update:edit-id": function($event) {
            _vm.editId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }