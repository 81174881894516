<template>
  <div class="manifests">
    <ConsignorTable></ConsignorTable>
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import ConsignorTable from "@/views/consignor/ConsignorTable.vue";
export default {
  name: "Consignor",
  title: "Shyplite - Consignor",
  components: {
    AppTopbar,
    ConsignorTable,
  },
  created() {
  	if (this.$store.state.global.showConsignor == false || this.$store.state.global.showConsignorrequest == false) {
  		this.$router.push('/**')
  	}
  }
};
</script>
