var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "add-warehouse-modal",
        "modal-class": "add-sku-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _vm.editId
                ? _c(
                    "span",
                    { staticClass: "d-block mt-auto fs18 lh28 fw500" },
                    [_vm._v(" Edit Warehouse ")]
                  )
                : _c(
                    "span",
                    { staticClass: "d-block mt-auto fs18 lh28 fw500" },
                    [_vm._v(" Add Warehouse ")]
                  ),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "b-form",
        {
          staticClass: "mw596 mx-auto",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "row underline-dashed" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 py-40" },
              [
                _c("span", { staticClass: "fs14 lh16 text-gray-600 mr-20" }, [
                  _vm._v(" Default Pickup ")
                ]),
                _c("b-form-checkbox", {
                  staticClass: "d-inline mr-40",
                  attrs: { name: "default-pickup", switch: "" },
                  on: {
                    change: function($event) {
                      return _vm.checkPickup(_vm.form.defaultPickup)
                    }
                  },
                  model: {
                    value: _vm.form.defaultPickup,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "defaultPickup", $$v)
                    },
                    expression: "form.defaultPickup"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 py-40" },
              [
                _c("span", { staticClass: "fs14 lh16 text-gray-600 mr-20" }, [
                  _vm._v(" Default Billing ")
                ]),
                _c("b-form-checkbox", {
                  staticClass: "d-inline",
                  attrs: { name: "default-pickup", switch: "" },
                  on: {
                    change: function($event) {
                      return _vm.checkBilling(_vm.form.defaultBilling)
                    }
                  },
                  model: {
                    value: _vm.form.defaultBilling,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "defaultBilling", $$v)
                    },
                    expression: "form.defaultBilling"
                  }
                })
              ],
              1
            ),
            _vm.errorText
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-12 col-md-6 py-10 text-gray-600",
                    staticStyle: { color: "#fa5a5a !important" }
                  },
                  [
                    _c("strong", [_vm._v("NOTE")]),
                    _vm._v(" : " + _vm._s(_vm.errorMsg))
                  ]
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "row pt-36" }, [
            _c(
              "div",
              { staticClass: "col-md-3 col-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "address-name-field",
                      label: "Address Name *",
                      "label-for": "address-name"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "address-name",
                        maxlength: "35",
                        required: "",
                        placeholder: "Address Name"
                      },
                      model: {
                        value: _vm.form.addressName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "addressName", $$v)
                        },
                        expression: "form.addressName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 col-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "vendor-name-field",
                      label: "Vendor Name",
                      "label-for": "vendor-name"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "vendor-name",
                        placeholder: "Vendor Name",
                        maxlength: "35"
                      },
                      model: {
                        value: _vm.form.vendorName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "vendorName", $$v)
                        },
                        expression: "form.vendorName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 col-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "pin-code-field",
                      label: "Pin Code *",
                      "label-for": "pin-code"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "pin-code",
                        placeholder: "Type Pin",
                        required: "",
                        formatter: _vm.numbers,
                        maxlength: "6",
                        minlength: "6",
                        state: _vm.pincode
                      },
                      on: {
                        keyup: function($event) {
                          return _vm.mobileValidation(
                            $event.target.value,
                            "pin"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.sellerPinCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sellerPinCode", $$v)
                        },
                        expression: "form.sellerPinCode"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 col-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "city-field",
                      label: "City *",
                      "label-for": "city"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "city",
                        required: "",
                        placeholder: "City",
                        maxlength: "35"
                      },
                      model: {
                        value: _vm.form.sellerCity,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sellerCity", $$v)
                        },
                        expression: "form.sellerCity"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-4 col-12 pt-8" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "contact-name-field",
                      label: "Contact Name *",
                      "label-for": "contact-name"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "contact-name",
                        maxlength: "35",
                        required: "",
                        placeholder: "Contact Name"
                      },
                      model: {
                        value: _vm.form.contactName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "contactName", $$v)
                        },
                        expression: "form.contactName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4 col-12 pt-8" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "contact-one-field",
                      label: "Contact No.1 *",
                      "label-for": "contact-one"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "contact-one",
                        formatter: _vm.numbers,
                        maxlength: "10",
                        minlength: "10",
                        required: "",
                        placeholder: "Contact Number",
                        state: _vm.isMobile
                      },
                      on: {
                        keyup: function($event) {
                          return _vm.mobileValidation(
                            $event.target.value,
                            "contact1"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.contactNumber1,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "contactNumber1", $$v)
                        },
                        expression: "form.contactNumber1"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4 col-12 pt-8" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "contact-two-field",
                      label: "Contact No.2",
                      "label-for": "contact-two"
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "contact-two",
                        pattern: "[6-9]{1}[0-9]{9}",
                        oninvalid:
                          "this.setCustomValidity('Enter valid mobile number.')",
                        oninput: "this.setCustomValidity('')",
                        formatter: _vm.numbers,
                        maxlength: "10",
                        minlength: "10",
                        placeholder: "Contact Number",
                        state: _vm.isMobile1
                      },
                      on: {
                        keyup: function($event) {
                          return _vm.mobileValidation(
                            $event.target.value,
                            "contact2"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.contactNumber2,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "contactNumber2", $$v)
                        },
                        expression: "form.contactNumber2"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col pt-8" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "address-field",
                      label: "Address *",
                      "label-for": "address",
                      description:
                        "Characters: " +
                        _vm.form.sellerAddressLine1.length +
                        " / " +
                        _vm.maxLength
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        id: "address",
                        required: "",
                        disabled: _vm.doEdit,
                        placeholder: "Add Address",
                        maxlength: _vm.maxLength,
                        rows: "3",
                        "max-rows": "6",
                        required: ""
                      },
                      model: {
                        value: _vm.form.sellerAddressLine1,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sellerAddressLine1", $$v)
                        },
                        expression: "form.sellerAddressLine1"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm.form.hyperlocal
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col py-12" })
              ])
            : _vm._e(),
          _vm.sent
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-4 col-12 pt-8" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "otp-field",
                          label: "Enter OTP *",
                          "label-for": "otp"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            id: "otp",
                            placeholder: "- OTP -",
                            autofocus: "",
                            required: "",
                            formatter: _vm.maxsix
                          },
                          model: {
                            value: _vm.form.otp,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "otp", $$v)
                            },
                            expression: "form.otp"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-4 col-12 pt-30" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-16",
                        attrs: {
                          variant: "primary",
                          disabled: !_vm.resend,
                          size: "lg"
                        },
                        on: { click: _vm.resendOtp }
                      },
                      [_vm._v(" " + _vm._s(_vm.btns.resend))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col pt-20 d-none d-md-flex" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-16",
                    attrs: {
                      type: "submit",
                      disabled: _vm.submitting || !_vm.isMobile || !_vm.pincode,
                      variant: "primary",
                      size: "lg"
                    }
                  },
                  [
                    _vm.sent
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.btns.verify) + " ")
                        ])
                      : _c("span", [_vm._v(" " + _vm._s(_vm.btns.otp) + " ")]),
                    _vm.submitting
                      ? _c("b-spinner", { staticClass: "ml-10" })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "secondary", size: "lg" },
                    on: { click: _vm.hideModal }
                  },
                  [_vm._v(" Cancel ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col pt-20 d-flex d-md-none" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "secondary", size: "lg" },
                    on: { click: _vm.hideModal }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-16 w-sm-100",
                    attrs: {
                      type: "submit",
                      variant: "primary",
                      size: "lg",
                      disabled: _vm.submitting || !_vm.isMobile || !_vm.pincode
                    }
                  },
                  [
                    _vm.sent
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.btns.verify) + " ")
                        ])
                      : _c("span", [_vm._v(" " + _vm._s(_vm.btns.otp) + " ")])
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }