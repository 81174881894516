var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "priority" }, [
    _c(
      "div",
      { staticClass: "container-fluid" },
      [
        _c("div", { staticClass: "card" }, [
          _vm._m(0),
          _c("div", { staticClass: "card__content overflow-auto" }, [
            _c(
              "ul",
              { staticClass: "switch-list bg-white" },
              _vm._l(_vm.tabs, function(tab) {
                return _c(
                  "li",
                  {
                    key: tab.id,
                    class: [{ active: _vm.selected.id === tab.id }]
                  },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "btn-switch btn ml-10 mr-10",
                        on: {
                          click: function($event) {
                            return _vm.changePriority(tab)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(tab.title) + " ")]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ]),
        _c(_vm.selected.component, { tag: "component" })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "fs18 fw500  d-block" }, [
        _vm._v(" Bulk & API Booking Settings ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }