 <template>
	<div class="ndr-sidebar">
		<b-sidebar
		id="sidebarFilters"
		right
		backdrop
		backdrop-variant="dark"
		sidebar-class="filter-side"
		no-header
		shadow
		v-model="sidebarVisible"
		>
		<div class="d-flex flex-column h100vh px-36 pb-32 pt-36">
			<div class="header d-flex align-items-center pb-40">
				<i class="icon-filter text-gray-600 fs24 pr-16"></i>
				<h2 class="text-gray-900">Filters</h2>
				<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
					<i class="icon-close-variant"></i>
				</b-button>
			</div>

			<b-form @submit.prevent="applyFilter">
				<b-form-group label-for="type" >
					<multiselect
		            id="channel"
		            v-model="form.sourceName"
		            :options="channels"
		            title="Channel"
		            :searchable="false"
		            :show-labels="false"
		            placeholder="Select Channel"
		            label="name"
		            class="mb-17"
		            @input="addFilter({sourceName: form.sourceName ? form.sourceName.name : ''},'sourceName')"
		          ></multiselect>
				</b-form-group>
				<b-form-group label-for="pincodeInput">
					<b-input
					id="pincodeInput"
					title="Search by Pincode"
					v-model="form.pincode"
					@input="addFilter({pincode:form.pincode})"
					class="mb-17"
					placeholder="Type Customer Pincode"
					></b-input>
				</b-form-group>
				<b-form-group label-for="contactInput">
					<b-input
					id="contactInput"
					title="Search by Contact"
					v-model="form.contact"
					@input="addFilter({contact:form.contact})"
					class="mb-17"
					placeholder="Type Customer Contact"
					></b-input>
				</b-form-group>
				<div class="text-right filter-div">
					<b-link
					v-if="hasFilters"
					size="md"
					class="secondary fw500 mr-20"
					@click="clearFilters()"
					>
					Clear filters
					</b-link>
					<b-button
					:disabled="!hasFilters"
					variant="primary"
					type="submit"
					class="ml-5"
					>
					Apply filter
					</b-button>
				</div>
			</b-form>

</div>
</b-sidebar>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
	name: "CustomerBookFilter",
	props: {
		filters: Array,
	},
	data() {
		return {
			date: "",
			sidebarVisible: false,
			selectedFilter: 1,
			selectedFilters: this.$store.getters.filters.customerbook || {},
			carriers: this.$store.getters.carriers.map(item => item.name),
			form:{},
			channels:[]
		};
	},
	components: {
		DatePicker,
		Multiselect,
	},
	computed: {
		hasFilters() {
			if (Object.keys(this.selectedFilters).length>0) {
				return true;
			} else {
				return false;
			}
		},
		...mapState(["modes"])
	},
	async created() {
		let form = Object.assign({}, this.$store.getters.filters.customerbook)
		if(form.sourceName) {
			form.sourceName = this.$store.getters.channels.find(i => i.name == form.sourceName)
		}
		this.form = form

		const res = await this.axios.get('/statics/channels')
		const obj = {
			name:"Manually Created",
			value:100
		}

		this.channels = res.data.filter(i => i.name != '')
		this.channels.unshift(obj)
		// this.channels = this.channels.push({name:"Manually Created",value:100})

	},
	methods: {
		applyFilter(event) {
			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'customerbook': this.selectedFilters})
 			this.$parent.getFilters()
		},


		addFilter(filter) {
			this.selectedFilters = {...this.selectedFilters, ...filter};
			if(Object.values(filter)[0]) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}else {
				let temp = this.selectedFilters
				delete temp[Object.keys(filter)[0]]
				this.selectedFilters = Object.assign({}, temp)
			}
		},
		clearFilters() {
			let filter = this.form
			this.form = {};
			this.selectedFilters = [];
			this.$store.commit('setFilter', {})
			if(Object.keys(filter).length>0) {
 				this.$parent.clearFilters();
 			}
 			this.selectedFilters = {}
			// if (this.selectedFilters.length > 0) {
			// 	this.$parent.clearFilters();
			// } 
		},
	},
	mounted() {
	}
};
</script>

<style scoped lang="scss">
.ndr-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	z-index: 10;
	.elem-outer {
		z-index: 10;
	}
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		position: fixed;
		top: rem(85px);
		right: rem(20px);
		z-index: 10;
	}
	input.multiselect__input{
		height:auto;
		padding:0;
	}
}

.filter-div {
	position: absolute;
	bottom: 5rem;
	right: 2rem;
}
</style>
