<template>
	<b-modal
	id="amazon-connector-modal"
	modal-class="amazon-connector-modal"
	size="lg"
	hide-footer
	v-model="isOpen"
	ref="amazonConnector"
	>
	<template v-slot:modal-header="{ close }">
		<span class="d-block mt-auto fs18 lh28 fw500">
			Amazon Credentials
		</span>
		<b-button variant="rounded" @click="close()">
			<i class="icon-close-variant"></i>
		</b-button>
	</template>
	<div class="mw644 mx-auto">
      <!-- <div class="row">
        <div
          class="col pt-30 pb-16 d-flex justify-content-between align-items-center"
        >
          <span class="fs16 fw500">
            1. Visit
            <b-link href="https://sellercentral.amazon.in/apps/store/dp/amzn1.sellerapps.app.bc94d92d-7f66-46d1-b867-836cc6668ee7" class="fs16">
              Amazon MWS
              <i class="icon-arrow-long ml-8 fs12"></i>
            </b-link>
          </span>
        </div>
    </div> -->

    <div class="row">
    	<div class="col pt-30 py-16">

    		<span class="d-block pt-12 text-gray-600">
						<strong>1.</strong> Click here to 
						<b-link  @click="connectAmazon($store.getters.user.id)" class="fs16">
							Install Amazon App
							<i class="icon-arrow-long ml-8 fs12"></i>
						</b-link>
					</span>
    	</div>
    </div>

    <div class="row">
    	<div class="col py-16">
    		<span class="d-block pt-12 text-gray-600">
    			<strong>2.</strong> You will get redirected to Authorize Shyplite Page. Click on Login to Shyplite now button.
    		</span>
    	</div>
    </div>

    <!-- <div v-if="hover" style="position: absolute; z-index: 999; margin-top: 1rem; margin-left: 25rem; border: 1px solid rgb(207, 207, 207);" id="authDeveloper">
    	<img src="https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/images/authDeveloper.png">
    </div> -->

    <div class="row">
    	<div class="col py-16">
    		<span class="d-block pt-12 text-gray-600">
    			<strong>3.</strong> After providing authorization, you will get redirected back to Shyplite App.
    		</span>
    	</div>
    </div>

<!-- <div v-if="hover1" style="position: absolute; z-index: 999; margin-top: 1rem; margin-left: 15rem; border: 1px solid rgb(207, 207, 207);" id="authDeveloper">
	<img src="https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/images/keyentry.png">
</div> -->

<!-- <div class="row">
	<div class="col">

		<span class="d-block pt-12 text-gray-600">
			<strong>4.</strong> Please copy and post the available credentials below :
		</span>
	</div>
</div> -->

<div class="row">
				<div class="col py-16">
					<span class="d-block text-gray-600">
						<strong>Note</strong> : During installation you may be prompted to login to your Amazon Seller account.
					</span>
				</div>
			</div>

<!-- <div class="row" v-if="showclickAmazon">
    	<div class="col pt-30 py-16">
    		Click to connect Amazon <b-button class="ml-10" variant="tertiary" @click="connectAmazon($store.getters.user.id)">Click</b-button>
    	</div>
    </div>
 -->
<b-form @submit.prevent="amazonConnect()" class="py-16" v-if="editId != undefined">

	<!-- <div class="row py-16">
		<div class="col-md-6 pt-8">
			<b-form-group
			id="seller-id-field"
			label="Seller ID"
			label-for="seller-id"
			>
			<b-form-input
			:disabled="sellerDisable"
			id="seller-id"
			required
			v-model="form.amzSellerId"
			placeholder="123 456 7890"
			></b-form-input>
			</b-form-group>
		</div>
		<div class="col-md-6 pt-8">
			<b-form-group
			id="mws-token-field"
			label="MWS Authorisation Token"
			label-for="mws-token"
			>
				<b-form-input
				required
				id="mws-token"
				v-model="form.authtoken"
				placeholder="MWS Auth Token"
				></b-form-input>
			</b-form-group>
		</div>
	</div> -->

	<div class="row">
		<div class="col-md-6">
			<b-form-group
			id="seller-id-field"
			label="Store Name"
			label-for="seller-id"
			>
			<b-form-input
			id="seller-id"
			v-model="form.storeName"
			placeholder="Store Name"
			></b-form-input>
			</b-form-group>
		</div>
	</div>

<div class="row">
	<div class="col-md-6 py-16">
		<b-form-group label="Get Orders">
			<b-form-radio name="get-orders" v-model="form.isactive" value="3" class="d-inline-block mr-20">
				Enable
			</b-form-radio>
			<b-form-radio name="get-orders" v-model="form.isactive" value="0" class="d-inline-block">
				Disable
			</b-form-radio>
		</b-form-group>
	</div>
	<div class="col-md-6 py-16">
		<b-form-group label="Auto Fullfilment">
			<b-form-radio name="auto-fullfilment" v-model="form.autoFullFill" :value="1" class="d-inline-block mr-20">
				On
			</b-form-radio>
			<b-form-radio name="auto-fullfilment" v-model="form.autoFullFill" :value="0" class="d-inline-block">
				Off
			</b-form-radio>
		</b-form-group>
	</div>
</div>

<div class="row">
	<div class="col pt-12 pb-8 d-none d-md-block">
		
		<b-button v-if="sellerDisable" type="submit" variant="primary mr-16" size="lg">
			<b-spinner v-if="updating"></b-spinner>
			Update
		</b-button>
		<!-- <b-button v-else type="submit" variant="primary mr-16" size="lg">
			<b-spinner v-if="updating"></b-spinner>
			Create
		</b-button> -->
		<!-- <b-button @click="hideModal" size="lg">Cancel</b-button> -->
	</div>
	<div class="col pt-12 pb-8 d-flex d-md-none">
		
		<b-button @click="hideModal" size="lg">Cancel</b-button>
		<b-button v-if="sellerDisable"  type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			<b-spinner v-if="updating"></b-spinner>
			Update
		</b-button>
		<b-button v-else type="submit" variant="primary" size="lg" class="ml-16 w-sm-100">
			<b-spinner v-if="updating"></b-spinner>
			Create
		</b-button>
	</div>
</div>

</b-form>


</div>
</b-modal>
</template>

<script>
export default {
	name: "SettingsAmazonConnector",
	props: {
		editId: Number
	},
	data() {
		return {
			updating:false,
			isOpen: this.modalOpen,
			hover:false,
			hover1:false,
			form:{
				isactive:"3"
			},
			sellerDisable:false,
			showclickAmazon : false,
			sellerIds:[165,168]
		};
	},
	created() {
		if (this.sellerIds.includes(this.$store.getters.user.id)) {
			this.showclickAmazon = true
		} else {
			this.showclickAmazon = false
		}
	},
	methods: {
		async connectAmazon (sellerid) {
			try {
				const res = await this.axios.get(`/v3/spapi?sellerID=${sellerid}`)
				if (res.data.success) {
					window.open(res.data.path)
				}
			} catch(e) {
				// statements
				console.log(e);
			}
		},
		async amazonConnect() {
			this.updating = true
			if(this.form.id) {
				try {
					const editres = await this.axios.post('/settings/connectors/amazon', this.form)
					if (editres.data.success) {
						this.popToast("booked", "Success!", editres.data.message);
						this.updating = false
						setTimeout(() => {
							this.$router.go()
						},1000)
					} else {
						this.updating = false
						this.popToast("failed", "Failed!", editres.data.error);
					}
				} catch(e) {
					// statements
					this.updating = false
					console.log(e);
					this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
				}

			} else {
				try {
					const res = await this.axios.put('/settings/connectors/amazon',this.form)
					if (res.data.success) {
						this.popToast("booked", "Success!", res.data.message);
						this.updating = false
						setTimeout(() => {
							this.$router.go()
						},1000)
					} else {
						this.updating = false
						this.popToast("failed", "Failed!", res.data.error);
					}
				} catch(e) {
					this.popToast("failed", "Failed!", "Data fetching Failed!! Please Try again Later")
					this.updating = false
					console.log(e);
			}

		}
	},
	hideModal() {
		this.$bvModal.hide("amazon-connector-modal");
		this.form = {}
	},
	async formatting(id) {
		try {
			const res = await this.axios.get(`/settings/connector/${id}`)
			const editConn = {
				id:res.data.result[0].id,
				amzSellerId:res.data.result[0].key4,
				authtoken:res.data.result[0].key5,
				isactive:res.data.result[0].isactive,
				autoFullFill:res.data.result[0].autoFullFill,
				storeName:res.data.result[0].storeName
			}

			this.form = editConn
		} catch(e) {
				// statements
				console.log(e);
			}
		}
	},
	watch:{
		isOpen(newValue) {
			if(newValue) {
				let amazon = false

				this.$root.$on('amazon', data => {
					amazon = data
				});

				if (this.editId) {
					this.formatting(this.editId)
					this.sellerDisable = true
				} else if (amazon) {
					this.form = {
						isactive:"3",
						autoFullFill:"1"
					}
					this.sellerDisable = false
				} else {
					this.form = {
						isactive:"3",
						autoFullFill:"1"
					}
					this.sellerDisable = false
				}
			} else {
				this.$emit('update:editId', undefined)
			}
		}
	}
};
</script>

<style lang="scss">

</style>
