var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid position-relative" },
    [
      !_vm.filterResultsToggle
        ? _c("transition", { attrs: { name: "slide-left" } }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex pb-22 align-items-center justify-content-between"
              },
              [
                _c("span", { staticClass: "fw700 pb-md-0" }),
                _c(
                  "span",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.left",
                            modifiers: { hover: true, left: true }
                          }
                        ],
                        attrs: { variant: "primary" },
                        on: { click: _vm.addNewUser }
                      },
                      [_vm._v(" Add New Customer")]
                    ),
                    _c("div", {
                      staticClass: "divider mx-20 my-auto vam d-md-flex"
                    }),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.sidebarFilters",
                            modifiers: { sidebarFilters: true }
                          }
                        ],
                        staticClass: "ndr-side-btn text-gray-900"
                      },
                      [
                        _c("span", [_vm._v(" Filters ")]),
                        _c("i", {
                          staticClass: "icon-filter text-gray-600 pl-10"
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.filterResultsToggle
          ? _c(
              "div",
              {
                staticClass:
                  "col pb-22 pt-md-20 pt-20 d-flex align-items-center justify-content-between"
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    this.selFilters.length != "0"
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "d-none d-md-flex mr-6 text-gray-600 wsnw"
                          },
                          [_vm._v(" Results for: ")]
                        )
                      : _vm._e(),
                    _c(
                      "ul",
                      { staticClass: "d-none d-md-flex result-list" },
                      _vm._l(_vm.selFilters, function(selFilter, index) {
                        return _c(
                          "li",
                          { key: index },
                          [
                            index != "filtering" && selFilter != ""
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mx-4 my-1",
                                    attrs: { variant: "tag" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "wsnw text-truncate" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              typeof selFilter == "number"
                                                ? _vm.modeTypes[selFilter]
                                                : selFilter
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "icon-close fs6",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFilter(index)
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    this.selFilters.length != "0"
                      ? _c("span", { staticClass: "d-flex d-md-none" }, [
                          _vm._v(
                            " " +
                              _vm._s(this.selFilters.length) +
                              " Filters selected. "
                          )
                        ])
                      : _c("span", { staticClass: "d-flex d-md-none" }, [
                          _vm._v(" No filters selected. ")
                        ]),
                    _c(
                      "b-link",
                      {
                        staticClass: "ml-24 mr-12 vam",
                        on: {
                          click: function($event) {
                            return _vm.clearFilters()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "text-gray-900 wsnw" }, [
                          _vm._v("Clear filters")
                        ]),
                        _c("i", {
                          staticClass:
                            "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle.sidebarFilters",
                            modifiers: { sidebarFilters: true }
                          }
                        ],
                        staticClass: "ndr-side-btn text-gray-900"
                      },
                      [
                        _c("span", [_vm._v(" Filters ")]),
                        _c("i", {
                          staticClass: "icon-filter text-gray-600 pl-10"
                        })
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "shipments-table table-sm-cards",
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  "sticky-header": "",
                  "no-border-collapse": "",
                  id: "manifests-table"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(id)",
                      fn: function(data) {
                        return [_vm._v(" " + _vm._s(data.item.id) + " ")]
                      }
                    },
                    {
                      key: "cell(email)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "text-truncate" }, [
                            _vm._v(_vm._s(_vm.ellipsize(data.item.email)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(address)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "text-truncate" }, [
                            _vm._v(_vm._s(_vm.ellipsize(data.item.address)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(city)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "text-truncate" }, [
                            _vm._v(_vm._s(_vm.ellipsize(data.item.city)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(action)",
                      fn: function(data) {
                        return [
                          _c("multiselect", {
                            staticClass: "h36-select w120px mx-auto",
                            attrs: {
                              id: "action",
                              options: _vm.actionOptions,
                              searchable: false,
                              "show-labels": false,
                              placeholder: "- Select -",
                              "track-by": "name",
                              label: "name"
                            },
                            on: {
                              input: function($event) {
                                return _vm.goNeworder(
                                  data.item.selected,
                                  data.item
                                )
                              }
                            },
                            model: {
                              value: data.item.selected,
                              callback: function($$v) {
                                _vm.$set(data.item, "selected", $$v)
                              },
                              expression: "data.item.selected"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3835721442
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.items, function(item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between w-100" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "d-block fw500 pb-8 cp",
                        on: {
                          click: function($event) {
                            return _vm.goShipment(item.id)
                          }
                        }
                      },
                      [_vm._v(" Customer : " + _vm._s(item.name) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-end justify-content-between"
                      },
                      [
                        _c("multiselect", {
                          staticClass: "h36-select w120px mx-auto",
                          attrs: {
                            id: "action",
                            options: _vm.actionOptions,
                            searchable: false,
                            "show-labels": false,
                            placeholder: "- Select -",
                            "track-by": "name",
                            label: "name"
                          },
                          on: {
                            input: function($event) {
                              return _vm.goNeworder(item.selected, item)
                            }
                          },
                          model: {
                            value: item.selected,
                            callback: function($$v) {
                              _vm.$set(item, "selected", $$v)
                            },
                            expression: "item.selected"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-end justify-content-between w-100 mb-12"
                  },
                  [
                    _c("div", { staticClass: "info" }, [
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(" " + _vm._s(item.email) + " ")
                      ]),
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(" " + _vm._s(item.contact) + " ")
                      ]),
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(_vm._s(item.city))
                      ])
                    ])
                  ]
                ),
                _c("span", [_vm._v(" " + _vm._s(item.address) + " ")]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex mt-5 mt-md-5 w-100 align-items-end justify-content-between"
                  },
                  [
                    _c("span", { staticClass: "fs12 lh16" }, [
                      _vm._v(" " + _vm._s(item.pincode) + " ")
                    ]),
                    _c("span", { staticClass: "tag" }, [
                      _vm._v(" " + _vm._s(item.sourceName) + " ")
                    ])
                  ]
                )
              ])
            }),
            0
          ),
      _c("div", { staticClass: "pagination-bar" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c(
                "div",
                { staticClass: "d-inline-flex wsnw align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-block w50px" },
                    [
                      _c("multiselect", {
                        staticClass: "pagination-items__pp",
                        attrs: {
                          searchable: false,
                          "show-labels": false,
                          "allow-empty": false,
                          options: _vm.pageOptions
                        },
                        on: { input: _vm.setPageNumber },
                        model: {
                          value: _vm.perPage,
                          callback: function($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "pl-8 fs12 lh24 text-gray-600 pagination-items"
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "col-8 text-right" }, [
              _c(
                "div",
                {
                  staticClass: "d-inline-flex ml-auto wsnw align-items-center"
                },
                [
                  _c("p", { staticClass: "fs12 lh24 text-gray-600 pr-14" }, [
                    _vm._v(" Page: " + _vm._s(_vm.currentPage) + " ")
                  ]),
                  _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.prevDisabled,
                            variant: "paginate left"
                          },
                          on: { click: _vm.prevPage }
                        },
                        [_c("i", { staticClass: "icon-back fs12" })]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.nextDisabled,
                            variant: "paginate right"
                          },
                          on: { click: _vm.nextPage }
                        },
                        [_c("i", { staticClass: "icon-arrow fs12" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm.items.length == 0 && !_vm.isBusy ? _c("ResultNotFound") : _vm._e(),
      _c("CustomerBookFilter", { ref: "filter" }),
      _c("AddNewCustomer", { ref: "addNewCustomer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }