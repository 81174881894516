 <template>
	<div class="ndr-sidebar">
		<b-sidebar
		id="sidebarFilters"
		right
		backdrop
		backdrop-variant="dark"
		sidebar-class="filter-side"
		no-header
		shadow
		v-model="sidebarVisible"
		>
		<div class="d-flex flex-column h100vh px-36 pb-32 pt-36">
			<div class="header d-flex align-items-center pb-40">
				<i class="icon-filter text-gray-600 fs24 pr-16"></i>
				<h2 class="text-gray-900">Filters</h2>
				<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
					<i class="icon-close-variant"></i>
				</b-button>
			</div>

			<b-form @submit.prevent="applyFilter">
				<b-form-group label-for="contactInput">
					<b-input
					id="contactInput"
					title="Search by Contact"
					v-model="form.phone"
					@input="addFilter({phone:form.phone})"
					class="mb-17"
					placeholder="Consignor Contact"
					></b-input>
				</b-form-group>
				<multiselect
		            id="type"
		            v-model="form.csbType"
		            title="Custom Type"
		            :options="types"
		            :searchable="false"
		            :show-labels="false"
		            placeholder="Select Custom Type"
		            class="mb-17"
		            @input="addFilter({csbType: form.csbType})"
		          ></multiselect>
	          	<multiselect
		            id="type"
		            v-model="form.status"
		            title="Status"
		            :options="status"
		            :searchable="false"
		            :show-labels="false"
		            placeholder="Select Status"
		            class="mb-17"
		            @input="addFilter({status: form.status})"
		          ></multiselect>
				<div class="text-right filter-div">
					<b-link
					v-if="hasFilters"
					size="md"
					class="secondary fw500 mr-20"
					@click="clearFilters()"
					>
					Clear filters
					</b-link>
					<b-button
					:disabled="!hasFilters"
					variant="primary"
					type="submit"
					class="ml-5"
					>
					Apply filter
					</b-button>
				</div>
			</b-form>

</div>
</b-sidebar>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
	name: "ConsignorFilter",
	props: {
		filters: Array,
	},
	data() {
		return {
			date: "",
			sidebarVisible: false,
			selectedFilter: 1,
			types:["CSB IV","CSB V"],
			status:["Active","Inactive"],
			selectedFilters: this.$store.getters.filters.consignor || {},
			form:{},
		};
	},
	components: {
		DatePicker,
		Multiselect,
	},
	computed: {
		hasFilters() {
			if (Object.keys(this.selectedFilters).length>0) {
				return true;
			} else {
				return false;
			}
		},
	},
	async created() {
		let form = Object.assign({}, this.$store.getters.filters.consignor)
		if(form.csbType) {
			form.csbType = this.types.find(i => i == form.csbType)
		}
		if(form.status) {
			form.status = this.status.find(i => i == form.status)
		}
		this.form = form

		// this.channels = this.channels.push({name:"Manually Created",value:100})

	},
	methods: {
		applyFilter(event) {
			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'consignor': this.selectedFilters})
 			this.$parent.getFilters()
		},


		addFilter(filter) {
			this.selectedFilters = {...this.selectedFilters, ...filter};
			if(Object.values(filter)[0]) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}else {
				let temp = this.selectedFilters
				delete temp[Object.keys(filter)[0]]
				this.selectedFilters = Object.assign({}, temp)
			}
		},
		clearFilters() {
			let filter = this.form
			this.form = {};
			this.selectedFilters = [];
			this.$store.commit('setFilter', {})
			if(Object.keys(filter).length>0) {
 				this.$parent.clearFilters();
 			}
 			this.selectedFilters = {}
			// if (this.selectedFilters.length > 0) {
			// 	this.$parent.clearFilters();
			// } 
		},
	}
};
</script>

<style scoped lang="scss">
.ndr-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	z-index: 10;
	.elem-outer {
		z-index: 10;
	}
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		position: fixed;
		top: rem(85px);
		right: rem(20px);
		z-index: 10;
	}
	input.multiselect__input{
		height:auto;
		padding:0;
	}
}

.filter-div {
	position: absolute;
	bottom: 5rem;
	right: 2rem;
}
</style>
