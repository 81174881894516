<template>
  <div class="container-fluid pb-80 pb-md-0">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card__title">
            <div class="d-flex align-items-center">
              <span class="fs20 lh24 fw500">
                Shyplite API
              </span>
            </div>
            <div class="ml-auto">
              <span class="vam fs16 lh24 text-gray-900 mr-12">
                {{ api.status == 'enable'? 'Enabled' : 'Disabled'}}
              </span>
              <b-form-group class="d-inline mb-0">
                <b-form-checkbox
                  v-model="api.status"
                  value="enable"
                  unchecked-value="disable"
                  class="d-inline"
                  name="default-pickup"
                  switch
                  @change="createPublicKey($event,'default')"
                ></b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="card__content">
            <p class="mb-22">
              The Shyplite API is a powerful way to expand and automate your
              online business.
            </p>
            <span>
              You can read the documentation
              <b-link href="https://app.shyplite.com/api" target="_blank" class="fs14 lh16"> here</b-link>
            </span>
          </div>
        </div>
        <div class="card mt-4" v-if="api.status == 'enable'">
          <div class="card__content">
            <div class="row">
              <div class="col d-flex">
                <div class="d-flex align-items-center">
                  <span class="fs20 lh24 fw500">
                    Your API Credentials
                  </span>
                </div>
                <div class="ml-auto">
                  <b-button @click="createPublicKey($event,'refresh')" value="refresh" variant="tertiary">
                    <i class="icon-refresh fs12 mr-12"></i>
                    Regenerate
                  </b-button>
                </div>
              </div>
            </div>
            <div class="row pt-22 pb-14">
              <div class="col-md-auto">
                <span class="d-block my-4">
                  <strong>Seller ID:</strong>
                  <span
                    class="d-inline-block px-12 py-10 ml-8 bg-orange-light rounded cp"
                    @click="copySuccess"
                    v-clipboard:copy="user.id"
                    v-b-tooltip.hover.right
                    title="Click to copy!"
                  >
                    {{user.id}}
                  </span>
                </span>
              </div>
              <div class="col-md-auto">
                <span class="d-block my-4">
                  <strong>Public Key :</strong>
                  <span
                    class="d-inline-block px-12 py-10 ml-8 bg-orange-light rounded cp"
                    @click="copySuccess"
                    v-clipboard:copy="api.key"
                    v-b-tooltip.hover.right
                    title="Click to copy!"
                  >
                    {{ api.key }}
                  </span>
                </span>
              </div>
              <div class="col-md-auto">
                <span class="d-block my-4">
                  <strong>App's ID :</strong>
                  <span
                    class="d-inline-block px-12 py-10 ml-8 bg-orange-light rounded cp"
                    @click="copySuccess"
                    v-clipboard:copy="api.appID"
                    v-b-tooltip.hover.right
                    title="Click to copy!"
                  >
                    {{ api.appID }}
                  </span>
                </span>
              </div>
            </div>
            <div class="row" v-if="showPrivateKey">
              <div class="col-md-auto">
                <span class="d-block my-4">
                  <strong>Private Key :</strong>
                  <span
                    class="d-inline-block px-12 py-10 mt-10 bg-orange-light rounded cp"
                    @click="copySuccess"
                    v-clipboard:copy="privateKey"
                    v-b-tooltip.hover.right
                    title="Click to copy!"
                  >
                    {{ privateKey }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SettingsApi",
  data() {
    return {
      apiEnabled: "enable",
      appId: "",
      publicKey: "",
      privateKey:"",
      showPrivateKey:false,
      api: this.$store.state.apiCredentials
    };
  },
  created() {
    if(this.$store.getters.user.childRole == 'OPERATIONS' || this.$store.getters.user.childRole == 'FINANCE') {
      this.$router.push('/unauthorized')
    }
  },
  methods: {
    copySuccess(){
      this.popToast("booked", "Success", "Successfully copied to clipboard!");
    },
     async createPublicKey(value,type){
      
      if (type == 'refresh') {
        try {
          const response = await this.axios.post('/settings/api/regenerate')
          this.api.key = response.data.status
          this.privateKey = response.data.secret
          this.showPrivateKey = true
          this.$store.commit('setAPICred', this.api);
          this.popToast("booked", "Success!", "Public and Private Key are regenerated Successfully");
        } catch(e) {
          // statements
          console.log(e);
        }
        // statement
      } else {
          try {
          const response = await this.axios.post(`settings/api/${value}`)
          if (value == 'enable') {
            this.api = response.data
            this.api.status = 'enable'
            this.privateKey = response.data.secret
            delete this.api.secret
            this.showPrivateKey = true
            this.popToast("booked", "Success!", "API access has been enabled on your account.");
          } else {
            this.api = {}
            this.api.status = 'disable'
            this.popToast("booked", "Success!", "API access has been disabled on your account.");
          }
          this.$store.commit('setAPICred', this.api);
        } catch(e) {
          // statements
          console.log(e);
        }
      }
    }
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
