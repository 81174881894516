var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "amazonConnector",
      attrs: {
        id: "amazon-connector-modal",
        "modal-class": "amazon-connector-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "d-block mt-auto fs18 lh28 fw500" }, [
                _vm._v(" Zoho Credentials ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "mw644 mx-auto" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-30" }, [
              _c(
                "span",
                { staticClass: "d-block pt-12 text-gray-600" },
                [
                  _c("strong", [_vm._v("1.")]),
                  _vm._v(" To Generate "),
                  _c("strong", [_vm._v("clientID")]),
                  _vm._v(" and "),
                  _c("strong", [_vm._v("clientSecret")]),
                  _vm._v(", "),
                  _c(
                    "b-link",
                    {
                      staticClass: "fs16",
                      attrs: {
                        target: "_blank",
                        href: "https://accounts.zoho.com/developerconsole"
                      }
                    },
                    [_vm._v(" Click Here")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-10" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("2.")]),
                _vm._v(" Now choose "),
                _c("strong", [_vm._v(" Add Client.")])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-10" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("3.")]),
                _vm._v(" Choose Server-based Application and click "),
                _c("strong", [_vm._v("Create Now.")])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-10" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("4.")]),
                _vm._v(" Fill the "),
                _c("strong", [_vm._v("Client Name")]),
                _vm._v(", "),
                _c("strong", [_vm._v("Homepage URL")]),
                _vm._v(" and "),
                _c("strong", [_vm._v("Authorized Redirect URIs")]),
                _vm._v(" as per details below: ")
              ]),
              _c("ul", { staticClass: "pt-10 text-gray-600" }, [
                _c("li", [
                  _c("strong", [_vm._v("Client Name : ")]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass:
                        "cp underline-dashed text-gray-800 text-decoration-none",
                      attrs: { title: "Click to copy!" },
                      on: {
                        click: function($event) {
                          return _vm.copySuccess("shyplite")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.shyplite) + " ")]
                  )
                ]),
                _c("li", [
                  _c("strong", [_vm._v("StHomepage URL : ")]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass:
                        "cp underline-dashed text-gray-800 text-decoration-none",
                      attrs: { title: "Click to copy!" },
                      on: {
                        click: function($event) {
                          return _vm.copySuccess("homeUrl")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.homeUrl) + " ")]
                  )
                ]),
                _c("li", [
                  _c("strong", [_vm._v("Authorized Redirect URIs : ")]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true }
                        }
                      ],
                      staticClass:
                        "cp underline-dashed text-gray-800 text-decoration-none",
                      attrs: { title: "Click to copy!" },
                      on: {
                        click: function($event) {
                          return _vm.copySuccess("callback")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.callback) + " ")]
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-10" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("5.")]),
                _vm._v(" Now Click on the "),
                _c("strong", [_vm._v("CREATE")]),
                _vm._v(", And this will generate clientID and clientSecret. ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-10" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("6.")]),
                _vm._v(
                  " The above details should be added in shyplite connector zoho and click "
                ),
                _c("strong", [_vm._v("CREATE")]),
                _vm._v(". ")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col pt-10" }, [
              _c("span", { staticClass: "d-block pt-12 text-gray-600" }, [
                _c("strong", [_vm._v("7.")]),
                _vm._v(" And finally click "),
                _c("strong", [_vm._v("VERIFY")]),
                _vm._v(
                  ", It will redirect to zoho and you are done with integration. "
                )
              ])
            ])
          ]),
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.zohoConnect()
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-15" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "seller-id-field",
                          label: "Store Name",
                          "label-for": "seller-id"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: { id: "seller-id", placeholder: "Store Name" },
                          model: {
                            value: _vm.form.storeName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "storeName", $$v)
                            },
                            expression: "form.storeName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-5" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "seller-id-field",
                          label: "Client ID",
                          "label-for": "seller-id"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "seller-id",
                            required: "",
                            placeholder: "clientID"
                          },
                          model: {
                            value: _vm.form.clientID,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "clientID", $$v)
                            },
                            expression: "form.clientID"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6 pt-5" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "mws-token-field",
                          label: "Client Secret",
                          "label-for": "mws-token"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            required: "",
                            id: "mws-token",
                            placeholder: "clientSecret"
                          },
                          model: {
                            value: _vm.form.clientSecret,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "clientSecret", $$v)
                            },
                            expression: "form.clientSecret"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 py-16" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Get Orders" } },
                      [
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block mr-20",
                            attrs: { name: "get-orders", value: "1" },
                            model: {
                              value: _vm.form.isactive,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isactive", $$v)
                              },
                              expression: "form.isactive"
                            }
                          },
                          [_vm._v(" Enable ")]
                        ),
                        _c(
                          "b-form-radio",
                          {
                            staticClass: "d-inline-block",
                            attrs: { name: "get-orders", value: "0" },
                            model: {
                              value: _vm.form.isactive,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isactive", $$v)
                              },
                              expression: "form.isactive"
                            }
                          },
                          [_vm._v(" Disable ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col pt-12 pb-8 d-none d-md-block" },
                  [
                    _vm.sellerDisable
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.verifyURL,
                              variant: "primary mr-16",
                              size: "lg"
                            }
                          },
                          [_vm._v(" Verify ")]
                        )
                      : _vm._e(),
                    _vm.sellerDisable
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary mr-16",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Update ")
                          ],
                          1
                        )
                      : _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary mr-16",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Create ")
                          ],
                          1
                        ),
                    _c(
                      "b-button",
                      { attrs: { size: "lg" }, on: { click: _vm.hideModal } },
                      [_vm._v("Cancel")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col pt-12 pb-8 d-flex d-md-none" },
                  [
                    _c(
                      "b-button",
                      { attrs: { size: "lg" }, on: { click: _vm.hideModal } },
                      [_vm._v("Cancel")]
                    ),
                    _vm.sellerDisable
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-16 w-sm-100",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Update ")
                          ],
                          1
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "ml-16 w-sm-100",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.updating ? _c("b-spinner") : _vm._e(),
                            _vm._v(" Create ")
                          ],
                          1
                        )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }